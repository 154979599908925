import React, {useState} from 'react';
import {getExtraGBPostPaidUrl} from "../../../redux/URL";
import axiosInstance from "../../../redux/axiosInstance";
import {connect} from "react-redux";
import LoadingBbBody from "../LoadingBbBody";
import TermsConditons from "../../common/TermsConditons";
import {CATCH_ERROR_MSG} from "../../../redux/Types";


function ExtraGBConfirmPayments({amount, amountUnit, amountPrice, setState, payType, profile, pId}) {

    const [agree, setAgree] = useState(false)
    const [showTerms, setShowTerms] = useState(false);

    const [loading, setLoading] = useState(false)

    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');


    const SuccessAlert = () => {

        return <div className='alertView'>
            <div>
                <div className='title'>Success</div>
               
                <div className='mainText'>
                    <div className="message">Successfully Add {amount}{amountUnit} Extra GB</div>
                </div>
                <div className='actionButton'>
                    <button className='blue p-1 pl-3 pr-3' onClick={() => {
                        setSuccess(false)
                        setState('SelectType')
                    }}>Ok
                    </button>
                </div>
            </div>
        </div>


    }

    const ErrorAlert = () => {

        return <div className='alertView'>
            <div>
                <div className='title'>Error</div>
                <div className='mainText'>
                    <div className="message">{error}</div>
                </div>
                <div className='actionButton'>
                    <button className='blue p-1 pl-3 pr-3' onClick={() => {
                        setError('')
                        setIsError(false)
                    }}>Ok
                    </button>
                </div>
            </div>
        </div>


    }

    const postPaid = () => {
        if (agree) {
            setLoading(true)
            let url = getExtraGBPostPaidUrl + 'subscriberID=' + profile.subscriberid + '&packageId=' + pId + '&commitUse=' + profile.fullname + '&channel=SCP'

            axiosInstance.post(url).then(response => {
                if (response.status === 200 && response.data.isSuccess) {
                    setSuccess(true)
                    setLoading(false)
                } else {
                    setIsError(true)
                    setError(response.data.errorShow)
                    setLoading(false)
                }
            }).catch(() => {
                setIsError(true)
                setError(CATCH_ERROR_MSG)
                setLoading(false)

            })
        } else {

        }
    }

    // const payNow = () => {
    //     if (agree) {
    //         setLoading(true)
    //         let url = PaymentInitUrl + '?ContactNumber=' + profile.phone + '&CustEmail=' + profile.email + '&subscriberID=' + profile.subscriberid +
    //             '&prepaidID=' + 'EGB' + '&reciever=' + '' + '&packageId=' + pId + '&channel=' + 'SLTPRE' + '&commitUser=' + 'Omni' + '&reporterPackage=' + '' + '&activatedBy=' + ''
    //         return url
    //         // axiosInstance.post(url).then((response) => {
    //         //     console.log(response)
    //         //     if (response.status === 200) {
    //         //         setHtml(response.data)
    //         //         setPaymentResponse(true)
    //         //         setLoading(false)
    //         //
    //         //     } else {
    //         //         setLoading(false)
    //         //         setError(response.data.errorShow)
    //         //     }
    //         // }).catch(Onerror => {
    //         //     setLoading(false)
    //         //     setError('Failed')
    //         // })
    //     }
    // }


    if (loading) {
        return <div className='paymentBody common-panel'>
            <div className='body-container'>
                <div className='title'>Confirm Payment</div>
            </div>
            <LoadingBbBody loading={loading} border={false}/>
        </div>
    }
    if (showTerms)
        return <div className='paymentBody common-panel'>
            <TermsConditons setShowTerms={setShowTerms} setAgree={setAgree}/>
        </div>
    else {
        return (
            <div className='paymentBody common-panel'>
                {success ? SuccessAlert() : null}
                {isError ? ErrorAlert() : null}

                <div className='body-container'>
                    <div className='title'>Confirm Payment</div>
                    <div className='wrapper'>
                        <div className='confirmPaymentBody'>
                            <div className='gbAmount'>{amount}{amountUnit}</div>
                            <div className='price'>Rs.{amountPrice} + Tax</div>

                            <div className='agreeCondition'>
                                <input type='checkbox' className='sign' checked={agree}
                                       onChange={(e) => setAgree(e.target.checked)}/>
                                <label className=''>I agree to the general <strong
                                    onClick={() => setShowTerms(true)}> terms and conditions</strong> </label>
                            </div>

                            <div className='actionButton'>
                                <button onClick={() => setState('SelectType')}>Back</button>
                                {
                                    payType === 'addToBill' ?
                                        <button className='buttonClass' disabled={!agree}
                                                onClick={() => postPaid()}>Submit</button> :
                                        <form action='https://billpay.slt.lk/bbtopup/summaryallAPImyslt.php'
                                              method='POST'>
                                            <input hidden={true} name='ContactNumber' value={profile.phone}/>
                                            <input hidden={true} name='CustEmail' value={profile.email}/>
                                            <input hidden={true} name='subscriberID' value={profile.subscriberid}/>
                                            <input hidden={true} name='prepaidID' value={'EGB'}/>
                                            <input hidden={true} name='reciever' value={''}/>
                                            <input hidden={true} name='packageId' value={pId}/>
                                            <input hidden={true} name='channel' value={'SLTPRE'}/>
                                            <input hidden={true} name='commitUser' value={'Omni'}/>
                                            <input hidden={true} name='reporterPackage' value={''}/>
                                            <input hidden={true} name='activatedBy' value={''}/>
                                            <input hidden={true} name='callbackURLSLT' value={''}/>
                                            <button className='buttonClass' type='submit' disabled={!agree}>Submit
                                            </button>
                                        </form>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = state => (
    {
        loadingProfile: state.broadBand.loadingProfile,
        profile: state.broadBand.profile,
    }
)

export default connect(mapStateToProps, {})(ExtraGBConfirmPayments);
