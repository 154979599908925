import React, { useState } from "react";
import Querystring from "querystring";
import SuccessAlert from "../../../alerts/SuccessAlert";
import ErrorAlert from "../../../alerts/ErrorAlert";
import logo from "../../../../assests/images/Logo.png";
import checkMark from "../../../../assests/images/check_mark.jpg";
import {
  UpdateAgentCode,
  ftthFileUpload,
  updateDraftDetails,
} from "../../../../redux/URL";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import LoadingBbBody from "../../../boardBand/LoadingBbBody";
import { CATCH_ERROR_MSG } from "../../../../redux/Types";
import ProgressBar from "../../../boardBand/fileUploadProgress";
import NewNavBar from "../../NewNavBar";
import Footer from "../../Footer";
import axiosInstanceFtth from "../../../../redux/axiosInstanceFtth";
import headerImage from "../../../../assests/images/header.png";
import footerImage from "../../../../assests/images/footer.png";
import downloadBtn from "../../../../assests/images/download.png";
import homeBtn from "../../../../assests/images/home.png";
import moment from "moment";
import Modal from "react-modal";

Modal.setAppElement("#root");

function FileUploadState({
  savedData,
  error,
  isError,
  setError,
  setIsError,
  success,
  orderStatus,
  setSuccess,
  fetchDraftDetails,
  fetchOrderStatus,
  REF_NO,
  SEQ_NO,
  setAgentCode,
  agentCode,
  setCallAgentCode,
  isAgentCodeSubmited,
  billAddress1,
  setBillAddress1,
  billAddress2,
  setBillAddress2,
  isSameAsServiceAddress,
  setIsSameAsServiceAddress,
  isDraftDetailsUpdate,
  setIsDraftDetailsUpdate,
  isBillAddressExist,
  bithday,
}) {
  const [loading, setLoading] = useState(false);
  const [nicFront, setNicFront] = useState(null);
  const [nicBack, setNicBack] = useState(null);

  const formatRequestDate = (dateString) => {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Extract the parts of the date
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as MM/DD/YYYY
    return `${month}/${day}/${year}`;
  };

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const [pass, setPass] = useState(null);
  const maxFileSize = 2 * 1024 * 1024;

  function successSave() {
    setSuccess(false);
    fetchOrderStatus();
    fetchDraftDetails();
  }

  function submitLater() {
    window.location.href = "/applyonline";
  }

  function printDocument() {
    if (savedData.nic && (!nicFront || !nicBack)) {
      setError("Please upload all the relevant files");
      setIsError(true);
    } else if (savedData.pp && !pass) {
      setError("Please upload all the relevant files");
      setIsError(true);
    } else if (
      nicFront &&
      nicBack &&
      (nicFront.size > maxFileSize || nicBack.size > maxFileSize)
    ) {
      setError("Maximum file upload size 2MB");
      setIsError(true);
    } else {
      setLoading(true);

      const input = document.getElementById("pdfViewer");

      var title = savedData.title;
      var firstName = savedData.firstname;
      var lastName = savedData.lastname;
      var dob = savedData.birtH_DATE;
      var gender = savedData.gender;
      var nic = savedData.nic ? savedData.nic : savedData.pp;
      var nationality = savedData.nationality;
      var mobile = savedData.mobileno;
      var email = savedData.email;

      var lat = savedData.latitude;
      var lng = savedData.longitude;

      var installationAddress1 = savedData.servicE_ADD_STREET1;
      var installationAddress2 = savedData.servicE_ADD_STREET2;

      var googleLocation = "https://www.google.com/maps/?q=" + lat + "," + lng;
      var googleLocationView = "lat - " + lat + " /long - " + lng;

      var billingAddress1 = savedData.billinG_ADD_STREET1;
      var billingAddress2 = savedData.billinG_ADD_STREET2;

      var existTelAccNo = "";

      var broadbandPackage = savedData.bB_PACKAGE;

      var PeoTvPackage1 = savedData.peO_PACKAGE;
      var PeoTvPackage2 = "";
      var PeoTvPackage3 = "";
      var PeoTvPackage4 = "";

      var voice = "1";
      var broadband = savedData.bB_PACKAGE ? "1" : "0";
      var peoTv = savedData.peO_PACKAGE ? "1" : "0";

      var billType = savedData.billinG_MODE; //post eBill
      var isExistCus = false;
      var activeIdd = savedData.iddstatus === "true";

      var myPhoneYes = "S";
      var myPhoneNo = "S";

      var exCusYes = "S";
      var exCuNo = "F";

      if (isExistCus === true) {
        exCusYes = "F";
        exCuNo = "S";
      }

      var billMode_EBill = "S";
      var billMode_Print = "F";

      if (billType === "eBill") {
        billMode_EBill = "F";
        billMode_Print = "S";
      }

      var activeIddYes = "S";
      var activeIddNo = "F";

      if (activeIdd === true) {
        activeIddYes = "F";
        activeIddNo = "S";
      }

      html2canvas(input)
        .then((canvas) => {
          const pdf = new jsPDF("p", "in", "a4");

          pdf.setFontSize(17);
          pdf.setFont("courier", "bold");
          pdf.text("SLT Application For New Services", 2.4, 0.46);
          pdf.addImage(logo, "JPEG", 0.3, 0.15, 2.0, 0.7);

          pdf.setFontSize(14);
          pdf.setFont("courier", "bold");
          // pdf.text('Registration No : ' + regNo, 2.40, 0.70)

          pdf.setLineWidth(0.01);
          pdf.line(0.3, 1.0, 8.0, 1.0, "S");

          //sub headers
          pdf.text("Customer Information", 0.3, 1.8);
          pdf.text("Contact Details", 0.3, 4.0);
          pdf.text("Service Installation Address", 0.3, 4.7);

          pdf.setFontSize(12);
          pdf.setFont("courier", "normal");

          // First Box Test
          pdf.text("Existing customer", 0.5, 7.0);
          pdf.text("Yes", 2.6, 7.0);
          pdf.text("No", 3.4, 7.0);
          pdf.text("Existing ", 4.1, 6.9);
          pdf.text("Tel/Acc No ", 4.1, 7.1);
          pdf.text(existTelAccNo, 5.3, 7.0);

          pdf.text("Your preferred billing", 0.5, 7.35);
          pdf.text("mode", 0.5, 7.55);
          pdf.text("SLT My Bill ", 2.85, 7.45);
          pdf.text("E Bill ", 4.35, 7.45);
          pdf.text("Printed ", 5.45, 7.35);
          pdf.text("bill ", 5.45, 7.55);

          pdf.setFontSize(14);
          pdf.setFont("courier", "bold");
          pdf.text("Mode of Connection", 0.5, 8.2);

          pdf.setFontSize(12);
          pdf.setFont("courier", "normal");

          pdf.text("Voice(Telephone)", 2.7, 8.45);
          pdf.text("Broadband", 4.55, 8.45);
          pdf.text("Peo TV", 6.25, 8.45);
          pdf.text("SLT Fiber", 1.0, 8.75);

          pdf.text(voice, 3.4, 8.8);
          pdf.text(broadband, 5.0, 8.8);
          pdf.text(peoTv, 6.6, 8.8);

          pdf.setFontSize(14);
          pdf.setFont("courier", "bold");
          pdf.text("Package Details", 0.5, 9.5);

          pdf.setFontSize(12);
          pdf.setFont("courier", "normal");

          pdf.text("Do you want to deactivate IDD ", 0.7, 9.75);
          pdf.text("Yes ", 4.5, 9.75);
          pdf.text("NO ", 5.3, 9.75);

          pdf.text("My Phone ", 0.7, 10.0);
          pdf.text("Yes ", 2.7, 10.0);
          pdf.text("NO ", 3.5, 10.0);

          pdf.text("Broadband Package ", 0.7, 10.3);
          pdf.text(broadbandPackage, 2.75, 10.3);
          pdf.text("Peo TV ", 0.7, 10.6);
          pdf.text("Packages ", 0.7, 10.8);

          pdf.setFontSize(10);

          pdf.text(PeoTvPackage1, 1.75, 10.7); //Peo TV  Packages
          pdf.text(PeoTvPackage2, 3.35, 10.7);
          pdf.text(PeoTvPackage3, 4.95, 10.7);
          pdf.text(PeoTvPackage4, 6.55, 10.7);

          //Check Box
          pdf.setFontSize(12);
          pdf.setFont("courier", "normal");

          pdf.text("Home  ", 2.4, 1.35);
          pdf.text("Office  ", 3.3, 1.35);
          pdf.text("Other  ", 4.4, 1.35);
          pdf.text("CA Number  ", 5.65, 1.35);

          pdf.text("Title  ", 0.3, 2.25);
          pdf.text(title, 2.45, 2.25);
          pdf.text("First Name  ", 0.3, 2.6);
          pdf.text(firstName, 2.45, 2.6);
          pdf.text("Last Name  ", 0.3, 2.95);
          pdf.text(lastName, 2.45, 2.95);
          pdf.text("Date of Birth  ", 0.3, 3.3);
          pdf.text(dob, 2.45, 3.3);
          pdf.text("Gender  ", 4.0, 3.3);
          pdf.text(gender, 5.3, 3.3);
          pdf.text("NIC / Passport ", 0.3, 3.65);
          pdf.text(nic, 2.45, 3.65);
          pdf.text("Nationality  ", 4.0, 3.65);
          pdf.text(nationality, 5.3, 3.65);

          pdf.text("Mobile  ", 0.3, 4.35);
          pdf.text(mobile, 2.45, 4.35);
          pdf.text("Email  ", 4.0, 4.35);
          pdf.text(email, 5.0, 4.35);

          pdf.text("Installation Address  ", 0.3, 5.05);
          pdf.text(installationAddress1, 2.45, 5.05);
          pdf.text(installationAddress2, 2.45, 5.4);
          pdf.text("Google Location  ", 0.3, 5.75);
          // pdf.text(googleLocation,2.45, 5.75)
          pdf.setTextColor(0, 0, 255);
          pdf.textWithLink(googleLocationView, 2.45, 5.75, {
            url: googleLocation,
          });
          pdf.setTextColor(0, 0, 0);
          pdf.text("Billing Address  ", 0.3, 6.1);
          pdf.text(billingAddress1, 2.45, 6.1);
          pdf.text(billingAddress2, 2.45, 6.45);

          // all boxes view in pdf
          pdf.setFillColor(0, 0, 0);
          pdf.setLineWidth(0.01);
          pdf.rect(7.1, 0.3, 0.8, 0.6); //top box

          //pdf.rect(3.0, 1.2, 0.2, 0.2, "F"); //home
          pdf.addImage(checkMark, "JPEG", 3.0, 1.2, 0.2, 0.2);

          pdf.rect(4.1, 1.2, 0.2, 0.2, "S"); //Office
          pdf.rect(5.1, 1.2, 0.2, 0.2); //Other

          pdf.rect(6.9, 1.15, 1.0, 0.3); //ca number

          pdf.rect(2.4, 2.05, 2.0, 0.3); //title
          pdf.rect(2.4, 2.4, 5.35, 0.3); //fname
          pdf.rect(2.4, 2.75, 5.35, 0.3); //lname
          pdf.rect(2.4, 3.1, 1.5, 0.3); //dob
          pdf.rect(5.25, 3.1, 2.0, 0.3); //gender
          pdf.rect(2.4, 3.45, 1.5, 0.3); //nic
          pdf.rect(5.25, 3.45, 2.0, 0.3); //nationalyty

          pdf.rect(2.4, 4.15, 1.5, 0.3); //mobile
          pdf.rect(4.95, 4.15, 2.8, 0.3); //email

          pdf.rect(2.4, 4.85, 5.35, 0.3); //installatin address part1
          pdf.rect(2.4, 5.2, 5.35, 0.3); //installatin address part1
          pdf.rect(2.4, 5.55, 5.35, 0.3); //Google Location
          pdf.rect(2.4, 5.9, 5.35, 0.3); //Billing Address  part 1
          pdf.rect(2.4, 6.25, 5.35, 0.3); //Billing Address  part 2

          pdf.rect(0.3, 6.6, 7.7, 1.2); //first form box

          if (exCusYes === "F") {
            pdf.addImage(checkMark, "JPEG", 3.0, 6.85, 0.2, 0.2);
          } else {
            pdf.rect(3.0, 6.85, 0.2, 0.2, exCusYes); //Yes
          }
          //pdf.rect(3.0, 6.85, 0.2, 0.2, exCusYes); //Yes

          if (exCuNo === "F") {
            pdf.addImage(checkMark, "JPEG", 3.7, 6.85, 0.2, 0.2);
          } else {
            pdf.rect(3.7, 6.85, 0.2, 0.2, exCuNo); //No
          }

          pdf.rect(5.25, 6.8, 2.5, 0.3); //Tel/Acc No

          pdf.rect(4.0, 7.3, 0.2, 0.2); //SLT My Bill

          //added by ylk
          if (billMode_EBill === "F")
            pdf.addImage(checkMark, "JPEG", 5.0, 7.3, 0.2, 0.2); //E Bill
          else pdf.rect(5.0, 7.3, 0.2, 0.2, billMode_EBill); //E Bill

          if (billMode_Print === "F")
            pdf.addImage(checkMark, "JPEG", 6.25, 7.3, 0.2, 0.2); //Printed Bill
          else pdf.rect(6.25, 7.3, 0.2, 0.2, billMode_Print); //Printed Bill

          //   pdf.rect(5.0, 7.3, 0.2, 0.2, billMode_EBill); //E Bill
          //   pdf.rect(6.25, 7.3, 0.2, 0.2, billMode_Print); //Printed Bill

          pdf.rect(0.3, 7.9, 7.7, 1.2); //second form box

          pdf.rect(2.7, 8.6, 1.5, 0.3); //MOF Voice
          pdf.rect(4.3, 8.6, 1.5, 0.3); //MOF Broadband
          pdf.rect(5.9, 8.6, 1.5, 0.3); //MOF Peo TV

          pdf.rect(0.3, 9.2, 7.7, 2.0); //third form box
          //   pdf.rect(4.85, 9.6, 0.2, 0.2, activeIddYes); //Yes
          //   pdf.rect(5.55, 9.6, 0.2, 0.2, activeIddNo); //No

          if (activeIddYes === "F")
            pdf.addImage(checkMark, "JPEG", 4.85, 9.6, 0.2, 0.2); //Yes
          else pdf.rect(4.85, 9.6, 0.2, 0.2, activeIddYes); //Yes

          if (activeIddNo === "F")
            pdf.addImage(checkMark, "JPEG", 5.55, 9.6, 0.2, 0.2); //No
          else pdf.rect(5.55, 9.6, 0.2, 0.2, activeIddNo); //No

          pdf.rect(3.05, 9.85, 0.2, 0.2, myPhoneYes); //Yes
          pdf.rect(3.75, 9.85, 0.2, 0.2, myPhoneNo); //No

          pdf.rect(2.7, 10.1, 2.0, 0.3); //Broadband Packages

          pdf.rect(1.7, 10.5, 1.5, 0.3); //Peo TV  Packages
          pdf.rect(3.3, 10.5, 1.5, 0.3); //Peo TV  Packages
          pdf.rect(4.9, 10.5, 1.5, 0.3); //Peo TV  Packages
          pdf.rect(6.5, 10.5, 1.4, 0.3); //Peo TV  Packages

          /// add new page
          pdf.addPage("a4", "p");
          pdf.setFillColor(0, 0, 0);
          pdf.setLineWidth(0.01);
          pdf.rect(0.3, 0.5, 7.7, 2.5); //Comment

          pdf.setFontSize(14);
          pdf.setFont("courier", "bold");
          pdf.text("Comment/Remark", 0.5, 0.9);

          pdf.text("Customer Agreement", 0.5, 3.5);

          let pageWidth = 8.5,
            lineHeight = 4.8,
            margin = 0.6,
            maxLineWidth = pageWidth - margin * 2,
            fontSize = 24,
            ptsPerInch = 72,
            oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
            text =
              "I do hereby declare and confirm that the information above, furnished by me are true and correct. I confirm that I have received, read and understood the 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' (SLT), which are also available on SLT website www.slt.lk. I shall abide by the said 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' as may be revisedmodified and supplemented by SLT from time to time and morefully stated/updated under the said SLT website www.slt.lk.";

          let textLines = pdf
            .setFontSize(12)
            .setFont("courier", "normal")
            .splitTextToSize(text, maxLineWidth);

          pdf.text(textLines, margin, margin + 2 * oneLineHeight);

          // pdf.text('Terms and Conditions http://www.slt.lk/en/terms-conditions',0.70, 5.60)
          pdf.setTextColor(0, 0, 255);
          pdf.textWithLink(
            "Terms and Conditions http://www.slt.lk/en/terms-conditions",
            0.7,
            5.6,
            { url: "http://www.slt.lk/en/terms-conditions" }
          );
          pdf.setTextColor(0, 0, 0);

          pdf.text("I agree terms and Conditions ", 1.05, 5.9);
          // pdf.text('Yes ',3.90, 5.90)
          // pdf.text('NO ',4.70, 5.90)

          //pdf.rect(0.8, 5.75, 0.2, 0.2, "F"); //Yes
          pdf.addImage(checkMark, "JPEG", 0.8, 5.75, 0.2, 0.2);
          //ylk test correct mark
          //   pdf.rect(0.8, 5.75, 0.2, 0.2); //Yes
          //   pdf.setFontSize(7);
          //   pdf.text('✔', 0.85, 5.9);
          // pdf.rect(5.00, 5.75, 0.2,0.2) //No

          let fileName = savedData.nic
            ? savedData.nic + ".pdf"
            : savedData.pp + ".pdf";

          let formData = new FormData();
          formData.append("nicfront_file", nicFront);
          formData.append("nicback_file", nicBack);
          formData.append("pp_file", pass);
          formData.append("application_file", pdf.output("blob"), fileName);
          formData.append("billingproof_file", null);
          formData.append(
            "nic",
            savedData.sltnic ? savedData.sltnic : savedData.nic
          );
          formData.append("ppNumber", savedData.pp);
          formData.append("rtom", savedData.rtom);
          formData.append("orderreff", REF_NO);

          axiosInstanceFtth
            .post(ftthFileUpload, formData)
            .then((response) => {
              if (response.data.isSuccess) {
                setSuccess(true);
                setLoading(false);
              } else {
                setError(response.data.errorShow);
                setIsError(true);
                setLoading(false);
              }
            })
            .catch(() => {
              setError(CATCH_ERROR_MSG);
              setIsError(true);
              setLoading(false);
            });

          pdf.save(fileName);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // function printPdf() {
  //     setLoading(true);

  //         const input = document.getElementById('pdfDoc');

  //         html2canvas(document.getElementById('pdfDoc'), {
  //             scrollX: 0,
  //             scrollY: 0
  //         }).then((canvas) => {
  //                 const pdf = new jsPDF("p", "mm", "a4");
  //                 const imgData = canvas.toDataURL('image/png');

  //                 const imgProps = pdf.getImageProperties(imgData);
  //                 const pdfWidth = pdf.internal.pageSize.getWidth();
  //                 const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //                 // Add image to PDF
  //                 pdf.addImage(imgData, 'PNG', 0, 0, 210, 200);

  //                 // Add custom header image
  //                 const headerImgWidth = 210;
  //                 const headerImgHeight = 30;
  //                 pdf.addImage(headerImage, 'JPEG', 0, 0, headerImgWidth, headerImgHeight);

  //                 // Add custom footer image
  //                 const footerImgWidth = 210;
  //                 const footerImgHeight = 30;
  //                 const footerX = pdf.internal.pageSize.getWidth() - footerImgWidth ;
  //                 const footerY = pdf.internal.pageSize.getHeight() - footerImgHeight ;
  //                 pdf.addImage(footerImage, 'JPEG', footerX, footerY, footerImgWidth, footerImgHeight);

  //                 // const header = 'Your Order Details';
  //                 // pdf.setFontSize(15);
  //                 // pdf.setTextColor(255, 255, 255);
  //                 // pdf.text(header, 80, 10);

  //                 pdf.save("ftth_order_recipt.pdf"); // Save PDF

  //                 setLoading(false);
  //             })
  //             .catch((error) => {
  //                 console.error('Error generating PDF:', error);
  //                 setLoading(false);
  //             });
  // }

  // function printPdf() {
  //   const elementsToHide = document.querySelectorAll(".no-print");

  //   elementsToHide.forEach((el) => {
  //     el.style.display = "none";
  //   });
  //   setLoading(true);

  //   html2canvas(document.querySelector("#pdfDoc"), {
  //     logging: true,
  //     allowTaint: false,
  //     useCORS: true,
  //     width: document.querySelector("#pdfDoc").scrollWidth,
  //     height: document.querySelector("#pdfDoc").scrollHeight,
  //     scrollX: -window.scrollX,
  //     scrollY: -window.scrollY,
  //   })
  //     .then((canvas) => {
  //       var imgWidth = 210;
  //       var pageHeight = 290;
  //       var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       var heightLeft = imgHeight;

  //       var doc = new jsPDF("p", "mm");
  //       var position = 35;
  //       var pageData = canvas.toDataURL("image/jpeg", 1.0);
  //       var imgData = encodeURIComponent(pageData);

  //       // Adding Header
  //       const headerImgWidth = 210;
  //       const headerImgHeight = 30;
  //       doc.addImage(headerImage, "PNG", 0, 0, headerImgWidth, headerImgHeight);

  //       // Adding Footer
  //       const footerImgWidth = 210;
  //       const footerImgHeight = 30;
  //       const footerX = doc.internal.pageSize.getWidth() - footerImgWidth;
  //       const footerY = doc.internal.pageSize.getHeight() - footerImgHeight;
  //       doc.addImage(
  //         footerImage,
  //         "PNG",
  //         footerX,
  //         footerY,
  //         footerImgWidth,
  //         footerImgHeight
  //       );

  //       doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       doc.setLineWidth(5);
  //       doc.setDrawColor(255, 255, 255);
  //       doc.rect(0, 0, 210, 295);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         doc.addPage();
  //         // Adding Header
  //         doc.addImage(
  //           headerImage,
  //           "PNG",
  //           0,
  //           0,
  //           headerImgWidth,
  //           headerImgHeight
  //         );
  //         // Adding Footer
  //         doc.addImage(
  //           footerImage,
  //           "PNG",
  //           footerX,
  //           footerY,
  //           footerImgWidth,
  //           footerImgHeight
  //         );
  //         doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //         doc.setLineWidth(5);
  //         doc.setDrawColor(255, 255, 255);
  //         doc.rect(0, 0, 210, 295);
  //         heightLeft -= pageHeight;
  //       }

  //       elementsToHide.forEach((el) => {
  //         el.style.display = "";
  //       });

  //       setLoading(false);
  //       doc.save("ftth_order_receipt.pdf");
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDF:", error);
  //       setLoading(false);

  //       elementsToHide.forEach((el) => {
  //         el.style.display = "";
  //       });
  //     });
  // }

  function printPdf() {
    const elementsToHide = document.querySelectorAll(".no-print");
    elementsToHide.forEach((el) => {
      el.style.display = "none";
    });
    setLoading(true);

    const doc = new jsPDF("p", "mm");

    // Adding Header
    const headerImgWidth = 210;
    const headerImgHeight = 30;
    doc.addImage(headerImage, "PNG", 0, 0, headerImgWidth, headerImgHeight);

    // Content layout
    const startY = 40; // Starting Y position for the text after header
    let currentY = startY;
    const lineHeight = 10; // Height between lines

    // Helper function to add text
    const addText = (text, x, y, options = {}) => {
      doc.setFontSize(options.fontSize || 12);
      doc.setTextColor(options.color || "#000000");

      // Set font weight
      if (options.fontWeight === "bold") {
        doc.setFont("helvetica", "bold");
      } else {
        doc.setFont("helvetica", "normal");
      }
      doc.text(text, x, y, options);
    };

    // Data from savedData
    const referenceNo = savedData.reF_NO || "N/A";
    const orderAmount = "Rs. 0.00";
    const statusMessage = orderStatus;
    const customerName = `${savedData.firstname} ${savedData.lastname}`;
    const customerLocation = `${savedData.servicE_ADD_CITY}, ${savedData.servicE_ADD_STREET1}`;
    const customerEmail = savedData.email || "N/A";
    const customerPhone = savedData.mobileno || "N/A";
    const orderDate = moment(savedData.requesT_DATE, "DD-MM-YYYY").format(
      "DD/MM/YYYY"
    );
    const servicePackageDetails = [
      `Internet - ${savedData.bB_PACKAGE || "N/A"}`,
      `PEOTV - ${savedData.peO_PACKAGE || "N/A"}`,
      `Voice - ${savedData.voicE_PACKAGE || "N/A"}`,
    ];

    // Reference No and Order Amount
    addText("Reference No :", 10, currentY);
    addText(referenceNo, 50, currentY, { fontSize: 12, color: "#0056a3" });
    currentY += lineHeight;
    addText("Order Amount :", 10, currentY);
    addText(orderAmount, 50, currentY, { fontSize: 12, color: "#0056a3" });
    currentY += lineHeight * 2;

    // Status
    addText("Status", 105, currentY, {
      align: "center",
      fontSize: 14,
      fontWeight: "bold",
    });
    currentY += lineHeight;
    addText(statusMessage, 105, currentY, {
      align: "center",
      maxWidth: 190,
      fontSize: 12,
      color: "#0056a3",
    });
    currentY += lineHeight * 2;

    // Customer Details
    addText("Customer Details", 105, currentY, {
      align: "center",
      fontSize: 14,
      fontWeight: "bold",
    });
    currentY += lineHeight;
    addText(customerName, 105, currentY, {
      align: "center",
      fontSize: 12,
      color: "#0056a3",
    });
    currentY += lineHeight;
    addText(`Location: ${customerLocation}`, 105, currentY, {
      align: "center",
      fontSize: 12,
      color: "#0056a3",
    });
    currentY += lineHeight;
    addText(customerEmail, 105, currentY, {
      align: "center",
      fontSize: 12,
      color: "#0056a3",
    });
    currentY += lineHeight;
    addText(customerPhone, 105, currentY, {
      align: "center",
      fontSize: 12,
      color: "#0056a3",
    });
    currentY += lineHeight;
    addText(`Order Date: ${orderDate}`, 105, currentY, {
      align: "center",
      fontSize: 12,
      color: "#0056a3",
    });
    currentY += lineHeight * 2;

    // Service & Package Details
    addText("Service & package details", 105, currentY, {
      align: "center",
      fontSize: 14,
      fontWeight: "bold",
    });
    currentY += lineHeight;
    servicePackageDetails.forEach((detail) => {
      addText(detail, 105, currentY, {
        align: "center",
        fontSize: 12,
        color: "#0056a3",
      });
      currentY += lineHeight;
    });

    // Adding Footer
    const footerImgWidth = 210;
    const footerImgHeight = 30;
    const footerX = 0;
    const footerY = doc.internal.pageSize.getHeight() - footerImgHeight;
    doc.addImage(
      footerImage,
      "PNG",
      footerX,
      footerY,
      footerImgWidth,
      footerImgHeight
    );

    elementsToHide.forEach((el) => {
      el.style.display = "";
    });

    setLoading(false);
    doc.save("ftth_order_receipt.pdf");
  }

  function backToHome() {
    window.location.href = "/applyonline";
  }

  const updateAgentCode = () => {
    if (!agentCode || agentCode.length < 5) {
      setError("Agent code must be greater than 4 characters long.");
      setIsError(true);
    } else {
      setLoading(true);

      let body = Querystring["stringify"]({
        REF_NO: REF_NO,
        SEQ_NO: SEQ_NO,
        AGENT_CODE: agentCode,
      });

      axiosInstanceFtth
        .post(UpdateAgentCode, body)
        .then((response) => {
          if (response.data.isSuccess) {
            setSuccess(true);
            setLoading(false);
            setCallAgentCode(true);
          } else {
            setError(response.data.errorShow);
            setIsError(true);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setError(CATCH_ERROR_MSG);
          setIsError(true);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updateDraft = () => {
    let body = Querystring["stringify"]({
      NIC: savedData.nic,
      PP: savedData.passport,
      TITLE: savedData.title,
      FIRSTNAME: savedData.firstname,
      LASTNAME: savedData.lastname,
      BIRTH_DATE: moment(bithday, "DD/MM/YYYY").format("YYYY-MM-DD"),
      NATIONALITY: savedData.nationality,
      SERVICE_ADD_NO: savedData.servicE_ADD_NO,
      SERVICE_ADD_STREET1: savedData.servicE_ADD_STREET1,
      SERVICE_ADD_STREET2: savedData.servicE_ADD_STREET2,
      SERVICE_ADD_CITY: savedData.servicE_ADD_CITY,
      SERVICE_ADD_DISTRICT: savedData.servicE_ADD_DISTRICT,
      BILLING_ADD_NO: savedData.billinG_ADD_NO,
      BILLING_ADD_STREET1: billAddress1,
      BILLING_ADD_STREET2: billAddress2,
      BILLING_ADD_CITY: savedData.billinG_ADD_CITY,
      SERVICE_ADD_POSTAL_CODE: savedData.servicE_ADD_POSTAL_CODE,
      RTOM: savedData.rtom,
      EQTYPE: savedData.eqtype,
      LONGITUDE: savedData.longitude,
      LATITUDE: savedData.latitude,
      SERVICE_TYPE: savedData.servicE_TYPE,
      SERVICE_ADD_PROVINCE: savedData.servicE_ADD_PROVINCE,
      BILLING_ADD_PROVINCE: savedData.billinG_ADD_PROVINCE,
      VOICE_PACKAGE: savedData.voicE_PACKAGE,
      BB_PACKAGE: savedData.bB_PACKAGE,
      PEO_PACKAGE: savedData.peO_PACKAGE,
      BILLING_ADD_POSTAL_CODE: savedData.billinG_ADD_POSTAL_CODE,
      gender: savedData.gender,
      iddstatus: savedData.iddstatus,
      billinG_MODE: savedData.billinG_MODE,
      email: savedData.email,
      mobileno: savedData.mobileno,
      LOOP_AVAILABLITY: savedData.looP_AVAILABLITY,
      SLTNIC: savedData.sltnic,
      REF_NO: savedData.reF_NO,
      USER_COMMENT: savedData.useR_COMMENT,
    });

    axiosInstanceFtth
      .post(updateDraftDetails, body)
      .then((response) => {
        if (response.data.isSuccess) {
          setIsDraftDetailsUpdate(true);
          fetchDraftDetails();
        } else {
          setError(response.data.errorShow);
          setIsError(true);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(CATCH_ERROR_MSG);
        setIsError(true);
        setLoading(false);
      });
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOk = () => {
    submitLater();
    closePopup();
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "8px",
      width: "100%",
      maxWidth: "500px",
      backgroundColor: "white",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  if (loading) {
    return (
      <div className="ftthBody newFTTHLandingPage">
        <NewNavBar />
        <div className="landingFTTHPage">
          <ProgressBar />
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div id="pdfDoc" className="fixedConnectionStatusTracking">
      {success ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "110%",
            height: "110%",
            display: "flex",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <SuccessAlert
            setSuccess={successSave}
            msg="Submitted your request successfully."
          />
        </div>
      ) : null}
      {isError ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "110%",
            height: "110%",
            display: "flex",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          {/* {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null} */}
          <ErrorAlert
            error={error}
            setError={setError}
            setIsError={setIsError}
          />
        </div>
      ) : null}
      <div className="header">
        <div className="titles">
          <p className="mobileResponsive" style={{ textAlign: "center" }}>
            {" "}
            Get a life with Fibre
          </p>
        </div>
      </div>

      <div className="header">
        <div className="sub-titles">
          <p>Stay Connected with Your Order</p>
        </div>
      </div>

      <div className="middle-container">
        <div>
          <div
            className="order-details"
            style={{
              padding: "20px",
              borderRadius: "8px",
              maxWidth: "400px",
              margin: "auto",
              backgroundColor: "#f9f9f9",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="order-status"
              style={{ display: "flex", marginBottom: "10px" }}
            >
              <div
                className="valName"
                style={{ fontWeight: "bold", marginRight: "10px" }}
              >
                Reference No :
              </div>
              <div className="value">{savedData.reF_NO}</div>
            </div>
            <div className="order-status" style={{ display: "flex" }}>
              <div
                className="valName"
                style={{ fontWeight: "bold", marginRight: "10px" }}
              >
                Order Amount :
              </div>
              <div className="value">
                {savedData.useR_COMMENT === "FULL"
                  ? "Rs. 10000.00"
                  : "Rs. 0.00"}
              </div>
            </div>
          </div>

          <div className="order-status">
            <div
              className="valName"
              style={{ fontSize: "17px", marginBottom: "10px" }}
            >
              Status
            </div>
            <div style={{ textAlign: "center" }}>
              <div
                className="value"
                style={{ display: "inline-block", maxWidth: "400px" }}
              >
                {orderStatus}
              </div>
            </div>
          </div>

          <div className="package-container">
            <div className="sub-title">Customer Details</div>
            <div className="details-title">
              {savedData.firstname ? (
                <p>{savedData.firstname + " " + savedData.lastname}</p>
              ) : null}
              <div
                style={{
                  maxWidth: "100%",
                  color: "#007eff",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                  textAlign: "center",
                  fontSize: "12px",
                  margin: "0 auto",
                  "@media (max-width: 768px)": {
                    fontSize: "5px",
                  },
                }}
              >
                {savedData.billinG_ADD_STREET1 && (
                  <p
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {savedData.billinG_ADD_STREET1} , <br />
                    {savedData.billinG_ADD_STREET2}.
                  </p>
                )}
              </div>

              {/* <p>
                Location :{" "}
                {savedData.BILLING_ADD_STREET1 === "Y"
                  ? " Service available"
                  : " Service unavailable"}{" "}
              </p> */}

              <p>
                Location:{"  "}
                {savedData.servicE_ADD_STREET1
                  ? `${savedData.servicE_ADD_STREET1}, ${
                      savedData.servicE_ADD_STREET2 || ""
                    }`
                  : "Service unavailable"}
              </p>

              {savedData.email ? <p>{savedData.email} </p> : null}
              {savedData.mobileno ? <p>{savedData.mobileno} </p> : null}
              {savedData.requesT_DATE ? (
                <p>Order Date: {formatRequestDate(savedData.requesT_DATE)} </p>
              ) : null}
            </div>
          </div>

          <div className="package-container">
            <div className="sub-title">Service & package details</div>
            <div className="details-title">
              {savedData.bB_PACKAGE ? (
                <p>
                  {" "}
                  Internet -{" "}
                  {savedData.bB_PACKAGE ? savedData.bB_PACKAGE : "N/A "}{" "}
                </p>
              ) : null}
              {savedData.peO_PACKAGE ? (
                <p>
                  {" "}
                  PEOTV -{" "}
                  {savedData.peO_PACKAGE ? savedData.peO_PACKAGE : "N/A "}{" "}
                </p>
              ) : null}

              <p>Voice - Home 1st Line - My Phone </p>
            </div>
          </div>
          {/* 
                    <button className='blue' style={{margin:'10px'}} onClick={printPdf}>Download PDF</button> */}
          <div className="formUpload no-print">
            <div style={{ textAlign: "center" }}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <button
                  className="blue"
                  style={{ margin: "10px", position: "relative" }}
                  onClick={backToHome}
                >
                  <img
                    src={homeBtn}
                    alt="Go to Home"
                    style={{ verticalAlign: "middle", maxHeight: "40px" }}
                  />
                </button>

                <button
                  className="blue"
                  style={{ margin: "10px", position: "relative" }}
                  onClick={printPdf}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={downloadBtn}
                    alt="Download PDF"
                    style={{ verticalAlign: "middle", maxHeight: "40px" }}
                  />
                </button>

                {/* {tooltipVisible && (
                            <span style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -150%)',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                color: '#fff',
                                padding: '5px 60px',
                                borderRadius: '4px',
                                fontSize: '14px',
                                zIndex: '999',
                            }}>Download Order Receipt</span>
                        )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formUpload no-print">
        {/* { !isBillAddressExist  && savedData.useR_COMMENT === 'FULL' ? */}
        {savedData.useR_COMMENT === "FULL" ? (
          <div className="formUpload">
            <div className="input-container">
              <label className="header">
                Billing Address <p className="d-inline red m-0 ml-1">*</p>
              </label>
              <div
                className="check-input"
                style={{ marginLeft: "10px", color: "#007bff" }}
              >
                <div className="input-field">
                  <input
                    type="checkbox"
                    id="same"
                    name="samebill"
                    className="sign"
                    checked={isSameAsServiceAddress || isBillAddressExist}
                    disabled={isBillAddressExist}
                    onChange={() =>
                      setIsSameAsServiceAddress(!isSameAsServiceAddress)
                    }
                  />
                  <label htmlFor="same" style={{ color: "#566673" }}>
                    {" "}
                    Same as service address
                  </label>
                </div>
              </div>
            </div>
            <div className="input-container" style={{ marginLeft: "10px" }}>
              <label>
                <div className="label" style={{ color: "#0056a3" }}>
                  Billing Address Line 1
                </div>
                <p className="d-inline red m-0 ml-1">*</p>
              </label>
              <div className="input-field">
                <input
                  className="w-100"
                  type="text"
                  name="address1"
                  value={billAddress1}
                  disabled={isSameAsServiceAddress || isBillAddressExist}
                  onChange={(event) =>
                    setBillAddress1(event.target.value.trim())
                  }
                  style={{
                    padding: "10px",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    color: "#1c4784",
                  }}
                />
              </div>
            </div>

            <div className="input-container" style={{ marginLeft: "10px" }}>
              <label>
                <div className="label" style={{ color: "#0056a3" }}>
                  Billing Address Line 2
                </div>
                <p className="d-inline red m-0 ml-1">*</p>
              </label>

              <input
                className="w-100"
                type="text"
                name="address2"
                value={billAddress2}
                disabled={isSameAsServiceAddress || isBillAddressExist}
                onChange={(event) => setBillAddress2(event.target.value.trim())}
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  color: "#1c4784",
                }}
              />
            </div>

            <div
              className="button-container"
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                textAlign: "center",
              }}
            >
              {!isBillAddressExist ? (
                <button
                  className="blue"
                  onClick={() => updateDraft()}
                  disabled={isBillAddressExist}
                  style={{ padding: "7px" }}
                >
                  Submit
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      <div className="formUpload no-print">
        {savedData.filE_UPLOAD_STATUS === "N" &&
        savedData.useR_COMMENT === "FULL" ? (
          <div className="formUpload">
            <div className="header">Upload Proof Document</div>

            <div className="uploadField">
              {savedData.nic ? (
                <>
                  <div>
                    <label>NIC Front</label>
                    <input
                      type="file"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={(event) => setNicFront(event.target.files[0])}
                    />
                  </div>

                  <div>
                    <label>NIC Back</label>
                    <input
                      type="file"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={(event) => setNicBack(event.target.files[0])}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <label>Passport</label>
                  <input
                    type="file"
                    className="custom-file-input"
                    accept="image/*"
                    onChange={(event) => setPass(event.target.files[0])}
                  />
                </div>
              )}
            </div>
            <p
              style={{ fontSize: "14px", marginLeft: "15px", color: "#FF6868" }}
            >
              {!isBillAddressExist
                ? "Note: Your service will be processed once submited billing address and proof documents."
                : "Note: Your service will be processed once uploaded the documents."}
            </p>

            <div className="footer" style={{ marginTop: "5px" }}>
              <div className="file-requrements" style={{ marginLeft: "10px" }}>
                <div className="requirement">Maximum file upload size 2MB</div>
                <div className="requirement">
                  Allow file types : png, jpg , jpeg
                </div>
              </div>
              <div style={{ marginRight: "20px" }} className="button-container">
                <button
                  className="blue"
                  onClick={() => printDocument()}
                  disabled={!isBillAddressExist}
                >
                  Submit Now
                </button>
              </div>
              {/* <div style={{ marginRight: "20px" }} className="button-container">
                <button
                  className="blue"
                  onClick={() => submitLater()}
                  disabled={!isBillAddressExist}
                >
                  Submit Later
                </button>
              </div> */}
              <div style={{ marginRight: "20px" }}>
                <button
                  className="blue"
                  style={{ padding: "8px" }}
                  onClick={openPopup}
                  disabled={!isBillAddressExist}
                >
                  Submit Later
                </button>

                <Modal
                  isOpen={isPopupOpen}
                  onRequestClose={closePopup}
                  contentLabel="Confirmation Popup"
                  style={modalStyles}
                >
                  <h2
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 15,
                      padding: 20,
                    }}
                  >
                    You can submit the documents later. You will receive the
                    link through SMS and click on it to upload the documents. We
                    will only start the service provisioning process once the
                    documents are uploaded.
                  </h2>
                  <div style={{ textAlign: "center" }}>
                    <button
                      onClick={handleOk}
                      style={{
                        marginRight: "10px",
                        padding: "10px 20px",
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      OK
                    </button>
                    <button
                      onClick={closePopup}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="formUpload no-print">
        <div className="formUpload">
          {!isAgentCodeSubmited ? (
            <>
              <div
                className="uploadField"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="header">Agent Code(Official Use Only)</div>
                <div className="input-field" style={{ width: "300px" }}>
                  <input
                    type="text"
                    className="w-100"
                    name="lName"
                    value={agentCode}
                    onChange={(event) =>
                      setAgentCode(event.target.value.trim())
                    }
                    style={{
                      padding: "10px",
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      color: "#1c4784",
                      textAlign: "center",
                    }}
                  />
                </div>

                <div
                  className="button-container"
                  style={{ marginTop: "10px", marginBottom: "15px" }}
                >
                  {agentCode.length >= 5 ? (
                    <button
                      className="blue"
                      onClick={() => updateAgentCode()}
                      disabled={agentCode.length < 5}
                    >
                      Submit
                    </button>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <div
              className="header"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              Agent Code - {agentCode}
            </div>
          )}
        </div>
      </div>

      <div id="pdfViewer" />
    </div>
  );
}

export default FileUploadState;
