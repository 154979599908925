import {getDraftDetails, paymentsLogs, getInvoice} from "../../URL";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_DRAFT_DETAILS, ERROR_GET_INVOICE, ERROR_GET_PAYMENT_LOG,
    LOADING_GET_DRAFT_DETAILS, LOADING_GET_INVOICE, LOADING_GET_PAYMENT_LOG,
    SUCCESS_GET_DRAFT_DETAILS, SUCCESS_GET_INVOICE, SUCCESS_GET_PAYMENT_LOG,

} from "../../Types";
import axiosInstance from "../../axiosInstance";

export function getDraftDetailFTTH() {
    let url = getDraftDetails + 'NIC='

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_DRAFT_DETAILS))
        axiosInstance.get(url).then((response) => {
            if (response.data.isSuccess && response.data.dataBundle.length > 0){
                dispatch(dispatchSuccess(SUCCESS_GET_DRAFT_DETAILS, response.data.dataBundle[0]))
                dispatch(getPaymentLogOfFtth(response.data.dataBundle[0].reF_NO))
                dispatch(getInvoiceOfFtth(response.data.dataBundle[0].reF_NO))
            }
            else {
                dispatch(dispatchError(ERROR_GET_DRAFT_DETAILS, response.data.errorShow))
            }
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_DRAFT_DETAILS, CATCH_ERROR_MSG))
        })
    }
}

export function getPaymentLogOfFtth(orderId) {

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_PAYMENT_LOG))
        axiosInstance.get(paymentsLogs + orderId).then((response) => {

            if (response.data.isSuccess && response.data.dataBundle.length > 0) {
                dispatch(dispatchSuccess(SUCCESS_GET_PAYMENT_LOG, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_PAYMENT_LOG, response.data.errorShow))
            }

        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_PAYMENT_LOG, CATCH_ERROR_MSG))
        })
    }
}

export function getInvoiceOfFtth(orderId) {

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_INVOICE))
        axiosInstance.get(getInvoice + 'REF_NO=' + orderId).then((response) => {

            if (response.data.isSuccess ) {// && response.data.dataBundle.length > 0
                dispatch(dispatchSuccess(SUCCESS_GET_INVOICE, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_INVOICE, response.data.errorShow))
            }

        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_INVOICE, CATCH_ERROR_MSG))
        })
    }
}