import React from 'react'

const CheckLTEStatus = ({crmStatus, sys_status}) => {

    const setStateOfOrder = () => {
        if (parseInt(crmStatus) === 0) {
            return <strong style={{color: '#ffb110'}}>Pending</strong>
        } else if (parseInt(crmStatus) > 0 && parseInt(crmStatus) < 10) {
            return <strong style={{color: '#ffb110'}}>In processing</strong>
        } else if (parseInt(crmStatus) === 10 && sys_status === '1') {
            return <strong style={{color: '#15d151'}}>Completed</strong>
        }else if (crmStatus === '-1') {
            return <strong style={{color: '#ff7878'}}>Failed</strong>
        }else {
            return <strong style={{color: '#ffb110'}}>In processing</strong>
        }
    }

  return (
    <div className='processingState'>
        <div>
            <div className='img-container'>
                <div className="success-img"/>
            </div>
            <div>Your order is successfully submitted.</div>
            <div>Thank you for choosing SLTMobitel.</div>
            <div className='d-flex justify-content-center mt-3'>
                 <div>Order Status –  {setStateOfOrder()}</div>
            </div>
        </div>


     </div>
  )
}

export default CheckLTEStatus