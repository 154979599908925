import React, { useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import PrePaidMyPackage from "./PrePaidMyPackage";
import { Link } from "react-router-dom";

function DataUsage({ loading, dataUsage, error }) {
  const [state, setState] = useState("Main");

  const filterArray = () => {
    if(state === 'Main') {
        return dataUsage.filter(val => val.packageCategory === 'Main Packages')
    }else {
        return dataUsage.filter(val => val.packageCategory === 'Add-ons')
    }
  }

  return (
    <div className="boardBandSummary common-panel">
      <div className="summary-container">
        <Link>
          <div
            className={state === "Main" ? "buttonTab blue" : "buttonTab"}
            onClick={() => setState("Main")}
          >
            <div className="text-center">
              <p className="m-0">Main Packages</p>
            </div>
          </div>
          <span hidden={state !== "Main"} className="inputIcon">
            <BsFillCaretRightFill size={20} color="#007ed6" />
          </span>
        </Link>

        <Link>
          <div
            className={state === "Add-ons" ? "buttonTab blue" : "buttonTab"}
            onClick={() => setState("Add-ons")}
          >
            <div className="text-center">
              <p className="m-0">Add-ons</p>
            </div>
          </div>
          <span hidden={state !== "Add-ons"} className="inputIcon">
            <BsFillCaretRightFill size={20} color="#007ed6" />
          </span>
        </Link>
      </div>

      <PrePaidMyPackage
        loading={loading}
        dataUsage={dataUsage && filterArray()}
        type={state}
      />
    </div>
  );
}

export default DataUsage;
