import React, {useCallback, useEffect, useState} from 'react';
import axiosInstance from "../../redux/axiosInstance";
import {
    ebillActivateStatusUrl,
    ebillActivateUrl,
    ebillCheckUserExistUrl,
    ebillSmsActivateStatusUrl
} from "../../redux/URL";
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";
import ActiveEBill from "./ActiveEBill";
import SuccessAlert from "../alerts/SuccessAlert";
import {Redirect} from "react-router-dom";
import EbillTermsConditions from "../common/EbillTermsConditions";


function UpdateEBillSms({accountList, service_list, loadingServices, loadingAccounts}) {

    const [servList, setServList] = useState(service_list);

    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    const [agreeEmail, setAgreeEmail] = useState(false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [otpStatus, setOtpStatus] = useState(false);
    const [isMail, setIsMail] = useState(null);
    const [showTerms, setShowTerms] = useState(false);

    useEffect(() => {
        if (!loadingServices && !loadingAccounts)
            getStatusRequest()
    }, [loadingServices, loadingAccounts]);// eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getStatusRequest = useCallback(() => {
        setLoading(true)
        let url
        const telNo = localStorage.getItem('selectedAcc');
        if (accountList) {
            url = ebillActivateStatusUrl + 'accountNo=' + service_list.accountNo + '&tpNo=' + telNo ?? accountList[0].telephoneno

            axiosInstance.get(url).then(response => {

                if (response.status === 200 && response.data.isSuccess) {

                    if (response.data.dataBundle.length > 0) {

                        if (response.data.dataBundle[0].emailaddress) {
                            setEmail(response.data.dataBundle[0].emailaddress)
                            setIsRegister(true)
                            if (response.data.dataBundle[0].mobileno) {
                                getMobilStatusRequest(response.data.dataBundle[0].mobileno)
                            } else
                                setLoading(false)
                        } else
                            setLoading(false)
                    } else
                        setLoading(false)
                } else
                    setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }

    })

    const getMobilStatusRequest = (mobile) => {
        let url
        const telNo = localStorage.getItem('selectedAcc');
        if (accountList) {
            url = ebillSmsActivateStatusUrl + 'accountNo=' + service_list.accountNo + '&tpNo=' + telNo ?? accountList[0].telephoneno + '&econtact=' + mobile + '&econtactType=MOBILE'
          
            axiosInstance.get(url).then(response => {
                if (response.status === 200 && response.data.isSuccess) {
                    if (response.data.dataBundle.isSuccess) {
                        setMobile(mobile)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                } else
                    setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }


    useEffect(() => {
        if (servList !== service_list && service_list) {
            setEmail('')
            setMobile('')
            if (!loadingServices && !loadingAccounts)
                getStatusRequest()
            setServList(...[service_list])
        }

    }, [service_list, loadingServices, loadingAccounts, servList, getStatusRequest])

    function ValidateEmail() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const checkEmailValidation = (econtact, type) => {
        setLoading(true)
        let url
        const telNo = localStorage.getItem('selectedAcc');
        if (accountList) {
            url = ebillCheckUserExistUrl + 'accountNo=' + service_list.accountNo + '&tpNo=' + telNo ?? accountList[0].telephoneno + '&econtact=' + econtact + '&econtactType=' + type
          
            axiosInstance.get(url).then(response => {

                if (response.status === 200 && response.data.isSuccess) {
                    if (response.data.dataBundle.userexist === 'N') {
                        setOtpStatus(true)
                        setLoading(false)
                    } else if (response.data.dataBundle.userexist === 'Y') {
                        eBillRegister(type)
                    } else {
                        setError(response.data.errorShow)
                        setIsError(true)
                        setLoading(false)
                    }
                } else
                    setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    const onClickEmailActivate = () => {
        setIsMail(true)
        if (ValidateEmail()) {
            checkEmailValidation(email, 'EMAIL')
        } else {
            setError('Enter valid email')
            setIsError(true)
        }
    }

    const onClickMobileActivate = () => {
        setIsMail(false)
        if (mobile.length === 10) {
            checkEmailValidation(mobile, 'MOBILE')
        } else {
            setError('Enter valid mobile')
            setIsError(true)
        }
    }

    const eBillRegister = (Type) => {
        let newEmail = email
        let newContactNumber = mobile
        let isPrestigeCustomer = false
        let isAlreadyRegistered = isRegister
        let isEnableSms = Type === 'MOBILE'
        let url
        const telNo = localStorage.getItem('selectedAcc');
        if (accountList) {
            url = ebillActivateUrl + 'accountNumber=' + service_list.accountNo + '&eventSource=' + telNo ?? accountList[0].telephoneno + '&newEmailAddress=' + newEmail + '&newContactNumber=' + newContactNumber + '&isEnableSms=' + isEnableSms + '&isAlreadyRegistered=' + isAlreadyRegistered + '&isPrestigeCustomer=' + isPrestigeCustomer
           
            axiosInstance.post(url).then(response => {
                if (response.status === 200 && response.data.isSuccess) {
                    setOtpStatus(false)
                    setSuccess(true)
                    setLoading(false)
                    getStatusRequest()
                } else
                    setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    if (loading || loadingServices || loadingAccounts) {
        return <div className='mainBody eBill common-panel '>
            <div className="body-container">
                <div className='title'>
                    {/* <p> {email && mobile ? 'Update Your eBill & SMS Notifications' : email ? 'Update Your eBill' : 'Active eBill'}</p> */}
                    <p> { isRegister ? 'Update Your eBill' : 'Activate eBill'}</p>
                </div>
                <LoadingBbBody/>
            </div>
        </div>
    } else if (!accountList || accountList.length === 0) {
        return <Redirect to='/noAccount'/>;
    } else if (otpStatus) {
        return <ActiveEBill setLoading={setLoading} isError={isError} setIsError={setIsError} error={error}
                            setError={setError} isMail={isMail} email={email} mobile={mobile}
                            setOtpStatus={setOtpStatus} eBillRegister={eBillRegister}/>
    } else if(showTerms)
        return <div className='mainBody eBill terms common-panel '>
            <EbillTermsConditions setShowTerms={setShowTerms} setAgree={setAgreeEmail}/>
        </div>

    return (
        <div className='mainBody eBill common-panel '>
            <div className="body-container">
                {success ?
                    <SuccessAlert setSuccess={setSuccess} msg='Successfully registered for eBill service'/> : null}
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className='title'>
                    {/* <p> {email && mobile ? 'Update Your eBill & SMS Notifications' : email ? 'Update Your eBill' : 'Active eBill'}</p> */}
                    <p> { isRegister ? 'Update Your eBill' : 'Activate eBill'}</p>
                </div>

                <div className='wrapper'>
                    <div className='content sameMargin'>
                        <div className='card'>
                            <div className='title'>
                                <p>eBill</p>
                            </div>
                            <div className='form'>
                                <div>
                                    <label className='d-block'>Enter Your Email <p className='d-inline red'>*</p>
                                    </label>
                                    <input type={"email"} className='pLeft' value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} required/>
                                </div>

                                <div className='mt-4' >
                                    {
                                        <div className='agreeCondition'>
                                            <input type='checkbox' className='sign' checked={agreeEmail}
                                                   onChange={(e) => setAgreeEmail(e.target.checked)}/>
                                            <label>I agree to the general <strong onClick={()=>setShowTerms(true)}> terms and conditions </strong> </label>
                                        </div>
                                    }

                                </div>


                                <div className='text-center mt-2'>
                                    <button className='medium blue'
                                            onClick={() => onClickEmailActivate()}>{isRegister ? 'Update' : 'Activate'}</button>
                                </div>


                            </div>
                        </div>
                    </div>


                    {/* {
                        email ? <div className='content sameMargin'>
                            <div className='card'>
                                <div className='title'>
                                    <p>SMS</p>
                                </div>
                                <div className='form'>
                                    <div>
                                        <label className='d-block'>Enter Your Mobile <p className='d-inline red'>*</p>
                                        </label>
                                        <input type={"email"} className='pLeft' value={mobile} onChange={(e) => {
                                            setMobile(e.target.value)
                                        }}/>
                                    </div>

                                    <div className='mt-4'>
                                        {
                                            mobile ? null : <div className='agreeCondition'>
                                                <input type='checkbox' className='sign' checked={agreeMobile}
                                                       onChange={(e) => setAgreeMobile(e.target.checked)}/>
                                                <label className=''>I agree to the general <strong onClick={()=>setShowTerms(true)}> terms and conditions</strong> </label>
                                            </div>
                                        }

                                    </div>

                                    <div className='text-center'>
                                        <button className='medium blue'
                                                onClick={() => onClickMobileActivate()}>{mobile ? 'Update' : 'Active'}</button>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    } */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    accountList: state.accountDetails.account_Details,
    service_list: state.accountDetails.service_list,
    loadingServices: state.accountDetails.loadingServiceList,
    loadingAccounts: state.accountDetails.loading,
})

export default connect(mapStateToProps, {})(UpdateEBillSms);