import {dispatchError, dispatchSuccess, loadingDispatch} from "../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_ADD_ONS,
    ERROR_GET_BONUS_DATA,
    ERROR_GET_EXTRAGB, ERROR_GET_FREE_DATA,
    ERROR_GET_PREPAID_DATA,
    ERROR_GET_PROFILE,
    ERROR_GET_SUMMARY,
    LOADING_ADD_ONS,
    LOADING_BONUS_DATA,
    LOADING_EXTRAGB, LOADING_FREE_DATA,
    LOADING_GET_PREPAID_DATA,
    LOADING_GET_SUMMARY, LOADING_PROFILE, SUCCESS_GET_ADD_ONS, SUCCESS_GET_BONUS_DATA,
    SUCCESS_GET_EXTRAGB, SUCCESS_GET_FREE_DATA, SUCCESS_GET_PREPAID_DATA, SUCCESS_GET_PROFILE,
    SUCCESS_GET_SUMMARY
} from "../Types";
import axiosInstance from "../axiosInstance";
import {
    getAddonsDataUrl,
    getBonusDataUrl,
    getExtraGBUrl,
    getFreeDataUrl,
    getProfileRequestUrl,
    getUsagePrepaidData,
    getUsageSummaryUrl
} from "../URL";

export function getBBSummary(subscriberID) {
    let url = getUsageSummaryUrl + 'subscriberID=' + subscriberID
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_SUMMARY))
        axiosInstance.get(url).then((response) => {
            if (response.data.isSuccess)
                dispatch(dispatchSuccess(SUCCESS_GET_SUMMARY, response.data.dataBundle))
            else
                dispatch(dispatchError(ERROR_GET_SUMMARY, response.data.errorShow))
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_SUMMARY, CATCH_ERROR_MSG))
        })
    }
}

export function getProfile(subscriberID) {
    let url = getProfileRequestUrl + 'subscriberID=' + subscriberID
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_PROFILE))
        axiosInstance.get(url).then((response) => {
            if (response.data.isSuccess)
                dispatch(dispatchSuccess(SUCCESS_GET_PROFILE, response.data.dataBundle))
            else
                dispatch(dispatchError(ERROR_GET_PROFILE, response.data.errorShow))
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_PROFILE, CATCH_ERROR_MSG))
        })
    }
}

export function getExtraGB(subID) {
    return (dispatch) => {
        dispatch(dataApis(getExtraGBUrl, subID, dispatch, LOADING_EXTRAGB, SUCCESS_GET_EXTRAGB, ERROR_GET_EXTRAGB))
    }
}

export function bonusData(subID) {
    return (dispatch) => {
        dispatch(dataApis(getBonusDataUrl, subID, dispatch, LOADING_BONUS_DATA, SUCCESS_GET_BONUS_DATA, ERROR_GET_BONUS_DATA))
    }
}

export function addOnsData(subID) {
    return (dispatch) => {
        dispatch(dataApis(getAddonsDataUrl, subID, dispatch, LOADING_ADD_ONS, SUCCESS_GET_ADD_ONS, ERROR_GET_ADD_ONS))
    }
}

export function getFreeData(subID) {
    return (dispatch) => {
        dispatch(dataApis(getFreeDataUrl, subID, dispatch, LOADING_FREE_DATA, SUCCESS_GET_FREE_DATA, ERROR_GET_FREE_DATA))
    }
}

function dataApis(originalURL, subID, dispatch, loading, success, error) {
    let url = originalURL + 'subscriberID=' + subID
    return (dispatch) => {
        dispatch(loadingDispatch(loading))
        axiosInstance.get(url).then((response) => {
            if (response.data.isSuccess)
                dispatch(dispatchSuccess(success, response.data.dataBundle))
            else
                dispatch(dispatchError(error, response.data.errorShow))
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_SUMMARY, CATCH_ERROR_MSG))
        })
    }
}

export function getPrepaidDataBalance(telephone) {
    let url = `${getUsagePrepaidData}LTESLTNumber=${telephone}&OperationType=QUERY_FREE_UNITS`
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_PREPAID_DATA))
        axiosInstance.get(url).then((response) => {
            console.log(response, 'getPrepaidDataBalance details')
            if (response.data.isSuccess)
                dispatch(dispatchSuccess(SUCCESS_GET_PREPAID_DATA, response.data.dataBundle))
            else
                dispatch(dispatchError(ERROR_GET_PREPAID_DATA, response.data.errorShow))
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_SUMMARY, CATCH_ERROR_MSG))
        })
    }
}