import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import setBillModeChange from "../../redux/action/bill/smartBillRegistration.action";
import getBillStatus from "../../redux/action/bill/billStatus.action";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";
import SuccessAlert from "../alerts/SuccessAlert";
import NoPreviledge from '../alerts/NoPreviledge';

const BillMode = (
  {
    setBillModeChange,
    service_list,
    accountList,
    billStatus,
    loading,
    loadingSmartBillRegistration,
    smartBillRegistrationError
  }
) => {

  const history = useHistory();

  const [show, setShow] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [email, setEmail] = useState('');
  const [billCode, setBillCode] = useState('');
  const [emailError, setEmailError] = useState('');
  const [billCodeError, setBillCodeError] = useState('');
  const [isAgreeError, setIsAgreeError] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  function selectBillMode(event) {
    setBillCode(event.target.value)
    if (event.target.value === "02") {
      setShow(true)
    } else {
      setShow(false)
      setEmail('')
    }
  }

  function ValidateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function onSubmit() {
    if (isAgree) {
      setIsAgreeError('')
      if (billCode) {
        if (billCode === '02') {
          if (email) {
            if (ValidateEmail()) {
              setEmailError('')
              setBillCodeError('')

              setBillModeChange(email, getTelephoneNumber(), service_list.accountNo, billCode, setSuccessMsg, setSuccess)
            } else {
              setEmailError('Enter valid email')
            }
          } else {
            setEmailError('E-Mail cannot be empty')
          }
        } else {
          setEmailError('')
          setBillCodeError('')

          setBillModeChange(email, getTelephoneNumber(), service_list.accountNo, billCode, setSuccessMsg, setSuccess)
        }
      } else {
        setBillCodeError('Please Select Bill type')
      }
    } else {
      setIsAgreeError('Please Check Checkbox to Agree')
    }


  }



  function getTelephoneNumber() {
    let saved = localStorage.getItem('selectedAcc')
    if (saved && accountList) {
        if (accountList.find(e => e.telephoneno === saved)) {
            return saved
        }
    } else
        return null
}


  useEffect(() => {
    if (billStatus && billStatus.hasOwnProperty('possiblebillmodelist') && billStatus.possiblebillmodelist.length > 0) {
      if (billCode === '') {
        setBillCode(billStatus.possiblebillmodelist[0].bill_code)
        if (billStatus.possiblebillmodelist[0].bill_code === "02") {
          setShow(true)
        } else {
          setShow(false)
        }
      } else {
        if (success) {
          setBillCode(billStatus.possiblebillmodelist[0].bill_code)
          if (billStatus.possiblebillmodelist[0].bill_code === "02") {
            setShow(true)
          } else {
            setShow(false)
          }
        }else{
          if (successMsg) {
            history.push('/boardBand/summary')
          }
        }
      }
    } else {
      setBillCode('')
    }
  }, [billStatus, billCode, setBillCode, success, successMsg]);// eslint-disable-line react-hooks/exhaustive-deps

  if (loading && loadingSmartBillRegistration) {
    return <div className='changeBBDetails billMode'><LoadingBbBody /></div>
  } else if (smartBillRegistrationError) {
    return <div className='graphBody-wrapper'><div className='graphBody noPadding p-0'><ErrorAlert error={smartBillRegistrationError} setIsError={null}
      setError={null} /></div></div>
  } else if (success) {
    return <div className='graphBody-wrapper'><div className='graphBody noPadding p-0'>{success ?
      <SuccessAlert setSuccess={setSuccess} msg={successMsg} /> : null}
    </div></div>
  } else if (billStatus.hasOwnProperty('possiblebillmodelist') && billStatus.possiblebillmodelist.length <= 0) {
    return <div className='graphBody-wrapper'><div className='graphBody noPadding p-0'>
      <NoPreviledge msg={'You do not have permission to change the bill method'} /> </div> </div>
  }

  return (
    <div className="changeBBDetails billMode">

      <div className="body">
        <div className="form">
            <div className="dBlock">
              <div>Current Bill Method </div>
              <div className=" current-bill-mode"> {
                billStatus.bill_code_desc ? billStatus.bill_code_desc: "No Bill Method"
            } </div>
            <div>
              {
                billStatus.bill_code === "02" ? 
                <svg onClick={() => { history.push('/eBill')}}
                  width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.66667 16.6667C1.20833 16.6667 0.816111 16.5036 0.49 16.1775C0.163333 15.8508 0 15.4583 0 15V1.66667C0 1.20833 0.163333 0.815833 0.49 0.489167C0.816111 0.163055 1.20833 0 1.66667 0H8.33333L13.3333 5V8.33333H11.6667V5.83333H7.5V1.66667H1.66667V15H6.66667V16.6667H1.66667ZM1.66667 15V1.66667V15ZM11.9167 10.4375L12.8125 11.3333L9.58333 14.5417V15.4167H10.4583L13.6875 12.2083L14.5625 13.0833L10.9792 16.6667H8.33333V14.0208L11.9167 10.4375ZM14.5625 13.0833L11.9167 10.4375L13.125 9.22917C13.2778 9.07639 13.4722 9 13.7083 9C13.9444 9 14.1389 9.07639 14.2917 9.22917L15.7708 10.7083C15.9236 10.8611 16 11.0556 16 11.2917C16 11.5278 15.9236 11.7222 15.7708 11.875L14.5625 13.0833Z" fill="white"/>
                </svg> : null
              }
              
            </div>
            

            </div>
        </div>
      </div>

      <div className="body">
        <div className="form">
          <div className="input-container p-0 m-0 my-3">
            <span className='error-message float-right'>{billCodeError}</span>
            <label className="d-block">
              Switch Bill Method<p className="d-inline red">*</p>
            </label>
            <select disabled={loading || loadingSmartBillRegistration} onChange={(e) => selectBillMode(e)}
              className="select" id="serviceType" name="serviceType"
            >
              {
                billStatus.possiblebillmodelist ? billStatus.possiblebillmodelist.map((val, index) => {
                  return <option key={index}
                    value={val.bill_code}>{val.bill_code_desc}</option>
                }) : null
              }

            </select>
          </div>
          {
            show ?
              <div className="input-container p-0 m-0 my-3">
                <span className='error-message float-right'>{emailError}</span>
                <label className="d-block">
                  Update your eBill contact<p className="d-inline red">*</p>
                </label>
                <input type="email" id="contactNumber" name="contactNumber" onChange={(event => setEmail(event.target.value))} />
              </div> : null
          }


          <div className="agreeCondition pr-2 my-3">

            <input type="checkbox" className="sign" checked={isAgree}
              onChange={e => setIsAgree(e.target.checked)}
            />
            {/* <span className='error-message float-right'>{isAgreeError}</span> */}
            <label className="">
              I agree to the general <strong> terms and conditions</strong>{" "}
            </label>
          </div>

          <div>
            <button className="medium blue float-right" disabled={loadingSmartBillRegistration || !isAgree} onClick={() => onSubmit()}>Submit</button>
          </div>
        </div>
      </div>



    </div>
  );
};

const mapStateToProps = state => ({
  accountList: state.accountDetails.account_Details,
  service_list: state.accountDetails.service_list,
  loading: state.billStatus.loading,
  billStatus: state.billStatus.dataBundle,
  loadingSmartBillRegistration: state.smartBillRegistration.loading,
  smartBillRegistrationStatus: state.smartBillRegistration.dataBundle,
  smartBillRegistrationError: state.smartBillRegistration.error,
})

// export default BillMode;
export default connect(mapStateToProps, { setBillModeChange, getBillStatus })(BillMode);