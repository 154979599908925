import React, { useEffect, useState } from 'react'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import PackageSelect from './packages/PackageSelect';
import FeasibilityCheck from './feasibility/FeasibilityCheck';
import CustomerDetails from './customerDetail/CustomerDetails';

import RequestCoverage from './feasibility/RequestCoverage';

import axiosInstance from '../../redux/axiosInstance';
import { getViewUserInfoUrl } from '../../redux/URL';
import { CATCH_ERROR_MSG } from '../../redux/Types';
import FileUploadsLTE from './customerDetail/FileUploadsLTE';
import LTEPayment from './payment/LTEPayment';
import CheckLTEStatus from './CheckLTEStatus';
import LoadingBbBody from '../boardBand/LoadingBbBody';

const MainLTE = ({
    loadingDraftLte,
    draftDetailsLte,
    loadingpayLogLte, 
    payLogLte, 
    charge, 
    loadingInvoice,
    invoice, 
    loadingInvoiceLte,
    invoiceLte,
    payLog
}) => {

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const [state, setState] = useState(1);
    const [userInfo, setUserInfo] = useState({
        title: '',
        fName: '',
        lName: '',
        gender: 'Male',
        iType: 'nic',
        nic: '',
        isNicVerify: false,
        passport: '',
        isPpVerify: false,
        lType: '',
        mobile: '',
        isMobileVerify: false,
        isEmailVerify: false,
        email: '',
        nationality: 'Sri Lankan',
        dateOfBirth: '04/11/1997',
        isExistCus: false,
        altContact: '',
        sltnic: '',
        locationAddress:'',
        billingAddress:''

    });
    const [locationInfo, setLocationInfo] = useState({
        district: 'Colombo',
        city: '',
        postalCode: '',
        address1: '',
        address2: '',
        rTom: '',
        lat: null,
        lng: null,
        dLat: 7.8742,
        dLng:  80.6511,
        grant: false,
    });
    const [packageInfo, setPackageInfo] = useState({
        peoTvPackages: '',
        bbPackages: '',
        voicePackages: '',
        bbPackagesData: {},
        voicePackagesData: {},
        bbType:'',
        isSameAsBilling: false,
        address1: '',
        address2: '',
        province: '',
        district: '',
        city: '',
        postalCode: '',
        billType: 'eBill',
        connection: 'cBoth',
        iddActivate: true
    });
    const [cityList, setCityList] = useState([]);

    const [orderRef, setOrderRef] = useState('');

    const [chargeLTE, setChargeLTE] = useState('');

    useEffect(() => {
        getUserDetails()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(charge.length > 0){
             for(let i = 0; i < charge.length; i++){
                 if(charge[i].BB_TYPE === 'SLT 4G'){
                     setChargeLTE(charge[i].INITIAL_FEE)
                    //  setChargeLTE(3900)
                 }
             }
        }
     }, [charge]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUserDetails = () => {
        setLoading(true)
        let log = localStorage.getItem('userName')

        axiosInstance.get(getViewUserInfoUrl).then(response => {

            if (response.data.isSuccess && response.data.dataBundle.altrContact) {

                if (localStorage.getItem('userType') === 'MOBILE') {
                    setUserInfo({
                        ...userInfo,
                        email: response.data.dataBundle.altrContact,
                        isEmailVerify: true,
                        mobile: log,
                        lType: 'MOBILE',
                        isMobileVerify: true,
                        altContact: response.data.dataBundle.altrContact
                    })
                    setLoading(false)
                } else {
                    setUserInfo({
                        ...userInfo,
                        mobile: response.data.dataBundle.altrContact,
                        isMobileVerify: true,
                        email: log,
                        lType: 'EMAIL',
                        isEmailVerify: true,
                        altContact: response.data.dataBundle.altrContact
                    })
                    setLoading(false)
                }

            } else {

                if (localStorage.getItem('userType') === 'MOBILE') {
                    setUserInfo({...userInfo, mobile: log, lType: 'MOBILE', isMobileVerify: true})
                } else {
                    setUserInfo({...userInfo, email: log, lType: 'EMAIL', isEmailVerify: true})
                }
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)

            if (localStorage.getItem('userType') === 'MOBILE') {
                setUserInfo({...userInfo, mobile: log, lType: 'MOBILE', isMobileVerify: true})
            } else {
                setUserInfo({...userInfo, email: log, lType: 'EMAIL', isEmailVerify: true})
            }
            setLoading(false)
        })
    }

    if (loadingDraftLte || loadingInvoiceLte|| loadingpayLogLte || loadingInvoice) {
        return <div className='mainViewLTE'>
            <LoadingBbBody/> 
        </div>
    }

    if(draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1')){
        return <div className='mainViewFTTH fileUploadFtth'>
            <div className='header'>
                <div className='titles'>
                    <p>NEW SERVICE REQUEST- SLTMOBITEL LTE HOME LINE</p>
                </div>
            </div>
            <CheckLTEStatus crmStatus={payLogLte[0].crm_state} sys_status={payLogLte[0].sys_status} />
        </div>
    }

    if(
        (invoice && invoice.length > 0   && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
        (invoiceLte && invoiceLte.length > 0  && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte))
    ){
        return <Redirect to='/cart' />
    }

  return (
    <div className='mainViewLTE'>
        <div className='header'>
            {
                state === 3 ? <div className='title'>LTE Connection Request -  Feasibility Check - Request Coverage</div>
                            : <div className='title'>NEW SERVICE REQUEST - SLTMOBITEL LTE</div>
            }
           {/* <div className='title'>NEW SERVICE REQUEST- SLTMOBITEL LTE</div> */}
        </div>

        <div className='bodyLTE wizard-container'>
            <div className='navigation'>
                <div className='wizard-navigation-set'>
                    <div className='wizard-item-container'>
                        <div
                            className={state === 1 ? "wizard-item active" : state > 1 ? "wizard-item done" : "wizard-item"}>
                            <div className="item-icon">1</div>
                            <div className="item-name">Package Selection </div>
                        </div>
                        <div
                            className={(state === 2 ||( 2 < state) && (state < 4))? "wizard-item active" : state > 3 ? "wizard-item done" : "wizard-item"}>
                            <div className="item-icon">2</div>
                            <div className="item-name">Feasibility Check</div>
                        </div>
                        <div
                            className={(state === 4 ||( 4 < state) && (state < 6) ) ? "wizard-item active" : state > 3 ? "wizard-item done" : "wizard-item"}>
                            <div className="item-icon">3</div>
                            <div className="item-name">Customer Details</div>
                        </div>
                        <div
                            className={state === 6 ? "wizard-item active" : state > 6 ? "wizard-item done" : "wizard-item"}>
                            <div className="item-icon">4</div>
                            <div className="item-name">Summary</div>
                        </div>
                    </div>
                </div>
            </div>
            {
                state === 1 ? <PackageSelect packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                        locationInfo={locationInfo} userInfo={userInfo} setState={setState}
                        setOrderRef={setOrderRef} orderRef={orderRef}/> 
                    : state === 2 ? <FeasibilityCheck locationInfo={locationInfo} setLocationInfo={setLocationInfo}
                    cityList={cityList} setCityList={setCityList} userInfo={userInfo}  packageInfo={packageInfo}
                    setState={setState} setOrderRef={setOrderRef} orderRef={orderRef}/> : state === 3 ? <RequestCoverage
                    userInfo={userInfo} setUserInfo={setUserInfo} packageInfo={packageInfo} setPackageInfo={setPackageInfo} locationInfo={locationInfo} 
                    setState={setState} loading={loading} setLoading={setLoading}
                    error={error} setError={setError} setIsError={setIsError}
                    isError={isError} setOrderRef={setOrderRef} orderRef={orderRef}/> : state === 4 ? <CustomerDetails 
                    userInfo={userInfo} setUserInfo={setUserInfo} packageInfo={packageInfo} setPackageInfo={setPackageInfo} locationInfo={locationInfo} 
                    setState={setState} loading={loading} setLoading={setLoading}
                    error={error} setError={setError} setIsError={setIsError}
                    isError={isError} setOrderRef={setOrderRef} orderRef={orderRef}/>: state === 5 ? <FileUploadsLTE 
                    setState={setState} loading={loading} setLoading={setLoading} userInfo={userInfo} 
                    locationInfo={locationInfo} packageInfo={packageInfo} orderRef={orderRef}/> : state === 6 ? <LTEPayment 
                    setState={setState} userInfo={userInfo} packageInfo={packageInfo} locationInfo={locationInfo} orderRef={orderRef} chargeLTE={chargeLTE}/> : null 
            }
        </div>
            {/* <button onClick={() => setState(state + 1)}>Click</button>
           <button onClick={() => setState(state - 1)}>Click back</button> */}
    </div>
  )
}

const mapStateToProps = state => ({
    loadingDraftLte: state.lte.loadingDraftLte,
    draftDetailsLte: state.lte.draftDetailsLte,
    loadingpayLogLte: state.lte.loadingPaymentLte,
    payLogLte: state.lte.paymentsLogLte,
    charge: state.charge.chargeDetails,
    loadingInvoice: state.ftth.loadingInvoice,
    invoice: state.ftth.invoice,
    payLog: state.ftth.paymentsLog,
    loadingInvoiceLte: state.lte.loadingInvoiceLte,
    invoiceLte: state.lte.invoiceLte,
})

export default connect(mapStateToProps, null) (MainLTE);

// export default MainLTE
