import {
    ERROR_DAILY_USAGE,
    LOADING_DAILY_USAGE,
    SUCCESS_DAILY_USAGE_CURRENT,
    SUCCESS_DAILY_USAGE_PREV1,
    SUCCESS_DAILY_USAGE_PREV2,
    SUCCESS_GETTING_DAILY_USAGE,
} from "../../Types";

const initialState = {
    loading: true,
    current: [],
    prev1: [],
    prev2: [],
    error:''
}

export default function BroadBandDailyUsageReducer(state= initialState, action){
    switch (action.type){

        case LOADING_DAILY_USAGE : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_DAILY_USAGE_CURRENT : {
            return {
                ...state,
                current: action.payload
            }
        }

        case SUCCESS_DAILY_USAGE_PREV1 : {
            return {
                ...state,
                prev1: action.payload
            }
        }

        case SUCCESS_DAILY_USAGE_PREV2 : {
            return {
                ...state,
                prev2: action.payload
            }
        }

        case SUCCESS_GETTING_DAILY_USAGE : {
            return {
                ...state,
                loading: false
            }
        }

        case ERROR_DAILY_USAGE : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}
