import React from 'react'
import { SingaleAccordion } from './SingaleAccordion';

const Internet = () => {
    const accordionData = [
        {
            title: 'How can I get an SLT Internet service? ',
            content: <ul>
                    <li>There are 3 options
                        <ul>
                            <li>SLT Megaline – Internet through  wired line  </li>
                            <li>SLT 4G LTE – Internet through Wireless</li>
                            <li>SLT Fibre – Internet with Voice through Fibre Optics</li>
                        </ul>
                    </li>
                    <li>Request a new service through the SLT Selfcare App or visit the nearest SLT office </li>
                </ul>
          },
          {
            title: 'What is the cost for an SLT Internet service? ',
            content: <ul>
                    <li>There is a onetime charge  for the installation, and a recurring monthly charge</li>
                    <li>Refer 
                        <a href="https://www.slt.lk/en/broadband"> https://www.slt.lk/en/broadband  </a> 
                        for installation charges
                    </li>
                    <li>Monthly charges vary as per the subscribed package </li>
                </ul>
          },
          {
            title: 'What are the advantages of using an SLT Internet service? ',
            content: <ul>
                    <li>Un-interrupted connectivity</li>
                    <li>Guaranteed high speed internet in Sri Lanka</li>
                    <li>Affordable  prices</li>
                    <li>Bundled with Value Added Services (SLT Storage, SLT Film hall,  SLT Voice App, PEOTV Go…etc.)</li>
                </ul>
          },
          {
            title: 'How can I resolve a matter regarding my SLT connection? ',
            content: <ul>
                    <li>Lodge your complaint via the App (Main Menu—complaints) </li>
                    <li>Dial 1212 from any telephone network to contact our 24/7 Contact Centre</li>
                </ul>
          },
          {
            title: 'How to check the internet data balance?',
            content: <ul>
                    <li>Summary of data balance can be viewed on Selfcare App home page  </li>
                    <li>Detailed data balance can be viewed through Broadband in the main menu </li>
                </ul>
          },
          {
            title: 'How to replace/relocate the internet equipment placing - within premises?',
            content: <ul>
                    <li>Provide a written request to your nearest SLT Office  </li>
                </ul>
          }
    ];

    return (
        <div className = 'faqSubBody'>
            <div className='title'>
                <p>Internet </p>
            </div>
            {accordionData.map(({ title, content }) => (
                 <SingaleAccordion title={title} content={content} />
             ))}
        </div>
        
    )
}

export default Internet
