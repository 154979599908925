import React, {useState} from 'react';
import {connect} from "react-redux";
import {putUpdateContacttUrl} from "../../../redux/URL";
import axiosInstance from "../../../redux/axiosInstance";
import LoadingBbBody from "../LoadingBbBody";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../../redux/Types";

function ChangeContactInformation({profile}) {


    const [fullName, setFullName] = useState(profile.fullname);
    const [email, setEmail] = useState(profile.email);
    const [mobile, setMobile] = useState(profile.phone)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const updateContact = () => {
        setLoading(true)
        let url = putUpdateContacttUrl + 'subscriberID=' + profile.subscriberid + '&fillname=' + fullName + '&email=' + email + '&phone=' + mobile

        setLoading(false)
        axiosInstance.post(url).then(response => {

            if (response.status === 200 && response.data.isSuccess) {
                setSuccess(true)
                setLoading(false)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)

            }

        }).catch(Onerror => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    if (loading) {
        return <div className='changeBBDetails common-panel'>
            <div className='body-container'>
                <div className='title'>
                    Change Contact Information
                </div>
                <LoadingBbBody loading={loading} border={false}/>
            </div>
        </div>
    } else {
        return (
            <div className='changeBBDetails common-panel'>
                <div className='body-container'>
                    <div className='title'>
                        Change Contact Information
                    </div>

                    {success ?
                        <SuccessAlert setSuccess={setSuccess} msg='Successfully updated contact Information'/> : null}
                    {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}

                    <div className='wrapper'>
                        <div className='body'>
                            <div className='form'>
                                <div>
                                    <label className='d-block'>Full Name<p className='d-inline red'>*</p></label>
                                    <input type={"text"} className='pl-2' value={fullName} onChange={(e) => {
                                        setFullName(e.target.value)
                                    }}/>

                                </div>
                                <div>
                                    <label className='d-block'>Email <p className='d-inline red'>*</p></label>
                                    <input type={"email"} className='pl-2' value={email} onChange={(e) => {
                                        setEmail(e.target.value.trim())
                                    }}/>

                                </div>

                                <div>
                                    <label className='d-block'>Mobile <p className='d-inline red'>*</p></label>
                                    <input type={"number"} className='pl-2' value={mobile} onChange={(e) => {
                                        setMobile(e.target.value.trim())
                                    }}/>

                                </div>

                                <div>
                                    <button className='medium blue float-right' onClick={() => updateContact()}>Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = state => ({
    profile: state.broadBand.profile,

})

export default connect(mapStateToProps, {})(ChangeContactInformation);
