export function formatDateTimeByString(inputDate) {
  const year = inputDate.slice(0, 4);
  const month = inputDate.slice(4, 6);
  const day = inputDate.slice(6, 8);

  // Define an array of month names
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
  ];

  // Get the corresponding month name from the array
  const monthName = monthNames[parseInt(month) - 1];

  // Add 'st', 'nd', 'rd', or 'th' to the day based on its value
  let daySuffix;
  if (day === "01" || day === "21" || day === "31") {
    daySuffix = "st";
  } else if (day === "02" || day === "22") {
    daySuffix = "nd";
  } else if (day === "03" || day === "23") {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  // Return the formatted date string
  return day + daySuffix + " " + monthName + " " + year;
  }

  // Function to format the date in YYYYMMDD format
export function formatDate(date) {
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 1).toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  return year + month + day;
}

export function formatTimeByString(date) {
  const hour = date.substring(8, 10);
  const minute = date.substring(10, 12);
  const period = hour < 12 ? 'am' : 'pm';
  
  // Convert the hour to 12-hour format if needed
  const formattedHour = hour % 12 === 0 ? '12' : hour % 12;
  
  // Create the formatted time string
  const formattedTime = `${formattedHour}:${minute} ${period}`;
  
  return formattedTime
}

    
    
export const downloadBase64File = (base64Data, contentType, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource; 
  downloadLink.download = fileName;
  downloadLink.click();
}