import React from "react";
import { connect } from "react-redux";
import DataUsage from "./DataUsage";
import { useState } from "react";
import DataPackages from "./DataPackages";
import { Redirect } from "react-router-dom";
import LoadingBbBody from "../boardBand/LoadingBbBody";

function PrepaidMain({
  loading,
  dataUsage,
  loadingAccounts,
  loadingServices,
  serviceList,
  loadingSubscribeStatus,
  subscribeStatus
}) {
  const [state, setState] = useState("dataUsage");

  if (
    serviceList.hasOwnProperty("promotionType") &&
    serviceList.promotionType !== "Prepaid"
  )
    return <Redirect to="/boardBand/summary" />;

  if (loadingAccounts || loadingServices || loadingSubscribeStatus) {
    return (
      <div className="mainBody eBill">
        <LoadingBbBody loading={true} />
      </div>
    );
  }

  return (
    <div className="mainBody">
      <div className="package-functions">
        <div className="package-functions-container clearfix">
          <div className="function-box-wrapper">
            <div className="function-box clearfix">
              <div className="pkg-details">
                <p className="detail-row">
                  <span className="label">Username : </span>
                  <span className="value"> {localStorage.getItem("selectedAcc")}</span>
                </p>

                <p className="detail-row">
                  <span className="label">Status : </span>
                  <span className="value">
                    {subscribeStatus.statusName}
                  </span>
                </p>
              </div>

              <div className="pkg-details-btn-container">
                <button
                  className={
                    state === "dataUsage"
                      ? "blue w-100 font-weight-bold"
                      : "blue w-100 font-weight-bold white"
                  }
                  onClick={() => setState("dataUsage")}
                >
                  Data Usage
                </button>
              </div>
            </div>
          </div>
          <div className="function-box-wrapper">
            <div className="function-box clearfix">
              <div className="button-container-50">
                <button
                  className={
                    state === "mainPackage"
                      ? "blue  w-100 font-weight-bold"
                      : "blue  w-100 font-weight-bold white"
                  }
                  onClick={() => setState("mainPackage")}
                >
                  Get Main Packages
                </button>
              </div>
              <div className="button-container-50">
                <button
                  className={
                    state === "dataAddOns"
                      ? "blue  w-100 font-weight-bold"
                      : "blue  w-100 font-weight-bold white"
                  }
                  onClick={() => setState("dataAddOns")}
                >
                  Get data Add-ons
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {state === "dataUsage" ? (
        <DataUsage
          loading={loading}
          dataUsage={dataUsage && dataUsage.listofFreeUnit}
        />
      ) : (
        <DataPackages state={state} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUsage: state.prepaid.prepaidData,
  loading: state.prepaid.loading,
  loadingAccounts: state.accountDetails.loading,
  loadingServices: state.accountDetails.loadingServiceList,
  serviceList: state.accountDetails.service_list,
  loadingSubscribeStatus: state.accountDetails.loadingSubscribeStatus,
  subscribeStatus: state.accountDetails.subscribeStatus,
});
export default connect(mapStateToProps, {})(PrepaidMain);
