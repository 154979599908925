import React, {useEffect, useState} from 'react';
import {CheckExistingCustomer, OTPRequest, AuthonticationOpenFTTHLogin, OTPValidation, updatesContacttnfoUrl, saveDraftDetails, updateDraftDetails, verifyOTPOpenFTTH, getCityListUrl, getFeasibilityCheck} from "../../../../redux/URL";
import OTPValidationFTTH from "./OTPValidationFTTH";
import ErrorAlert from "../../../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../../../redux/Types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Querystring from "querystring";
import SearchLocation from './SearchLocation';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { MAP_KEY } from '../../../../redux/MAPKEYS';
import { FaInfoCircle } from "react-icons/fa";

import mapMarkerBlue from '../../../../assests/images/mapMarkerBlue.svg'
import axiosInstanceFtth from '../../../../redux/axiosInstanceFtth';

import { Prompt } from "react-router-dom";

function UserInformation({userInfo, setUserInfo, packageInfo, setPackageInfo, locationInfo, setLocationInfo,
    setCityList, google,
    cityList, setState, loading, setLoading, setError, setIsError, error, isError, setOrderRef, orderRef, chargeFTTH,
    setCheckFesibilty,checkFesibilty}) {

    const logName = localStorage.getItem('userName')
    const logType = localStorage.getItem('userType')

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%'
    }

    const [otpState, setOtpState] = useState(false);
    const [otpType, setOtpType] = useState('');
    const [userRecognitionID, setUserRecognitionID] = useState('');

    const [isCitySelect, setIsCitySelect] = useState(true);
    const [zoom, setZoom] = useState(7);
    const [valueLocSearch, setValueLocSearch] = useState('');
    const homeLine = 390.0
    const [broadbandMonthlyRental, SetBroadbandMonthlyRental] = useState(packageInfo.bbPackagesData ? packageInfo.bbPackagesData.MONTHLY_RENTAL : 0);
    const [peoTvMonthlyRental, SetPeoTvMonthlyRental] = useState(packageInfo.peoPackagesData ? packageInfo.peoPackagesData.MONTHLY_RENTAL : 0);
    const [voiceMonthlyRental, SetVoiceMonthlyRental] = useState(packageInfo.voicePackagesData ? packageInfo.voicePackagesData.MONTHLY_RENTAL : 0);

    const [errorList, setErrorList] = useState({});

    useEffect(() => {
        const beforeUnloadHandler = (event) => {
          event.preventDefault();
          event.returnValue = "Your work will be lost. Are you sure you want to leave?";
        };
    
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);

    const dragEndLatLon = (coord) => {
        const {latLng} = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        setLocationInfo({...locationInfo, lat: lat, lng: lng, dLat: lat, dLng: lng})
        setZoom(16)
    };

    const searchLatLon = (place) => {
        const {location} = place.geometry;
        const lat = location.lat();
        const lng = location.lng();

        setLocationInfo(prevState => ({...prevState, lat: null, lng: null, dLat: lat, dLng: lng}))
        setZoom(16)
    };

    const setCity = (e, option) => {

        if (option) {
            setLocationInfo({
                ...locationInfo,
                district: option.district,
                city: option.city,
                rTom: option.rtom,
                dLat: parseFloat(option.latitude),
                dLng: parseFloat(option.longitude),
                postalCode: option.postalcode,
                // address1: "",
                // address2: "",
                lat: null,
                lng: null,
            })
            setZoom(14)
            setIsCitySelect(true)
            setValueLocSearch('')
        } else {
            setLocationInfo({
                ...locationInfo,
                district: '',
                city: '',
                rTom: '',
                postalCode: '',
                // address1: "",
                // address2: "",
                lat: null,
                lng: null,
            })
            setZoom(14)
            setIsCitySelect(false)
            setValueLocSearch('')
        }


    }

    const getCityList = () => {
        setLoading(true)
        axiosInstanceFtth.get(getCityListUrl).then((response) => {

            if (response.data.isSuccess) {
                setCityList(...[response.data.dataBundle])
                setLoading(false)
            } else {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    const validateEmail = (email) => {

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }


    const handleChange = event => {
        setErrorList({})
        const {name, value} = event.target
        if (name === 'mobile') {
            if (value === logName || (userInfo.altContact && value === userInfo.altContact))
                setUserInfo({...userInfo, [name]: value, isMobileVerify: true})
            else
                setUserInfo({...userInfo, [name]: value, isMobileVerify: false})
        } else if (name === 'email') {
            if (value === logName || (userInfo.altContact && value === userInfo.altContact))
                setUserInfo({...userInfo, [name]: value, isEmailVerify: true})
            else
                setUserInfo({...userInfo, [name]: value, isEmailVerify: false})
        } else if (name === 'title') {
            if (value === 'Mr') {
                setUserInfo({...userInfo, [name]: value, gender: 'Male', nic: '', isNicVerify: false, dateOfBirth: ''})
            } else {
                setUserInfo({...userInfo, [name]: value, gender: 'Female', nic: '', isNicVerify: false, dateOfBirth: ''})
            }
            setUserRecognitionID('')
            // checkUserRecognitionIDType()
        } else if (name === 'dateOfBirth') {
            if (userInfo.nationality === 'Sri Lankan' && userInfo.nic) {
                setUserInfo({...userInfo, [name]: getDobByNIC(userInfo.nic)})
            } else {
                setUserInfo({...userInfo, [name]: value})
            }
        } else if (name === 'nic' || name === 'passport') {
            setUserInfo({...userInfo, [name]: value.toUpperCase(), isNicVerify: false})
        } else {
            setUserInfo({...userInfo, [name]: value})
        }
    }

    const handleChangeBillAddress = event => {
        const {name, value} = event.target
        setPackageInfo({...packageInfo, [name]: value})
    }

    const handleChangeLocationAddress = event => {
        const {name, value} = event.target
        setLocationInfo({...locationInfo, [name]: value})
    }

    const sendOTPRequest = (type) => {
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let mobileRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

        if (type === 'EMAIL') {
            if (!emailRegex.test(userInfo.email)) {
                setError('Enter valid email')
                setIsError(true)
            } else {
                setLoading(true)
                let val = type === 'EMAIL' ? userInfo.email : userInfo.mobile
                let url = OTPRequest + 'requestType=FTTH&requestPeriod=10&otpSource=' + type + '&otpContact=' + val

                axiosInstanceFtth.post(url).then((response) => {

                    if (response.status === 200 && response.data.isSuccess) {
                        setOtpState(true)
                        setLoading(false)
                    } else {
                        setError(response.data.errorShow)
                        setIsError(true)
                        setLoading(false)
                    }
                }).catch(() => {
                    setError(CATCH_ERROR_MSG)
                    setIsError(true)
                    setLoading(false)
                })
            }
        } else if (type === 'MOBILE') {
            if (!mobileRegex.test(userInfo.mobile)) {
                setError('Enter a valid mobile phone number.')
                setIsError(true)
            } else {
                setLoading(true)
                let body = Querystring['stringify']({
                    'userName': userInfo.mobile,
                    'userType': type,
                    'channelID': 'WEB',
                })
            
                axiosInstanceFtth.post(AuthonticationOpenFTTHLogin, body).then(response => {
                    if (response.data.isSuccess) {
                        setOtpState(true)
                        setLoading(false)
                    } else {
                        setError(response.data.errorShow)
                        setIsError(true)
                        setLoading(false)
                    }
                }).catch(() => {
                    setError(CATCH_ERROR_MSG)
                    setIsError(true)
                    setLoading(false)
                })
                
            }
        }


    }

    const otpValidation = (otp) => {
        setLoading(true)

        if(otpType === 'MOBILE'){

            let body = Querystring['stringify']({
                'userName': userInfo.mobile,
                'userType': 'MOBILE',
                'verifyOtp':otp,
                'channelID': 'WEB',
    
            })
        
            axiosInstanceFtth.post(verifyOTPOpenFTTH, body).then(response => {
                if (response.data.isSuccess) {
                    setOtpState(false)
                    setErrorList({})
                    setUserInfo({...userInfo, isMobileVerify: true})
    
                    localStorage.setItem('userName', response.data.dataBundle.user_id)
                    localStorage.setItem('slt_ftth_accessToken', response.data.dataBundle.accessToken)
                    localStorage.setItem('slt_ftth_refreshToken', response.data.dataBundle.refreshToken)
                   
                    updateContact(userInfo.mobile)
                    getCityList()
                    setLoading(false)
                } else {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }
            }).catch(() => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            })
        }else if (otpType === 'EMAIL') {
            let loginType = localStorage.getItem('userType')
            let url = OTPValidation + 'requestType=FTTH&otpSource=' + otpType + '&otpCode=' + otp
            axiosInstanceFtth.post(url).then((response) => {
                if (response.status === 200 && response.data.isSuccess) {
                    setOtpState(false)
                    setErrorList({})
                    if (otpType === 'MOBILE') {
                        setUserInfo({...userInfo, isMobileVerify: true})
                    } else {
                        setUserInfo({...userInfo, isEmailVerify: true})
                    }
                    if (loginType === 'MOBILE' && otpType === 'EMAIL') {
                        updateContact(userInfo.email)
                    }
                    if (loginType !== 'MOBILE' && otpType === 'MOBILE') {
                        updateContact(userInfo.mobile)
                    }
                    setLoading(false)
                } else {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }
            }).catch(() => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            })
        }

    }

    const updateContact = (value) => {
        setLoading(true)
        const Querystring = require('querystring');
        let body = Querystring['stringify']({
            userName: localStorage.getItem('userName'),
            name: userInfo.fName + ' ' + userInfo.lName,
            altrContact: value
        })
        axiosInstanceFtth.post(updatesContacttnfoUrl, body).then(response => {
            if (response.data.isSuccess) {
                setLoading(false)
            } else {
                setError(response.data.erroShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        if(localStorage.getItem('slt_ftth_accessToken')){
           getCityList()
        }
        if(userInfo.iType === 'nic'){
            setUserRecognitionID(userInfo.nic)
        }else {
            setUserRecognitionID(userInfo.passport)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const checkUserRecognitionIDType = () => {
        let isNicRegex = /(?:^(?:[0-9]))/;
        let passportRegex = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;

        if(!userRecognitionID){
            setUserInfo({...userInfo, nic: '', passport: '', iType: 'nic'})
        } else if (isNicRegex.test(userRecognitionID)) {
            setUserInfo({...userInfo, nic: userRecognitionID, iType: 'nic', dateOfBirth: getDobByNIC(userRecognitionID)})
        } else {
            setUserInfo({...userInfo, passport: userRecognitionID, iType: 'pp'})
        }

    }

    const checkNicExistingUser = () => {
        
        let isNicRegex = /(?:^(?:[0-9]))/
        let nicRegex = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/
        let passportRegex = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;

        if(!userRecognitionID){
            setError('Passport/NIC Number cannot be empty')
            setIsError(true)
        } else if (isNicRegex.test(userRecognitionID)) {
            setUserInfo({...userInfo, nic: userRecognitionID, iType: 'nic'})

            if (nicRegex.test(userRecognitionID)) {
                if (getGenderByNIC(userRecognitionID, userInfo.gender)) {
                    let url = CheckExistingCustomer + 'NIC=' + userRecognitionID
                    axiosInstanceFtth.get(url).then(response => {

                        if (response.data.isSuccess) {
                            let obj = response.data.dataBundle

                            if (obj.eligibility === 'N') {
                                setError(response.data.errorMessege)
                                setIsError(true)
                                setUserInfo({...userInfo, nic: ''})
                            } else if (obj.eligibility === 'Y') {
                                if (obj.customer_exist === 'Y') {
                                    setUserInfo({
                                        ...userInfo,
                                        isExistCus: true,
                                        sltnic: obj.sltnic,
                                        isNicVerify: true,
                                        nic: userRecognitionID,
                                        dateOfBirth: getDobByNIC(userRecognitionID)
                                    })
                                } else {
                                    setUserInfo({
                                        ...userInfo,
                                        isExistCus: false,
                                        sltnic: obj.sltnic,
                                        isNicVerify: true,
                                        nic: userRecognitionID,
                                        dateOfBirth: getDobByNIC(userRecognitionID)
                                    })
                                }

                            }

                        } else {
                            setError(response.data.errorShow)
                            setIsError(true)
                        }
                    }).catch((err) => {

                        setError('Oops, something went wrong..!')
                        setIsError(true)
                    })
                } else {
                    let errors = {};
                    errors['title'] = 'Not match with Nic'
                    errors['nic'] = 'Not match with title'
                    setErrorList(errors)
                    setError('NIC and your name title not match')
                    setUserInfo({...userInfo, nic: ''})
                    setIsError(true)
                }
            } else {
                setError('Enter Valid NIC Number')
                setIsError(true)
            }

        } else {
            setUserInfo({...userInfo, passport: userRecognitionID, iType: 'pp'})

            if (passportRegex.test(userRecognitionID)) {
                let url = CheckExistingCustomer + 'PP=' + userRecognitionID
                axiosInstanceFtth.get(url).then(response => {

                    if (response.data.isSuccess) {

                        let obj = response.data.dataBundle
                        if (obj.customer_exist === 'Y') {
                            setUserInfo({...userInfo, isExistCus: true})
                        } else {
                            setUserInfo({...userInfo, isExistCus: false})
                        }
                        if (obj.eligibility === 'N' && obj.outstanding === 'Y') {
                            setError('Please settle the current outstanding balance..!')
                            setIsError(true)
                            setUserInfo({...userInfo, passport: ''})
                        } else if (obj.eligibility === 'N' && obj.outstanding === 'Y') {
                            setError('Oops, you are not eligible to request this service..!')
                            setIsError(true)
                            setUserInfo({...userInfo, passport: ''})
                        } else if (obj.eligibility === 'Y') {

                            setUserInfo({...userInfo, isNicVerify: true, iType: 'pp', passport: userRecognitionID})

                        }
                    } else {
                        setError(response.data.errorShow)
                        setIsError(true)
                    }
                }).catch((err) => {

                    setError('Oops, something went wrong..!')
                    setIsError(true)
                })
            } else {
                setError('Enter Valid Passport Number')
                setIsError(true)
            }
        }

    }

    const getGenderByNIC = (NIC, Gender) => {

        var validGender = false;

        if (NIC.length === 10) {
            let dates = Number(NIC.substring(2, 5));
            if (dates < 500) {
                if (Gender === "Male") {
                    validGender = true;
                }
            } else {
                if (Gender === "Female") {
                    validGender = true;
                }
            }
        } else if (NIC.length === 12) {
            let datesN = Number(NIC.substring(4, 7));
            if (datesN < 500) {
                if (Gender === "Male") {
                    validGender = true;
                }
            } else {
                if (Gender === "Female") {
                    validGender = true;
                }
            }
        }

        return validGender;
    }

    const getDateMonth = (date) => {
        let dateMonth;
        if (date > 31) {
            if (date > 60) {
                if (date > 91) {
                    if (date > 121) {
                        if (date > 152) {
                            if (date > 182) {
                                if (date > 213) {
                                    if (date > 244) {
                                        if (date > 274) {
                                            if (date > 305) {
                                                if (date > 335) {
                                                    date = date - 335;
                                                    dateMonth = "12-" + (date + "").padStart(2, '0');
                                                } else {
                                                    date = date - 305;
                                                    dateMonth = "11-" + (date + "").padStart(2, '0');
                                                }

                                            } else {
                                                date = date - 274;
                                                dateMonth = "10-" + (date + "").padStart(2, '0');
                                            }

                                        } else {
                                            date = date - 244;
                                            dateMonth = "09-" + (date + "").padStart(2, '0');
                                        }

                                    } else {
                                        date = date - 213;
                                        dateMonth = "08-" + (date + "").padStart(2, '0');
                                    }

                                } else {
                                    date = date - 182;
                                    dateMonth = "07-" + (date + "").padStart(2, '0');
                                }

                            } else {
                                date = date - 152;
                                dateMonth = "06-" + (date + "").padStart(2, '0');
                            }

                        } else {
                            date = date - 121;
                            dateMonth = "05-" + (date + "").padStart(2, '0');
                        }

                    } else {
                        date = date - 91;
                        dateMonth = "04-" + (date + "").padStart(2, '0');
                    }

                } else {
                    date = date - 60;
                    dateMonth = "03-" + (date + "").padStart(2, '0');
                }

            } else {
                date = date - 31;
                dateMonth = "02-" + (date + "").padStart(2, '0');
            }

        } else {
            dateMonth = "01-" + (date + "").padStart(2, '0');
        }

        return dateMonth;
    }

    const getDobByNIC = (NIC) => {
        let dob = "";
        if (NIC.length === 10) {
            let year = Number(19 + NIC.substring(0, 2));
            let dates = Number(NIC.substring(2, 5));
            if (dates < 500) {
                dob = year + "-" + getDateMonth(dates);
            } else {
                dates = dates - 500;
                dob = year + "-" + getDateMonth(dates);
            }
        } else if (NIC.length === 12) {
            let year = Number(NIC.substring(0, 4));
            let dates = Number(NIC.substring(4, 7));
            if (dates < 500) {
                dob = year + "-" + getDateMonth(dates);
            } else {
                dates = dates - 500;
                dob = year + "-" + getDateMonth(dates);
            }
        }

        return dob;
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.city,
    });

    const validation = () => {

        let errors = {};
        let formIsValid = true;
        if (!userInfo.title) {
            errors['title'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!userInfo.fName) {
            errors['fName'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!/^[A-Za-z]+$/.test(userInfo.fName)) {
            errors['fName'] = 'Only letters are allowed';
            formIsValid = false;
        } else if (!userInfo.lName) {
            errors['lName'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!/^[A-Za-z]+$/.test(userInfo.lName)) {
            errors['lName'] = 'Only letters are allowed';
            formIsValid = false;
        } else if (!userInfo.isMobileVerify) {
            errors['mobile'] = 'Should be verified'
            formIsValid = false
        } else if (!validateEmail(userInfo.email)){
            errors['email'] = 'Invalid Email Format'
            formIsValid = false
        
        // } else if (!userInfo.isEmailVerify) {
        //     errors['email'] = 'Should be verified'
        //     formIsValid = false
        } else if (!userInfo.email) {
            errors['email'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!locationInfo.address1) {
            errors['address1'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!locationInfo.address2) {
            errors['address2'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!userInfo.dateOfBirth) {
            errors['dateOfBirth'] = 'Cannot be Empty'
            formIsValid = false
        } else if (userInfo.iType === 'nic') {
            if (!userInfo.nic) {
                errors['nic'] = 'Cannot be Empty'
                formIsValid = false
            }else if(!userInfo.isNicVerify){
                errors['nic'] = 'Should be verified'
                formIsValid = false
            }
        } else if (userInfo.iType === 'pp') {
            if (!userInfo.passport) {
                errors['nic'] = 'Cannot be Empty'
                formIsValid = false
            }else if(!userInfo.isNicVerify){
                errors['nic'] = 'Should be verified'
                formIsValid = false
            }
        }

        if (formIsValid) {
            if(!orderRef){
                saveDraft()
            }else{ 
                updateDraft()
            }
        } else {
            setErrorList(errors)
        }

    }

    const saveDraft = () => {
        let body = Querystring['stringify']({
            'NIC': userInfo.nic,
            'PP': userInfo.passport,
            'TITLE': userInfo.title,
            'FIRSTNAME': userInfo.fName,
            'LASTNAME': userInfo.lName,
            'BIRTH_DATE': userInfo.dateOfBirth,
            'NATIONALITY': userInfo.nationality,
            'SERVICE_ADD_NO': 60,
            'SERVICE_ADD_STREET1': locationInfo.address1,
            'SERVICE_ADD_STREET2': locationInfo.address2,
            'SERVICE_ADD_CITY': locationInfo.city,
            'SERVICE_ADD_DISTRICT': locationInfo.district,
            'BILLING_ADD_NO': 60,
            'BILLING_ADD_STREET1': packageInfo.address1,
            'BILLING_ADD_STREET2': packageInfo.address2,
            'BILLING_ADD_CITY': packageInfo.city,
            'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
            'RTOM': locationInfo.rTom,
            'EQTYPE': 'FDP',
            'LONGITUDE': locationInfo.lng,
            'LATITUDE': locationInfo.lat,
            'SERVICE_TYPE': 'FTTH',
            'SERVICE_ADD_PROVINCE': '',
            'BILLING_ADD_PROVINCE': '',
            'VOICE_PACKAGE': packageInfo.voicePackages,
            'BB_PACKAGE': packageInfo.bbPackages,
            'PEO_PACKAGE': packageInfo.peoTvPackages,
            'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
            'gender': userInfo.gender,
            'iddstatus': packageInfo.iddActivate,
            'billinG_MODE': packageInfo.billType,
            'email': userInfo.email,
            'mobileno': userInfo.mobile,
            'LOOP_AVAILABLITY': 'Y',
            'SLTNIC': userInfo.sltnic
        })

        axiosInstanceFtth.post(saveDraftDetails, body).then((response) => {

            if (response.data.isSuccess) {
                setOrderRef(response.data.dataBundle.orderRef) 
                // setState(3)
                isFesibilty()
            } else {
                let errors = {};
                errors['pack'] = 'Save draft failed'
                setErrorList(errors)
            }
        }).catch(() => {
            let errors = {};
            errors['pack'] = 'Save draft failed'
            setErrorList(errors)
        })
    }
    
    const updateDraft = (availability) => {
    
        let body = Querystring['stringify']({
            'NIC': userInfo.nic,
            'PP': userInfo.passport,
            'TITLE': userInfo.title,
            'FIRSTNAME': userInfo.fName,
            'LASTNAME': userInfo.lName,
            'BIRTH_DATE': userInfo.dateOfBirth,
            'NATIONALITY': userInfo.nationality,
            'SERVICE_ADD_NO': 60,
            'SERVICE_ADD_STREET1': locationInfo.address1,
            'SERVICE_ADD_STREET2': locationInfo.address2,
            'SERVICE_ADD_CITY': locationInfo.city,
            'SERVICE_ADD_DISTRICT': locationInfo.district,
            'BILLING_ADD_NO': 60,
            'BILLING_ADD_STREET1': packageInfo.address1,
            'BILLING_ADD_STREET2': packageInfo.address2,
            'BILLING_ADD_CITY': packageInfo.city,
            'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
            'RTOM': locationInfo.rTom,
            'EQTYPE': 'FDP',
            'LONGITUDE': locationInfo.lng,
            'LATITUDE': locationInfo.lat,
            'SERVICE_TYPE': 'FTTH',
            'SERVICE_ADD_PROVINCE': '',
            'BILLING_ADD_PROVINCE': '',
            'VOICE_PACKAGE': packageInfo.voicePackages,
            'BB_PACKAGE': packageInfo.bbPackages,
            'PEO_PACKAGE': packageInfo.peoTvPackages,
            'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
            'gender': userInfo.gender,
            'iddstatus': packageInfo.iddActivate,
            'billinG_MODE': packageInfo.billType,
            'email': userInfo.email,
            'mobileno': userInfo.mobile,
            'LOOP_AVAILABLITY': availability,
            'SLTNIC': userInfo.sltnic,
            'REF_NO': orderRef
        })
    
        axiosInstanceFtth.post(updateDraftDetails, body).then((response) => {
            if (response.data.isSuccess) {
                // setState(3)
                isFesibilty()
            } else {
                let errors = {};
                errors['pack'] = response.data.errorShow
                setErrorList(errors)
            }
            setLoading(false)
        }).catch(() => {
            let errors = {};
            errors['pack'] = 'Update draft failed'
            setErrorList(errors)
        })
    }

    const isMobileVerify = userInfo.isMobileVerify;

    const isFesibilty = () => {
        if (locationInfo.city && locationInfo.lat) {
          let url =
            getFeasibilityCheck +
            "rtom=" +
            locationInfo.rTom +
            "&eqtype=FDP&longitude=" +
            locationInfo.lng +
            "&latitude=" +
            locationInfo.lat +
            "&mobileno=" + userInfo.mobile;
    
        //   setLoading(true);
        axiosInstanceFtth
            .get(url)
            .then((response) => {
              if (response.data.isSuccess) {
                if (response.data.dataBundle.dpexist === "Y") {
                  setCheckFesibilty(true);
                  setState(3)
                } else {
                  setCheckFesibilty(false);
                  setState(3)
                }
                // setLoading(false);
              } else {
                // setLoading(false);
                setError(response.data.errorShow);
                // setIsError(true);
                setCheckFesibilty(false);
                if (!checkFesibilty) {
                    setError(response.data.errorShow)
                    setIsError(true);
                  } else {
                setState(3)
                  }
              }
            })
            .catch(() => {
                //   setLoading(false);
                setError(CATCH_ERROR_MSG);
                //   setIsError(true);
                setCheckFesibilty(false);
                setState(3)
            });
        } else {
          setError("Please select your service installation location");
          setIsError(true);
        }
      };

      const getConnectionfees = (voice, broadband, peoTv) => {
        // Validate input values
        const isVoiceValid = !isNaN(parseFloat(voice));
        const isBroadbandValid = !isNaN(parseFloat(broadband));
        const isPeoTvValid = !isNaN(parseFloat(peoTv));
    
        // Check if at least one parameter is a valid number
        if (isVoiceValid || isBroadbandValid || isPeoTvValid) {
            // Add the valid values and return the sum
            let sum = 0;
            if (isVoiceValid) sum += parseFloat(voice);
            if (isBroadbandValid) sum += parseFloat(broadband);
            if (isPeoTvValid) sum += parseFloat(peoTv);
    
            return sum;
        } else {
            return 0; 
        }
    };

    const tooltipStyle = {
      position: "relative",
      display: "inline-block",
    };
  
    const tooltipTextStyle = {
      visibility: "hidden",
      width: "250px",
      backgroundColor: "#555",
      color: "#fff",
      textAlign: "center",
      borderRadius: "5px",
      padding: "5px",
      position: "absolute",
      zIndex: 1,
      bottom: "125%",
      left: "50%",
      marginLeft: "-125px",
      opacity: 0,
      transition: "opacity 0.3s",
    };
  
    const tooltipTextAfterStyle = {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-5px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "#555 transparent transparent transparent",
    };

    const showTooltip = (event) => {
      const tooltip = event.currentTarget.querySelector(".tooltiptext");
      if (tooltip) {
        tooltip.style.visibility = "visible";
        tooltip.style.opacity = "1";
      }
    };
  
    const hideTooltip = (event) => {
      const tooltip = event.currentTarget.querySelector(".tooltiptext");
      if (tooltip) {
        tooltip.style.visibility = "hidden";
        tooltip.style.opacity = "0";
      }
    };

    return (
        <div className='userInformation'>
            <Prompt when={true} message="Do you want to leave this web page? Your existing data will be gone. Please click the bottom back button to return to the previous step." />
            {otpState ?
                <OTPValidationFTTH otpValidation={otpValidation} email={userInfo.email} mobile={userInfo.mobile}
                                   loading={loading}
                                   otpType={otpType} setOtpState={setOtpState}
                                   sendOTPRequest={sendOTPRequest}/> : null}
            {
                isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null
            }
            <div className='userInfoForm'>
                <div className="packages-container">
                    <div className='package-container'>
            
                        <div className='main-title'>
                            Your selected package  details:
                        </div>
                        <div className='details-title'>
                            {
                                packageInfo.bbPackages ? 
                                    <p> Internet - { packageInfo.bbPackagesData ? packageInfo.bbPackages + " - "  : 'N/A '}
                                    {packageInfo.bbPackagesData.STANDARD_GB !== '0' ? packageInfo.bbPackagesData.STANDARD_GB : 'Unlimited Data'}{packageInfo.bbPackagesData.FREE_GB !== '0' ? ' + ' + packageInfo.bbPackagesData.FREE_GB : null}{packageInfo.bbPackagesData.STANDARD_GB !== '0' ? ' GB' : null }  |  Rs. {parseFloat(broadbandMonthlyRental)}
                                    </p> : null
                            }
                            {
                                packageInfo.peoTvPackages ? 
                                    <p> PEOTV &nbsp; - { packageInfo.peoTvPackages ? packageInfo.peoTvPackages + " - "  : 'N/A ' } 
                                        {packageInfo.peoPackagesData.NO_OF_CHANNELS} Channels |  Rs. {parseFloat(peoTvMonthlyRental)}
                                    </p> : null
                            }
                            {
                                packageInfo.voicePackages ? 
                                    <p> Voice &nbsp; &nbsp; &nbsp;- { packageInfo.voicePackages ? packageInfo.voicePackages + " - "  : 'N/A ' } 
                                        {packageInfo.voicePackagesData.NO_OF_CHANNELS} |  Rs. {parseFloat(voiceMonthlyRental)}
                                    </p> : null
                            }
                            
                        </div>
                    </div>
                    <div className='package-container'>
                        {/* <div className='mini-title'>
                            You have Selected
                        </div>
                        <div className='main-title'>
                            Rs.{chargeFTTH} or Rs.500
                        </div> */}
                        <div className='main-title'>
                             Monthly Rental
                        </div>
                        <div className='details-title' style={{marginTop:'20px'}}>
                        Rs. 
                        {
                            getConnectionfees(voiceMonthlyRental, broadbandMonthlyRental, peoTvMonthlyRental)
                        }
                        {" "} + Usage Charges + Taxes
                        </div>
                        <div style={{ textAlign: 'left' , marginTop:'30Px', fontSize: '15px' , color:'#1057a6'}}>
                            Taxes are applicable * 
                        </div>
                    </div>
                </div>

                <div className="form-container clearfix">
                    <div className="form-inputs-container">
                        <div className='d-flex'>

                            <div className='input-container title-input w-auto'>
                                <label>
                                    <div className="label">Title</div>
                                    <p className='d-inline red m-0 ml-1'>*</p>
                                </label>
                                <div className="input-field"> {errorList.title ?
                                    <div className="error-msg mt-2">{errorList.title}</div> : null}
                                    <select name='title' value={userInfo.title}
                                            onChange={handleChange}>
                                        <option value='' hidden={true}/>
                                        <option value='Mr'>Mr</option>
                                        <option value='Mrs'>Mrs</option>
                                        <option value='Ms'>Ms</option>
                                    </select></div>
                            </div>

                            <div className='input-container'>
                                <label>
                                    <div className="label">First Name</div>
                                    <p className='d-inline red m-0 ml-1'>*</p>
                                </label>
                                <div className="input-field">
                                    {errorList.fName ? <div className="error-msg">{errorList.fName}</div> : null}
                                    <input type='text' className={errorList.fName ? 'w-100 error' : 'w-100'}
                                           name='fName' value={userInfo.fName}
                                           onChange={handleChange}/></div>
                            </div>
                        </div>
                        <div className='input-container'>
                            <label>
                                <div className="label">Last Name</div>
                                <p className='d-inline red m-0 ml-1'>*</p>
                            </label>
                            <div className="input-field">{errorList.lName ?
                                <div className="error-msg">{errorList.lName}</div> : null}
                                <input type='text' className={errorList.lName ? 'w-100 error' : 'w-100'}
                                       name='lName' value={userInfo.lName}
                                       onChange={handleChange}/></div>
                        </div>

                        <div className='input-container verified'>
                            <label>
                                <div className="label">Mobile</div>
                                <p className='d-inline red m-0 ml-1'>*</p>
                            </label>
                            <div className="input-field">
                                {errorList.mobile ? <div className="error-msg">{errorList.mobile}</div> : null}
                                <input type='text' className={errorList.mobile ? 'w-100 error' : 'w-100'}
                                       disabled={logType === 'MOBILE'}
                                       name='mobile' value={userInfo.mobile}
                                       onChange={handleChange}/>
                                {!userInfo.isMobileVerify ?
                                    <button disabled={loading} className='btn-verify' onClick={() => {
                                        setOtpType('MOBILE')
                                        sendOTPRequest('MOBILE')
                                    }}>{loading ? 'Verifying' : 'Verify'}</button> :
                                    <span className='confirm'><span className="icon-confirm"/></span>}
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className='input-container'>
                                <label>
                                    <div className="label">NIC/ Passport</div>
                                    <p className='d-inline red m-0 ml-1'>*</p>
                                </label>
                                <div className="input-field">
                                    {errorList.nic ? <div className="error-msg">{errorList.nic}</div> : null}
                                        <input type='text' className={errorList.nic ? 'w-100 error' : 'w-100'}
                                                name='nic' 
                                                //value={userRecognitionID} 
                                                // value={userInfo.iType === 'nic' ? userInfo.nic : userInfo.passport} 
                                                value={userRecognitionID} 
                                                // value={userInfo.iType === 'nic' ? userInfo.nic : userInfo.passport} 
                                                disabled={!userInfo.isMobileVerify}
                                                onChange={(e) => {
                                                        setUserRecognitionID(e.target.value.trim())
                                                        setErrorList({})
                                                        checkUserRecognitionIDType()
                                                    }
                                                } onBlur={() => {
                                                        checkNicExistingUser()
                                                        checkUserRecognitionIDType()
                                                    }
                                                }
                                                style={{ backgroundColor: !userInfo.isMobileVerify ? '#e0e0e0' : 'inherit' }}/>
                                            {userInfo.isNicVerify ?
                                            <span className='confirm'><span
                                                className="icon-confirm"/></span> : null}
                                    </div>
                            </div>

                            <div className='input-container'>
                                <label>
                                    <div className="label">Date of Birth</div>
                                    <p className='d-inline red m-0 ml-1'>*</p>
                                </label>
                                <div className="input-field"> {errorList.dateOfBirth ?
                                    <div className="error-msg">{errorList.dateOfBirth}</div> : null}
                                    <input type='date' className={errorList.dateOfBirth ? 'w-100 error' : 'w-100 '}
                                           disabled={
                                                userInfo.iType === "nic" ? 
                                                (userInfo.isNicVerify && userInfo.nic) : 
                                                !( userInfo.isNicVerify && userInfo.passport)
                                            }
                                           name='dateOfBirth' dataformatas={'dd/mm/yyyy'} placeholder={'dd/mm/yyyy'}
                                           value={userInfo.dateOfBirth}
                                           onChange={handleChange}/>
                                </div>
                            </div>

                        </div>

                        <div className='input-container verified'>
                        <div
                          style={tooltipStyle}
                          onMouseEnter={showTooltip}
                          onMouseLeave={hideTooltip}
                        >
                          <label>
                            <div className="label">Email</div>
                          </label>

                          <p className="d-inline red m-0 ml-1">*</p>
                          &nbsp;<FaInfoCircle />                 
                          <span className="tooltiptext" style={tooltipTextStyle}>
                            You will receive your monthly bill to the above email. You can
                            change your preferred bill receiving method (to email/ SMS /
                            or MySLT App) by contacting 1212 or via the MySLT App.
                            <span style={tooltipTextAfterStyle}></span>
                          </span>
                        </div>
                            {/* <div className="input-field">
                                {errorList.email ? <div className="error-msg">{errorList.email}</div> : null}
                                <input type='text' className={errorList.email ? 'w-100 error' : 'w-100'}
                                       disabled={!userInfo.isMobileVerify}
                                       name='email' value={userInfo.email}
                                       onChange={handleChange}/>
                                {!userInfo.isEmailVerify ?
                                    <button disabled={loading} className='btn-verify' onClick={() => {
                                        setOtpType('EMAIL')
                                        sendOTPRequest('EMAIL')
                                    }}>{loading ? 'Verifying' : 'Verify'}</button> :
                                    <span className='confirm'><span className="icon-confirm"/></span>
                                }</div> */}
                                <div className="input-field">
                                {errorList.email ? <div className="error-msg">{errorList.email}</div> : null}
                                <input type='text' className={errorList.email ? 'w-100 error' : 'w-100'}
                                       name='email' value={userInfo.email}
                                       disabled={!userInfo.isMobileVerify}
                                       onChange={handleChange}
                                       style={{ backgroundColor: !userInfo.isMobileVerify ? '#e0e0e0' : 'inherit' }}/>
                                </div>
                        </div>

                        <div  className='input-container'>
                            <label>
                                <div className="label">Address Line 1</div>
                                    <p className='d-inline red m-0 ml-1'>*</p>
                            </label>
                            <div className="input-field">

                                {errorList.address1 ? <div className="error-msg">{errorList.address1}</div>
                                    : null}
                                    <input className={errorList.address1 ? 'w-100 error' : 'w-100'} type='text'
                                        name='address1' value={locationInfo.address1}
                                        disabled={packageInfo.isSameAsBilling && !userInfo.isMobileVerify}
                                        style={{ backgroundColor: !userInfo.isMobileVerify ? '#e0e0e0' : 'inherit' }}
                                        onChange={handleChangeLocationAddress}/>
                            </div>
                        </div>

                        <div  className='input-container'>

                            <label>
                                <div className="label">Address Line 2</div>
                                <p className='d-inline red m-0 ml-1'>*</p>
                            </label>

                            <div className="input-field">
                                {errorList.address2 ? <div className="error-msg">{errorList.address2}</div>
                                    : null}
                                    <input className={errorList.address2 ? 'w-100 error' : 'w-100'} type='text'
                                        name='address2' value={locationInfo.address2}
                                        disabled={packageInfo.isSameAsBilling && !userInfo.isMobileVerify}
                                        style={{ backgroundColor: !userInfo.isMobileVerify ? '#e0e0e0' : 'inherit' }}
                                        onChange={handleChangeLocationAddress}/>
                            </div>
                              
                        </div>

                    </div>
                    <div className="form-inputs-container">

                        <div className='input-container' style={{marginBottom:'20px'}}>

                            <label className='white large' style={{marginBottom:'20px'}}>Nearest City <p
                                className='d-inline red m-0 ml-1'>* {!isCitySelect ? 'Please select a city' : null}</p>
                            </label>

                            <Autocomplete
                                className={`autoCom ${isMobileVerify ? 'mobile-verify' : 'not-mobile-verify'}`}
                                options={cityList}
                                getOptionLabel={(option) => option.city}
                                // style={{height: 40, padding: 0}}
                                onChange={setCity}
                                value={cityList.find(obj => {
                                    return obj.city === locationInfo.city
                                })}
                                filterOptions={filterOptions}
                                // onInputChange={()=>InputChanges()}
                                disabled={!userInfo.isMobileVerify}
                                style={{ backgroundColor: !userInfo.isMobileVerify ? '#e0e0e0' : 'inherit' , borderRadius:'5px' }}
                                freeSolo
                                renderInput={(params) => <TextField  {...params} label="" placeholder="First select your nearest city/home town/village name" variant="outlined"
                                                                    style={{margin: '0px' }}/>}
                            />
                        </div>


                        <label className='white large' style={{marginBottom:'20px'}}>Place the pin in your home/business location </label>

                        <div className='map-container'>
                            {!locationInfo.city && !userInfo.isMobileVerify ? <div className='mask'/> : null}

                            <div className={`input-container ${!locationInfo.city ? 'masked' : ''}`} disabled={true} style={{marginBottom:'20px'}}>
                                <SearchLocation searchLatLon={searchLatLon} valueLocSearch={valueLocSearch}
                                                setValueLocSearch={setValueLocSearch}/>
                                <span className='btn-remove' hidden={!valueLocSearch} onClick={() => setValueLocSearch('')}/>
                            </div>

                            <div className={`map-container ${!locationInfo.city? 'masked' : ''}`} disabled={true}>
                                <Map google={google} zoom={zoom} containerStyle={containerStyle}
                                    center={{lat: locationInfo.dLat, lng: locationInfo.dLng}}
                                    mapTypeControl={false}
                                    onClick={(t, map, coord) => dragEndLatLon(coord)}
                                >
                                    {locationInfo.lat && (
                                        <Marker
                                            name={'Current location'} draggable={true}
                                            position={{lat: locationInfo.lat, lng: locationInfo.lng}}
                                            onDragend={(t, map, coord) => dragEndLatLon(coord)}
                                            icon={{
                                                url: mapMarkerBlue,
                                                scaledSize: new google.maps.Size(40, 70)
                                            }}
                                        />
                                    )}
                                </Map>
                            </div>
                        </div>

                        <div className='input-container'></div>
                        </div>
                </div>
            </div>


            <div className='container-footer'>
                <div className="action-container right">
                    <button className="btn-back" onClick={() => setState(1)}>Back</button>
                    <button onClick={() => validation()} disabled={!userInfo.isMobileVerify}>Next</button>
                </div>
            </div>
        </div>
    );
   
}

export default GoogleApiWrapper({
    apiKey: (MAP_KEY)
})(UserInformation)

// export default UserInformation;
