import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_PURCHASE_PAYMENT_HISTORY,
    LOADING_PURCHASE_PAYMENT_HISTORY,
    SUCCESS_PURCHASE_PAYMENT_HISTORY
} from "../../Types";
import {getHistoryGBDetailsUrl} from "../../URL";

export default function getBroadbandPurchaseHistory(subcriberID, from, to){
    let url = getHistoryGBDetailsUrl + 'historyFrom=' + from + '&historyTo=' + to + '&subscriberID=' + subcriberID
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_PURCHASE_PAYMENT_HISTORY))
        axiosInstance.get(url).then((response) => {
            if(response.status=== 200 && response.data.isSuccess){

                dispatch(dispatchSuccess(SUCCESS_PURCHASE_PAYMENT_HISTORY, response.data.dataBundle))
            }else {
                dispatch(dispatchError(ERROR_PURCHASE_PAYMENT_HISTORY, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_PURCHASE_PAYMENT_HISTORY, CATCH_ERROR_MSG))
        })
    }
    // axiosInstance.get()
}
