import axiosInstance from "../../axiosInstance";
import { dispatchError, dispatchSuccess, loadingDispatch } from "../../DispatchFunctions";
import { 
    CATCH_ERROR_MSG,
    ERROR_GET_BILL_STATUS,
    GET_BILL_STATUS,
    SUCCESS_GET_BILL_STATUS 
} from "../../Types";

import { ebillTypeStatusUrl } from "../../URL";

export default function getBillStatus(telephoneNo, accountNo){
    let url = ebillTypeStatusUrl + 'tpNo=' + telephoneNo + '&accountNo=' + accountNo

    return (dispatch) => {
        dispatch(loadingDispatch(GET_BILL_STATUS))
        axiosInstance.get(url).then((response) => {

            if(response.status=== 200 && response.data.isSuccess){

                dispatch(dispatchSuccess(SUCCESS_GET_BILL_STATUS, response.data.dataBundle))
            }else {

                dispatch(dispatchError(ERROR_GET_BILL_STATUS, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_BILL_STATUS, CATCH_ERROR_MSG))
        })
    }

}

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}