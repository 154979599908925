import React, {useState} from 'react'
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";

import Internet from '../faq/Internet';
import BillAndPayment from '../faq/BillAndPayment';
import General from '../faq/General';
import PeoTV from '../faq/PeoTV';
import SltVoice from '../faq/SltVoice';

const FAQ = () => {
    const {path, url} = useRouteMatch()
    const [state, setState] = useState('sltVoice');

    function classNameSet(link) {
        let word = window.location.href

        if (word.split("/").includes(link)) {
            return 'buttonBB colored'
        } else {
            return 'buttonBB'
        }

    }

    function sltVoiceClassName() {
        let word = window.location.href

        if (word.split("/").includes('sltVoice') && state === 'sltVoice') {
            return 'buttonBB colored'
        } else {
            return 'buttonBB'
        }
    }

 

    return (
        <div className='mainBody'>
             <div className='boardBandNav'>
                <Link to={`${url}/sltVoice`} className={sltVoiceClassName()} onClick={()=> setState('sltVoice')}>
                    <div className='white'>SltVoice</div>
                </Link>
                <Link to={`${url}/internet`} className={classNameSet('internet')}>
                    <div>Internet</div>
                </Link>
                <Link to={`${url}/peoTV`} className={classNameSet('peoTV')}>
                    <div>Peo TV</div>
                </Link>
                <Link to={`${url}/billAndPayment`} className={classNameSet('billAndPayment')}>
                    <div>Bill & Payment</div>
                </Link>
                <Link to={`${url}/general`} className={classNameSet('general')}>
                    <div>General</div>
                </Link>
             </div>
             <Switch>
                <Route path={`${path}/internet`}>
                    <Internet state={state} setState={setState}/>
                </Route>
                <Route path={`${path}/internet`}>
                    <Internet/>
                </Route>
                <Route path={`${path}/general`}>
                    <General/>
                </Route>
                <Route path={`${path}/billAndPayment`}>
                    <BillAndPayment/>
                </Route>
                <Route path={`${path}/sltVoice`}>
                    <SltVoice />
                </Route>
                <Route path={`${path}/peoTV`}>
                    <PeoTV/>
                </Route>
            </Switch>
        </div>
    )
}

export default FAQ
