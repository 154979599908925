import passwordLog from "../../assests/images/newLoginSvg/password svg.svg";
import eyeIcon from "../../assests/images/loginUI/br2128px.png";
import OtpInput from 'react-otp-input';

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {resetPasswordOTP, resendOTP, setPublicActionToInitialState} from "../../redux/action/login.action";
import eyeCloseIcon from "../../assests/images/loginUI/br1128px.png";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";
import {Link} from "react-router-dom";
import backIcon from "../../assests/images/loginUI/back.png";

function OtpValidation(props) {
    const [OTP, setOTP] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const togglePasswordVisiblity = () => {
        setIsPasswordShown(!isPasswordShown)

    }


    useEffect(() => {
        props.setPublicActionToInitialState()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {
        if (OTP.length === 6) {
            if (password.length < 6) {
                setPasswordError('Minimum 6 characters')
            } else {
                const Querystring = require('querystring');
                let body = Querystring['stringify']({
                    verifyOtp: OTP,
                    newPassword: password,
                    UserName: localStorage.getItem('userName')
                })
                props.resetPasswordOTP(body, props.history, setError, setIsError)
            }
        }
    }

    if (props.loading)
        return <LoadingBbBody/>


    return (
        <div>
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
            <div className='form d-block m-auto  login-form'>
                <p className='titles'>Forgot Password</p>

                <p className='small message mt-4 text-center'>Enter 6 digits OTP code to reset your profile password.</p>

                <div className='mt-4 w-100'>


                    <div className='ml-lg-4 ml-md-4 ml-sm-4 ml-5'>
                        <OtpInput
                            value={OTP}
                            onChange={setOTP}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle='otp'
                            className='otpContainer'
                        />

                    </div>

                    <div className='mt-3 position-relative'>

                        <span className='error-message float-right'>{passwordError}</span>
                        <label className='d-block'>New Password <p className='d-inline red'>*</p></label>

                        <img src={passwordLog} alt='userLogo' className='icons'/>
                        <img src={isPasswordShown ? eyeIcon : eyeCloseIcon} alt='userLogo' className='eye'
                             onClick={() => togglePasswordVisiblity()}/>
                        <input type={isPasswordShown ? "text" : "password"} className='pLeft' value={password} placeholder='Minimum 6 characters'
                               onChange={(e) => setPassword(e.target.value.trim())}/>

                    </div>

                    <div className='mt-4 text-center'>
                        <button className='medium blue'
                                onClick={(e) => submit(e)}>{props.loading ? 'Please wait...' : 'Submit'}</button>
                    </div>

                    {/*<div className='mt-4'>*/}
                    {/*    <p className='small message d-inline-block'>Have you received your OTP yet?</p>*/}
                    {/*    <button className='medium blue ml-3' onClick={() => props.resendOTP()}>Resend</button>*/}
                    {/*</div>*/}

                    <div className='register-container mt-3' >
                        <p className='register-label'>Have you received your OTP yet?</p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className='btn-register'   onClick={() => props.resendOTP()}>Resend
                        </a>
                    </div>

                </div>


            </div>

            <Link to='/' className="btn-back mt-3">
                <div className='back'>
                    <img src={backIcon} alt='backLogo' className='back'/>
                    <p>Back to sign in</p>

                </div>
            </Link>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error
})
export default connect(mapStateToProps, {resetPasswordOTP, resendOTP, setPublicActionToInitialState})(OtpValidation);
