import { downloadBase64File } from "../../../utils/utils";
import axiosInstance from "../../axiosInstance";
import { dispatchError, dispatchSuccess, loadingDispatch } from "../../DispatchFunctions";
import { 
    CATCH_ERROR_MSG,
    ERROR_GET_BILL_DOWNLOAD_REQUEST,
    GET_BILL_DOWNLOAD_REQUEST,
    SUCCESS_GET_BILL_DOWNLOAD_REQUEST
} from "../../Types";
import { ebillDownloadRequestUrl } from "../../URL";


export default function getBillDownloadRequest(email, telephoneNo, accountNo, ebillMonth, setSuccess, setIsError, setError){
    let url = ebillDownloadRequestUrl + 'eContact=' + email+ '&tpNo=' + telephoneNo + '&accountNo=' + accountNo + '&ebillMonth=' + ebillMonth

    return (dispatch) => {
        dispatch(loadingDispatch(GET_BILL_DOWNLOAD_REQUEST))
        axiosInstance.get(url).then((response) => {

            if(response.status=== 200 && response.data.isSuccess){
                dispatch(dispatchSuccess(SUCCESS_GET_BILL_DOWNLOAD_REQUEST, response.data.dataBundle))
                setSuccess(true)
                downloadBase64File(response.data.dataBundle, 'application/pdf', 'test.pdf')
            }else {
                dispatch(dispatchError(ERROR_GET_BILL_DOWNLOAD_REQUEST, response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_BILL_DOWNLOAD_REQUEST, CATCH_ERROR_MSG))
            setError(CATCH_ERROR_MSG)
                setIsError(true)
        })
    }

}

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}