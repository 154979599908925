import {combineReducers} from "redux";
import loginReducer from "./login.reducer";
import bannerReducers from "./banner.reducers";
import accountDetailsReducers from "./accountDetails.reducers";
import broadBandReducers from "./broadBand.reducers";
import BroadBandPaymentReducer from "./broadBand/BroadBandPayment.reducer";
import BroadBandDailyUsageReducer from "./broadBand/BroadBandDailyUsage.reducer";
import protocolReportReducer from "./broadBand/protocolReport.reducer";
import dataAddOnsReducer from "./broadBand/dataAddOns.reducer";
import packageUpgradeReducer from "./broadBand/packageUpgrade.reducer";
import extraGBDetailsReducer from "./broadBand/extraGBDetails.reducer";
import billHistoryReducer from "./bill/billHistory.reducer";
import complaintsReducer from "./complaints/complaints.reducer";
import redeemVoucherReducer from "./redeemVoucher/redeemVoucher.reducers";
import giftDataDetailsReducer from "./broadBand/giftDataDetails.reducer";
import ftthReducers from "./ftth/ftth.reducers";
import lteReducers from "./lte/lte.reducers";
import ChargeReducers from "./charge/charge.reducer";
import billStatusReducer from "./bill/billStatus.reducer";
import billDownloadRequestReducer from "./bill/billDownloadRequest.reducer";
import smartBillRegistrationReducer from "./bill/smartBillRegistration.reducer"
import prepaidReducer from "./prepaid/prepaid.reducers";


export default combineReducers({
    login: loginReducer,
    banners: bannerReducers,
    accountDetails: accountDetailsReducers,
    broadBand: broadBandReducers,
    broadBandPayment: BroadBandPaymentReducer,
    dailyUsage: BroadBandDailyUsageReducer,
    protocolReport: protocolReportReducer,
    dataAddOns: dataAddOnsReducer,
    packageUpgrade: packageUpgradeReducer,
    extraGB: extraGBDetailsReducer,
    billHistory: billHistoryReducer,
    complaint: complaintsReducer,
    voucher: redeemVoucherReducer,
    giftData: giftDataDetailsReducer,
    ftth: ftthReducers,
    lte: lteReducers,
    charge: ChargeReducers,
    billStatus: billStatusReducer,
    billDownload: billDownloadRequestReducer,
    smartBillRegistration: smartBillRegistrationReducer,
    prepaid: prepaidReducer

})
