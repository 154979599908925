import axiosInstance from  "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions"
import { ERROR_GET_COMPLAINTS_LIST, ERROR_POST_COMPLAINTS, LOADING_GET_COMPLAINTS_LIST, LOADING_POST_COMPLAINTS, SUCCESS_GET_COMPLAINTS_LIST, SUCCESS_POST_COMPLAINTS } from "../../Types";
import { createFaultReques, getComplaintsListUrl } from "../../URL";

export function getUserComplaintsList(telephoneNo){
    let url = getComplaintsListUrl + 'faultyTelephoneNo=' + telephoneNo
    return (dispatch) => {
       dispatch(loadingDispatch(LOADING_GET_COMPLAINTS_LIST)) 
       axiosInstance.get(url).then((response) => {
           if(response.status === 200 && response.data.isSuccess){

                dispatch(dispatchSuccess(SUCCESS_GET_COMPLAINTS_LIST, response.data.dataBundle))
           }else{

                dispatch(dispatchError(ERROR_GET_COMPLAINTS_LIST, response.error.errorShow))
           } 
            
       }).catch((error) => {
        dispatch(dispatchError(ERROR_GET_COMPLAINTS_LIST, 'erross'))
       })
    }
  
}

export function createUserComplaint(telephoneNo, serviceID, serviceOption, contactNo, faultdescription, lattitude, longitude, status){
    let url = createFaultReques + 'faultyTelephoneNo=' + telephoneNo + '&serviceOption=' + serviceOption + '&contactNo='+  contactNo +'&faultdescription='+ faultdescription +'&lattitude='+ lattitude +'&longitude=' + longitude + '&status=' + status + '&serviceID=' + serviceID
    return(dispatch) => {
        dispatch(loadingDispatch(LOADING_POST_COMPLAINTS))
        axiosInstance.post(url).then((response) => {
            if(response.status === 200 && response.data.isSuccess){

                dispatch(dispatchSuccess(SUCCESS_POST_COMPLAINTS, response.data.dataBundle))
                // dispatch(loadingDispatch(LOADING_GET_COMPLAINTS_LIST)) 
            }else{

                dispatch(dispatchError(ERROR_POST_COMPLAINTS, response.data.errorShow))
                dispatch(loadingDispatch(LOADING_GET_COMPLAINTS_LIST)) 
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_POST_COMPLAINTS, 'errorPost'))
            // dispatch(loadingDispatch(LOADING_GET_COMPLAINTS_LIST)) 
        })
    }
}