import OtpInput from 'react-otp-input';
import React, {useEffect, useState} from "react";
import backIcon from "../../assests/images/loginUI/back.png";
import {connect} from "react-redux";
import {resetPasswordOTP, resendOTP, setPublicActionToInitialState} from "../../redux/action/login.action";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";

function NewOtpValidation(props) {
    const [OTP, setOTP] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordShown] = useState(false);

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');




    useEffect(() => {
        props.setPublicActionToInitialState()
    }, [props.setPublicActionToInitialState]);// eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {
        if (OTP.length === 6) {
            if (password.length < 6) {
                setPasswordError('Minimum 6 characters')
            } else {
                const Querystring = require('querystring');
                let body = Querystring['stringify']({
                    verifyOtp: OTP,
                    newPassword: password,
                    UserName: localStorage.getItem('userName')
                })
                props.resetPasswordOTP(body, props.history, setError, setIsError, props.setState)
            }
        }
    }

    if (props.loading)
        return <LoadingBbBody/>


    return (
        <div className='auth-container justify-content-center'>
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
            <div className='login-form'>
                <div className='form d-block m-auto'>
                    <p className='titles'>Forgot Password</p>

                    <p className='message'>Enter 6 digits OTP code to reset your profile
                        password.</p>

                    <div className='mt-4 w-auto mx-auto'>


                        <div className='d-flex justify-content-center'>
                            <OtpInput
                                value={OTP}
                                onChange={setOTP}
                                numInputs={6}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle='otp'
                                className='otpContainer'
                            />

                        </div>

                        <div
                            className={passwordError ? "input-container error ml-1 position-relative" : "input-container ml-1 "}>

                            <span className='error-message float-right'>{passwordError}</span>
                            <label className='d-block'>Password <p className='d-inline red'>*</p></label>
                            {/*<img src={isPasswordShown ? eyeIcon : eyeCloseIcon} alt='eyeLogo' className=''*/}
                            {/*     onClick={(e) => togglePasswordVisiblity()}/>*/}
                            <input type={isPasswordShown ? "text" : "password"} className='pLeft'
                                   value={password}
                                   placeholder='Minimum 6 characters'
                                   onChange={(e) => setPassword(e.target.value.trim())}/>

                        </div>

                        <div className='mt-4 text-center'>
                            <button className='medium blue'
                                    onClick={(e) => submit(e)}>{props.loading ? 'Please wait...' : 'Submit'}</button>
                        </div>

                        {/*<div className='mt-5'>*/}
                        {/*    <p className='small grey d-inline-block text-white'>Have you received your OTP yet?</p>*/}
                        {/*    <button className='medium white ml-3' onClick={() => props.resendOTP()}>Resend</button>*/}
                        {/*</div>*/}

                        <div className='register-container mt-5' >
                            <p className='register-label'>Have you received your OTP yet?</p>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className='btn-register' onClick={() => props.resendOTP()}>Resend</a>
                        </div>

                    </div>


                </div>


                <div className='btn-back back mt-4' onClick={() => props.setState('signin')}>
                    <img src={backIcon} alt='backLogo' className='back'/>
                    <p>Back to sign in</p>

                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error
})
export default connect(mapStateToProps, {resetPasswordOTP, resendOTP, setPublicActionToInitialState})(NewOtpValidation);
