import axiosInstance from "../../axiosInstance";
import { dispatchError, dispatchSuccess, loadingDispatch } from "../../DispatchFunctions";
import { 
    CATCH_ERROR_MSG,
    ERROR_SET_SMART_BILL_REGISTRATIONT,
    SET_SMART_BILL_REGISTRATION,
    SUCCESS_SET_SMART_BILL_REGISTRATION
} from "../../Types";
import { ebillSmartBillRegistrationUrl } from "../../URL";

import getBillStatus from "./billStatus.action";


export default function setBillModeChange(email, telephoneNo, accountNo, billCode, setSuccessMsg, setSuccess){//email, telephoneNo, accountNo, billCode
    let url = ebillSmartBillRegistrationUrl //+ 'econtact=' + email+ '&tpNo=' + telephoneNo + '&accountNo=' + accountNo + '&billCode=' + billCode
    const Querystring = require('querystring');
    let body = Querystring['stringify']({
        econtact: email,
        tpNo: telephoneNo,
        accountNo: accountNo,
        billCode: billCode
    })
    return (dispatch) => {
        dispatch(loadingDispatch(SET_SMART_BILL_REGISTRATION))
        axiosInstance.post(url, body).then((response) => {

            if(response.status=== 200 && response.data.isSuccess){
                if(response.data.dataBundle.isSuccess){
                    setSuccessMsg("Your bill method changed successfully.")
                    setSuccess(true)
                    dispatch (getBillStatus(telephoneNo, accountNo))
                    dispatch(dispatchSuccess(SUCCESS_SET_SMART_BILL_REGISTRATION, response.data.dataBundle.dataBundle))
                }else{
                    dispatch(dispatchError(ERROR_SET_SMART_BILL_REGISTRATIONT, response.data.dataBundle.errorShow))
                }
            }else {
                dispatch(dispatchError(ERROR_SET_SMART_BILL_REGISTRATIONT, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_SET_SMART_BILL_REGISTRATIONT, CATCH_ERROR_MSG))
        })
    }

}

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}