import axiosInstance from "../../axiosInstance";
import { dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    LOADING_DAILY_USAGE,
    SUCCESS_DAILY_USAGE_CURRENT, SUCCESS_DAILY_USAGE_PREV1, SUCCESS_DAILY_USAGE_PREV2, SUCCESS_GETTING_DAILY_USAGE,

} from "../../Types";
import {getCurrentMonthsDailyUsageUrl, getPreviousMonthsDailyUsageUrl} from "../../URL";


export default function getDailyUsage(subscriberID, billDate) {
    let currentURL = getCurrentMonthsDailyUsageUrl + "subscriberID=" + subscriberID + '&billDate=' + billDate

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_DAILY_USAGE))
        axiosInstance.get(currentURL).then((response) => {

            if( response.status===200){
                dispatch(dispatchSuccess(SUCCESS_DAILY_USAGE_CURRENT, response.data.dataBundle))
                dispatch(getPrevMonth1(subscriberID, billDate))
                dispatch(getPrevMonth2(subscriberID, billDate))

            }else {

            }


        })
    }
}

function getPrevMonth1(subscriberID, billDate){
    let prevMonth1 = getPreviousMonthsDailyUsageUrl + "subscriberID=" + subscriberID + '&billDate=' + billDate + '&monthIndex=' + 1
    return (dispatch) => {
        axiosInstance.get(prevMonth1).then((response) => {
            dispatch(dispatchSuccess(SUCCESS_DAILY_USAGE_PREV1, response.data.dataBundle))
        })
    }

}

function getPrevMonth2(subscriberID, billDate){
    let prevMonth1 = getPreviousMonthsDailyUsageUrl + "subscriberID=" + subscriberID + '&billDate=' + billDate + '&monthIndex=' + 2
    return (dispatch) => {
        axiosInstance.get(prevMonth1).then((response) => {

            dispatch(dispatchSuccess(SUCCESS_DAILY_USAGE_PREV2, response.data.dataBundle))
            dispatch(loadingDispatch(SUCCESS_GETTING_DAILY_USAGE))
        })
    }

}
