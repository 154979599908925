import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import getGiftDataDetails from '../../../redux/action/broadBand/giftDateDetails.action'

import {Slider} from "@material-ui/core";
import LoadingBbBody from '../LoadingBbBody';

const GiftDataDetails = ({
    getGiftDataDetails,
    profile,
    loading,
    giftDataList,
    amount,
    setAmount,
    amountPrice,
    setAmountPrice,
    amountUnit,
    setAmountUnit,
    setState,
    setPId
}) => {

    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
        getGiftDataDetails(profile.subscriberid)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const createBoundry = () => {
        let max = 0
        let min = 0
        const marks = []
        giftDataList.forEach((val, index) => {
            if (val.volume > 9 && min === 0) {
                min = val.volume
            }
            if (val.volume > 9 && index === (giftDataList.length - 1)) {
                max = val.volume
            }

            if (val.volume > 9) {
                let obj
                if (min === val.volume || max === val.volume) {
                    obj = {
                        value: val.volume,
                        label: val.volume + val.volumeUnit
                    }
                } else {
                    obj = {
                        value: val.volume,
                    }
                }

                marks.push(obj)
            }

        })
        let obj = {
            min: min,
            max: max,
            marks: marks
        }

        return obj
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prev = usePrevious(giftDataList);

    useEffect(() => {
        if (prev !== giftDataList) {
            if (giftDataList.length > 0) {
                setAmount(giftDataList[0].volume)
                setAmountUnit(giftDataList[0].volumeUnit)
                setAmountPrice(giftDataList[0].postPrice)
                setPId(giftDataList[0].packageId)
            }
        }

    }, [giftDataList])// eslint-disable-line react-hooks/exhaustive-deps

    function sliderOnChange(value) {
        setAmount(value)
        let result = giftDataList.find(obj => {
            return obj.volume === value
        })
        setAmountPrice(result.postPrice)
        setAmountUnit(result.volumeUnit)
        setPId(result.packageId)
    }

    function clickMore() {
        setAmount(createBoundry().min)
        let result = giftDataList.find(obj => {
            return obj.volume === createBoundry().min
        })
        setAmountPrice(result.postPrice)
        setAmountUnit(result.volumeUnit)
        setPId(result.packageId)
        setShowBar(!showBar)
    }

    function clickLess() {
        setAmount(giftDataList[0].volume)
        setAmountUnit(giftDataList[0].volumeUnit)
        setAmountPrice(giftDataList[0].postPrice)
        setPId(giftDataList[0].packageId)
        setShowBar(!showBar)
    }

    if (loading)
    return <LoadingBbBody loading={loading} border={true}/>
    
    return (
        <div className='getExtraGB common-panel'>
            <div className='hidePlan'>
                <p className='float-right m-0'> Hide Price Plan</p>
            </div>

            <div className='pricePlan'>
                <div>
                    <p className='m-0'>1GB to 4GB</p>
                    <p className='m-0'>100 LKR Per GB</p>
                </div>
                <div className='blue'>
                    <p className='m-0'>5GB to 19GB</p>
                    <p className='m-0'>85 LKR Per GB</p>
                </div>
                <div>
                    <p className='m-0'>20GB to 49GB</p>
                    <p className='m-0'>75 LKR Per GB</p>
                </div>
                <div className='blue'>
                    <p className='m-0'>Above 50GB</p>
                    <p className='m-0'>60 LKR Per GB</p>
                </div>
            </div>

            <div className='selectGB clearfix'>
                <div className='leftSide'>
                    <div className='tenGB'>{amount}{amountUnit}</div>
                    <div className='price'>Rs.{amountPrice} + Tax</div>
                </div>

                <div className='rightSide'>
                    <div hidden={showBar} className='gbBox'>
                        {
                            giftDataList.map((val, index) => {
                                if (val.volume <= 9) {
                                    return (
                                        <button key={index} className={amount === val.volume ? 'selected' : null}
                                                onClick={() => sliderOnChange(val.volume)}>
                                            {val.volume}{val.volumeUnit}
                                        </button>
                                    )
                                } else
                                    return null

                            })
                        }

<div className='extra-more' style={{color: 'blue'}}
                                   onClick={() => clickMore()}>more</div>
                    </div>

                    <div hidden={!showBar} className='slider'>
                        <Slider
                            value={amount}
                            min={createBoundry().min}
                            max={createBoundry().max}
                            aria-labelledby="discrete-slider-restrict"
                            step={null}
                            valueLabelDisplay="auto"
                            marks={createBoundry().marks}
                            onChange={(e, value) => sliderOnChange(value)}
                        />
                        <div className='float-right' onClick={() => clickLess()}>less</div>
                    </div>
                    <button className='nextButton' onClick={() => setState('SelectType')}>Next</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
    giftDataList: state.giftData.dataBundle,
    loading: state.giftData.loading,
    error: state.giftData.error,
})

export default connect(mapStateToProps, {getGiftDataDetails}) (GiftDataDetails)
