import React, {useState} from 'react';
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ConfirmAlert from "../alerts/ConfirmAlert";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import {removeAccountUrl} from "../../redux/URL";
import axiosInstance from "../../redux/axiosInstance";
import {getAccountDetails} from "../../redux/action/accountDetails.action";
import {useHistory} from "react-router-dom";
import {CATCH_ERROR_MSG} from "../../redux/Types";


function ManageConnections({accountList, getAccountDetails, service_list}) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const [selectedAccount, setSelectedAccount] = useState('');

    const removeAccount = () => {
        let url = removeAccountUrl + 'accountNo=' + service_list.accountNo + '&telephoneNo=' + selectedAccount
        axiosInstance.post(url).then((response) => {
            if (response.data.isSuccess) {
                setSuccess(true)
                getAccountDetails()
                history.push('/boardBand/summary')
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(Onerror => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    if (loading) {
        return (
            <div className='mainBody eBill common-panel '>
                <div className="body-container">
                    <div className='title'>
                        <p>Manage Connections</p>
                    </div>

                    <LoadingBbBody loading={loading} border={false}/>
                </div>
            </div>
        )
    }
    return (
        <div className='mainBody eBill common-panel '>
            <div className="body-container">
                {confirmShow ? <ConfirmAlert setConfirmShow={setConfirmShow} onClick={removeAccount}
                                             msg={'Do you want to remove this connections ?'}/> : null}

                {success ?
                    <SuccessAlert setSuccess={setSuccess}
                                  msg='Removed connection successfully'/> : null}

                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}

                <div className='title'>
                    <p>Manage Connections</p>
                </div>

                <div className='wrapper'>


                    <div className='connection'>

                        {
                            accountList.map((val, key) => {
                                return <div key={key}>
                                    <div>{val.telephoneno}</div>
                                    <div>
                                        <svg onClick={() => {
                                            setSelectedAccount(val.telephoneno)
                                            setConfirmShow(true)
                                        }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 183 192"
                                        >
                                            <path fill="#3388C0" d="M39.715 174h103.57L149 51H34l5.715 123z"/>
                                            <path
                                                fill="#49ACD6"
                                                d="M175.375 34.56h-30.5v-19.2c0-8.472-6.839-15.36-15.25-15.36h-76.25c-8.411 0-15.25 6.888-15.25 15.36v19.2h-30.5C3.407 34.56 0 37.992 0 42.24v7.68c0 1.056.858 1.92 1.906 1.92h14.392l5.886 125.52c.381 8.184 7.1 14.64 15.226 14.64h108.18c8.149 0 14.845-6.432 15.226-14.64l5.886-125.52h14.392A1.919 1.919 0 00183 49.92v-7.68c0-4.248-3.407-7.68-7.625-7.68zM55.281 17.28h72.438v17.28H55.281V17.28zm88.474 157.44H39.245L33.478 51.84h116.043l-5.766 122.88z"
                                            />
                                            <path
                                                fill="#49ACD6"
                                                d="M58.328 73.943H65.532V155.99099999999999H58.328z"
                                            />
                                            <path
                                                fill="#49ACD6"
                                                d="M87.846 73.943H95.05V155.99099999999999H87.846z"
                                            />
                                            <path
                                                fill="#49ACD6"
                                                d="M117.363 73.943H124.567V155.99099999999999H117.363z"
                                           />
                                        </svg>
                                    </div>
                                </div>
                            })
                        }


                    </div>
                </div>
            </div>
        </div>


    );
}


const mapStateToProps = state => ({
    accountList: state.accountDetails.account_Details,
    service_list: state.accountDetails.service_list,
})

export default connect(mapStateToProps, {getAccountDetails})(ManageConnections);
