import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {login, externalLogin, setPublicActionToInitialState} from '../../redux/action/login.action'
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
// import userLog from "../../assests/images/newLoginSvg/uID svg.svg";
// import passwordLog from "../../assests/images/newLoginSvg/password svg.svg";
import eyeIcon from "../../assests/images/loginUI/br2128px.png";
import googleIcon from "../../assests/images/loginUI/google-logo.png";
import facebookIcon from "../../assests/images/loginUI/facebook-color.png";
// import appleIcon from "../../assests/images/loginUI/Apple-512.png";
import {firebaseID} from "../../redux/URL";
import eyeCloseIcon from '../../assests/images/loginUI/br1128px.png'
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";
import backIcon from "../../assests/images/loginUI/back.png";

const Querystring = require('querystring');

function NewSignIn(props) {

    const [userId, setName] = useState(localStorage.getItem('userName'));
    const [password, setPassword] = useState("");
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        props.setPublicActionToInitialState()
    }, [props.setPublicActionToInitialState]);// eslint-disable-line react-hooks/exhaustive-deps

    const checkStatus = (value) => {
        setUserNameError('')
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

        if (!value) {
            setUserNameError('User ID cannot be empty')
            return false
        } else if (re.test(value))
            return 'EMAIL'
        else if (value.match(regExp)) {
            return 'MOBILE'
        } else {
            setUserNameError('Enter valid email or mobile ')
            return false
        }


    }

    const togglePasswordVisiblity = () => {
        setIsPasswordShown(!isPasswordShown)

    }

    const onKeyPress = (e) => {
        if (e.which === 13) {
            submit();
        }
    }

    const submit = () => {
        // e.preventDefault()
        if (checkStatus(userId) !== false) {
            if (password.length < 6) {
                setPasswordError('Minimum 6 characters')
            } else {
                let body = Querystring['stringify']({
                    username: userId,
                    password: password,
                    channelID: 'WEB'
                })
                localStorage.setItem('userType', checkStatus(userId))
                localStorage.setItem('userName', userId)
                localStorage.setItem('loginBy', 'ftth')
                props.login(body, setError, setIsError)
            }

        }


    }

    const responseGoogle = (response) => {

        localStorage.setItem('userType', 'Google')
        localStorage.setItem('userName', response.profileObj.email)
        localStorage.setItem('userImage', response.profileObj.imageUrl)
        let body = Querystring['stringify']({
            provider: 'Google',
            externalAccessToken: response.tokenObj.id_token,
            externalAccessToken2: '',
            firebaseId: firebaseID,
            appVersion: 1.0,
            osType: 'WEB 10',
            channelID: 'WEB'
        })
        localStorage.setItem('loginBy', 'ftth')
        props.externalLogin(body, setError, setIsError)
    }

    const responseFacebook = (response) => {

        localStorage.setItem('userType', 'Facebook')

        let body = Querystring['stringify']({
            provider: 'Facebook',
            externalAccessToken: response.accessToken,
            externalAccessToken2: '',
            firebaseId: firebaseID,
            appVersion: 1.0,
            osType: 'Android 10',
            channelID: 'WEB'
        })
        localStorage.setItem('loginBy', 'ftth')
        props.externalLogin(body, setError, setIsError)
    }

    if (props.loading)
        return <LoadingBbBody/>

    return (


        <div className='auth-container justify-content-center'>

            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
            <div className='login-form'>
                <div className='form d-block m-auto'>
                    <p className='titles'>Sign In</p>

                    <div className="input-group">
                        <div className={userNameError ? "input-container error" : "input-container"}>
                            <span className='error-message float-right'>{userNameError}</span>
                            <label className='d-block'>User ID <p className='d-inline red'>*</p></label>
                            {/* <img src={userLog} alt='userLogo' className='icons'/> */}
                            <input type='text' className='' value={userId}
                                   onChange={(e) => {
                                       setName(e.target.value.trim())
                                       setUserNameError('')
                                   }}
                                   placeholder='Email or mobile number'/>
                        </div>


                        <div className={passwordError ? "input-container error" : "input-container"}>
                            <span className='error-message float-right'>{passwordError}</span>
                            <label className='d-block'>Password <p className='d-inline red'>*</p></label>

                            {/* <img src={passwordLog} alt='userLogo' className='icons'/> */}
                            <img src={isPasswordShown ? eyeIcon : eyeCloseIcon} alt='userLogo' className='eye '
                                 onClick={() => togglePasswordVisiblity()}/>
                            <input type={isPasswordShown ? "text" : "password"} className='' value={password}
                                   placeholder='Minimum 6 characters'
                                   onKeyPress={onKeyPress} onChange={(e) => {
                                setPassword(e.target.value.trim())
                                setPasswordError('')
                            }}/>

                        </div>
                    </div>
                    {/*<span className='error-message float-right'>{passwordError}</span>*/}
                    <div className='input-container-footer'>
                        <p onClick={() => props.setState('fg')} className='fg'>Forgot Password ?</p>

                        <button disabled={props.loading} className='medium blue float-right'
                                onClick={() => submit()}>{props.loading ? 'Please wait...' : 'Sign In'}</button>
                    </div>
                </div>
            </div>
            <div className="social">
                <div className="social-container">
                    <div className="box"><p className='line'><span className='or'>Or Sign In With</span></p>

                        <div className="social-icons clearfix">
                            <GoogleLogin
                                clientId="531709258665-atiep7rt3mrbutfrosms4sset496l026.apps.googleusercontent.com"
                                render={renderProps => (
                                    <button className='google-btn-sign social-icon' onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}><img src={googleIcon} alt={'googleIcon'}/>
                                    </button>
                                )}
                                buttonText="Login"
                                onSuccess={responseGoogle}

                                cookiePolicy={'single_host_origin'}
                            />

                            <FacebookLogin
                                appId="3893048667416465"
                                autoLoad={false}
                                callback={responseFacebook}
                                textButton=''
                                cssClass='facebook-btn-sign  social-icon'
                                icon={<img src={facebookIcon} alt={'facebookIcon'}/>}
                                render={renderProps => (
                                    <button className='facebook-btn-sign  social-icon' onClick={renderProps.onClick}/>
                                )}
                            />


                            {/*<button className='apple-btn-sign  social-icon'><img src={appleIcon} alt={'appleIcon'}/></button>*/}

                        </div>

                        <div className='register-container'>
                            <p className='register-label'>Don't have an account?</p>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className='btn-register' onClick={() => props.setState('reg')}>Register</a>
                        </div>

                    </div>
                </div>
            </div>
            <Link to={'/promo/buyonline'}>
                <div className='btn-back back' onClick={() => props.setState('signin')}>
                    <img src={backIcon} alt='backLogo' className='back'/>
                    <p>Back to home</p>
                </div>
            </Link>
        </div>

    )
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error,

})
export default connect(mapStateToProps, {login, externalLogin, setPublicActionToInitialState})(NewSignIn);
