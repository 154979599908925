
import axiosInstance from "../../axiosInstance";
import { dispatchError, dispatchSuccess, loadingDispatch } from "../../DispatchFunctions"
import { ERROR_REDEEM_VOUCHER, SUCCESS_REDEEM_VOUCHER, LOADING_REDEEM_VOUCHER } from "../../Types"
import { redeemVoucher } from "../../URL";
 
export function applyRedeemVoucher(subscriberId, voucherId, channel, success, isError, error){
    let url = redeemVoucher + 'subscriberID=' + subscriberId + '&voucherid=' + voucherId + '&channel=' + channel
    return(dispatch) => {
        dispatch(loadingDispatch(LOADING_REDEEM_VOUCHER))
        axiosInstance.post(url).then((response) => {
            if(response.status === 200 && response.data.isSuccess){
                dispatch(dispatchSuccess(SUCCESS_REDEEM_VOUCHER, response.data.dataBundle))
                success(true)
            }else{
                error(response.data.errorShow)
                isError(true)
                dispatch(dispatchError(ERROR_REDEEM_VOUCHER, response.data.errorShow))
            }

        }).catch((error) => {
            error('errorPost')
            isError(true)
            dispatch(dispatchError(ERROR_REDEEM_VOUCHER, 'errorPost'))
        })
    }
}  