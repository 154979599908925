import React from 'react';
import { BiArrowBack } from "react-icons/bi";
import image1 from '../../assests/images/peoTV/language-128px.png'
import image2 from '../../assests/images/peoTV/clock-128px.png'


function SingleVod({obj, setSelected}) {
    return (
        <div className='oneVod'>
            <div className='d-flex ml-2'>
                <div className='d-flex align-items-center' onClick={()=>setSelected(null)}>
                    <BiArrowBack />
                    {/*<p className='grey'>Back</p>*/}
                </div>

                <p className='ml-2 mt-3'> {obj.Movie_title} - {obj.Year}</p>
            </div>

            <div className='upperBody'>
                <img src={obj.full_size_poster} alt={'searchIcon'} className='imageView'/>
                <div>
                    <div className='d-flex align-items-center'>
                        <img src={image1} width={30} alt='image_one_classic' className='mr-3'/>
                      <div>
                          {obj.Genre}
                      </div>
                    </div>
                    <div className='d-flex align-items-center mt-3 mb-2'>
                        <img src={image2} width={30} alt='image_two_classic' className='mr-3'/>
                        <div>{obj.Duration}min</div>
                    </div>

                    <div className='float-right'>{obj.Language}</div>
                </div>
            </div>

            <div className='lowerBody'>
                <div className='movie'>
                    <div className='font-weight-bold'>Movie Description </div>
                    <div className='d-flex'>
                    <div className='font-weight-bold'>{`Actors :  ${obj.Actors}`}</div>
                    {/*<div>{obj.Actors}</div>*/}
                    </div>
                </div>

                <div className='description'>
                    {obj.Synopsys}
                </div>
            </div>
        </div>
    );
}

export default SingleVod;
