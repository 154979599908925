import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {connectionRequestUrl} from "../../redux/URL";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ConfirmAlert from "../alerts/ConfirmAlert";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import axiosInstance from "../../redux/axiosInstance";
import {connect} from "react-redux";
import {CATCH_ERROR_MSG} from "../../redux/Types";

function NewServices({loadingAccounts, loadingServices, serviceList}) {
    let {status, type} = useParams();

    const history = useHistory();
    const [title, setTitle] = useState('SLT ' + type);
    const [uType, setUType] = useState(type);
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [nic, setNic] = useState('');
    const [mobile, setMobile] = useState('');

    const [isVoice, setIsVoice] = useState(false);
    const [isPeo, setIsPeo] = useState(false);
    const [isBB, setIsBB] = useState(false);

    const [confirmShow, setConfirmShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');


    const [fNameError, setFNameError] = useState('');
    const [lNameError, setLNameError] = useState('');
    const [nicError, setNicError] = useState('');
    const [mobileError, setMobileError] = useState('');


    const setTitleName = (key) => {
        setTitle('SLT + ' + key)
    }


    const onSubmit = () => {
        let url
        if (status === 'new') {
            if (validation()) {
                let DescriptionEn = title + ','
                if (isVoice === true) {
                    DescriptionEn = DescriptionEn + 'Voice,'
                }
                if (isPeo === true) {
                    DescriptionEn = DescriptionEn + 'PEO TV,'
                }
                if (isBB === true) {
                    DescriptionEn = DescriptionEn + 'Broadband,'
                }
                url = connectionRequestUrl + "telephoneNo=NA&title=&firstName=" + fName + "&lastName=" + lName +
                    "&nic=" + nic + "&ConatctTelNo=" + mobile + "&description=" + DescriptionEn;
            } else
                return null


        } else {
            let DescriptionEn = title + ','
            if (!checkVoiceDisable() && isVoice === true) {
                DescriptionEn = DescriptionEn + 'Voice,'
            }
            if (!checkPeoTvDisable() && isPeo === true) {
                DescriptionEn = DescriptionEn + 'PEO TV,'
            }
            if (!checkBroadbandDisable() && isBB === true) {
                DescriptionEn = DescriptionEn + 'Broadband,'
            }
            url = connectionRequestUrl + "telephoneNo=" + localStorage.getItem('selectedAcc') + "&title=" + null + "&firstName=" + fName + "&lastName=" + lName +
                "&nic=NA&ConatctTelNo=" + mobile + "&description=" + DescriptionEn;

        }

        setLoading(true)
        axiosInstance.post(url).then(response => {

            if (response.data.isSuccess) {
                setSuccess(true)
                setLoading(false)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })

    }

    const validation = () => {

        if (fName.length === 0) {
            setFNameError('First name can not be empty')
            return false
        }

        if ( lName.length === 0) {
            setLNameError('Last name can not be empty')
            return false
        }

        if (mobile.length !== 10) {
            setMobileError('Enter valid mobile')
            return false
        }

        if (nic.length === 10 && nic.match("^[0-9]{9}[a-zA-Z]{1}$")) {
            return true
        } else if (nic.length === 12 && nic.match("[0-9]+")) {
            return true
        } else {
            setNicError('Enter valid NIC number')
            return false
        }

    }


    const checkVoiceDisable = () => {
        if (status === 'upgrade') {
            if (serviceList && serviceList.hasOwnProperty('listofPEOService')) {
                if (serviceList.listofVoiceService.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    const checkPeoTvDisable = () => {
        if (status === 'upgrade') {
            if (serviceList && serviceList.hasOwnProperty('listofPEOService')) {
                if (serviceList.listofPEOService.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    const checkBroadbandDisable = () => {
        if (status === 'upgrade') {
            if (serviceList && serviceList.hasOwnProperty('listofBBService')) {
                if (serviceList.listofBBService.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    if (loading || loadingServices || loadingAccounts) {
        return <div className='mainViewFTTH newService'>
            <div className='title'>
                Connection Request
            </div>
            <LoadingBbBody/>
        </div>
    }

    return (
        <div className='mainViewFTTH' style={{background:'#0f3b7a'}}>
            <div className='newService'>

                {confirmShow ? <ConfirmAlert setConfirmShow={setConfirmShow} onClick={onSubmit}
                                             msg={'You want to request new connection?'}/> : null}

                {success ? <SuccessAlert setSuccess={setSuccess} msg='Submitted your request successfully.'/> : null}

                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}

                <div className='title'>
                    Connection Request
                </div>

                <div className='formViews'>

                    {
                        status === 'new' ? <div className='radioButtons'>
                            <div>
                                <input type='radio' name="type" id="fiber" checked={uType === 'fiber'}
                                       onChange={() => {
                                           setUType('fiber')
                                           // history.push('/ftthrequest')
                                       }}

                                />
                                <label htmlFor="fiber">Fiber</label>
                            </div>


                            <div>
                                <input type='radio' name="type" id="Megaline"
                                       checked={uType === 'Megaline'} onChange={() => {
                                    setUType('Megaline')
                                    setTitleName('Megaline')
                                }}/>
                                <label htmlFor="Megaline">Megaline</label>
                            </div>
                            <div>
                                <input type='radio' name="type" id="4g" checked={uType === '4G LTE'}
                                       onChange={() => {
                                           setUType('4G LTE')
                                           setTitleName('4G LTE')
                                       }}/>
                                <label htmlFor="4g">4G LTE</label>
                            </div>
                        </div> : <div className='headerTitle'>{title}</div>
                    }

                    {
                        uType === 'fiber' ? <div className='fiber'>
                                <div>SLT FIBRE HOME LINE</div>
                                <div className='submit'>
                                    <button className='medium blue ' onClick={() => history.push('/applyonline')}>Request
                                    </button>
                                </div>
                            </div> :
//commented to hide navigate 4g lte request
                            // uType === '4G LTE' ? <div className='fiber'>
                            //     <div>SLT 4G LTE HOME LINE</div>
                            //     <div className='submit'>
                            //         <button className='medium blue ' onClick={() => history.push('/lterequest')}>Request 
                            //         </button>
                            //     </div>
                            // </div> :


                            <>
                                <div className='fields clearfix'>
                                    <div>
                                        <span className='error-message float-right'>{fNameError}</span>
                                        <label>First Name</label>
                                        <input type='text' name='fName' value={fName}
                                               onChange={(e) => setFName(e.target.value)}/>
                                    </div>

                                    <div>
                                        <span className='error-message float-right'>{lNameError}</span>
                                        <label>Last Name</label>
                                        <input type='text' value={lName} onChange={(e) => setLName(e.target.value)}/>
                                    </div>

                                    {
                                        status === 'new' ? <div>
                                            <span className='error-message float-right'>{nicError}</span>
                                            <label>NIC</label>
                                            <input type='text' value={nic}
                                                   onChange={(e) => setNic(e.target.value.trim())}/>
                                        </div> : null
                                    }


                                    <div>
                                        <span className='error-message float-right'>{mobileError}</span>
                                        <label>Mobile</label>
                                        <input type='number' value={mobile}
                                               onChange={(e) => setMobile(e.target.value.trim())}
                                               /* eslint-disable-next-line no-script-url */
                                               onKeyDown="javascript: return event.keyCode == 69 ? false : true"/>
                                    </div>
                                </div>
                                <div className="service-types">
                                    <div className='checkBoxHeader'>
                                        Choose Your Service Type
                                    </div>


                                    <div className='radioButtons'>
                                        <div>
                                            <input type='checkbox' name="isVoice" id="isVoice"
                                                   checked={isVoice || checkVoiceDisable()}
                                                   disabled={checkVoiceDisable()}
                                                   onChange={(e) => setIsVoice(e.target.checked)}/>
                                            <label htmlFor="isVoice">Voice</label>
                                        </div>


                                        <div>
                                            <input type='checkbox' name="isPeo" id="isPeo"
                                                   checked={isPeo || checkPeoTvDisable()}
                                                   disabled={checkPeoTvDisable()}
                                                   onChange={(e) => setIsPeo(e.target.checked)}/>
                                            <label htmlFor="isPeo">PEO TV</label>
                                        </div>
                                        <div>
                                            <input type='checkbox' name="isBB" id="isBB"
                                                   checked={isBB || checkBroadbandDisable()}
                                                   disabled={checkBroadbandDisable()}
                                                   onChange={(e) => setIsBB(e.target.checked)}/>
                                            <label htmlFor="isBB">Broadband</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='submit'>
                                    <button className='medium blue ' onClick={() => {
                                        setNicError('')
                                        setMobileError('')
                                        if(validation()) {
                                            setConfirmShow(true)
                                        }
                                    }}>Submit
                                    </button>
                                </div>
                            </>
                    }
                </div>


            </div>
        </div>

    );
}


const mapStateToProps = state => ({
    loadingAccounts: state.accountDetails.loading,
    loadingServices: state.accountDetails.loadingServiceList,
    loadingProfile: state.broadBand.loadingProfile,
    serviceList: state.accountDetails.service_list,
    account: state.accountDetails.account_Details,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {})(NewServices);
