import React,{useState} from 'react'
import {connect} from "react-redux";
import NoPreviledge from '../../alerts/NoPreviledge';
import GiftDataDetails from './GiftDataDetails';
import GiftDataPaymentType from './GiftDataPaymentType';
import GiftDataComfirmPayments from './GiftDataComfirmPayments';


const GetGiftData = ({profile, receiverId}) => {

    const [amount, setAmount] = useState(null);
    const [amountUnit, setAmountUnit] = useState(null);
    const [amountPrice, setAmountPrice] = useState(null);
    const [payType, setPayType] = useState('');
    const [pId, setPId] = useState('');

    const [state, setState] = useState('Select');

    if (!profile.priviledges.data_gifting) {
        return <div className='getExtraGB noPadding'>
            <NoPreviledge msg={'Please change the SLT package for Gift Data'}/>
        </div>
    } else if (state === 'Select') {
        return <GiftDataDetails amount={amount} setAmount={setAmount} amountUnit={amountUnit}
        setAmountUnit={setAmountUnit} amountPrice={amountPrice} setAmountPrice={setAmountPrice}
        setState={setState} setPId={setPId}/>
    }else if (state === 'SelectType') {
        return <GiftDataPaymentType setState={setState} amount={amount} amountUnit={amountUnit}
                                   amountPrice={amountPrice} payType={payType} setPayType={setPayType}/>
    } else if (state === 'Confirm') {
        return <GiftDataComfirmPayments setState={setState} amount={amount} amountUnit={amountUnit}
                                       amountPrice={amountPrice} payType={payType} pId={pId} receiverId ={receiverId}/>
    } else
        return null

    
}

const mapStateToProps = state => ({
    profile: state.broadBand.profile,

})

export default connect(mapStateToProps, {}) (GetGiftData)
