import React from 'react'
import { SingaleAccordion } from './SingaleAccordion';

const General = () => {

    const accordionData = [
        {
            title: 'How to access an SLT email account?',
            content: <ul>
                    <li>Provide your details in 
                        <a href= "https://mail.sltnet.lk/hpronto/" > &nbsp; https://mail.sltnet.lk/hpronto/ </a>
                        to login to your SLT e-mail account.
                    </li>
                </ul>
          },
          {
            title: 'How to get a Business Solutions to your company?',
            content: <ul>
                    <li>For the best solutions, click  
                        <a href="https://www.slt.lk/en/business/enterprises  "> &nbsp; https://www.slt.lk/en/business/enterprises </a> .
                    </li>
                    <li>For more information, dial 0112389389</li>
                </ul>
          },
          {
            title: 'How to find Careers at SLT? ',
            content: <ul>
                    <li>Visit  
                        <a href = " https://www.slt.lk/en/careers "> https://www.slt.lk/en/careers </a>
                        to find out career opportunities at SLT 
                    </li>
                </ul>
          }
    ];

    return (
        <div className = 'faqSubBody'>
            <div className='title'>
                <p>General </p>
            </div>
            {accordionData.map(({ title, content }) => (
                 <SingaleAccordion title={title} content={content} />
                ))}
        </div>
    )
}

export default General
