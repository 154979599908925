import axiosInstance from "../axiosInstance";
import {
    AddPrePaidAccountURL,
    addAccountUrl,
    addVasAccountUrl,
    billDetailsPrePaidUrl,
    billDetailsUrl,
    getAccountDetailsUrl,
    getServiceDetailRequestUrl,
    getSubscribePrepaidUrl
} from "../URL";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_ACCOUNT_DETAILS, ERROR_GET_PROFILE, ERROR_GET_SERVICE_LIST,
    ERROR_PAYMENT_LIST,
    LOADING_ACCOUNT_DETAILS, LOADING_ADD_ACCOUNT,
    LOADING_GET_SERVICE_LIST, LOADING_PAYMENT_LIST, LOADING_PROFILE, SUCCESS_ADD_ACCOUNT,
    SUCCESS_GET_ACCOUNT_DETAILS,
    SUCCESS_GET_SERVICE_LIST, SUCCESS_PAYMENT_LIST, SUCCESS_SUSCRIBE_STATUS, LOADING_SUSCRIBE_STATUS, ERROR_SUSCRIBE_STATUS, SUCCESS_GET_PROFILE
} from "../Types";
import {getBBSummary, getPrepaidDataBalance, getProfile} from "./broadBand.action";

import {getBannerDetails} from "./banner.action";

import getBillStatus from "./bill/billStatus.action";


export function getAccountDetails() {

    let url = getAccountDetailsUrl + 'username=' + localStorage.getItem('userName')

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_ACCOUNT_DETAILS))
        dispatch(loadingDispatch(LOADING_GET_SERVICE_LIST))
        dispatch(loadingDispatch(LOADING_PROFILE))
        axiosInstance.get(url).then(response => {

            if (response.data.isSuccess) {
                dispatch(dispatchSuccess(SUCCESS_GET_ACCOUNT_DETAILS, response.data.dataBundle))
                if (response.data.dataBundle.length === 0) {
                    dispatch(dispatchError(ERROR_GET_SERVICE_LIST, ''))
                    dispatch(dispatchError(ERROR_GET_PROFILE, ''))
                }
            } else {
                dispatch(dispatchError(ERROR_GET_ACCOUNT_DETAILS, response.data.errorShow))
                dispatch(dispatchError(ERROR_GET_SERVICE_LIST, response.data.errorShow))
                dispatch(dispatchError(ERROR_GET_PROFILE, response.data.errorShow))
            }

        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_ACCOUNT_DETAILS, CATCH_ERROR_MSG))
            dispatch(dispatchError(ERROR_GET_SERVICE_LIST, CATCH_ERROR_MSG))
            dispatch(dispatchError(ERROR_GET_PROFILE, CATCH_ERROR_MSG))
        })
    }
}

export function getServiceDetails(telephone, account) {

    let url = getServiceDetailRequestUrl + 'telephoneNo=' + telephone
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_SERVICE_LIST))
        dispatch(loadingDispatch(LOADING_PROFILE))
        dispatch(loadingDispatch(LOADING_PAYMENT_LIST))
        axiosInstance.get(url).then(response => {
            if (response.data.isSuccess) {
                dispatch(
                  dispatchSuccess(
                    SUCCESS_GET_SERVICE_LIST,
                    response.data.dataBundle
                  )
                );
                if (isPrepaid(response.data.dataBundle)) {
                  dispatch(getPaymentDetailsPrepaid(telephone));
                  dispatch(getSusbcribeStatusPrepaid(telephone));
                } else dispatch(getPaymentDetails(telephone, account));
                dispatch(getBillStatus(telephone, account))
                dispatch(getBannerDetails())
                if (response.data.dataBundle.listofBBService.length > 0) {
                    if (isPrepaid(response.data.dataBundle)) {
                        dispatch(getPrepaidDataBalance(telephone))
                        dispatch(loadingDispatch(SUCCESS_GET_PROFILE))
                    } else {
                        dispatch(getProfile(response.data.dataBundle.listofBBService[0].serviceID))
                        dispatch(getBBSummary(response.data.dataBundle.listofBBService[0].serviceID))
                    }
                     
                } else {
                    dispatch(dispatchError(ERROR_GET_PROFILE, ''))
                }
            } else {
                dispatch(dispatchError(ERROR_GET_SERVICE_LIST, response.data.errorShow))
                dispatch(dispatchError(ERROR_GET_PROFILE, response.data.errorShow))
            }

        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_SERVICE_LIST, CATCH_ERROR_MSG))
            dispatch(dispatchError(ERROR_GET_PROFILE, CATCH_ERROR_MSG))
        })
    }
}

export function getPaymentDetails(telephone, account) {

    let url = billDetailsUrl + 'telephoneNo=' + telephone + '&accountNo=' + account
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_PAYMENT_LIST))
        axiosInstance.get(url).then(response => {
            if (response.status === 200) {
                dispatch(dispatchSuccess(SUCCESS_PAYMENT_LIST, response.data.dataBundle))
            } else
                dispatchError(ERROR_PAYMENT_LIST, response.data.errorShow)

        }).catch(() => {
            dispatchError(ERROR_PAYMENT_LIST, CATCH_ERROR_MSG)
        })
    }
}

export function getPaymentDetailsPrepaid(telephone) {

    let url = billDetailsPrePaidUrl + 'LTESLTNumber=' + telephone 
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_PAYMENT_LIST))
        axiosInstance.get(url).then(response => {
            if (response.status === 200) {
                dispatch(dispatchSuccess(SUCCESS_PAYMENT_LIST, response.data.dataBundle))
            } else
                dispatchError(ERROR_PAYMENT_LIST, response.data.errorShow)

        }).catch(() => {
            dispatchError(ERROR_PAYMENT_LIST, CATCH_ERROR_MSG)
        })
    }
}

export function getSusbcribeStatusPrepaid(telephone) {

    let url = getSubscribePrepaidUrl + 'LTESLTNumber=' + telephone 
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_SUSCRIBE_STATUS))
        axiosInstance.get(url).then(response => {
            if (response.status === 200) {
                dispatch(dispatchSuccess(SUCCESS_SUSCRIBE_STATUS, response.data.dataBundle))
            } else
                dispatchError(ERROR_SUSCRIBE_STATUS, response.data.errorShow)

        }).catch(() => {
            dispatchError(ERROR_SUSCRIBE_STATUS, CATCH_ERROR_MSG)
        })
    }
}

export function addBroadBandAccount(vasusername, userkey, setSuccess, setIsError, setError) {
    // let url = addVasAccountUrl + 'vasusername=' + vasusername + '&userkey=' + userkey
    const params = new URLSearchParams({
        "vasusername": vasusername,
        "userkey": userkey        
      }).toString();

      let url = addVasAccountUrl + params;
    
    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_ADD_ACCOUNT))
        axiosInstance.post(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                setSuccess(true);
                // AlertView('Success Add Account')
                dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
                localStorage.setItem('selectedAcc',response.data.dataBundle.split(',')[0])
                dispatch(getAccountDetails())
            } else {
                dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
                setError(response.data.errorShow)
                setIsError(true)
                // AlertView(response.data.errorShow)
            }

        }).catch(() => {
            dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
            setError('Error Add Account')
            setIsError(true)
        })
    }
}

export function addTelAccountPostPaid(accountNo, telephoneNo, nic, setSuccess, setIsError, setError) {
    let url = addAccountUrl + 'accountNo=' + accountNo + '&TelephoneNo=' + telephoneNo + '&nic=' + nic

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_ADD_ACCOUNT))
        axiosInstance.post(url).then((response) => {

            if (response.status === 200 && response.data.isSuccess) {
                setSuccess(true);
                localStorage.setItem('selectedAcc',response.data.dataBundle.split(',')[0])
                dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
                dispatch(getAccountDetails())

            } else {
                dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
                setError(response.data.errorShow)
                setIsError(true)
            }

        }).catch(() => {
            dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
            setError('Error Add Account')
            setIsError(true)
        })
    }
}

export function addTelAccountPrePaid(telephoneNo, nic, setSuccess, setIsError, setError) {
    let url = AddPrePaidAccountURL + '&TelephoneNo=' + telephoneNo + '&nic=' + nic

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_ADD_ACCOUNT))
        axiosInstance.post(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                setSuccess(true);
                localStorage.setItem('selectedAcc',response.data.dataBundle.split(',')[0])
                dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
                dispatch(getAccountDetails())

            } else {
                dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
                setError(response.data.errorShow)
                setIsError(true)
            }

        }).catch(() => {
            dispatch(loadingDispatch(SUCCESS_ADD_ACCOUNT))
            setError('Error Add Account')
            setIsError(true)
        })
    }
}

export const isPrepaid = (data) => data.hasOwnProperty('promotionType') && data.promotionType === 'Prepaid'