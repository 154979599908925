import React, {useEffect, useState} from 'react';


function SelectPeoTvPackages({packageInfo, setPackageInfo, setIsOpenPeo, data, setSelectedPeoObj}) {

    const [colorArray] = useState(['#852D84', '#852D84', '#8B949B', '#E3BA44', '#E28A74', '#727473', '#B3B3B3', '#737824']);

    const [open, setOpen] = useState(false);

    // const addPackage = (newPack) => {
    //     let pack = packageInfo.peoTvPackages
    //     if (pack.length > peoCount)
    //         pack[peoCount] = newPack
    //     else
    //         pack.push(newPack)
    //     setPackageInfo({...packageInfo, peoTvPackages: pack})
    // }

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 0)
    }, [open]);


    return (
        <div className='packageSelect'>


            {
                <div className={open?'peoTvPack show':'peoTvPack'}>
                        <div className='peoTVPackages'>
                            {
                                data.map((obj, index) => {
                                    return <div className="package-item-wrapper">
                                        <div key={index} onClick={() => {
                                            // addPackage(obj.PEO_PACKAGE_NAME)
                                            setSelectedPeoObj(obj)
                                            setPackageInfo({
                                                ...packageInfo,
                                                peoTvPackages: obj.PEO_PACKAGE_NAME,
                                                peoPackagesData: obj
                                            })
                                            setTimeout(() => {
                                                setIsOpenPeo(false)
                                            }, 150)

                                        }}
                                              className={packageInfo.peoTvPackages === obj.PEO_PACKAGE_NAME ? obj.PEO_PACKAGE_NAME.replace(" ", "_").toLowerCase() + ' package-item selected' : obj.PEO_PACKAGE_NAME.replace(" ", "_").toLowerCase() + " package-item"}
                                            //  className={packageInfo.peoTvPackages ? ' package-item selected' : " package-item"}
                                             style={{'background-color': colorArray[index]}}>
                                            {/*<img src={obj.package.Package_image} alt={'searchIcon'}/>*/}
                                            <div className='count'>
                                        <span className="number"
                                              style={{color: colorArray[index]}}> {obj.NO_OF_CHANNELS}</span> <span
                                                className="name">Channels</span>
                                            </div>
                                            <div className="package-name">{obj.PEO_PACKAGE_NAME}</div>

                                            <div className="price">{obj.MONTHLY_RENTAL} LKR + Tax</div>
                                        </div>
                                    </div>
                                })
                            } </div>
                        {/*<div class="popup-footer">*/}
                        {/*    <div class="container-right">*/}
                        {/*        <button className='btn-ok'>Compare</button>*/}
                        {/*        <button className='btn-ok' onClick={() => setIsOpenPeo(false)}>Select</button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
            }


        </div>
    );
}

export default SelectPeoTvPackages;
