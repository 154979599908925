import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import LoadingBbBody from '../boardBand/LoadingBbBody'
import axiosInstance from '../../redux/axiosInstance'
import { getDraftDetailsLTE, getDraftDetails, getInvoice, removeInvoiceUrl } from '../../redux/URL'
import { getDraftDetailFTTH } from '../../redux/action/ftth/ftth.action';
import { getDraftDetailLTE } from '../../redux/action/lte/lte.action';
import { CATCH_ERROR_MSG } from '../../redux/Types'
import routerImage from '../../assests/images/routerpng.png'
import Querystring from "querystring";
import EmptyCart from './EmptyCart';
import LTECart from './LTECart';
import FTTHCart from './FTTHCart';

const Cart = ({
    loadingDraft,
    draftDetails, 
    errorDraft,
    loadingDraftLte, 
    errorDraftLte,
    draftDetailsLte,
    loadingpayLogLte,
    payLogLte, 
    loadingpayLog,
    payLog, 
    charge, 
    loadingInvoice,
    invoice, 
    errorInvoice,
    loadingInvoiceLte, 
    invoiceLte, 
    errorInvoiceLte,
    getDraftDetailFTTH, 
    getDraftDetailLTE 
}) => {

    const [savedDataBroadband, setSavedDataBroadband] = useState({});
    const [savedDataVoice, setSavedDataVoice] = useState({});
    

    const [savedDataBroadbandFTTH, setSavedDataBroadbandFTTH] = useState({});
    const [savedDataPeotvFTTH, setSavedDataPeotvFTTH] = useState({});

    const [orderRef, setOrderRef] = useState(''); 
    const [orderRefFTTH, setOrderRefFTTH] = useState('');

    const [initCharge, setInitCharge] = useState('');
    const [initChargeFTTH, setInitChargeFTTH] = useState('');

    // const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getDraftDetailFTTH()
        getDraftDetailLTE()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        if(draftDetails){
            setOrderRefFTTH(draftDetails.reF_NO)   
        }

        if(draftDetailsLte){
            setOrderRef(draftDetailsLte.reF_NO) 
        }

        if(invoice && invoice.length > 0){
            for (let i = 0; i < invoice.length; i++) {
                if(invoice[i].servicE_TYPE === 'BEARER'){
                    setInitChargeFTTH(invoice[i].inI_CHARGE)
                }

               if(invoice[i].servicE_TYPE === 'BROADBAND'){
                    setSavedDataBroadbandFTTH(invoice[i])
                }

                if(invoice[i].servicE_TYPE === 'PEOTV'){
                    setSavedDataPeotvFTTH(invoice[i])
                }
            }     
        }

        if(invoiceLte && invoiceLte.length > 0){
            for (let i = 0; i < invoiceLte.length; i++) {
                if(invoiceLte[i].servicE_TYPE === 'BEARER'){
                    setInitCharge(invoiceLte[i].inI_CHARGE)
                }

               if(invoiceLte[i].servicE_TYPE === 'BROADBAND'){
                    setSavedDataBroadband(invoiceLte[i])
                }

                if(invoiceLte[i].servicE_TYPE === 'VOICE'){
                    setSavedDataVoice(invoiceLte[i])
                }
            }  
        }

    }, [invoice, invoiceLte, draftDetails, draftDetailsLte ]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if((invoiceLte) && (invoiceLte.length > 0)){
             for(let i = 0; i < invoiceLte.length; i++){
                
                if(invoiceLte[i].servicE_TYPE === 'BEARER'){
                    setInitCharge(invoiceLte[i].inI_CHARGE)
                }

               if(invoiceLte[i].servicE_TYPE === 'BROADBAND'){
                    setSavedDataBroadband(invoiceLte[i])
                }

                if(invoiceLte[i].servicE_TYPE === 'VOICE'){
                    setSavedDataVoice(invoiceLte[i])
                }
             }
        }
     }, [invoiceLte]);// eslint-disable-line react-hooks/exhaustive-deps

    const removeInvoice = (orderId) => {
        let body = Querystring['stringify']({
            'REF_NO': orderId
        })

        axiosInstance.post(removeInvoiceUrl, body).then(response => {

            if(response.data.isSuccess ){
                setInitCharge('')
                setInitChargeFTTH('')
                setSavedDataBroadband('')
                setSavedDataBroadbandFTTH('')
                setSavedDataPeotvFTTH('')
                getDraftDetailFTTH()
                getDraftDetailLTE()
            }else{
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)
            }
         
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }

    if (loadingDraftLte || loadingInvoiceLte || loadingpayLogLte || loadingDraft || loadingInvoice || loadingpayLog) {
        return <div className='mainViewLTE'>
            <LoadingBbBody/> 
        </div>
    }


  return (
    <div className='mainViewLTE'>
           {/* && savedDataBroadband.packagE_NAME      */}
        {
            initCharge && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte) ? 
           
                <LTECart
                    savedDataBroadband={savedDataBroadband}
                    savedDataVoice={savedDataVoice}
                    initCharge={initCharge}
                    savedData={draftDetailsLte}
                    orderRef={orderRef}
                    removeInvoice={removeInvoice}
                />
              
            : initChargeFTTH && (savedDataBroadbandFTTH.packagE_NAME || savedDataPeotvFTTH.packagE_NAME) && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)  ? 
            
                <FTTHCart
                    savedDataBroadband={savedDataBroadbandFTTH}
                    savedDataPeotv={savedDataPeotvFTTH}
                    initCharge={initChargeFTTH}
                    savedData={draftDetails}
                    orderRef={orderRefFTTH}
                    removeInvoice={removeInvoice}
                />
                
            :
                <EmptyCart/>
            
        }

        

        

    </div>
  )
}

const mapStateToProps = state => ({
    loadingDraftLte: state.lte.loadingDraftLte,
    draftDetailsLte: state.lte.draftDetailsLte,
    errorDraftLte: state.lte.errorDraftLte,
    loadingDraft: state.ftth.loadingDraft,
    draftDetails: state.ftth.draftDetails,
    errorDraft: state.ftth.errorDraft,
    loadingpayLogLte: state.lte.loadingPaymentLte,
    payLogLte: state.lte.paymentsLogLte,
    loadingpayLog: state.ftth.loadingPayment,
    payLog: state.ftth.paymentsLog,
    charge: state.charge.chargeDetails,
    loadingInvoice: state.ftth.loadingInvoice,
    invoice: state.ftth.invoice,
    errorInvoice: state.ftth.errorInvoice,
    loadingInvoiceLte: state.lte.loadingInvoiceLte,
    invoiceLte: state.lte.invoiceLte,
    errorInvoiceLte: state.lte.errorInvoiceLte,
})

export default connect(mapStateToProps, {getDraftDetailFTTH, getDraftDetailLTE}) (Cart);

// export default Cart