import React from "react";
import {Link} from "react-router-dom";

import logo from "../../assests/images/logo-dark.png";

function NewNavBar() {

    return (
        <nav>
            <div className="nav-container clearfix">
                <Link to={'/applyonline'}> <img src={logo} alt='mobitel_Logo' className='logo'/></Link>
            </div>

        </nav>
    )
}

export default NewNavBar;
