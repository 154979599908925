import axios from "axios";


const normalAxios = axios.create()

normalAxios.interceptors.request.use(
    config => {
        config.headers = { "Content-Type": "multipart/form-data" }
        return config;
    },
    error => {
        Promise.reject(error)
    });


normalAxios.interceptors.response.use((response) => {
    return response
}, function (error) {

    return Promise.reject(error);
});

export default normalAxios
