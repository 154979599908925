import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { createUserComplaint } from '../../redux/action/complaints/complaints.action'
import LoadingBbBody from '../boardBand/LoadingBbBody';
import SuccessAlert from '../alerts/SuccessAlert';
import ErrorAlert from '../alerts/ErrorAlert'
import { useHistory } from "react-router-dom";
import ComplainMap from "./ComplainMap";
import { isPrepaid } from '../../redux/action/accountDetails.action';



const CreateComplaint = (props) => {
    const history = useHistory();
    const [contactNumber, setContactNumber] = useState("");
    const [lat, setLat] = useState(6.927079);
    const [lng, setLong] = useState(79.861244);
    const [zoom, setZoom] = useState(10);

    const [selectLocation, setLocation] = useState(false);
    const [serviceType, setService] = useState("VOICE");
    const [description, setDescription] = useState("");


    const [isErrorValidate, setIsError] = useState(false)
    const [errorValidate, seterror] = useState('')

    const [voiceStatus, setVoiceStatus] = useState('NA')
    const [broadbandStatus, setBroadbandStatus] = useState('NA')
    const [peoTVStatus, setPeoTVStatus] = useState('NA')
    const [summaryStatus, setSummaryStatus] = useState('NA')

    const [serviceLst, setServiceList] = useState(props.serviceList)
    const [sum, setSUM] = useState(props.summary.status)

    const [payment, setPayment] = useState(false);
    const [bbThrottled, setBBThrottled] = useState(false);
    
   

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%'
    }

    const createSelectbox = () => {
        if( props.serviceList.hasOwnProperty('listofPEOService')){
          for(let i=0 ; i < props.serviceList.listofPEOService.length ; i++){
              if(peoTVStatus !== "Active" ){
                setPeoTVStatus(props.serviceList.listofPEOService[i].serviceStatus)
              }
          }
        }

        if( props.serviceList.hasOwnProperty('listofVoiceService')){
            for(let i=0 ; i < props.serviceList.listofVoiceService.length ; i++){
                setVoiceStatus(props.serviceList.listofVoiceService[i].serviceStatus)
            }
        }

        if( props.serviceList.hasOwnProperty('listofBBService')){
            for(let i=0 ; i < props.serviceList.listofBBService.length ; i++){
                setBroadbandStatus(props.serviceList.listofBBService[i].serviceStatus)
            }
        }

        if( props.summary.hasOwnProperty('status')){
           
                setSummaryStatus(props.summary.status)
        }


    }

    useEffect(() => {

        navigator.geolocation.getCurrentPosition(
            position => {
                setZoom(10)
            },
            err => console.log(err)
        );

        createSelectbox() 
        
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (serviceLst !== props.serviceList) {
            setServiceList(...[props.serviceList])
            createSelectbox()
           
        }

        if (sum !== props.summary.status) {
            setSUM(props.summary.status)
            createSelectbox()
            
        }

    }, [props.serviceList, props.summary.status])// eslint-disable-line react-hooks/exhaustive-deps
    
    const dragEndLatLon = (coord) => {
        const {latLng} = coord;

         setLat(latLng.lat());
         setLong(latLng.lng());
         setLocation(true);

        setZoom(16)
    };

    const searchLatLon = (place) => {
        const {location} = place.geometry;
         setLat(location.lat());
         setLong(location.lng());
         setLocation(true);
        setZoom(16)
    };

      const complaintSusses = () =>{
        // <Link to ='/complaints'/>
        // props.complaintCreateList = false
        history.push('/complaints')
      }

      const complaintIsError = () =>{
        // <Link to ='/complaints'/>
        // history.push('/complaints')
      }

      const paymentIsError = () =>{
        history.push('/bill/0')
      }

      const bbThrottledIsError = () =>{
      history.push('/boardBand/summary')
    }


      const validateFormSubmit = () => {

          if(contactNumber.length === 10){
              if(selectLocation){
                  if( checkBillComplete() ){ 
                    setIsError(true)
                    setPayment(true)
                    seterror('Dear valued customer, your voice service is not active at the moment. Kindly settle your bills to experience an uninterrupted service . ')
                  }else{
                      if(checkBBThrottled()){
                            setIsError(true)
                            setBBThrottled(true)
                            seterror('Sorry! Your Broadband is Throttled. Please purchase Extra GB. ')  
                      }else{
                        if(description === '') {
                            setIsError(true)
                            seterror('Sorry, Description cannot be empty.')
                        }else {
                            let serviceID
                            if(serviceType === 'BB') serviceID = props.serviceList.listofBBService[0].serviceID
                            else if(serviceType === 'PEOTV') serviceID = props.serviceList.listofPEOService[0].serviceID
                            else serviceID = props.serviceList.listofVoiceService[0].serviceID

                            props.createUserComplaint(localStorage.getItem('selectedAcc'), serviceID, serviceType, contactNumber,description,lat,lng, 'ACTIVE')
                        }
                            
                      }
                        
                  }
                       
              }else{
                setIsError(true)
                seterror('Sorry, Please Select Location .')
                // alert("Sorry, Please Select Location .");
              }

          }else{
            setIsError(true)
            seterror('Sorry, Please Enter Valide Contact number (0xxxxxxxxx).')
            // alert("Sorry, Please Enter Valide Contact number (0xxxxxxxxx).");
          }
        };

        const checkBillComplete = () => {

            if(serviceType === "VOICE"){
               return ((voiceStatus !== "NA" && voiceStatus !== "Active"));
            }

            if(serviceType === "BB"){
               return ((broadbandStatus !== "NA" && broadbandStatus !== "Active"));
            }

            if(serviceType === "PEOTV"){
               return ((peoTVStatus !== "NA" && peoTVStatus !== "Active"));
            }

            if(serviceType === "4"){
               return  false;
            }

            // return ( (voiceStatus !== "NA" && voiceStatus !== "Active") || (broadbandStatus !== "NA" && broadbandStatus !== "Active") || (peoTVStatus !== "NA" && peoTVStatus !== "Active") );
        }

        const checkBBThrottled = () => {
            return ((serviceType === "BB")&& (summaryStatus !=="NA") && (summaryStatus === "THROTTLED")); 
        }

      if(!props.serviceList.hasOwnProperty('listofVoiceService')){
          return  <div className='mainBody createComplaint  common-panel' >
            {/* <div className='createComplaint'> */}
            <LoadingBbBody loading={props.serviceListLoading}/>
            {/* </div> */}
          </div>
          
      }

      if(props.loading){
        return  <div className='mainBody createComplaint  common-panel' >
          {/* <div className='createComplaint'> */}
          <LoadingBbBody loading={props.loading}/>
          {/* </div> */}
        </div>
        
    }
     
    if (isPrepaid(props.serviceList && props.serviceList))
        return <Redirect to='/prepaid/boardBand'/>

    return (
        <div className="mainBody createComplaint  common-panel p-0" >
            {( 
                        props.error?(
                            <ErrorAlert error ={props.error} setError = {complaintSusses} setIsError = {complaintIsError}/>
                        ):null
                          
                    )}
            {
                isErrorValidate?(
                    payment?(
                        <ErrorAlert error ={errorValidate} setError = {paymentIsError} setIsError = {setIsError}/>
                    ):(
                        bbThrottled?(
                            <ErrorAlert error ={errorValidate} setError = {bbThrottledIsError} setIsError = {setIsError}/>                      
                        ):(
                            <ErrorAlert error ={errorValidate} setError = {seterror} setIsError = {setIsError}/>
                        )
                        
                    )
                    
                ):(
                    props.complaintCreateList?( 
                        <SuccessAlert msg='Complaint Success' setSuccess={complaintSusses}/>
                    ):( 
                        props.error?(
                            <ErrorAlert error ={props.error} setError = {complaintSusses} setIsError = {complaintIsError}/>
                        ):null
                          
                    )
                )
                    
                    
            }
            <div className='title mt-2'>

                {
                    props.complaintList && props.complaintList.asset === null ? null : <div onClick={()=>history.push('/complaints')}>
                        <Link to ='/complaints'>
                            <em className = "backButton mr-3">
                                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.233848 8.57101L7.42898 15.7637C7.74469 16.0787 8.25621 16.0787 8.57272 15.7637C8.88844 15.4488 8.88844 14.9373 8.57272 14.6224L1.9483 8.00037L8.57192 1.37834C8.88764 1.06342 8.88764 0.551907 8.57192 0.23619C8.25621 -0.07873 7.7439 -0.07873 7.42818 0.23619L0.233051 7.42886C-0.0778164 7.74052 -0.0778164 8.26007 0.233848 8.57101Z" fill="#707070"/>
                                </svg> &nbsp;
                                Back
                            </em>
                        </Link>
                    </div>


                }
                Submit Complaints

            
            </div>
              
                {
                    props.serviceListLoading?( 
                        <LoadingBbBody loading={props.serviceListLoading}/>
                    ):(
                        <div className='content'>
                            <div className='card cardCeateComplaint mx-3'>
                                <div className='form'>


                                    <div className='rowCeateComplaint'>
                                            <div>
                                                <div className='createComplaintLabel'>
                                                    <label htmlFor="serviceType">Service Type </label>
                                                </div>
                                                <div className='createComplaintDropDown'>
                                                    <select id="serviceType" name="serviceType" onChange={(e) => {
                                                        setService(e.target.value)
                                                    }}>
                                                        {
                                                            voiceStatus !== "NA" ? (
                                                                <option value="VOICE">Voice</option>
                                                            ) : null

                                                        }
                                                        {
                                                            broadbandStatus !== "NA" ? (
                                                                <option value="BB">Broadband</option>
                                                            ) : null

                                                        }
                                                        {
                                                            peoTVStatus !== "NA" ? (
                                                                <option value="PEOTV">Peo TV</option>
                                                            ) : null

                                                        }

                                                        <option value="VOICE">All</option>
                                                        {/* <option value="4">Other</option> */}
                                                    </select>
                                                </div>


                                            <div>
                                                <div className='createComplaintLabel'>
                                                    <label htmlFor="contactNumber">Contact Number</label>
                                                </div>
                                                <div className='createComplaintInput'>
                                                    <input type="text" id="contactNumber" name="contactNumber"
                                                           value={contactNumber} onChange={(e) => {
                                                        setContactNumber(e.target.value)
                                                    }}/>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='createComplaintLabel'>
                                                    <label htmlFor="complaintDescription">Complaint Description</label>
                                                </div>
                                                <div className='createComplaintInput'>
                                                    <textarea name="complaintDescription" onChange={(e) => {
                                                        setDescription(e.target.value)
                                                    }}>
                                                            
                                                    </textarea>
                                                </div>
                                            </div>


                                            </div>
                                        <ComplainMap searchLatLon={searchLatLon} google={props.google} zoom={zoom}
                                                     containerStyle={containerStyle} lat={lat} lng={lng}
                                                     onClick={(t, map, coord) => dragEndLatLon(coord)}/>
                                    </div>

                                </div>
                                <div className="createComplaintButtonContanner">
                                    {/* <button className="createComplaintButton" onClick={() => props.createUserComplaint(localStorage.getItem('selectedAcc'),2, props.profile.phone,'Test', '6.8053744', '79.9538724', 'ACTIVE')}>Submit Your Complaint</button> */}
                                    <button className="createComplaintButton" onClick={validateFormSubmit}>Submit Your
                                        Complaint
                                    </button>
                                </div>
                            </div>
                        </div>
                    )

                }
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.complaint.loadingCreateFault,
    complaintCreateList: state.complaint.complaintCreateList,
    error: state.complaint.errorCreateFault,
    complaintList: state.complaint.complaintList,
    serviceListLoading: state.accountDetails.loadingServiceList,
    serviceList: state.accountDetails.service_list,
    profile: state.broadBand.profile,
    summary: state.broadBand.summary
    
})

// export default connect (mapStateToProps, {createUserComplaint})( CreateComplaint) AIzaSyBgcfGpUoXgog2I3KMo_6JYjZ32NDXtQ0E

export default (connect (mapStateToProps, {createUserComplaint})(CreateComplaint))
