import React, {useState} from 'react';
import {connect} from "react-redux";
import {addBroadBandAccount} from "../../../redux/action/accountDetails.action";


function AddBroadband({addBroadBandAccount, loadingAddAccount,   setSuccess,  setError,  setIsError}) {
    const [userId, setName] = useState('');
    const [password, setPassword] = useState("");
    const [userIdError, setUserIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');



    function onSubmit() {
        if (userId) {
            if (password) {
                if (userId.length < 30) {
                    if (password.length < 50) {
                        addBroadBandAccount(userId, password,  setSuccess, setIsError, setError)
                    } else {
                        setPasswordError('Only 50 max characters')
                    }
                } else
                    setUserIdError('Only 30 max characters')
            } else {
                setPasswordError('Password cannot be empty')
            }
        } else
            setUserIdError('User ID cannot be empty')
    }

    return (
        <div className='m-3 mt-5 addAccount'>
            <div className='mt-2'>
                <span className='error-message float-right'>{userIdError}</span>
                <label className='d-block'>Broadband ID<p className='d-inline red'>*</p></label>
                <input type='text' className='pl-2' value={userId} onChange={(event => setName(event.target.value))}/>

            </div>

            <div className='mt-3'>
                <span className='error-message float-right'>{passwordError}</span>
                <label className='d-block'>Password <p className='d-inline red'>*</p></label>
                <input type={"password"} className='pl-2' value={password}
                       onChange={(e) => setPassword(e.target.value)}/>

            </div>


            <div className='mt-4'>
                <button className='medium blue float-right' disabled={loadingAddAccount} onClick={()=> onSubmit()}>{loadingAddAccount? 'Loading...' : 'Submit'}</button>
            </div>

        </div>
    );
}

const mapStateToProps = state => ({
    loadingAddAccount: state.accountDetails.loadingAddAccount,
})
export default connect(mapStateToProps, {addBroadBandAccount})(AddBroadband);

