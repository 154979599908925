import React, {useEffect, useState} from 'react';
import axiosInstance from "../../redux/axiosInstance";
import {getCallForwardingRequestUrl, getCallForwardingStatusUrl} from "../../redux/URL";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import {connect} from "react-redux";
import {CATCH_ERROR_MSG} from "../../redux/Types";

function CallForwarding({serviceList}) {

    const [servList, setServList] = useState(serviceList);

    const [slt, setSlt] = useState(localStorage.getItem('selectedAcc'));
    const [mobile, setMobile] = useState('');
    const [agree, setAgree] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reg, setReg] = useState(false);

    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        getStatus()
    }, []);

    const getStatus = () => {
        let url = getCallForwardingStatusUrl + 'telephoneNo=' + localStorage.getItem('selectedAcc')
        setLoading(true)
        axiosInstance.get(url).then(response => {
            if (response.data.isSuccess) {
                if (response.data.dataBundle.status === 'Y') {
                    setReg(true)
                    setMobile(response.data.dataBundle.fwdNo)
                    setLoading(false)
                } else {
                    setReg(false)
                    setLoading(false)
                }
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setLoading(false)
        })
    }

    const Subscribe = (type) => {
        if (mobile.length === 10) {
            if (type === 'Y' && !agree) {
                setError('please agree the condition')
                setIsError(true)
            } else {
                setLoading(true)
                let url = getCallForwardingRequestUrl + 'telephoneNo=' + localStorage.getItem('selectedAcc') + '&mobileNo=' + mobile + '&requestType=' + type
                axiosInstance.get(url).then(response => {
                    if (response.data.isSuccess) {
                        setSuccessMsg(response.data.errorShow)
                        setSuccess(true)
                        setLoading(false)
                        getStatus()
                    } else {
                        setError(response.data.errorShow)
                        setIsError(true)
                        setLoading(false)
                    }
                }).catch(() => {
                    setError(CATCH_ERROR_MSG)
                    setIsError(true)
                    setLoading(false)
                })
            }

        } else {
            setError('Enter valid number')
            setIsError(true)
        }

    }


    useEffect(() => {
        if (servList !== serviceList) {
            if (serviceList) {
                getStatus()
            }
            setServList(...[servList])
        }

    }, [serviceList, servList])

    if (serviceList.hasOwnProperty('listofPEOService') && serviceList.listofVoiceService.length === 0)
        return null
    if (loading)
        return <div className='rightSide loading'> <LoadingBbBody loading={loading} border={false}/> </div>
    if (isError) {
        return <div className='rightSide'> <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> </div>
    }
    if (success)
        return <div className='rightSide'> <SuccessAlert setSuccess={setSuccess} msg={successMsg}/> </div>
    return (
        <div className='rightSide'>
            <div className='callForwarding'>
                <div className='content'>
                    <div className='card'>
                        <div className='title m-2'>
                            <p>Call Forwarding</p>
                        </div>


                        <div className='form'>
                            <div>
                                <label className='d-block'>Your Number<p className='d-inline red'>*</p>
                                </label>
                                <input disabled={true} type={"number"} className='pLeft' value={slt} onChange={(e) => {
                                    setSlt(e.target.value)
                                }}/>
                            </div>

                            <div className='mt-4'>
                                <label className='d-block'>Forwarding Number<p className='d-inline red'>*</p>
                                </label>
                                <input disabled={reg} type={"number"} className='pLeft' value={mobile}
                                       onChange={(e) => {
                                           setMobile(e.target.value)
                                       }}/>
                            </div>

                            <div className='mt-4'>
                                {
                                    reg ? null : <div className='agreeCondition'>
                                        <input type='checkbox' className='sign' checked={agree}
                                               onChange={(e) => setAgree(e.target.checked)}/>
                                        <label className=''>I agree to the general <strong> terms and
                                            conditions</strong> </label>
                                    </div>
                                }

                            </div>

                            <div className='text-center'>
                                {
                                    reg ? <button className='medium long blue'
                                                  onClick={() => Subscribe('N')}>Unsubscribe</button> :
                                        <button className='medium blue' disabled={!agree}
                                                onClick={() => Subscribe('Y')}>Subscribe</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => ({
    serviceList: state.accountDetails.service_list,
})
export default connect(mapStateToProps, {})(CallForwarding);