import React from 'react'
import { SingaleAccordion } from './SingaleAccordion';

const PeoTV = () => {

    const accordionData = [
        {
            title: ' How do I get an SLT PEO TV service? ',
            content: <ul>
                    <li>There are 3 options
                        <ul>
                            <li>SLT Megaline – PEOTV with Voice or Voice & Internet through wired line    </li>
                            <li>SLT 4G LTE – PEOTV Go with Internet or Voice & Internet </li>
                            <li>SLT Fibre – PEOTV with Voice or Voice & Internet through Fibre Optics</li>
                        </ul>
                    </li>
                    <li>Request a new service through the SLT Selfcare App or visit the nearest SLT office  </li>
                </ul>
          },
          {
            title: 'What is the cost for a PEO TV service? ',
            content: <ul>
                    <li>There is a onetime charge  for the installation, and a recurring monthly charge</li>
                    <li>Refer 
                        <a href="https://www.slt.lk/en/personal/peo-tv"> https://www.slt.lk/en/personal/peo-tv </a> 
                        for installation charges
                    </li>
                    <li>Monthly charges vary as per the subscribed PEO TV package  </li>
                </ul>
          },
          {
            title: 'What are the advantages of PEO TV service? ',
            content: <ul>
                    <li> Uninterrupted entertainment  </li>
                    <li>Best collection of local & international channels for attractive prices</li>
                    <li>48 hours of rewind TV facility</li>
                    <li>Movie archives of all Genres</li>
                    <li>Extra featured services such as Picture in Picture, Music on Demand, Education on Demand etc.</li>
                    <li>Ability to extend home PEO TV to your smart device with PEO TV Go </li>
                </ul>
          },
          {
            title: ' How do I resolve a matter regarding a PEO TV connection? ',
            content: <ul>
                    <li>Lodge your complaint on the SLT Selfcare App (Main Menu—complaints)   </li>
                    <li>Dial 1212 from any telephone network to contact our 24/7 Contact Centre</li>
                </ul>
          },
          {
            title: ' How do I register and use the PEO TV GO app?',
            content: <ul>
                    <li>You can register by accessing &nbsp;
                        <a href="https://selfcare.peotv.com"> https://selfcare.peotv.com</a>  
                    </li>
                    <li>Once registration is completed you can download the PEO TV GO App through Google play store or Apple store  </li>
                </ul>
          },
          {
            title: 'How do I relocate the PEO TV equipment within premises?',
            content: <ul>
                    <li>Provide a written request to your nearest SLT Office     </li>
                </ul>
          }
    ];

    return (
        <div className = 'faqSubBody'>
            <div className='title'>
                <p>PEO TV </p>
            </div>
            {accordionData.map(({ title, content }) => (
                 <SingaleAccordion title={title} content={content} />
                ))}
        </div>
    )
}

export default PeoTV
