import { ERROR_GET_PREPAID_DATA, LOADING_GET_PREPAID_DATA, SUCCESS_GET_PREPAID_DATA } from "../../Types";

const initialState = {
    loading: true,
    prepaidData: null,
    error: '',
}

export default function prepaidReducer(state = initialState, action) {
    switch (action.type) {

        case LOADING_GET_PREPAID_DATA : {
            return {
                ...state,
                loading: true,
            }
        }

        case SUCCESS_GET_PREPAID_DATA : {
            return {
                ...state,
                loading: false,
                prepaidData: action.payload
            }
        }

        case ERROR_GET_PREPAID_DATA : {
            return {
                ...state,
                loading: false,
                prepaidData: null,
                error: action.payload,
            }
        }

        default:
            return state

    }
}
