import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import logo from "../../assests/images/logo-dark.png"
import userLog from '../../assests/images/naBarUserLogo.png'
import {logOut} from "../../redux/action/login.action";
import {getAccountDetails, getServiceDetails} from "../../redux/action/accountDetails.action";
import {getDraftDetailFTTH} from "../../redux/action/ftth/ftth.action";
import { getDraftDetailLTE } from "../../redux/action/lte/lte.action";
import { getCharge } from "../../redux/action/charge/charge.action";

import {useHistory, Link} from "react-router-dom";
import useComponentVisible from "../common/UseComponentVisible";


function NewNavBar({
                       logOut,
                       loggedIn,
                       draftDetails,
                       payLog,
                       draftDetailsLte,
                       getDraftDetailLTE,
                       getDraftDetailFTTH,
                       payLogLte,
                       invoice,
                       invoiceLte,
                       getCharge,
                       charge
                   }) {
    const history = useHistory();

    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);
    // const [chargeADSL, setChargeADSL] = useState('');
    // const [chargeLTE, setChargeLTE] = useState('');
    // const [chargeFTTH, setChargeFTTH] = useState('');

    useEffect(() => {
        getDraftDetailFTTH()
        getDraftDetailLTE()
        getCharge()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //    if(charge.length > 0){
    //         for(let i = 0; i < charge.length; i++){
    //             if(charge[i].BB_TYPE === 'ADSL'){
    //                 setChargeADSL(charge[i].INITIAL_FEE)
    //             }
    //             if(charge[i].BB_TYPE === 'SLT 4G'){
    //                 setChargeLTE(charge[i].INITIAL_FEE)
    //             }
    //             if(charge[i].BB_TYPE === 'SLT Fiber'){
    //                 setChargeFTTH(charge[i].INITIAL_FEE)
    //             }
    //         }
    //    }
    // }, [charge]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <nav>
            <div className="nav-container clearfix">
            <Link to={'/promo/buyonline'}> <img src={logo} alt='SLT Mobitel_Logo' className='logo'/></Link>

                {
                    loggedIn ? <div className='right-options-container float-right'>


                        <img className='user'
                             src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : userLog}
                             alt='userLogo' onClick={() => setIsComponentVisible(!isComponentVisible)}/>
                        {draftDetails && payLog && (payLog[0].gw_status === '1') || draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                            <strong className='ftthLabel'>1</strong> : null}


                        {
                            isComponentVisible ? <div className='moreSelect' ref={ref}>
                                <div>
                                    <div className="userdetails">
                                        <div>
                                            <img className='user'
                                                 src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : userLog}
                                                 alt='userLogo'/>

                                        </div>
                                        <div className="info">
                                            <div className='my-slt'>My SLT</div>
                                        </div>
                                    </div>
                                    <div className='items'>

                                        {
                                            // draftDetails && payLog && (payLog[0].gw_status === '1') ?
                                            // <div className="item" onClick={() => {
                                            //     // setIsComponentVisible(false)
                                            //     history.push('/connectionOrder')
                                            // }}>FIBER Order <strong>1</strong>
                                            // </div>                                      
                                            // :
                                            // ( 
                                            //     (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
                                            //     (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte)) 
                                            // ) ? <div className="item" onClick={() => {
                                            //     setIsComponentVisible(false)
                                            //     history.push('/cart')
                                            // }}>Request Fiber Connection
                                            // </div> :
                                            // <div className="item" onClick={() => {
                                            //         // setIsComponentVisible(false)
                                            //         history.push('/ftthrequest')
                                            //     }}>Request Fiber Connection
                                            //     </div>

                                        }

                                        {/* ylk temp comment{
                                            draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                                            <div className="item" onClick={() => {
                                                // setIsComponentVisible(false)
                                                history.push('/lterequest')
                                            }}>4G LTE Order <strong>1</strong>
                                            </div> 
                                            : 
                                            ( 
                                                (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
                                                (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte)) 
                                            ) ? <div className="item" onClick={() => {
                                                setIsComponentVisible(false)
                                                history.push('/cart')
                                            }}>Request LTE Connection
                                            </div> :
                                            <div className="item" onClick={() => {
                                                    // setIsComponentVisible(false)
                                                    history.push('/lterequest')
                                                }}>Request LTE Connection
                                                </div>

                                        }--ylk */}
                                        

                                        {/* {
                                             draftDetails && payLog && (payLog[0].gw_status === '1') ||  draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                                             <div className="item" onClick={() => {
                                                // setIsComponentVisible(false)
                                                history.push('/connectionOrder')
                                            }}>My Orders <strong>1</strong>
                                            </div> : null
                                        } */}


                                        <div className="item" onClick={() => {
                                            setIsComponentVisible(false)
                                            logOut(history)
                                        }}>Logout
                                        </div>
                                    </div>

                                </div>

                            </div> : null
                        }

                        {/* {
                            draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                            <div className="cart">
                                <div className="item" onClick={() => {
                                    // setIsComponentVisible(false)
                                    history.push('/lterequest')
                                    }}>
                                        <svg width="50" height="50" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31 0C48.1208 0 62 13.8792 62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0ZM46.6249 13.097L41.1075 14.8302C40.3771 15.0701 39.8906 15.7484 39.8058 16.4422L39.4955 19.4809L14.5123 22.2698C13.4396 22.4776 12.6858 23.4462 12.836 24.4419L14.3875 33.1833C14.5849 34.0976 15.3422 34.7244 16.1849 34.7348H38.3791L38.8143 37.2134H17.7364C16.6343 37.2522 15.827 38.0915 15.8141 39.0715C15.8598 40.1894 16.7609 40.9212 17.7364 40.9333H41.047C42.2227 40.836 43.0859 39.9043 42.905 38.7649L41.9136 33.3082L43.4651 18.0543L47.7412 16.6921C48.2372 16.5267 48.6096 16.2157 48.8576 15.7611C49.3268 14.7778 48.8885 13.689 48.0515 13.2181C47.5738 12.9698 47.1072 12.9388 46.6249 13.097ZM38.224 42.0044C36.6189 42.0044 35.3177 43.3056 35.3177 44.9106C35.3177 46.5157 36.6189 47.8169 38.224 47.8169C39.829 47.8169 41.1302 46.5157 41.1302 44.9106C41.1302 43.3056 39.8291 42.0044 38.224 42.0044ZM21.1649 42.0044C19.5599 42.0044 18.2587 43.3056 18.2587 44.9106C18.2587 46.5157 19.5599 47.8169 21.1649 47.8169C22.77 47.8169 24.0712 46.5157 24.0712 44.9106C24.0712 43.3056 22.77 42.0044 21.1649 42.0044Z" fill="url(#paint0_linear_3076_10283)"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_3076_10283" x1="31" y1="0" x2="31" y2="62" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#ECEFF1"/>
                                    <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                    </defs>
                                    </svg>
                                    
                                </div>
                            </div>
                        : */}

                        {/* <div className="cart">
                            <div className="item" onClick={() => {
                                // setIsComponentVisible(false)
                                history.push('/cart')
                                }}>
                                    <svg width="50" height="50" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31 0C48.1208 0 62 13.8792 62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0ZM46.6249 13.097L41.1075 14.8302C40.3771 15.0701 39.8906 15.7484 39.8058 16.4422L39.4955 19.4809L14.5123 22.2698C13.4396 22.4776 12.6858 23.4462 12.836 24.4419L14.3875 33.1833C14.5849 34.0976 15.3422 34.7244 16.1849 34.7348H38.3791L38.8143 37.2134H17.7364C16.6343 37.2522 15.827 38.0915 15.8141 39.0715C15.8598 40.1894 16.7609 40.9212 17.7364 40.9333H41.047C42.2227 40.836 43.0859 39.9043 42.905 38.7649L41.9136 33.3082L43.4651 18.0543L47.7412 16.6921C48.2372 16.5267 48.6096 16.2157 48.8576 15.7611C49.3268 14.7778 48.8885 13.689 48.0515 13.2181C47.5738 12.9698 47.1072 12.9388 46.6249 13.097ZM38.224 42.0044C36.6189 42.0044 35.3177 43.3056 35.3177 44.9106C35.3177 46.5157 36.6189 47.8169 38.224 47.8169C39.829 47.8169 41.1302 46.5157 41.1302 44.9106C41.1302 43.3056 39.8291 42.0044 38.224 42.0044ZM21.1649 42.0044C19.5599 42.0044 18.2587 43.3056 18.2587 44.9106C18.2587 46.5157 19.5599 47.8169 21.1649 47.8169C22.77 47.8169 24.0712 46.5157 24.0712 44.9106C24.0712 43.3056 22.77 42.0044 21.1649 42.0044Z" fill="url(#paint0_linear_3076_10283)"/>
                                <defs>
                                <linearGradient id="paint0_linear_3076_10283" x1="31" y1="0" x2="31" y2="62" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#ECEFF1"/>
                                <stop offset="1" stop-color="white"/>
                                </linearGradient>
                                </defs>
                                </svg>

                                {
                                    ( 
                                        (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
                                        (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte)) 
                                    ) ?
                                    <strong className='ftthLabel'>1</strong> : null
                                 }
                                
                            </div>
                        </div> */}
                    {/* } */}

                    </div> : null
                }


            </div>

        </nav>
    )
}

const mapStateToProps = state => ({
    draftDetails: state.ftth.draftDetails,
    payLog: state.ftth.paymentsLog,
    invoice: state.ftth.invoice,
    draftDetailsLte: state.lte.draftDetailsLte,
    payLogLte: state.lte.paymentsLogLte,
    invoiceLte: state.lte.invoiceLte,
    loggedIn: state.login.loggedIn,
    charge: state.charge.chargeDetails,
})

export default connect(mapStateToProps, {logOut, getAccountDetails, getServiceDetails, getDraftDetailFTTH, getDraftDetailLTE, getCharge})(NewNavBar);
//export default NavBar
