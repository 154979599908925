import React from 'react'
import { SingaleAccordion } from './SingaleAccordion';

const SltVoice = () => {

    const accordionData = [
        {
            title: 'How can I get SLT voice service? ',
            content: <ul>
                    <li>There are 3 options
                        <ul>
                            <li>SLT Megaline – Voice through wired line </li>
                            <li>SLT 4G LTE – Voice through wireless</li>
                            <li>SLT Fibre – Voice with Internet through Fibre Optics</li>
                        </ul>
                    </li>
                    <li>Request a new service through the SLT Selfcare App or visit the nearest SLT office </li>
                </ul>
          },
          {
            title: 'What is the cost for an SLT Voice service? ',
            content: <ul>
                    <li>There is a onetime charge for the installation, and a recurring monthly charge</li>
                    <li>Refer  
                        <a href="https://www.slt.lk/en/personal/telephone ">  https://www.slt.lk/en/personal/telephone </a> 
                         for installation charges
                    </li>
                    <li>Monthly charges vary as per the additional subscribed services </li>
                </ul>
          },
          {
            title: 'What are the advantages of SLT voice service? ',
            content: <ul>
                    <li>Superior voice clarity, can be upgraded with Internet, PEOTV and host of other services</li>
                </ul>
          },
          {
            title: 'How long does it take to activate SLT voice service? ',
            content: <ul>
                    <li>Same day service activation for SLT 4G LTE </li>
                    <li>For SLT Megaline and SLT Fiber, service delivery is assured within 7 days. (Subject to availability of necessary infrastructure).</li>
                </ul>
          },
          {
            title: 'How can I resolve a matter regarding my SLT connection? ',
            content: <ul>
                    <li>You can lodge your complaint via SLT SelfCare App (Main Menu—complaints)  </li>
                    <li>Dial 1212 from any telephone network to contact our 24/7 Contact Centre </li>
                </ul>
          },
          {
            title: 'I want to replace/relocate the wiring within/outside my premises ',
            content: <ul>
                    <li>Provide a written request to your nearest SLT Office    </li>
                </ul>
          },
          {
            title: 'I want to purchase a new Telephone instrument ',
            content: <ul>
                    <li>I want to purchase a new Telephone instrument 
                        <a href="https://www.slt.lk/en/e-teleshop"> https://www.slt.lk/en/e-teleshop</a> .
                    </li>
                    <li>You can also visit any of our Offices island wide. Use this link :  
                        <a href="https://www.slt.lk/en/contact-us/branch-locator/our-locations/our-network">
                        &nbsp; https://www.slt.lk/en/contact-us/branch-locator/our-locations/our-network 
                        </a>, to find your closest SLT office
                    </li>
                </ul>
          }
    ];
    

    return (
        <div className = 'faqSubBody'>
            <div className='title'>
                <p>SLT Voice </p>
            </div>
             {accordionData.map(({ title, content }) => (
                 <SingaleAccordion title={title} content={content} />
             ))}
            
        </div>
    )
}

export default SltVoice
