import React, {useEffect, useState} from 'react';
import {GetAgentCode, GetDraftDetailsV2, getOrderFTTHOrderStatus} from "../../../redux/URL";
import FileUploadState from "./subFileUploadComponent/FileUploadState";
import {CATCH_ERROR_MSG} from "../../../redux/Types";
import NewNavBar from '../NewNavBar';
import Footer from '../Footer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstanceFtth from '../../../redux/axiosInstanceFtth';
import ProgressBar from '../../boardBand/fileUploadProgress';

function FileUploadsFTTH () {

    let {orderId} = useParams();

    const [savedData, setSavedData] = useState({});
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [orderStatus, setOrderStatus] = useState('N/A');
    const [refNo, setRefNo] = useState(orderId);
    const [seqNo, setSeqNo] = useState('');
    
    const [agentCode, setAgentCode] = useState('');
    const [billAddress1, setBillAddress1] = useState('');
    const [billAddress2, setBillAddress2] = useState('');
    const [isSameAsServiceAddress, setIsSameAsServiceAddress] = useState(false);
    const [isAgentCodeSubmited, setIsAgentCodeSubmited] = useState(false);
    const [callAgentCode, setCallAgentCode] = useState(false);
    const [initialFetchDone, setInitialFetchDone] = useState(false);
    const [secondFetchDone, setSecondFetchDone] = useState(false);
    const [loading, setLoading] = useState(true);

    const [isDraftDetailsUpdate, setIsDraftDetailsUpdate] = useState(false);

    const [isBillAddressExist, setIsBillAddressExist] = useState(false);
    const [bithday, setBithday] = useState("");

    useEffect(() => {
       if(seqNo && seqNo && callAgentCode){
            getAgentCode()
            setCallAgentCode(false)
       }
    }, [refNo, seqNo, callAgentCode]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!initialFetchDone) {

            const delay = setTimeout(() => {
                setInitialFetchDone(true);
                setSecondFetchDone(true)
                setLoading(false);
            }, 5000);

            return () => clearTimeout(delay);
        }
    }, [initialFetchDone]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (initialFetchDone) {
            fetchDraftDetails();
            fetchOrderStatus();
        }
    }, [initialFetchDone]); 

    useEffect(() => {
        if (secondFetchDone) {

            const delay = setTimeout(() => {
                setSecondFetchDone(false)
                setLoading(false);
                fetchDraftDetails();
                fetchOrderStatus();
            }, 5000);

            return () => clearTimeout(delay);
        }
    }, [secondFetchDone]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSameAsServiceAddress) {
            setBillAddress1(savedData.servicE_ADD_STREET1);
            setBillAddress2(savedData.servicE_ADD_STREET2);
        }else{
            setBillAddress1("");
            setBillAddress2("");
        }
    }, [isSameAsServiceAddress]); 

    const fetchDraftDetails = () => {
       
        let url = GetDraftDetailsV2 + 'REF_NO=' + refNo
        axiosInstanceFtth.get(url).then(response => {


            if (response.data.isSuccess && response.data.dataBundle.length > 0) {
                setSavedData(response.data.dataBundle[0])
                setRefNo(response.data.dataBundle[0].reF_NO)
                setSeqNo(response.data.dataBundle[0].paymenT_REFUND_STATUS)
                setCallAgentCode(true)

                if (response.data.dataBundle[0].billinG_ADD_STREET1 || response.data.dataBundle[0].billinG_ADD_STREET2) {
                    setIsBillAddressExist(true) 
                    setBillAddress1(response.data.dataBundle[0].billinG_ADD_STREET1);
                    setBillAddress2(response.data.dataBundle[0].billinG_ADD_STREET2);
                }

                setBithday(response.data.dataBundle[0].birtH_DATE)

            } else {
                setError(response.data.errorShow)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
        })
    }


    const getAgentCode = () => {

        let url = GetAgentCode + 'REF_NO=' + refNo + '&SEQ_NO=' + seqNo
        axiosInstanceFtth.get(url).then((response) => {

            if (response.data.isSuccess  && response.data.dataBundle.length > 0) {
                setAgentCode(response.data.dataBundle[0].agentcode)
                setIsAgentCodeSubmited(true)
            } else {
                setError(response.data.errorShow)
                setAgentCode('')
                setIsAgentCodeSubmited(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setAgentCode('')
            setIsAgentCodeSubmited(false)
        })
      }

    const fetchOrderStatus = () => {
       
        let url = getOrderFTTHOrderStatus + 'orderId=' + refNo
        axiosInstanceFtth.get(url).then(response => {

            if (response.data.isSuccess && response.data.dataBundle) {
                setOrderStatus(response.data.dataBundle.status)  
            } else {
                setError(response.data.errorShow)
                setOrderStatus('N/A')  
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setOrderStatus('N/A')  
        })
    }
        return (
            <div className='ftthBody newFTTHLandingPage'>
                <NewNavBar/>
                <div className='landingFTTHPage'>
                {loading ? (
                    <div className='landingFTTHPage'>
                        <ProgressBar />
                    </div>
                ) : (
                    <FileUploadState savedData={savedData} error={error} setError={setError} isError={isError}
                        setIsError={setIsError} success={success} setSuccess={setSuccess} orderStatus={orderStatus}
                        fetchDraftDetails={fetchDraftDetails} fetchOrderStatus={fetchOrderStatus}
                        REF_NO={refNo} SEQ_NO={seqNo} setAgentCode={setAgentCode} agentCode={agentCode}
                        setCallAgentCode={setCallAgentCode} isAgentCodeSubmited={isAgentCodeSubmited} 
                        billAddress1 ={billAddress1} setBillAddress1={setBillAddress1} billAddress2={billAddress2}
                        setBillAddress2={setBillAddress2} isSameAsServiceAddress={isSameAsServiceAddress} 
                        setIsSameAsServiceAddress={setIsSameAsServiceAddress} isBillAddressExist={isBillAddressExist}
                        setIsDraftDetailsUpdate={setIsDraftDetailsUpdate} isDraftDetailsUpdate={isDraftDetailsUpdate}
                        bithday={bithday} />
                )}
                </div>
                <Footer />
            </div>
        );
   
}

export default FileUploadsFTTH;
