import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import ErrorAlert from "../../alerts/ErrorAlert";
import LoadingGraphs from "../../boardBand/LoadingGraphs";
import ProgressBarView from "../../boardBand/ProgressBarView";
import NoDataToShow from "../../common/NoDataToShow";
import { formatDateTimeByString } from "../../../utils/utils";

function PrePaidMyPackage({ dataUsage, loading, error, type }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(0)
  },[dataUsage])

  function increment() {
    let length = dataUsage.length;
    if (length - 1 > count) {
      setCount(count + 1);
    }
  }

  function decrement() {
    if (count > 0) {
      setCount(count - 1);
    }
  }

  const getPrecentage = (obj) => {
    const dataBalancePrentage =
      (parseFloat(obj.currentAmount) / parseFloat(obj.initialAmount)) * 100;
    return Number(dataBalancePrentage.toFixed(2));
  };

  const getRemaining = (obj) => {
    const dataBalancePrentage =
      parseFloat(obj.initialAmount) - parseFloat(obj.currentAmount);
    return Number(dataBalancePrentage.toFixed(2));
  };

  if (loading) return <LoadingGraphs loading={loading} />;
  if (error)
    return (
      <div className="graphBody-wrapper">
        <div className="graphBody noPadding">
          <ErrorAlert error={error} setIsError={null} setError={null} />
        </div>
      </div>
    );
  else if (dataUsage) {
    return (
      <div className="graphBody-wrapper">
        <div className="graphBody">
          {dataUsage.length > 0 ? (
            <div>
              <p className="graph-body-title">
                <span className="speed">{`${type} Packages`}</span>
              </p>
            </div>
          ) : null}
          {dataUsage.length === 0 ? (
            <NoDataToShow />
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <span
                style={{ minWidth: "20px", cursor: "pointer" }}
                onClick={() => decrement()}
              >
                <BiChevronLeft size={40} hidden={count === 0} />
              </span>

              <div>
                <Carousel
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  selectedItem={count}
                  width={500}
                >
                  {dataUsage.map((val, key) => {
                    return (
                      <div
                        key={key}
                        className=" m-auto"
                        style={{ width: "100%" }}
                      >
                        <div className="text-center">
                          <div className="name">{val.packageName}</div>
                          <div className="progress-bar-container">
                            <ProgressBarView
                              progress={getPrecentage(val)}
                              word={Number(
                                parseFloat(val.currentAmount).toFixed(2)
                              )}
                              unit={"GB"}
                            />
                          </div>
                          <div className="used-of">
                            {getRemaining(val)} {"GB"} USED OF{" "}
                            {Number(parseFloat(val.initialAmount).toFixed(2))}{" "}
                            {"GB"}
                          </div>
                          {dataUsage.length > 0 ? (
                            <p className="text-center blue">
                              (Valid Till : {formatDateTimeByString(val.expireTime)})
                            </p>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>

              <span
                style={{ minWidth: "20px", cursor: "pointer" }}
                onClick={() => increment()}
              >
                <BiChevronRight
                  size={40}
                  hidden={count === dataUsage.length - 1}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  } else
    return (
      <div className="graphBody-wrapper">
        <div className="graphBody">
          <NoDataToShow />
        </div>
      </div>
    );
}

export default PrePaidMyPackage;
