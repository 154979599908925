import React, { useEffect, useState, useRef } from 'react';
import closeButon from "../../../../assests/images/close-o.png"

function SelectPeoTvPackages({ packageInfo, setPackageInfo, setIsOpenPeo, data, setSelectedPeoObj }) {
    const [colorArray] = useState(['#852D84', '#852D84', '#8B949B', '#E3BA44', '#E28A74', '#727473', '#B3B3B3', '#737824']);
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
            document.addEventListener('mousedown', handleClickOutside);
        }, 0);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenPeo(false);
        }
    };

    const handleClosePopup = () => {
        setIsOpenPeo(false);
    };

    return (
        <div className='packageSelect' ref={ref}>
            {open && (
                <div className={open ? 'peoTvPack show' : 'peoTvPack'}>
                    <img src={closeButon} alt="Close" className='close-icon' style={{ position: 'absolute',maxHeight:'20px', top: '10px', right: '10px', cursor: 'pointer', textAlign: 'right' }} onClick={handleClosePopup} />
                    <div className='peoTVPackages'>
                        {data.map((obj, index) => {
                            return (
                                <div className='package-item-wrapper' key={index}>
                                    <div
                                        onClick={() => {
                                            setSelectedPeoObj(obj);
                                            setPackageInfo({
                                                ...packageInfo,
                                                peoTvPackages: obj.PEO_PACKAGE_NAME,
                                                peoPackagesData: obj,
                                            });
                                            setTimeout(() => {
                                                setIsOpenPeo(false);
                                            }, 150);
                                        }}
                                        className={
                                            packageInfo.peoTvPackages === obj.PEO_PACKAGE_NAME
                                                ? obj.PEO_PACKAGE_NAME.replace(' ', '_').toLowerCase() + ' package-item selected'
                                                : obj.PEO_PACKAGE_NAME.replace(' ', '_').toLowerCase() + ' package-item'
                                        }
                                        style={{ backgroundColor: colorArray[index] }}
                                    >
                                        <div className='count'>
                                            <span className='number' style={{ color: colorArray[index] }}>
                                                {' '}
                                                {obj.NO_OF_CHANNELS}
                                            </span>{' '}
                                            <span className='name'>Channels</span>
                                        </div>
                                        <div className='package-name'>{obj.PEO_PACKAGE_NAME}</div>
                                        <div className='price'>{obj.MONTHLY_RENTAL} LKR + Tax</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SelectPeoTvPackages;
