import {
    ERROR_GET_PAYMENT,
    LOADING_GET_PAYMENT,
   SUCCESS_GET_PAYMENT,

} from "../../Types";

const initialState = {
    loading: false,
    chargeDetails: [],
    error: '',
}

export default function ChargeReducers(state = initialState, action) {
    switch (action.type) {

        case LOADING_GET_PAYMENT : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_PAYMENT : {
            return {
                ...state,
                chargeDetails: action.payload,
                loading: false
            }
        }

        case ERROR_GET_PAYMENT : {
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        }

        default:
            return state

    }
}
