import React from "react";
import img1 from '../../assests/images/new_ VAS icons/ic_slt_go.jpg'
import img2 from '../../assests/images/new_ VAS icons/ic_kaspersky.png'
import img3 from '../../assests/images/new_ VAS icons/ic_peo_go.png'
import img4 from '../../assests/images/new_ VAS icons/ezgif.com-gif-maker.png'
import img5 from '../../assests/images/new_ VAS icons/ic_storage_vas.png'
import axios from "axios";
// import {jwtURL} from "../../redux/URL";
import {jwtURLV2} from "../../redux/URL";
import {connect} from "react-redux";
import duthaya from '../../assests/images/digitalLifeImg/duthaya_logo.png'

function ValueAdded({profile}) {

    // const getApi = () => {

    //     let body = {
    //         broadBandId: profile.subscriberid
    //     }
    //     axios.post(jwtURL, body).then(response => {
    //         if (response.status === 200) {
    //             let kasperyskyWebURL = "https://kaspersky-dp.slt.lk/landingpage?bbId=" + response.data.responseData.broadBandId + "&jwt=" + response.data.responseData.jwt;
    //             window.open(kasperyskyWebURL, "_blank")
    //         }
    //     }).catch(() => {

    //     })
    // }

    const getApiV2 = () => {
        let body = {
            subscriberId: profile.subscriberid,
            service: "KSS"
        }
        axios.post(jwtURLV2, body).then(response => {
            if (response.status === 200) {
                let kasperyskyWebURL = "https://kaspersky-dp.slt.lk/?jwt=" + response.data.jwt;
                window.open(kasperyskyWebURL, "_blank")
            }
        })
    }


    return (
        <div className='valueServices'>
            <p className='block-title'>Value Added Services</p>
            <div>

                <div onClick={() => window.open('https://duthaya.lk/', "_blank")}>
                    <div>
                        <img className='service-img' src={duthaya} style={{background:'white', padding:'10px'}} alt={'duthaya'} width={40} height={40}/>
                    </div>
                    <p className='service-label'>Duthaya</p>
                </div>


                <div onClick={() => getApiV2()}>
                    <div>
                        <img className='service-img' src={img2} alt={'img1'} width={40} height={40}/>
                    </div>
                    <p className='service-label'>Kaspersky</p>
                </div>


                <div onClick={() => window.open('https://www.slt.lk/en/peotv-go', "_blank")}>
                    <div>
                        <img className='service-img' src={img3} alt={'img1'} width={40} height={40}/>
                    </div>
                    <p className='service-label'>PEOTV GO</p>
                </div>

                <div onClick={() => window.open('https://play.google.com/store/apps/details?id=com.arimac.slt&hl=en&gl=US', "_blank")}>
                    <div>
                        <img className='service-img' src={img4} alt={'img1'} width={40} height={40}/>
                    </div>
                    <p className='service-label'>SLT Kimaki</p>
                </div>

                <div onClick={() => window.open('https://storage.slt.lk/portal/register/', "_blank")}>
                    <div>
                        <img className='service-img' src={img5} alt={'img1'} width={40} height={40}/>
                    </div>
                    <p className='service-label'>Storage</p>
                </div>

                {/*<div>*/}
                {/*    <div>*/}
                {/*        <img className='service-img' src={img4} alt={'img1'} width={40} height={40}/>*/}
                {/*    </div>*/}
                {/*    <p className='service-label'>Storage</p>*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*    <div>*/}
                {/*        <img className='service-img' src={img4} alt={'img1'} width={40} height={40}/>*/}
                {/*    </div>*/}
                {/*    <p className='service-label'>Storage</p>*/}
                {/*</div>*/}


            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {})(ValueAdded);