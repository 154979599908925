import {getDraftDetailsLTE, paymentsLogs, getInvoice} from "../../URL";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_DRAFT_DETAILS_LTE, ERROR_GET_INVOICE_LTE, ERROR_GET_PAYMENT_LOG_LTE,
    LOADING_GET_DRAFT_DETAILS_LTE, LOADING_GET_INVOICE_LTE, LOADING_GET_PAYMENT_LOG_LTE,
    SUCCESS_GET_DRAFT_DETAILS_LTE, SUCCESS_GET_INVOICE_LTE, SUCCESS_GET_PAYMENT_LOG_LTE,

} from "../../Types";
import axiosInstance from "../../axiosInstance";

export function getDraftDetailLTE() {
    let url = getDraftDetailsLTE + 'NIC='

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_DRAFT_DETAILS_LTE))
        axiosInstance.get(url).then((response) => {
            if (response.data.isSuccess && response.data.dataBundle.length > 0){
                dispatch(dispatchSuccess(SUCCESS_GET_DRAFT_DETAILS_LTE, response.data.dataBundle[0]))
                dispatch(getPaymentLogOfLte(response.data.dataBundle[0].reF_NO))
                dispatch(getInvoiceOfLTE(response.data.dataBundle[0].reF_NO))
            }
            else {
                dispatch(dispatchError(ERROR_GET_DRAFT_DETAILS_LTE, response.data.errorShow))
            }
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_DRAFT_DETAILS_LTE, CATCH_ERROR_MSG))
        })
    }
}

export function getPaymentLogOfLte(orderId) {

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_PAYMENT_LOG_LTE))
        axiosInstance.get(paymentsLogs + orderId).then((response) => {

            if (response.data.isSuccess && response.data.dataBundle.length > 0) {
                dispatch(dispatchSuccess(SUCCESS_GET_PAYMENT_LOG_LTE, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_PAYMENT_LOG_LTE, response.data.errorShow))
            }

        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_PAYMENT_LOG_LTE, CATCH_ERROR_MSG))
        })
    }
}

export function getInvoiceOfLTE(orderId) {

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_INVOICE_LTE))
        axiosInstance.get(getInvoice + 'REF_NO=' + orderId).then((response) => {

            if (response.data.isSuccess ) {//&& response.data.dataBundle.length > 0
                dispatch(dispatchSuccess(SUCCESS_GET_INVOICE_LTE, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_INVOICE_LTE, response.data.errorShow))
            }

        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_INVOICE_LTE, CATCH_ERROR_MSG))
        })
    }
}
