import {getAddCategoryUrl, getbannerImagesUrl} from "../URL";
import {dispatchSuccess, loadingDispatch} from "../DispatchFunctions";
import {LOADING_BANNERS, SET_BANNER_SLIDER_NO, SUCCESS_GET_BANNERS} from "../Types";
import axiosInstance from "../axiosInstance";
import normalAxios from "../normalAxios";


export function getBannerDetails() {
    return (dispatch) => {

        dispatch(loadingDispatch(LOADING_BANNERS))
        let url = getAddCategoryUrl + 'username=' + localStorage.getItem('userName')
        axiosInstance.get(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                if (response.data.dataBundle.length > 0) {
                    let tempArray = []
                    for (let i = 0; i < response.data.dataBundle.length; i++) {
                        tempArray.push(response.data.dataBundle[i].categorY_ID)
                    }
                    if(tempArray.length === 0)
                        tempArray.push(8)
                    dispatch(getImageUrls(tempArray))

                } else {

                }

            }

        }).catch(onerror => {
            console.log(onerror)

        })
    }

}


export function getImageUrls(categoryIDs) {

    const params = new FormData()
    params.append('category_ids', categoryIDs.toString())
    params.append('aspect_ratio', '16:9')
    return (dispatch) => {
        normalAxios.post(getbannerImagesUrl, params).then((response) => {
            dispatch(dispatchSuccess(SUCCESS_GET_BANNERS, response.data))

        }).catch(error => {
            console.log(error)
        })
    }
}

export function setBannerSlide(number) {
    return (dispatch) => {
        dispatch(dispatchSuccess(SET_BANNER_SLIDER_NO, number))
    }
}