import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getExtraGB} from "../../redux/action/broadBand.action";
import ProgressBarView from "./ProgressBarView";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import LoadingGraphs from "./LoadingGraphs";
import NoDataToShow from "../common/NoDataToShow";
import noExtraGB from '../../assests/images/nodatashow/extra gb.png'
import NoPreviledge from "../alerts/NoPreviledge";
import ErrorAlert from "../alerts/ErrorAlert";

function ExtraGb({getExtraGB, serviceList, extraGB, loading, profile, extraGbError}) {

    const [count, setCount] = useState(0);

    function increment() {
        let length = extraGB.usageDetails.length
        if (length - 1 > count) {
            setCount(count + 1)
        }


    }

    function decrement() {
        if (count > 0) {
            setCount(count - 1)
        }

    }

    function splitWord(word) {
        let day = word.split('T')[0]
        let time = word.split('T')[1]

        return day + ' ' + time
    }

    useEffect(() => {
        if (profile.hasOwnProperty('priviledges') && profile.priviledges.extra_gb) {
            getExtraGB(serviceList.listofBBService[0].serviceID)
        }

    }, [getExtraGB, profile, serviceList]);


    if (profile.hasOwnProperty('priviledges') && !profile.priviledges.extra_gb) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><NoPreviledge msg={'Please change the SLT package for Extra GB'}/>
        </div></div>
    } else if (loading) {
        return <LoadingGraphs loading={loading}/>
    } else if (extraGbError) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><ErrorAlert error={extraGbError} setIsError={null}
                                                                setError={null}/></div></div>
    } else if (extraGB.hasOwnProperty('usageDetails')) {
        return (
            <div className='graphBody-wrapper'>
            <div className='graphBody'>

                <div>
                    <p className='graph-body-title'>Remaining Volume as of : {splitWord(extraGB.reported_time)}</p>
                </div>

                {
                    extraGB.usageDetails.length === 0 ? <NoDataToShow imgPath={noExtraGB} msg='Wait for extra GB'/> :
                        <div className='d-flex justify-content-between align-items-center'>

                    <span style={{minWidth: '20px', cursor: 'pointer'}} className=''
                          onClick={() => decrement()}><BiChevronLeft
                        size={40} hidden={count === 0}/></span>

                            <div>
                                <Carousel showArrows={false} showStatus={false} showIndicators={false}
                                          selectedItem={count}
                                          width={500}>


                                    {
                                        extraGB.usageDetails.map((val, key) => {
                                            return <div key={key} className='h-auto m-auto' >
                                                <div className='text-center'>
                                                    <p>{val.name}</p>
                                                    <ProgressBarView progress={val.percentage} word={val.remaining}
                                                                     unit={val.volume_unit}/>
                                                    <div className='used-of'>{val.used}{val.volume_unit} USED
                                                        OF {val.limit} {val.volume_unit}</div>
                                                </div>
                                                {
                                                    extraGB.usageDetails.length > 0 ?
                                                        <p className='text-center blue'>(Valid Till
                                                            : {extraGB.usageDetails[0].expiry_date})</p> : null
                                                }
                                            </div>
                                        })
                                    }


                                </Carousel>
                            </div>

                            <span style={{minWidth: '20px', cursor: 'pointer'}}
                                  onClick={() => increment()}><BiChevronRight size={40}
                                                                              hidden={count === extraGB.usageDetails.length - 1 || extraGB.usageDetails.length === 0}/></span>
                        </div>}


            </div>
            </div>
        );
    } else
        return null
}

const mapStateToProps = state => ({
    loading: state.broadBand.loadingExtraGB,
    extraGB: state.broadBand.extraGB,
    extraGbError: state.broadBand.extraGbError,
    serviceList: state.accountDetails.service_list,
    profile: state.broadBand.profile,
})
export default connect(mapStateToProps, {getExtraGB})(ExtraGb);
