import React, {useState} from "react";
// import fiber from '../../assests/images/noAccountButtonImages/Logos-01.png'
// import adsl from '../../assests/images/noAccountButtonImages/Logos-02.png'
// import lte from '../../assests/images/noAccountButtonImages/Logos-03.png'
import AddAccount from "../common/addAccount/AddAccount";
import {connect} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";

function NoAnyAccount({account, error}) {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const closeModel= () => {
        setShow(false)
    }
    if(account.length > 0)
        return <Redirect to='/boardBand/summary'/>;
    if(error){
        return (
            <div className='mainBody imgBackground'>
               <div className='errorMsg'> {error}</div>
            </div>
        )
    }
    return (
        <div className='mainBody imgBackground'>
            <div className='noAnyAccount sentence pt-3 mt-5'>
                <p className='font-weight-bold d-inline-flex ml-4'>Are you an existing SLT Customer?</p>
                <button className='blue ml-4 medium' onClick={()=> setShow(true)}>Join now</button>
                <AddAccount show={show} hide={closeModel}/>
            </div>

            {/*<div className='noAnyAccount buttonSet row'>*/}

            {/*    <div className='col-4 text-center' onClick={() => history.push('/ftthrequest')}>*/}
            {/*        <button className='noAccountButton '>*/}
            {/*            <img src={adsl} alt='adsl'/>*/}
            {/*        </button>*/}
            {/*    </div>*/}

            {/*    <div className='col-4 text-center' onClick={() => history.push('/newService/new/Megaline')}>*/}
            {/*        <button className='noAccountButton'>*/}
            {/*            <img src={fiber} alt='fiber'/>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <div className='col-4 text-center'>*/}
            {/*        <button className='noAccountButton col-4' onClick={() => history.push('/newService/new/4G LTE')}>*/}
            {/*            <img src={lte} alt='lte'/>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className='buttonSet noAcc'>
                <div className="button-set-item noAcc">
                    <div className="image-container">
                        <div className="image-fiber"/>
                    </div>
                    <div className='mx-auto my-3'>
                        <button className='blue medium' onClick={() => {
                            // setNaviagetpath('/ftthrequest')
                            history.push('/promo/buyonline/ftth')
                        }}>Register
                        </button>
                    </div>

                </div>
                <div className="button-set-item noAcc">
                    <div className="image-container">
                        <div className="image-adsl"/>
                    </div>
                    <div className='mx-auto my-3'>
                        <button className='blue medium' onClick={() => {
                            // setNaviagetpath('/newService/new/Megaline')
                            history.push('/promo/buyonline/megaline')
                        }}>Register</button>
                    </div>

                </div>
                <div className="button-set-item noAcc">
                    <div className="image-container">
                        <div className="image-4g"/>
                    </div>
                    <div className='mx-auto my-3' onClick={() => {
                        // setNaviagetpath('/newService/new/4G LTE')
                        history.push('/promo/buyonline/4G LTE')
                    }}>
                        <button className='blue medium'>Register</button>
                    </div>

                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    account: state.accountDetails.account_Details,
    error: state.accountDetails.error,
})

export default connect(mapStateToProps, {})(NoAnyAccount);