import React, {useEffect, useState} from 'react';
import Querystring from "querystring";
import axiosInstanceFtth from "../../../redux/axiosInstanceFtth";
import {AuthonticationOpenFTTHLogin, GetAgentCode, GetDraftDetailsV2, getOrderFTTHOrderStatus, verifyOTPOpenFTTH, verifyOTPOpenFTTHV2} from "../../../redux/URL";
import FileUploadState from "./subFileUploadComponent/FileUploadState";
import {CATCH_ERROR_MSG} from "../../../redux/Types";
import NewNavBar from '../NewNavBar';
import Footer from '../Footer';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingBbBody from '../../boardBand/LoadingBbBody';
import ProgressBar from '../../boardBand/fileUploadProgress';
import OTPValidationFTTH from './userInformation/OTPValidationFTTH';
import ErrorAlert from '../../alerts/ErrorAlert';

function MobileStateCheckFTTH () {

    let {orderId} = useParams();
    const history = useHistory();

    const [savedData, setSavedData] = useState({});
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [orderStatus, setOrderStatus] = useState('N/A');
    const [refNo, setRefNo] = useState(orderId);
    const [seqNo, setSeqNo] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [agentCode, setAgentCode] = useState('');
    const [isAgentCodeSubmited, setIsAgentCodeSubmited] = useState(false);
    const [callAgentCode, setCallAgentCode] = useState(false);

  const [otpState, setOtpState] = useState(false);
  const [isOtpVerify, setIsOtpVerify] = useState(false);

    useEffect(() => {
       if(seqNo && seqNo && callAgentCode){
            getAgentCode()
            setCallAgentCode(false)
       }
    }, [refNo, seqNo, callAgentCode]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // fetchDraftDetails()
        // fetchOrderStatus()
        // verifyOTPOpen()
        requestOTPOpen()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDraftDetails = () => {

        setLoading(true);
       
        let url = GetDraftDetailsV2 + 'REF_NO=' + refNo
        axiosInstanceFtth.get(url).then(response => {


            if (response.data.isSuccess && response.data.dataBundle.length > 0) {
                setSavedData(response.data.dataBundle[0])
                setRefNo(response.data.dataBundle[0].reF_NO)
                setSeqNo(response.data.dataBundle[0].paymenT_REFUND_STATUS)
                setCallAgentCode(true)

            } else {
                setError(response.data.errorShow)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
        }).finally(() => {
            setLoading(false); 
        });
    }

    const getAgentCode = () => {

        setLoading(true);

        let url = GetAgentCode + 'REF_NO=' + refNo + '&SEQ_NO=' + seqNo
        axiosInstanceFtth.get(url).then((response) => {

            if (response.data.isSuccess  && response.data.dataBundle.length > 0) {
                setAgentCode(response.data.dataBundle[0].agentcode)
                setIsAgentCodeSubmited(true)
            } else {
                setError(response.data.errorShow)
                setAgentCode('')
                setIsAgentCodeSubmited(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setAgentCode('')
            setIsAgentCodeSubmited(false)
        }).finally(() => {
            setLoading(false); 
        });
    }

    const fetchOrderStatus = () => {
        setLoading(true);

        let url = getOrderFTTHOrderStatus + 'orderId=' + refNo
        axiosInstanceFtth.get(url).then(response => {

            if (response.data.isSuccess && response.data.dataBundle) {
                setOrderStatus(response.data.dataBundle.status)  
            } else {
                setError(response.data.errorShow)
                setOrderStatus('N/A')  
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setOrderStatus('N/A')  
        }).finally(() => {
        setLoading(false); 
    });
    }

    const requestOTPOpen = () => {
        let body = Querystring['stringify']({
            'userName': refNo,
            'userType': 'MOBILE',
            'channelID': 'WEB',
        })

        setIsOtpVerify(false)
    
        axiosInstanceFtth.post(AuthonticationOpenFTTHLogin, body).then(response => {
            if (response.data.isSuccess) {
                setOtpState(true)
                setLoading(false)
                setIsError(false)
                // console.log(response)
            } else {
              setLoading(false)
              setIsError(true)
            setError(response.data.errorShow)
            }
          }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
          })
    }

    const otpValidation = (otp) => {
        setOtpState(false)
        setLoading(true)
    
        let body = Querystring['stringify']({
        'code':refNo,
        'channelID':'WEB',
        'recievedOtp':otp,
        })
    
        axiosInstanceFtth.post(verifyOTPOpenFTTHV2, body).then(response => {
          if (response.data.isSuccess) {
                setIsError(false)
    
              localStorage.setItem('userName', response.data.dataBundle.user_id)
              localStorage.setItem('slt_ftth_accessToken', response.data.dataBundle.accessToken)
              localStorage.setItem('slt_ftth_refreshToken', response.data.dataBundle.refreshToken)
          
              setLoading(false)

              setIsOtpVerify(true)
    
              history.push('/applyonline/fibre/statustracking/' + refNo)
    
          } else {
                setIsError(true)
                setError(response.data.errorShow)
              setLoading(false)
              setIsOtpVerify(false)
          }
        }).catch(() => {
            setIsError(true)
            setError(CATCH_ERROR_MSG)
            setLoading(false)
            setIsOtpVerify(false)
        })
      }

    // const verifyOTPOpen = () => {

    //     setLoading(true);

    //     let body = Querystring['stringify']({
    //     'code': refNo,
    //     'channelID': 'WEB'
    //     })

    //     axiosInstanceFtth.post(verifyOTPOpenFTTHV2, body).then((response) => {
    //         if (response.data.isSuccess) {
    //             localStorage.setItem('userName', response.data.dataBundle.user_id)
    //             localStorage.setItem('slt_ftth_accessToken', response.data.dataBundle.accessToken)
    //             localStorage.setItem('slt_ftth_refreshToken', response.data.dataBundle.refreshToken)

    //             fetchDraftDetails()
    //             fetchOrderStatus()
               
    //         } else {
    //             setIsError(true)
    //             setError(response.data.errorShow)
    //         }
    //     }).catch(() => {
    //         setIsError(true)
    //         setError(CATCH_ERROR_MSG)
    //     }).finally(() => {
    //     setLoading(false); 
    // });
    
    // }

    if (loading) {
        return (
            <div className='ftthBody newFTTHLandingPage'>
            <NewNavBar/>
            <div className='landingFTTHPage'>
            <ProgressBar />
            </div>
            <Footer />
            </div>
        );
    }

    if (otpState) {
        return (
            <div className='ftthBody newFTTHLandingPage'>
                <NewNavBar/>
                <div className='landingFTTHPage'>

                    <div className="landingFTHOuter">
                    {otpState ? 
                    <OTPValidationFTTH otpValidation={otpValidation} email='' mobile={refNo}
                                        loading={loading}
                                        otpType={'MOBILE'} setOtpState={setOtpState}
                                        sendOTPRequest={requestOTPOpen}/> : null}
                    </div>
              
                </div>
                <Footer />
            </div>
        );
    }


    if(isOtpVerify){
        return (
            <div className='ftthBody newFTTHLandingPage'>
                <NewNavBar/>
                <div className='landingFTTHPage'>
                    <FileUploadState savedData={savedData} error={error} setError={setError} isError={isError}
                        setIsError={setIsError} success={success} setSuccess={setSuccess} orderStatus={orderStatus}
                        fetchDraftDetails={fetchDraftDetails} fetchOrderStatus={fetchOrderStatus} 
                        REF_NO={refNo} SEQ_NO={seqNo} setAgentCode={setAgentCode} agentCode={agentCode} 
                        setCallAgentCode ={setCallAgentCode} isAgentCodeSubmited={isAgentCodeSubmited}/>
                        
                </div>
                <Footer />
            </div>
        );
    }
      
    return (
        <div className='ftthBody newFTTHLandingPage'>
            <NewNavBar/>
            <div className='landingFTTHPage'>
                <div className='fixedConnectionStatusTracking'>
                    {isError ? 
                        <div style={{ 
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '110%',
                            height: '110%',
                            display: 'flex',                        
                            alignItems: 'center',
                            zIndex: '1000',
                        }}>
                            <ErrorAlert error={error} setError={setError} setIsError={setIsError} onclick={requestOTPOpen}/>

                        </div>
                    : null}
                    <div className="connectionStatusCkeckContainer">
                        <fieldset>
                        <div className="input-group">
                            <div
                            className={
                                isError ? "input-container" : "input-container"
                            }
                            
                            >
                            <span className="error-message float-right">
                                {error}
                            </span>
                            <input
                                type="text"
                                className=""
                                value={refNo}
                                onChange={
                                (e) => {
                                    setRefNo(e.target.value.trim());
                                }
                                }
                                placeholder="Order Id"
                            />
                            </div>
                            <button
                            disabled={loading}
                            className="medium blue float-right"
                            onClick={() => requestOTPOpen()}
                            >
                            {loading ? "Please wait..." : "Continue"}
                            </button>
                        </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
        
   
}

export default MobileStateCheckFTTH;
