import {
    ERROR_GET_EXTRA_GB_PACKAGES_DETAILS,
    GET_EXTRA_GB_PACKAGES_DETAILS,
    SUCCESS_GET_EXTRA_GB_PACKAGES_DETAILS,
} from "../../Types";

const initialState = {
    loading: true,
    dataBundle: {},
    error: ''
}

export default function extraGBDetailsReducer(state = initialState, action) {
    switch (action.type) {

        case GET_EXTRA_GB_PACKAGES_DETAILS : {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }

        case SUCCESS_GET_EXTRA_GB_PACKAGES_DETAILS : {
            return {
                ...state,
                loading: false,
                dataBundle: action.payload,
                error: ''
            }
        }

        case ERROR_GET_EXTRA_GB_PACKAGES_DETAILS : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}
