import React from 'react';
// import normalAxios from "../../redux/normalAxios";
import {Carousel} from "react-bootstrap";
import {connect} from "react-redux";
import {setBannerSlide} from "../../redux/action/banner.action";
import bannerNew2 from "../../assests/images/slt web banner _ vectors/ftth_300_speed.jpg";



const NewLandingBannerView = () => {

    // const [loading, setLoading] = useState(false);
    // const [images, setImages] = useState([]);

    const bannerArray = [bannerNew2]

    // useEffect(() => {
    //
    //     if (images.length === 0) {
    //         getBanners();
    //     }
    // })
    //
    // const getBanners = () => {
    //
    //     setLoading(true)
    //     const params = new FormData()
    //     params.append('category_ids', '34')
    //     params.append('aspect_ratio', '3.0')
    //
    //     normalAxios.post('http://link.lakmobile.com/BannerPortal/API/getBannerUrls', params).then((response) => {
    //
    //         setImages(...[response.data])
    //     }).catch(error => {
    //
    //     })
    //
    // }

    // if (loading && images.length === 0)
        return <Carousel fade={true}>
            {
                bannerArray.map((val, key) => {
                    return <Carousel.Item key={key}>
                        <img className='imageBanner' src={val} alt='temporaryBanner'/>
                    </Carousel.Item>
                })
            }
        </Carousel>

    // else {
    //     return (
    //         <Carousel fade={true} defaultActiveIndex={0} onSlide={(eventKey => setBannerSlide(eventKey))}>
    //             {/*defaultActiveIndex={slideNO}  onSlide={(eventKey => setBannerSlide(eventKey))}*/}
    //             {
    //                 images.map((val, key) => {
    //                     return <Carousel.Item key={key}>
    //                         <img src={val.url} alt='temporaryBanner' className='imageBanner'/>
    //                     </Carousel.Item>
    //                 })
    //             }
    //
    //
    //         </Carousel>
    //
    //     )
    // }
};


const mapStateToProps = state => ({
    slideNO: state.banners.slideNO,
})

export default connect(mapStateToProps, {setBannerSlide})(NewLandingBannerView);


