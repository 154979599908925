import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import HappyDayTitle from "./HappyDayTitle";
import "react-datepicker/dist/react-datepicker.css";
import NoPreviledge from "../../alerts/NoPreviledge";
import { connect } from "react-redux";
import { useState } from "react";
import { HappyDaySetUrl } from "../../../redux/URL";
import LoadingBbBody from "../LoadingBbBody";
import axiosInstance from "../../../redux/axiosInstance";
import { CATCH_ERROR_MSG } from "../../../redux/Types";
import ErrorAlert from "../../alerts/ErrorAlert";
import SuccessAlert from "../../alerts/SuccessAlert";
import { getProfile } from "../../../redux/action/broadBand.action";
import HappyDaySuccessAlert from "./HappyDaySuccessAlert";

export const DATE_NOT_SET = "DATE_NOT_SET";
export const DATE_CAN_UPDATE = "DATE_CAN_UPDATE";
export const REDEEMED = "REDEEMED";

function HappyDay({ profile, serviceList, getProfile }) {
  let today = new Date();
  let tomorrow = new Date();
  tomorrow = tomorrow.setDate(today.getDate() + 1);
  const [selectDay, setSelectDay] = useState(null);
  const [status, setStatus] = useState(DATE_NOT_SET);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (profile.hasOwnProperty("happy_day") && profile.happy_day) {
      let timestamp = Date.parse(profile.happy_day);
      let dateObject = new Date(timestamp);

      const yearOfHappydate = dateObject.getFullYear();
      const currentYear = new Date().getFullYear();

      if (yearOfHappydate === currentYear) {
        if (today >= dateObject) {
          setStatus(REDEEMED);
        } else if (today < dateObject) {
          setStatus(DATE_CAN_UPDATE);
        } else {
          setStatus(DATE_NOT_SET);
        }
        setSelectDay(dateObject);
      }
    }
  }, [profile, today]);

  function setMaxDate() {
    let lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);
    if (today === lastDayOfYear) {
      return new Date(new Date().getFullYear() + 1, 11, 31);
    }
    return lastDayOfYear;
  }

  function setSelectDate() {
    let url = `${HappyDaySetUrl}subscriberID=${
      serviceList.listofBBService[0].serviceID
    }&happydate=${selectDay.toLocaleDateString(
      "en-CA"
    )}&channel=SCP&commituser=user`;

    axiosInstance
      .post(url)
      .then((response) => {
        if (response.data.isSuccess) {
          setSuccess(true);
          setLoading(false);
        } else if (response.data.errorShow) {
          setError(response.data.errorShow);
          setIsError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(CATCH_ERROR_MSG);
        setIsError(true);
        setLoading(false);
      });
  }

  function afterSuccess() {
    getProfile(serviceList.listofBBService[0].serviceID);
    setSuccess(false);
  }

  if (!profile.priviledges.happy_day) {
    return (
      <div className="graphBody-wrapper">
        <div className="graphBody noPadding">
          <NoPreviledge msg={"You are not eligible for Happy Data"} />{" "}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="bbSubBody">
        <div className="body-container">
          <div className="title">Happy Day</div>
          <LoadingBbBody loading={loading} border={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="bbSubBody">
      <div className="body-container happyday">
        {success ? (
          <HappyDaySuccessAlert
            setSuccess={afterSuccess}
            msg={`Your Happy Day is ${
              selectDay ? selectDay.toLocaleDateString("en-CA") : null
            }`}
          />
        ) : null}
        {isError ? (
          <ErrorAlert
            error={error}
            setError={setError}
            setIsError={setIsError}
          />
        ) : null}

        <div className="title">Happy Day</div>

        <div className="wrapper">
          <div className="title_happyday">
            <HappyDayTitle status={status} date={profile.happy_day} />
          </div>

          <div className="date_select">
            <div>
              <ReactDatePicker
                selected={selectDay}
                onChange={setSelectDay}
                dateFormat="yyyy-MM-dd"
                className="date_input"
                minDate={tomorrow}
                maxDate={setMaxDate()}
                disabled={status === REDEEMED}
              />
            </div>
            <div>
              <button
                className="medium blue"
                disabled={status === REDEEMED}
                onClick={() => setSelectDate()}
              >
                Set Date
              </button>
            </div>
          </div>

          <div className="terms">
            <div className="head">Terms & Condition</div>
            <div>
              {" "}
              <span>*</span> Select any day and enjoy Free data for 24 hours.
            </div>
            <div>
              {" "}
              <span>*</span> You can change the date as your requirement, one
              day prior to the selected date.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.broadBand.profile,
  serviceList: state.accountDetails.service_list,
});

export default connect(mapStateToProps, { getProfile })(HappyDay);
