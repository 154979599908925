import {
    ERROR_GET_PROTOCOL_REPORT, LOADING_PROTOCOL_REPORT, SUCCESS_GET_PROTOCOL_REPORT,

} from "../../Types";

const initialState = {
    loading: false,
    dataBundle: {},
    error: ''
}

export default function protocolReportReducer(state = initialState, action) {
    switch (action.type) {

        case LOADING_PROTOCOL_REPORT : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_PROTOCOL_REPORT : {
            return {
                ...state,
                loading: false,
                dataBundle: action.payload
            }
        }

        case ERROR_GET_PROTOCOL_REPORT : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}
