import { ERROR_GET_COMPLAINTS_LIST, ERROR_POST_COMPLAINTS, LOADING_GET_COMPLAINTS_LIST, LOADING_POST_COMPLAINTS, SUCCESS_GET_COMPLAINTS_LIST, SUCCESS_POST_COMPLAINTS } from "../../Types"

 const initialState = {
     loading: true,
     loadingCreateFault: false,
     faultSucces: '',
     complaintList: [],
     complaintCreateList: false,
     error: '',
     errorCreateFault: ''
 }

 export default function complaintsReducer(state = initialState, action){
     switch(action.type){

        case LOADING_GET_COMPLAINTS_LIST: {
            return {
                ...state,
                loading: true,
                complaintCreateList: false,
                error: '',
                // errorCreateFault: ''
            }
        }

        case SUCCESS_GET_COMPLAINTS_LIST: {
            return {
                ...state,
                complaintList: action.payload,
                loading: false,
                errorCreateFault: ''
            }
        }

        case ERROR_GET_COMPLAINTS_LIST : {
            return {
                loading : false,
                error : action.payload,
                errorCreateFault: ''
            }
        }

        case LOADING_POST_COMPLAINTS: {
            return {
                ...state,
                loadingCreateFault: true
            }
        }

        case SUCCESS_POST_COMPLAINTS :{
            return{
                ...state,
                loadingCreateFault:false,
                complaintCreateList : true
            }
        }

        case ERROR_POST_COMPLAINTS :{
            return {
                loadingCreateFault : false,
                // complaintCreateList : false,
                errorCreateFault : action.payload
            }
        }

         default:
             return state
     }
 }
  