import React, {useState} from 'react';
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import logo from "../../../assests/images/Logo.png";
import axiosInstance from "../../../redux/axiosInstance";
import {ftthFileUpload} from "../../../redux/URL";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import LoadingBbBody from "../../boardBand/LoadingBbBody";
import {CATCH_ERROR_MSG} from "../../../redux/Types";

function FileUploadState({
                             savedData,
                             error,
                             isError,
                             setError,
                             setIsError,
                             success,
                             setSuccess,
                             fetchDraftDetails,
                             setOrderStatus
                         }) {

    const [loading, setLoading] = useState(false);
    const [nicFront, setNicFront] = useState(null);
    const [nicBack, setNicBack] = useState(null);
    const [addressVerProof, setAddressVerProof] = useState(null);
    const [pass, setPass] = useState(null);
    const maxFileSize = 2 * 1024 * 1024
    function successSave() {
        setSuccess(false)
        setOrderStatus('L')
        fetchDraftDetails()
    }



    function printDocument() {

        if (savedData.nic && (!nicFront || !nicBack)) {
            setError('Please upload all the relevant files')
            setIsError(true)
        } else if (savedData.pp && !pass) {
            setError('Please upload all the relevant files')
            setIsError(true)
        } else if ((nicFront && nicBack) && (nicFront.size > maxFileSize || nicBack.size > maxFileSize)) {
            setError('Maximum file upload size 2MB')
            setIsError(true)
        }else {

            setLoading(true)

            const input = document.getElementById('pdfViewer');



            var title = savedData.title;
            var firstName = savedData.firstname;
            var lastName = savedData.lastname;
            var dob = savedData.birtH_DATE;
            var gender = savedData.gender;
            var nic = savedData.nic ? savedData.nic : savedData.pp;
            var nationality = savedData.nationality;
            var mobile = savedData.mobileno;
            var email = savedData.email;

            var lat = savedData.latitude;
            var lng = savedData.longitude;

            var installationAddress1 = savedData.servicE_ADD_STREET1;
            var installationAddress2 = savedData.servicE_ADD_STREET2;

            // var googleLocation = "https://goo.gl/maps/Xkkr87VCR9HppxRU8";

            var googleLocation = "https://www.google.com/maps/?q=" + lat + "," + lng;
            var googleLocationView = "lat - " + lat + " /long - " + lng;

            var billingAddress1 = savedData.billinG_ADD_STREET1;
            var billingAddress2 = savedData.billinG_ADD_STREET2;

            var existTelAccNo = ""

            var broadbandPackage = savedData.bB_PACKAGE;

            var PeoTvPackage1 = savedData.peO_PACKAGE;
            var PeoTvPackage2 = "";
            var PeoTvPackage3 = "";
            var PeoTvPackage4 = "";

            var voice = '1'
            var broadband = savedData.bB_PACKAGE ? '1' : '0'
            var peoTv = savedData.peO_PACKAGE ? '1' : '0'

            var billType = savedData.billinG_MODE;//post eBill
            var isExistCus = false;
            var activeIdd = savedData.iddstatus === 'true';

            var myPhoneYes = "S";
            var myPhoneNo = "S";

            var exCusYes = "S";
            var exCuNo = "F";

            if (isExistCus === true) {
                exCusYes = "F";
                exCuNo = "S";
            }

            var billMode_EBill = "S";
            var billMode_Print = "F";

            if (billType === "eBill") {
                billMode_EBill = "F";
                billMode_Print = "S";
            }

            var activeIddYes = "S";
            var activeIddNo = "F";

            if (activeIdd === true) {
                activeIddYes = "F";
                activeIddNo = "S";
            }


            html2canvas(input)
                .then((canvas) => {

                    const pdf = new jsPDF("p", "in", "a4");

                    pdf.setFontSize(17);
                    pdf.setFont("courier", "bold");
                    pdf.text('SLT Application For New Services', 2.40, 0.46)
                    pdf.addImage(logo, "JPEG", 0.30, 0.15, 2.00, 0.70);

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                   // pdf.text('Registration No : ' + regNo, 2.40, 0.70)

                    pdf.setLineWidth(0.01);
                    pdf.line(0.30, 1.00, 8.00, 1.00, 'S');

                    //sub headers
                    pdf.text('Customer Information', 0.30, 1.80)
                    pdf.text('Contact Details', 0.30, 4.00)
                    pdf.text('Service Installation Address', 0.30, 4.70)

                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    // First Box Test
                    pdf.text('Existing customer', 0.50, 7.00)
                    pdf.text('Yes', 2.60, 7.00)
                    pdf.text('No', 3.40, 7.00)
                    pdf.text('Existing ', 4.10, 6.90)
                    pdf.text('Tel/Acc No ', 4.10, 7.10)
                    pdf.text(existTelAccNo, 5.30, 7.00)

                    pdf.text('Your preferred billing', 0.50, 7.35)
                    pdf.text('mode', 0.50, 7.55)
                    pdf.text('SLT My Bill ', 2.85, 7.45)
                    pdf.text('E Bill ', 4.35, 7.45)
                    pdf.text('Printed ', 5.45, 7.35)
                    pdf.text('bill ', 5.45, 7.55)

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                    pdf.text('Mode of Connection', 0.50, 8.20)

                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    pdf.text('Voice(Telephone)', 2.70, 8.45)
                    pdf.text('Broadband', 4.55, 8.45)
                    pdf.text('Peo TV', 6.25, 8.45)
                    pdf.text('SLT Fiber', 1.00, 8.75)

                    pdf.text(voice, 3.40, 8.80)
                    pdf.text(broadband, 5.00, 8.80)
                    pdf.text(peoTv, 6.60, 8.80)

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                    pdf.text('Package Details', 0.50, 9.50)

                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    pdf.text('Do you want to deactivate IDD ', 0.70, 9.75)
                    pdf.text('Yes ', 4.50, 9.75)
                    pdf.text('NO ', 5.30, 9.75)

                    pdf.text('My Phone ', 0.70, 10.00)
                    pdf.text('Yes ', 2.70, 10.00)
                    pdf.text('NO ', 3.50, 10.00)

                    pdf.text('Broadband Package ', 0.70, 10.30)
                    pdf.text(broadbandPackage, 2.75, 10.30)
                    pdf.text('Peo TV ', 0.70, 10.60)
                    pdf.text('Packages ', 0.70, 10.80)

                    pdf.setFontSize(10)

                    pdf.text(PeoTvPackage1, 1.75, 10.70)//Peo TV  Packages
                    pdf.text(PeoTvPackage2, 3.35, 10.70)
                    pdf.text(PeoTvPackage3, 4.95, 10.70)
                    pdf.text(PeoTvPackage4, 6.55, 10.70)


                    //Check Box
                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    pdf.text('Home  ', 2.40, 1.35)
                    pdf.text('Office  ', 3.30, 1.35)
                    pdf.text('Other  ', 4.40, 1.35)
                    pdf.text('CA Number  ', 5.65, 1.35)


                    pdf.text('Title  ', 0.30, 2.25)
                    pdf.text(title, 2.45, 2.25)
                    pdf.text('First Name  ', 0.30, 2.60)
                    pdf.text(firstName, 2.45, 2.60)
                    pdf.text('Last Name  ', 0.30, 2.95)
                    pdf.text(lastName, 2.45, 2.95)
                    pdf.text('Date of Birth  ', 0.30, 3.30)
                    pdf.text(dob, 2.45, 3.30)
                    pdf.text('Gender  ', 4.00, 3.30)
                    pdf.text(gender, 5.30, 3.30)
                    pdf.text('NIC / Passport ', 0.30, 3.65)
                    pdf.text(nic, 2.45, 3.65)
                    pdf.text('Nationality  ', 4.00, 3.65)
                    pdf.text(nationality, 5.30, 3.65)

                    pdf.text('Mobile  ', 0.30, 4.35)
                    pdf.text(mobile, 2.45, 4.35)
                    pdf.text('Email  ', 4.00, 4.35)
                    pdf.text(email, 5.00, 4.35)

                    pdf.text('Installation Address  ', 0.30, 5.05)
                    pdf.text(installationAddress1, 2.45, 5.05)
                    pdf.text(installationAddress2, 2.45, 5.40)
                    pdf.text('Google Location  ', 0.30, 5.75)
                    // pdf.text(googleLocation,2.45, 5.75)
                    pdf.setTextColor(0,0,255)
                    pdf.textWithLink(googleLocationView, 2.45, 5.75, {url: googleLocation});
                    pdf.setTextColor(0,0,0)
                    pdf.text('Billing Address  ', 0.30, 6.10)
                    pdf.text(billingAddress1, 2.45, 6.10)
                    pdf.text(billingAddress2, 2.45, 6.45)

                    // all boxes view in pdf
                    pdf.setFillColor(0, 0, 0);
                    pdf.setLineWidth(0.01)
                    pdf.rect(7.10, 0.30, 0.80, 0.60); //top box

                    pdf.rect(3.00, 1.20, 0.2, 0.2, 'F'); //home
                    pdf.rect(4.10, 1.20, 0.2, 0.2, 'S'); //Office
                    pdf.rect(5.10, 1.20, 0.2, 0.2); //Other

                    pdf.rect(6.90, 1.15, 1.0, 0.3) //ca number

                    pdf.rect(2.40, 2.05, 2.0, 0.3) //title
                    pdf.rect(2.40, 2.40, 5.35, 0.3) //fname
                    pdf.rect(2.40, 2.75, 5.35, 0.3) //lname
                    pdf.rect(2.40, 3.10, 1.5, 0.3) //dob
                    pdf.rect(5.25, 3.10, 2.0, 0.3) //gender
                    pdf.rect(2.40, 3.45, 1.5, 0.3) //nic
                    pdf.rect(5.25, 3.45, 2.0, 0.3) //nationalyty

                    pdf.rect(2.40, 4.15, 1.5, 0.3) //mobile
                    pdf.rect(4.95, 4.15, 2.8, 0.3) //email

                    pdf.rect(2.40, 4.85, 5.35, 0.3) //installatin address part1
                    pdf.rect(2.40, 5.20, 5.35, 0.3) //installatin address part1
                    pdf.rect(2.40, 5.55, 5.35, 0.3) //Google Location
                    pdf.rect(2.40, 5.90, 5.35, 0.3) //Billing Address  part 1
                    pdf.rect(2.40, 6.25, 5.35, 0.3) //Billing Address  part 2


                    pdf.rect(0.30, 6.60, 7.70, 1.2) //first form box

                    pdf.rect(3.00, 6.85, 0.2, 0.2, exCusYes) //Yes
                    pdf.rect(3.70, 6.85, 0.2, 0.2, exCuNo) //No
                    pdf.rect(5.25, 6.80, 2.5, 0.3) //Tel/Acc No

                    pdf.rect(4.00, 7.30, 0.2, 0.2) //SLT My Bill
                    pdf.rect(5.00, 7.30, 0.2, 0.2, billMode_EBill) //E Bill
                    pdf.rect(6.25, 7.30, 0.2, 0.2, billMode_Print) //Printed Bill

                    pdf.rect(0.30, 7.90, 7.70, 1.2) //second form box

                    pdf.rect(2.70, 8.60, 1.5, 0.3) //MOF Voice
                    pdf.rect(4.30, 8.60, 1.5, 0.3) //MOF Broadband
                    pdf.rect(5.90, 8.60, 1.5, 0.3) //MOF Peo TV

                    pdf.rect(0.30, 9.20, 7.70, 2.0) //third form box
                    pdf.rect(4.85, 9.60, 0.2, 0.2, activeIddYes) //Yes
                    pdf.rect(5.55, 9.60, 0.2, 0.2, activeIddNo) //No
                    pdf.rect(3.05, 9.85, 0.2, 0.2, myPhoneYes) //Yes
                    pdf.rect(3.75, 9.85, 0.2, 0.2, myPhoneNo) //No

                    pdf.rect(2.70, 10.10, 2.0, 0.3) //Broadband Packages

                    pdf.rect(1.70, 10.50, 1.5, 0.3) //Peo TV  Packages
                    pdf.rect(3.30, 10.50, 1.5, 0.3) //Peo TV  Packages
                    pdf.rect(4.90, 10.50, 1.5, 0.3) //Peo TV  Packages
                    pdf.rect(6.50, 10.50, 1.4, 0.3) //Peo TV  Packages


                    /// add new page
                    pdf.addPage("a4", "p");
                    pdf.setFillColor(0, 0, 0);
                    pdf.setLineWidth(0.01)
                    pdf.rect(0.30, 0.50, 7.70, 2.5) //Comment

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                    pdf.text('Comment/Remark', 0.50, 0.90)

                    pdf.text('Customer Agreement', 0.50, 3.50)

                    let pageWidth = 8.5,
                        lineHeight = 4.80,
                        margin = 0.6,
                        maxLineWidth = pageWidth - margin * 2,
                        fontSize = 24,
                        ptsPerInch = 72,
                        oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
                        text = "I do hereby declare and confirm that the information above, furnished by me are true and correct. I confirm that I have received, read and understood the 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' (SLT), which are also available on SLT website www.slt.lk. I shall abide by the said 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' as may be revisedmodified and supplemented by SLT from time to time and morefully stated/updated under the said SLT website www.slt.lk.";

                    let textLines = pdf.setFontSize(12)
                        .setFont("courier", "normal")
                        .splitTextToSize(text, maxLineWidth);

                    pdf.text(textLines, margin, margin + 2 * oneLineHeight);

                    // pdf.text('Terms and Conditions http://www.slt.lk/en/terms-conditions',0.70, 5.60)
                    pdf.setTextColor(0,0,255)
                    pdf.textWithLink('Terms and Conditions http://www.slt.lk/en/terms-conditions', 0.70, 5.60, {url: 'http://www.slt.lk/en/terms-conditions'});
                    pdf.setTextColor(0,0,0)

                    pdf.text('I agree terms and Conditions ', 1.05, 5.90)
                    // pdf.text('Yes ',3.90, 5.90)
                    // pdf.text('NO ',4.70, 5.90)

                    pdf.rect(0.80, 5.75, 0.2, 0.2, 'F') //Yes
                    // pdf.rect(5.00, 5.75, 0.2,0.2) //No


                    let fileName = savedData.nic ? savedData.nic + '.pdf' : savedData.pp + '.pdf'

                    let formData = new FormData();
                    formData.append('nicfront_file', nicFront)
                    formData.append('nicback_file', nicBack)
                    formData.append('pp_file', pass)
                    formData.append('application_file', pdf.output('blob'), fileName)
                    formData.append('billingproof_file', addressVerProof)
                    formData.append('nic', savedData.sltnic ? savedData.sltnic : savedData.nic)
                    formData.append('ppNumber', savedData.pp)
                    formData.append('rtom', savedData.rtom)


                    axiosInstance.post(ftthFileUpload, formData).then(response => {

                        if (response.data.isSuccess) {
                            setSuccess(true)
                            setLoading(false)
                        } else {
                            setError(response.data.errorShow)
                            setIsError(true)
                            setLoading(false)
                        }
                    }).catch(() => {
                        setError(CATCH_ERROR_MSG)
                        setIsError(true)
                        setLoading(false)
                    })

                    pdf.save(fileName);
                }).catch((error) => {

                    console.log(error)
            });
        }
    }


    if (loading) {
        return <div className='mainViewFTTH fileUploadFtth'>
            <div className="header">
                <div className='titles'>
                    <p>NEW SERVICE REQUEST- SLTMOBITEL FIBRE HOME LINE</p>
                </div>
            <LoadingBbBody/>
            </div>
        </div>
    }
    return (
        <div className='mainViewFTTH fileUploadFtth'>



                {success ? <SuccessAlert setSuccess={successSave} msg='Submitted your request successfully.'/> : null}

                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className="header">
                    <div className='titles'>
                        <p>NEW SERVICE REQUEST- SLTMOBITEL FIBRE HOME LINE</p>
                    </div>
                    <div className="connection-details">
                        <div className='details'>
                            <div className='valName'>Order Reference {' : '}</div>
                            <div className='value'> {savedData.reF_NO}</div>
                        </div>
                        <div className='details'>
                            <div className='valName'>Status {' : '}</div>
                            <div className='value  status'> Pending Document Upload</div>
                        </div>
                    </div>
                </div>


                <div className='formUpload'>
                    <div className='header'>Upload Documents</div>


                    {/*<div className='radioButtons'>*/}
                    {/*    <div>*/}
                    {/*        <input type='radio' id="type-nic" name="type" checked={type === 'nic'}*/}
                    {/*               onChange={() => setType('nic')}/>*/}
                    {/*        <label htmlFor="type-nic">NIC</label>*/}
                    {/*    </div>*/}

                    {/*    <div>*/}
                    {/*        <input type='radio' id="type-pp" checked={type === 'pp'}*/}
                    {/*               onChange={() => setType('pp')}/>*/}
                    {/*        <label htmlFor="type-pp">Passport</label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className='uploadField'>
                        {
                            savedData.nic ? <>
                                <div>
                                    <label>NIC Front</label>
                                    <input type='file' className="custom-file-input" accept="image/*"
                                           onChange={(event) => setNicFront(event.target.files[0])}/>
                                </div>

                                <div>
                                    <label>NIC Back</label>
                                    <input type='file' className="custom-file-input" accept="image/*"
                                           onChange={(event) => setNicBack(event.target.files[0])}/>
                                </div>
                            </> : <div>
                                <label>Passport</label>
                                <input type='file' className="custom-file-input" accept="image/*"
                                       onChange={(event) => setPass(event.target.files[0])}/>
                            </div>


                        }


                        {
                            savedData.hasOwnProperty('billinG_ADD_STREET1') ? savedData.billinG_ADD_STREET1 === savedData.servicE_ADD_STREET1 ? null :
                                <div>
                                    <label>Address Verification Proof *</label>
                                    <input type='file' accept="image/*"
                                           onChange={(event) => setAddressVerProof(event.target.files[0])}/>
                                </div> : null
                        }


                    </div>
                    <div className="footer">
                        <div className="file-requrements">
                            <div className="requirement">Maximum file upload size 2MB</div>
                            <div className="requirement">Allow file types : png, jpg , jpeg</div>
                        </div>
                        <div className='button-container'>
                            <button className='blue' onClick={() => printDocument()}>Submit
                            </button>
                        </div>
                    </div>


                </div>




            <div id='pdfViewer'/>
        </div>
    );
}

export default FileUploadState;
