
 //export const BASE_URL = 'https://omnitest.slt.com.lk/api/' //  ---- TEST_BASE_URL

export const BASE_URL = 'https://omniscapp.slt.lk/mobitelint/slt/api/'
//export const BASE_URL = 'https://omniscappstg.slt.lk/intstg/slt/api/'
//export const BASE_URL = 'https://omniscappstg.slt.lk/mobitelint/slt/api/' 

export const BILL_PAY = "https://billpay.slt.lk/";
export const PTL_URL = 'https://bannerportal.slt.lk/'
export const firebaseID = '123123123'
export const refreshTokenUrl = BASE_URL +"Account/RefreshToken"

export const externalLoginUrl = BASE_URL + "Account/LoginExternal";
export const loginUrl = BASE_URL + "Account/Login";

export const signUpUrl = BASE_URL + "Account/RegisterV2"
export const otpResendUrl = BASE_URL + "Account/ResendOTPV2";
export const otpAuthenticateUrl = BASE_URL + "Account/OTPAuthRequest";
// export const forgotPasswordRequestUrl = BASE_URL + "Account/ResendOTPV2";
export const forgotPasswordAuthenticateUrl = BASE_URL + "Account/ForgotPassword";
export const getAddCategoryUrl = BASE_URL + "Banner/BannerDetailRequest?";
export const getbannerImagesUrl = 'https://bannerportal.slt.lk/API/getBannerUrls';


export const getAccountDetailsUrl = BASE_URL + "AccountOMNI/GetAccountDetailRequest?";
export const getServiceDetailRequestUrl = BASE_URL +"AccountOMNI/GetServiceDetailRequest?";
export const billDetailsUrl = BASE_URL + "AccountOMNI/BillPaymentRequest?"
export const getUsageSummaryUrl = BASE_URL +"BBVAS/UsageSummary?";
export const getProfileRequestUrl = BASE_URL +"VAS/GetProfileRequest?";
export const addAccountUrl = BASE_URL + "AccountOMNI/AddAccountRequest?";
export const AddPrePaidAccountURL = BASE_URL + "LTEPrepaid/AddAccountRequestLTE?"
export const addVasAccountUrl = BASE_URL + "AccountOMNI/VASAccountRequest?";
export const getSubmitClaimUrl = BASE_URL +"BBVAS/UpgradeLoyalty?";
export const getVASBundleUnsubscriptionUrl = BASE_URL +"BBVAS/VASBundleUnsubscription?";

export const getUsagePrepaidData = BASE_URL +"LTEPrepaid/getLTEFreeunitDetail?";
export const getUsagePackages = BASE_URL +"LTEPrepaid/LTEPrepaidPackageList?";
export const billDetailsPrePaidUrl = BASE_URL + "LTEPrepaid/GetWalletDetail?"
export const getSubscribePrepaidUrl = BASE_URL + "LTEPrepaid/subscriberStatus?"
export const activePrepaidPackageUrl = BASE_URL + "LTEPrepaid/LTEPrepaidAddDeleteOffer?"
export const getPrepaidTransactionHistoryUrl = BASE_URL + "LTEPrepaid/viewTransactionsHistory?"

export const getExtraGBUrl = BASE_URL +"BBVAS/ExtraGB?";
export const getBonusDataUrl = BASE_URL +"BBVAS/BonusData?";
export const getAddonsDataUrl = BASE_URL +"BBVAS/GetDashboardVASBundles?";
export const getFreeDataUrl = BASE_URL +"BBVAS/FreeData?";
//added comment
export const getValidateDataGiftSubUrl = BASE_URL +"BBVAS/ValidateDataGiftSub?";

//bbHistory
export const getHistoryGBDetailsUrl = BASE_URL +"BBVAS/GetPurchaseHistory?";

//bbDailyUsage
export const getCurrentMonthsDailyUsageUrl = BASE_URL +"BBVAS/EnhancedCurrentDailyUsage?";
export const getPreviousMonthsDailyUsageUrl = BASE_URL +"BBVAS/EnhancedPreviousDailyUsage?";
export const purchaseProtocolReportUrl = BASE_URL +"BBVAS/PurchaseAdvancedReportsPostPaid?";
export const UnsubscribeAdvancedReportsUrl = BASE_URL +"BBVAS/UnsubscribeAdvancedReports?";

export const getProtocolReportUrl = BASE_URL +"BBVAS/ProtocolReport?";

//get addon list DataUrl
export const getAddonListDataUrl = BASE_URL +"BBVAS/GetVASDataBundlePackages?";
export const getAddVASDataBundlePostPaidUrl = BASE_URL +"BBVAS/AddVASDataBundlePostPaid?";


export const getAddVASDataBundlePostPaidV2Url = BASE_URL +"BBVAS/AddVASDataBundlePostPaidV2?";

//service related upgrade package
export const getBBPackageComparisonUrl = BASE_URL +"BBExternal/GetBBPackagesV2?";
export const getCurrentBBPackageUrl = BASE_URL +"BBExternal/GetCurrentBBPackageV2?";
export const BBPackageChangeRequest = BASE_URL +"BBExternal/BBPackageChangeRequest?";

//getExtraGb Details
export const getExtraGBDetailsUrl = BASE_URL +"BBVAS/GetExtraGBPackages?";
export const  getExtraGBPostPaidUrl = BASE_URL +"BBVAS/PurchaseExtraGBPostpaid?";

//bill history
export const getBillHistoryRequestUrl = BASE_URL +"AccountOMNI/BillHistoryRequestV2?";

//update
export const putUpdateContacttUrl = BASE_URL +"BBVAS/UpdateContact?";
export const ChangeBBPassword = BASE_URL +"BBVAS/ChangeBBPassword?";

//paymentInit
export const PaymentInitUrl = "https://billpay.slt.lk/bbtopup/summaryall.php";


//User Complaints
export const getComplaintsListUrl = BASE_URL + "Fault/ViewFaultList?";
export const createFaultReques = BASE_URL + "Fault/CreateFaultRequestV2?";

//ebill
export const ebillActivateStatusUrl = BASE_URL + "ebill/eBillStatusRequest?";
export const ebillSmsActivateStatusUrl = BASE_URL + "ebill/SMSServiceStatusRequest?";
export const ebillCheckUserExistUrl = BASE_URL + "ebill/eBillCheckUserExistV2?";
export const ebillOtpAuthUrl = BASE_URL + "ebill/OTPeBillAuthRequest?";
export const ebillActivateUrl = BASE_URL + "ebill/eBillRegistration?";
export const payBillUrl = BILL_PAY+"confirm.php";
export const removeAccountUrl = BASE_URL + "AccountOMNI/RemoveAccountRequest?";
export const eBillResendRequest = BASE_URL +"ebill/eBillResendRequest?";
export const popupMessageBanner = BASE_URL + "notifications/PopupMessageBanner?"

export const ebillTypeStatusUrl = BASE_URL + "ebill/BillStatusRequest?";
export const ebillDownloadRequestUrl = BASE_URL + "ebill/BillDownloadRequest?";

export const ebillSmartBillRegistrationUrl = BASE_URL + "ebill/SmartBillRegistration";
//vocie
export const getCallForwardingStatusUrl = BASE_URL +"Voice/CheckCallForwardingStatus?";
export const getCallForwardingRequestUrl = BASE_URL +"Voice/CallForwardingRequest?";

//ftth urls
export const OTPRequest = BASE_URL +"Verify/SendOTPRequest?";
export const AuthonticationOpenFTTHLogin = BASE_URL +"Account/AuthonticationOpenFTTHLogin";
export const OTPValidation = BASE_URL +"Verify/VerifyOTPRequest?";
export const verifyOTPOpenFTTH = BASE_URL +"Account/verifyOTPOpenFTTH";
export const paymentsLogs = BASE_URL + 'NewCon/GetPaymentLogs?orderreff='
export const getDraftDetails = BASE_URL + 'NewCon/GetDraftDetails?'
export const GetDraftDetailsV2 = BASE_URL + 'NewCon/GetDraftDetailsV2?'

export const getOrderFTTHOrderStatus = BASE_URL + 'NewCon/getOrderFTTHOrderStatus?'

export const verifyOTPOpenFTTHV2 = BASE_URL + 'Account/verifyOTPOpenFTTHV2'


export const saveDraftDetails = BASE_URL + 'NewCon/SaveDraftData'
export const updateDraftDetails = BASE_URL + 'NewCon/UpdateDraftData'

export const saveDraftDetailsLTE = BASE_URL + 'NewCon/SaveDraftDataLTE'
export const updateDraftDetailsLTE = BASE_URL + 'NewCon/UpdateDraftDataLTE'
export const getDraftDetailsLTE = BASE_URL + 'NewCon/GetDraftDetailsLTE?'

export const saveInvoice = BASE_URL + 'NewCon/SaveInvoiceData'
export const updateInvoice = BASE_URL + 'NewCon/UpdateInvoiceData'
export const getInvoice = BASE_URL + 'NewCon/GetInvoiceData?'
export const removeInvoiceUrl = BASE_URL + 'NewCon/BulkUpdateInvoiceData?'

export const salesLeadCreatUrl = BASE_URL + 'NewCon/NewConSalesLeadCreation'
export const getNewConInitiationCharges = BASE_URL + 'NewCon/GetNewConInitiationCharges'

export const UpdateAgentCode = BASE_URL + 'NewCon/UpdateAgentCode'
export const GetAgentCode = BASE_URL + 'NewCon/GetAgentCode?'


export const reserveFacility = BASE_URL + 'NewCon/ReserveFacility?'
export const ftthFileUpload = BASE_URL + 'NewCon/UploadMultipartSingle'
export const refundMoneyURL = BASE_URL + 'NewCon/UpdatePaymentLogs?'
export const CheckExistingCustomer = BASE_URL + 'NewCon/CheckExistCustomer?'
export const getCityListUrl = BASE_URL + 'NewCon/GetCityList'
export const getFeasibilityCheck = BASE_URL + 'NewCon/GetFeasibilityCheckV2?'
export const getBbPackagesList = BASE_URL + 'NewCon/GetBBPackagesInterim?'
export const getBbPackagesListV2 = BASE_URL + 'NewCon/GetBBPackagesInterimV2?'
export const getPeoTvPackagesList = BASE_URL + 'NewCon/GetPEOPackagesInterim?'
export const getPeoTvPackagesListV2 = BASE_URL + 'NewCon/GetPEOPackagesInterimV2?'
export const getVoicePackagesList = BASE_URL + 'NewCon/GetVoicePackagesInterim?'
export const getVoicePackagesListV2 = BASE_URL + 'NewCon/GetVoicePackagesInterimV2?'
//googleMapApis
// export const googleMapApiSLT = 'AIzaSyBueJkoLK9q9p-8mDKsX8Kv12L0EMiVevc'
// export const googleMapApiLKM = 'AIzaSyAMXJrO2RJvxOSPjp4MBZg-F-IbogtSyLQ'

//kmlLayerUrl
export const kmlLayerUrl =  ""; //need to add later

//RedeemVoucher
export const redeemVoucher = BASE_URL + "BBVAS/RedeemVoucher?";

//connection request
export const connectionRequestUrl = BASE_URL + "Sales/SalesLeadCreationRequest?";

// Gift Data
export const getDataGiftPackagesUrl = BASE_URL +"BBVAS/DataGiftPackages?";
export const getDataGiftEnrollPPUrl = BASE_URL + "BBVAS/DataGiftEnroll?";

//profile
export const terminateProfileUrl = BASE_URL + "Account/TerminateProfile";
export const getViewUserInfoUrl = BASE_URL +"Account/ViewUserInfo";
export const updatesContacttnfoUrl  = BASE_URL +"Account/UpdateUserInfo"

// //kespersky
// export const jwtURL = "https://kaspersky-dp.slt.lk:5000/slt-kss-mw-be/token/app/generate";

//kesperskyV2
export const jwtURLV2 = "https://api.kaspersky-dp.slt.lk/api/auth/jwt-rq";

//happy day
export const HappyDaySetUrl = BASE_URL + "BBVAS/HappyDay?"

//promotion
export const getPromoPackURL = BASE_URL + "TimelyPay/checkofferavaliability?telephoneno="
export const activePromoPackURL = BASE_URL + "TimelyPay/packageActivation?"