import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

function TopNavigation({ accountList, serviceList }) {
  const history = useHistory();

  function classNameSet(link) {
    let word = window.location.href;

    if (word.split("/").includes(link)) {
      return "selected clearfix";
    } else {
      return "clearfix";
    }
  }

  const disabled =
    !accountList ||
    accountList.length === 0 ||
    (serviceList.hasOwnProperty("promotionType") &&
      serviceList.promotionType === "Prepaid");
  const disabledBB = !accountList || accountList.length === 0;

  return (
    <div className="deviceType">
      <button
        className={classNameSet("boardBand")}
        disabled={disabledBB}
        onClick={() => history.push("/boardBand/summary")}
      >
        <div className="product-icon broadbrand"></div>
        <div className="product-label">Broadband</div>
      </button>

      <button
        className={classNameSet("peoTV")}
        disabled={disabled}
        onClick={() => history.push("/peoTV")}
      >
        <div className="product-icon peo"></div>
        <div className="product-label">PEOTV</div>
      </button>

      <button
        className={classNameSet("voice")}
        disabled={disabled}
        onClick={() => history.push("/voice")}
      >
        <div className="product-icon voice"></div>
        <div className="product-label">Voice</div>
      </button>

      <button
        className={classNameSet("mobile")}
        disabled={disabled}
        onClick={() => history.push("/mobile")}
      >
        <div className="product-icon mobile"></div>
        <div className="product-label">Mobile</div>
      </button>

      <button
        className={classNameSet("promotion")}
        onClick={() => history.push("/promotion")}
      >
        <div className="product-icon promotion"></div>
        <div className="product-label">Promotion</div>
      </button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountList: state.accountDetails.account_Details,
  serviceList: state.accountDetails.service_list,
});

export default connect(mapStateToProps, {})(TopNavigation);
