import React from 'react';
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {MAP_KEY} from "../../../../redux/MAPKEYS";

function SearchLocation({searchLatLon, setValueLocSearch, valueLocSearch}) {


    return (
        <ReactGoogleAutocomplete className='search'

                                 apiKey={MAP_KEY}
                                 onPlaceSelected={(place) => {
                                     searchLatLon(place)
                                     setValueLocSearch(place.name)

                                 }}
                                 options={{
                                     types: ["geocode", "establishment"],
                                     componentRestrictions: {country: "lk"},
                                     fields: ["address_components", "geometry", "icon", "name"],
                                 }}
                                 onChange={(event => setValueLocSearch(event.target.value))}
                                 value={valueLocSearch}

        />


    );
}

export default SearchLocation;
// apiKey={'AIzaSyBueJkoLK9q9p-8mDKsX8Kv12L0EMiVevc'}
// apiKey={'AIzaSyBgcfGpUoXgog2I3KMo_6JYjZ32NDXtQ0E'}
