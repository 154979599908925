import { 
    GET_BILL_STATUS,
    SUCCESS_GET_BILL_STATUS,
    ERROR_GET_BILL_STATUS
} from "../../Types";

const initialState = {
    loading: true,
    dataBundle: {},
    error:''
}


export default function billStatusReducer(state= initialState, action){
    switch (action.type){

        case GET_BILL_STATUS : {
            return {
                ...state,
                loading: true,
                dataBundle: {}
            }
        }

        case SUCCESS_GET_BILL_STATUS : {
            return {
                ...state,
                loading: false,
                dataBundle: action.payload
            }
        }

        case ERROR_GET_BILL_STATUS : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}