import React from 'react';
import {connect} from "react-redux";
import {getBBSummary} from "../../redux/action/broadBand.action";
import MyPackage from "./MyPackage";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import ExtraGB from "./ExtraGB";
import BonusData from "./BonusData";
import AddOnsData from "./AddOnsData";
import FreeData from "./FreeData";
import {BsFillCaretRightFill} from "react-icons/bs";
import GetExtraGb from "./GetExtraGB/GetExtraGB";
import GetDataAddOns from "./getDataAddOns/GetDataAddOns";
import PackageUpgrade from "./packageUpgrade/PackageUpgrade";

function BroadBandSummary({serviceList, loading, summary, profile, state, setState}) {

    let {path, url} = useRouteMatch();



    function classNameSet(link) {
        let word = window.location.href

        if (word.split("/").includes(link)) {
            return 'buttonTab blue'
        } else {
            return 'buttonTab'
        }


    }

    function myPack() {
        let word = window.location.href

        if (word.slice(-1) === 'y')
            return 'buttonTab blue'
        else {
            return 'buttonTab'
        }
    }

    function setSpan(obj) {

        if (loading)
            return <span>Loading</span>
        else {
            if (obj) {
                return <p className='pkg-option'>{obj.used} used from {obj.limit}{obj.volume_unit}</p>
            } else
                return <p className='pkg-option'>N/A</p>
        }

    }


    return (
        <>
            <div className='package-functions'>
                <div className='package-functions-container clearfix'>
                    <div className='function-box-wrapper'>
                        <div className='function-box clearfix'>
                            <div className='pkg-details'>
                                <p className='detail-row'><span className="label">Package : </span><span
                                    className="value">{profile.subscriber_package_display}</span></p>
                                <p className='detail-row'><span className="label">Status : </span><span
                                    className="value"> {serviceList.listofBBService[0].serviceStatus}</span></p>
                                <p className='detail-row'><span className="label">Username : </span><span
                                    className="value"> {profile.subscriberid}</span></p>

                            </div>
                            <div className='pkg-details-btn-container'>
                                <button
                                    className={state === 'packUpgrade' ? 'blue w-100 font-weight-bold' : 'blue w-100 font-weight-bold white'}
                                    onClick={() => setState('packUpgrade')}>Package Upgrade
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className='function-box-wrapper'>
                        <div className='function-box clearfix'>
                            <div className='button-container-50'>
                                <button
                                    className={state === 'extraGb' ? 'blue  w-100 font-weight-bold' : 'blue  w-100 font-weight-bold white'}
                                    onClick={() => setState('extraGb')}>Get
                                    Extra GB
                                </button>
                            </div>
                            <div className='button-container-50'>
                                <button
                                    className={state === 'dataAddOns' ? 'blue  w-100 font-weight-bold' : 'blue  w-100 font-weight-bold white'}
                                    onClick={() => setState('dataAddOns')}>Get data Add-ons
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                state === 'summary' ? <div className='boardBandSummary common-panel'>

                    <div className='summary-container'>
                        <Link to={`${url}`} style={{textDecoration: 'none'}}>
                            <div className={myPack()}>
                                <div className='text-center'>
                                    <p className='m-0'>My Package</p>
                                    {setSpan(summary.my_package_summary)}
                                </div>
                            </div>
                            <span hidden={myPack() !== 'buttonTab blue'} className='inputIcon'><BsFillCaretRightFill
                                size={20} color='#007ed6'/></span>
                        </Link>

                        <Link to={`${url}/extraGB`} style={{textDecoration: 'none'}}>
                            <div className={classNameSet('extraGB')}>
                                <div className='text-center'>
                                    <p className='m-0'>Extra GB</p>
                                    {setSpan(summary.extra_gb_data_summary)}
                                </div>

                            </div>
                            <span hidden={classNameSet('extraGB') !== 'buttonTab blue'}
                                  className='inputIcon'><BsFillCaretRightFill size={20} color='#007ed6'/></span>
                        </Link>

                        <Link to={`${url}/bonusData`} style={{textDecoration: 'none'}}>
                            <div className={classNameSet('bonusData')}>
                                <div className='text-center'>
                                    <p className='m-0'>Bonus Data</p>
                                    {setSpan(summary.bonus_data_summary)}
                                </div>

                            </div>
                            <span hidden={classNameSet('bonusData') !== 'buttonTab blue'}
                                  className='inputIcon'><BsFillCaretRightFill size={20} color='#007ed6'/></span>
                        </Link>

                        <Link to={`${url}/addOns`} style={{textDecoration: 'none'}}>
                            <div className={classNameSet('addOns')}>
                                <div className='text-center'>
                                    <p className='m-0'>Add-Ons Data</p>
                                    {setSpan(summary.vas_data_summary)}
                                </div>

                            </div>
                            <span hidden={classNameSet('addOns') !== 'buttonTab blue'}
                                  className='inputIcon'><BsFillCaretRightFill size={20} color='#007ed6'/></span>
                        </Link>

                        <Link to={`${url}/freeData`} style={{textDecoration: 'none'}}>
                            <div className={classNameSet('freeData')}>
                                <div className='text-center'>
                                    <p className='m-0'>Free Data</p>
                                    {setSpan(summary.free_data_summary)}
                                </div>
                            </div>
                            <span hidden={classNameSet('freeData') !== 'buttonTab blue'}
                                  className='inputIcon'><BsFillCaretRightFill size={20} color='#007ed6'/></span>
                        </Link>


                    </div>

                    <Switch>

                        <Route exact path={`${path}`}>
                            <MyPackage/>
                        </Route>
                        <Route path={`${path}/extraGB`}>
                            <ExtraGB/>
                        </Route>
                        <Route path={`${path}/bonusData`}>
                            <BonusData/>
                        </Route>

                        <Route path={`${path}/addOns`}>
                            <AddOnsData/>
                        </Route>

                        <Route path={`${path}/freeData`}>
                            <FreeData/>
                        </Route>
                    </Switch>


                </div> : state === 'extraGb' ? <GetExtraGb/> : state === 'dataAddOns' ?
                    <GetDataAddOns/> : state === 'packUpgrade' ? <PackageUpgrade/> : null}

        </>
    );
}

const mapStateToProps = state => ({
    serviceList: state.accountDetails.service_list,
    summary: state.broadBand.summary,
    loading: state.broadBand.loading,
    profile: state.broadBand.profile
})
export default connect(mapStateToProps, {getBBSummary})(BroadBandSummary);

