import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_GIFT_DATA_PACKAGES_DETAILS,
    GET_GIFT_DATA_PACKAGES_DETAILS,
    SUCCESS_GET_GIFT_DATA_PACKAGES_DETAILS,
} from "../../Types";
import {getDataGiftPackagesUrl} from "../../URL";

export default function getGiftDataDetails(subscriberID) {

    let url = getDataGiftPackagesUrl + 'subscriberID=' + subscriberID 
   
    return (dispatch) => {
        // console.log(url)
        // console.log("calling api giftdata Dispatch")
        dispatch(loadingDispatch(GET_GIFT_DATA_PACKAGES_DETAILS))
        axiosInstance.get(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {

                dispatch(dispatchSuccess(SUCCESS_GET_GIFT_DATA_PACKAGES_DETAILS, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_GIFT_DATA_PACKAGES_DETAILS, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_GIFT_DATA_PACKAGES_DETAILS, CATCH_ERROR_MSG))
        })
    }

}