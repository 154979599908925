import React from 'react';

function NoPreviledge({msg}) {
    return (
        <div className='alertView'>
            <div>
                <div className='title'>No privilege</div>
                <div className='mainText'>
                    <div className="message">{msg}</div>
                </div>
            </div>

        </div>
    );
}

export default NoPreviledge;