import React, {useEffect, useState} from 'react'
import LoadingBbBody from '../../boardBand/LoadingBbBody'
import axiosInstance from '../../../redux/axiosInstance'
import { ftthFileUpload } from '../../../redux/URL'
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import { CATCH_ERROR_MSG } from '../../../redux/Types';
import logo from "../../../assests/images/Logo.png";

const FileUploadsLTE = ({setState, loading, setLoading, userInfo, locationInfo, packageInfo, orderRef}) => {

    const [nicFront, setNicFront] = useState(null);
    const [nicBack, setNicBack] = useState(null);
    const [pass, setPass] = useState(null);

    const maxFileSize = 2 * 1024 * 1024

    const [errorList, setErrorList] = useState({});

   

    const validation = () => {
        let errors = {};
        let formIsValid = true;

        if (userInfo.nic  && (!nicFront || !nicBack)) {//(userInfo.iType === 'nic')
            errors['file'] = 'Please upload all the relevant files'
            formIsValid = false
        } else if (userInfo.passport && !pass) { //(userInfo.iType === 'pp')
            errors['file'] = 'Please upload all the relevant files'
            formIsValid = false
        }else if ((nicFront && nicBack) && (nicFront.size > maxFileSize || nicBack.size > maxFileSize)) {
            errors['file'] = 'Maximum file upload size 2MB'
            formIsValid = false
        }
        
        if (formIsValid) {
            setErrorList('')
            // setState(5)
            setLoading(true)
            saveFileUpload()

        } else {
            setErrorList(errors)
            // setFormIsValid(formIsValid)
        }
    }

    const saveFileUpload = () => {
        setLoading(true)

        const input = document.getElementById('pdfViewer');

        var title = userInfo.title;
            var firstName = userInfo.fName;
            var lastName = userInfo.lName;
            var dob = userInfo.dateOfBirth;
            var gender = userInfo.gender;
            var nic = userInfo.nic ? userInfo.nic : userInfo.passport;
            var nationality = userInfo.nationality;
            var mobile = userInfo.mobile;
            var email = userInfo.email;

            var lat = locationInfo.lat;
            var lng = locationInfo.lng;

            var installationAddress1 = locationInfo.address1;
            var installationAddress2 = locationInfo.address2;

            // var googleLocation = "https://goo.gl/maps/Xkkr87VCR9HppxRU8";

            var googleLocation = "https://www.google.com/maps/?q=" + lat + "," + lng;
            var googleLocationView = "lat - " + lat + " /long - " + lng;

            var billingAddress1 = locationInfo.address1;
            var billingAddress2 = locationInfo.address2;

            var existTelAccNo = ""

            var broadbandPackage = packageInfo.bbPackages;

            var PeoTvPackage1 = packageInfo.peoTvPackages;
            var PeoTvPackage2 = "";
            var PeoTvPackage3 = "";
            var PeoTvPackage4 = "";

            var voice = packageInfo.voicePackages ? '1' : '0'
            var broadband = packageInfo.bbPackages ? '1' : '0'
            var peoTv = packageInfo.peoTvPackages ? '1' : '0'

            var billType = packageInfo.billType;//post eBill
            var isExistCus = false;
            var activeIdd = packageInfo.iddActivate === 'true';

            var myPhoneYes = "S";
            var myPhoneNo = "S";

            var exCusYes = "S";
            var exCuNo = "F";

            if (isExistCus === true) {
                exCusYes = "F";
                exCuNo = "S";
            }

            var billMode_EBill = "S";
            var billMode_Print = "F";

            if (billType === "eBill") {
                billMode_EBill = "F";
                billMode_Print = "S";
            }

            var activeIddYes = "S";
            var activeIddNo = "F";

            if (activeIdd === true) {
                activeIddYes = "F";
                activeIddNo = "S";
            }

            html2canvas(input)
                .then((canvas) => {

                    const pdf = new jsPDF("p", "in", "a4");

                    pdf.setFontSize(17);
                    pdf.setFont("courier", "bold");
                    pdf.text('SLT Application For New Services', 2.40, 0.46)
                    pdf.addImage(logo, "JPEG", 0.30, 0.15, 2.00, 0.70);

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                   // pdf.text('Registration No : ' + regNo, 2.40, 0.70)

                    pdf.setLineWidth(0.01);
                    pdf.line(0.30, 1.00, 8.00, 1.00, 'S');

                    //sub headers
                    pdf.text('Customer Information', 0.30, 1.80)
                    pdf.text('Contact Details', 0.30, 4.00)
                    pdf.text('Service Installation Address', 0.30, 4.70)

                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    // First Box Test
                    pdf.text('Existing customer', 0.50, 7.00)
                    pdf.text('Yes', 2.60, 7.00)
                    pdf.text('No', 3.40, 7.00)
                    pdf.text('Existing ', 4.10, 6.90)
                    pdf.text('Tel/Acc No ', 4.10, 7.10)
                    pdf.text(existTelAccNo, 5.30, 7.00)

                    pdf.text('Your preferred billing', 0.50, 7.35)
                    pdf.text('mode', 0.50, 7.55)
                    pdf.text('SLT My Bill ', 2.85, 7.45)
                    pdf.text('E Bill ', 4.35, 7.45)
                    pdf.text('Printed ', 5.45, 7.35)
                    pdf.text('bill ', 5.45, 7.55)

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                    pdf.text('Mode of Connection', 0.50, 8.20)

                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    pdf.text('Voice(Telephone)', 2.70, 8.45)
                    pdf.text('Broadband', 4.55, 8.45)
                    pdf.text('Peo TV', 6.25, 8.45)
                    pdf.text('SLT LTE', 1.00, 8.75)

                    pdf.text(voice, 3.40, 8.80)
                    pdf.text(broadband, 5.00, 8.80)
                    pdf.text(peoTv, 6.60, 8.80)

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                    pdf.text('Package Details', 0.50, 9.50)

                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    pdf.text('Do you want to deactivate IDD ', 0.70, 9.75)
                    pdf.text('Yes ', 4.50, 9.75)
                    pdf.text('NO ', 5.30, 9.75)

                    pdf.text('My Phone ', 0.70, 10.00)
                    pdf.text('Yes ', 2.70, 10.00)
                    pdf.text('NO ', 3.50, 10.00)

                    pdf.text('Broadband Package ', 0.70, 10.30)
                    pdf.text(broadbandPackage, 2.75, 10.30)
                    pdf.text('Peo TV ', 0.70, 10.60)
                    pdf.text('Packages ', 0.70, 10.80)

                    pdf.setFontSize(10)

                    pdf.text(PeoTvPackage1, 1.75, 10.70)//Peo TV  Packages
                    pdf.text(PeoTvPackage2, 3.35, 10.70)
                    pdf.text(PeoTvPackage3, 4.95, 10.70)
                    pdf.text(PeoTvPackage4, 6.55, 10.70)


                    //Check Box
                    pdf.setFontSize(12)
                    pdf.setFont("courier", "normal");

                    pdf.text('Home  ', 2.40, 1.35)
                    pdf.text('Office  ', 3.30, 1.35)
                    pdf.text('Other  ', 4.40, 1.35)
                    pdf.text('CA Number  ', 5.65, 1.35)


                    pdf.text('Title  ', 0.30, 2.25)
                    pdf.text(title, 2.45, 2.25)
                    pdf.text('First Name  ', 0.30, 2.60)
                    pdf.text(firstName, 2.45, 2.60)
                    pdf.text('Last Name  ', 0.30, 2.95)
                    pdf.text(lastName, 2.45, 2.95)
                    pdf.text('Date of Birth  ', 0.30, 3.30)
                    pdf.text(dob, 2.45, 3.30)
                    pdf.text('Gender  ', 4.00, 3.30)
                    pdf.text(gender, 5.30, 3.30)
                    pdf.text('NIC / Passport ', 0.30, 3.65)
                    pdf.text(nic, 2.45, 3.65)
                    pdf.text('Nationality  ', 4.00, 3.65)
                    pdf.text(nationality, 5.30, 3.65)

                    pdf.text('Mobile  ', 0.30, 4.35)
                    pdf.text(mobile, 2.45, 4.35)
                    pdf.text('Email  ', 4.00, 4.35)
                    pdf.text(email, 5.00, 4.35)

                    pdf.text('Installation Address  ', 0.30, 5.05)
                    pdf.text(installationAddress1, 2.45, 5.05)
                    pdf.text(installationAddress2, 2.45, 5.40)
                    pdf.text('Google Location  ', 0.30, 5.75)
                    // pdf.text(googleLocation,2.45, 5.75)
                    pdf.setTextColor(0,0,255)
                    pdf.textWithLink(googleLocationView, 2.45, 5.75, {url: googleLocation});
                    pdf.setTextColor(0,0,0)
                    pdf.text('Billing Address  ', 0.30, 6.10)
                    pdf.text(billingAddress1, 2.45, 6.10)
                    pdf.text(billingAddress2, 2.45, 6.45)

                    // all boxes view in pdf
                    pdf.setFillColor(0, 0, 0);
                    pdf.setLineWidth(0.01)
                    pdf.rect(7.10, 0.30, 0.80, 0.60); //top box

                    pdf.rect(3.00, 1.20, 0.2, 0.2, 'F'); //home
                    pdf.rect(4.10, 1.20, 0.2, 0.2, 'S'); //Office
                    pdf.rect(5.10, 1.20, 0.2, 0.2); //Other

                    pdf.rect(6.90, 1.15, 1.0, 0.3) //ca number

                    pdf.rect(2.40, 2.05, 2.0, 0.3) //title
                    pdf.rect(2.40, 2.40, 5.35, 0.3) //fname
                    pdf.rect(2.40, 2.75, 5.35, 0.3) //lname
                    pdf.rect(2.40, 3.10, 1.5, 0.3) //dob
                    pdf.rect(5.25, 3.10, 2.0, 0.3) //gender
                    pdf.rect(2.40, 3.45, 1.5, 0.3) //nic
                    pdf.rect(5.25, 3.45, 2.0, 0.3) //nationalyty

                    pdf.rect(2.40, 4.15, 1.5, 0.3) //mobile
                    pdf.rect(4.95, 4.15, 2.8, 0.3) //email

                    pdf.rect(2.40, 4.85, 5.35, 0.3) //installatin address part1
                    pdf.rect(2.40, 5.20, 5.35, 0.3) //installatin address part1
                    pdf.rect(2.40, 5.55, 5.35, 0.3) //Google Location
                    pdf.rect(2.40, 5.90, 5.35, 0.3) //Billing Address  part 1
                    pdf.rect(2.40, 6.25, 5.35, 0.3) //Billing Address  part 2


                    pdf.rect(0.30, 6.60, 7.70, 1.2) //first form box

                    pdf.rect(3.00, 6.85, 0.2, 0.2, exCusYes) //Yes
                    pdf.rect(3.70, 6.85, 0.2, 0.2, exCuNo) //No
                    pdf.rect(5.25, 6.80, 2.5, 0.3) //Tel/Acc No

                    pdf.rect(4.00, 7.30, 0.2, 0.2) //SLT My Bill
                    pdf.rect(5.00, 7.30, 0.2, 0.2, billMode_EBill) //E Bill
                    pdf.rect(6.25, 7.30, 0.2, 0.2, billMode_Print) //Printed Bill

                    pdf.rect(0.30, 7.90, 7.70, 1.2) //second form box

                    pdf.rect(2.70, 8.60, 1.5, 0.3) //MOF Voice
                    pdf.rect(4.30, 8.60, 1.5, 0.3) //MOF Broadband
                    pdf.rect(5.90, 8.60, 1.5, 0.3) //MOF Peo TV

                    pdf.rect(0.30, 9.20, 7.70, 2.0) //third form box
                    pdf.rect(4.85, 9.60, 0.2, 0.2, activeIddYes) //Yes
                    pdf.rect(5.55, 9.60, 0.2, 0.2, activeIddNo) //No
                    pdf.rect(3.05, 9.85, 0.2, 0.2, myPhoneYes) //Yes
                    pdf.rect(3.75, 9.85, 0.2, 0.2, myPhoneNo) //No

                    pdf.rect(2.70, 10.10, 2.0, 0.3) //Broadband Packages

                    pdf.rect(1.70, 10.50, 1.5, 0.3) //Peo TV  Packages
                    pdf.rect(3.30, 10.50, 1.5, 0.3) //Peo TV  Packages
                    pdf.rect(4.90, 10.50, 1.5, 0.3) //Peo TV  Packages
                    pdf.rect(6.50, 10.50, 1.4, 0.3) //Peo TV  Packages


                    /// add new page
                    pdf.addPage("a4", "p");
                    pdf.setFillColor(0, 0, 0);
                    pdf.setLineWidth(0.01)
                    pdf.rect(0.30, 0.50, 7.70, 2.5) //Comment

                    pdf.setFontSize(14)
                    pdf.setFont("courier", "bold");
                    pdf.text('Comment/Remark', 0.50, 0.90)

                    pdf.text('Customer Agreement', 0.50, 3.50)

                    let pageWidth = 8.5,
                        lineHeight = 4.80,
                        margin = 0.6,
                        maxLineWidth = pageWidth - margin * 2,
                        fontSize = 24,
                        ptsPerInch = 72,
                        oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
                        text = "I do hereby declare and confirm that the information above, furnished by me are true and correct. I confirm that I have received, read and understood the 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' (SLT), which are also available on SLT website www.slt.lk. I shall abide by the said 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' as may be revisedmodified and supplemented by SLT from time to time and morefully stated/updated under the said SLT website www.slt.lk.";

                    let textLines = pdf.setFontSize(12)
                        .setFont("courier", "normal")
                        .splitTextToSize(text, maxLineWidth);

                    pdf.text(textLines, margin, margin + 2 * oneLineHeight);

                    // pdf.text('Terms and Conditions http://www.slt.lk/en/terms-conditions',0.70, 5.60)
                    pdf.setTextColor(0,0,255)
                    pdf.textWithLink('Terms and Conditions http://www.slt.lk/en/terms-conditions', 0.70, 5.60, {url: 'http://www.slt.lk/en/terms-conditions'});
                    pdf.setTextColor(0,0,0)

                    pdf.text('I agree terms and Conditions ', 1.05, 5.90)
                    // pdf.text('Yes ',3.90, 5.90)
                    // pdf.text('NO ',4.70, 5.90)

                    pdf.rect(0.80, 5.75, 0.2, 0.2, 'F') //Yes
                    // pdf.rect(5.00, 5.75, 0.2,0.2) //No


                    let fileName = userInfo.nic ? userInfo.nic + '.pdf' : userInfo.passport + '.pdf'

                    let formData = new FormData();
                    formData.append('nicfront_file', nicFront)
                    formData.append('nicback_file', nicBack)
                    formData.append('pp_file', pass)
                    formData.append('application_file', pdf.output('blob'), fileName)
                    formData.append('billingproof_file', null)
                    formData.append('nic', userInfo.sltnic ? userInfo.sltnic : userInfo.nic)
                    formData.append('ppNumber', userInfo.passport)
                    formData.append('rtom', locationInfo.rTom)  
                    formData.append('orderreff', orderRef)

                    axiosInstance.post(ftthFileUpload, formData).then(response => {
                            // console.log(response)
                        if (response.data.isSuccess) {
                            // setSuccess(true)

                            setLoading(false)
                            setState(6)
                        } else {
                            // setError(response.data.errorShow)
                            // setIsError(true)
                           
                            let errors = {};
                            errors['file'] = response.data.errorShow
                            setErrorList(errors)
                            setLoading(false)
                        }
                    }).catch(() => {
                        // setError(CATCH_ERROR_MSG)
                        let errors = {};
                        errors['file'] = CATCH_ERROR_MSG
                        setErrorList(errors)
                        setLoading(false)
                    })

                    pdf.save(fileName);
                }).catch((error) => {

                    console.log(error)
            });
    }


    // console.log(locationInfo.rTom)
    // console.log(userInfo)
    if(loading)
        return <div className='form-inputs-container'><LoadingBbBody/></div> 
   return (
        <div className='fileUploadLTE'>
            <div className='header'>
                <div className='titles'>
                    <p>Customer Details  - Document Upload</p>
                </div>
            </div>
            <div className='formUpload'>
                {/* <div className='nicUpload'>
                    <div className='nicUploadSelect'>
                        <div onClick={() =>setIsNicFront(!isNicFront)} className={isNicFront? 'active' : 'deactive'}> 
                            Upload  NIC front
                        </div>
                        <div onClick={() =>setIsNicFront(!isNicFront)} className={!isNicFront? 'active' : 'deactive'}>
                            Upload  NIC back
                        </div>
                    </div>

                    {
                        isNicFront ? 
                        <div className='nicUploadimage'>
                            <div>
                                <label>
                                   
                                    <div>
                                        <svg width="73" height="85" viewBox="0 0 73 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M66.8125 30.375H42.5625V6.125H6.1875V78.875H66.8125V30.375ZM64.3026 24.3125L48.625 8.63488V24.3125H64.3026ZM3.15625 0.0625H48.625L72.875 24.3125V81.9062C72.875 82.7102 72.5556 83.4812 71.9872 84.0497C71.4187 84.6181 70.6477 84.9375 69.8438 84.9375H3.15625C2.35231 84.9375 1.5813 84.6181 1.01283 84.0497C0.444363 83.4812 0.125 82.7102 0.125 81.9062V3.09375C0.125 2.28981 0.444363 1.5188 1.01283 0.950332C1.5813 0.381863 2.35231 0.0625 3.15625 0.0625ZM18.3125 42.5H54.6875V48.5625H18.3125V42.5ZM18.3125 24.3125H33.4688V30.375H18.3125V24.3125ZM18.3125 60.6875H54.6875V66.75H18.3125V60.6875Z" fill="#C4C4C4"/>
                                        </svg>
                                    </div>
                                    <input type='file' className="custom-file-input" accept="image/*" hidden 
                                    //    onChange={(event) => setNicFront(event.target.files[0])}
                                    /><br/>
                                    <span id="imageName">Please upload  a front picture of your NIC.</span>
                                </label>
                                
                            </div>
                            <span id="imageName">Note : *Image should be less than 2MB.</span>
                        </div> : 
                        <div className='nicUploadimage'>
                            <div>
                                <label>NIC Back</label>
                                <input type='file' className="custom-file-input" accept="image/*"
                                    //    onChange={(event) => setNicBack(event.target.files[0])}
                                />
                            </div>
                        </div>
                    // }
                    
                </div> */}

                <div className='uploadField'>
                    {
                        userInfo.nic ? 
                        <>
                            <div>
                                <label>NIC Front</label>
                                <input type='file' className="custom-file-input" accept="image/*"
                                       onChange={(event) => setNicFront(event.target.files[0])}
                                />
                            </div>

                            <div>
                                <label>NIC Back</label>
                                <input type='file' className="custom-file-input" accept="image/*"
                                       onChange={(event) => setNicBack(event.target.files[0])}
                            />
                            </div>
                            
                        </> : <>
                            <div>
                                <label>Passport</label>
                                <input type='file' className="custom-file-input" accept="image/*"
                                       onChange={(event) => setPass(event.target.files[0])}
                                       />
                            </div>
                           
                        </>
                    }
                    
                </div>
                {errorList.file ? <div className="error-msg">{errorList.file}</div> : null}
                {/* {errorList.passport ? <div className="error-msg">{errorList.passport}</div> : null} */}
            </div>
            <div className='container-footer'>
                <div className="action-container right">
                <button className="btn-back" onClick={() => setState(4)}>Back</button>
                    <button onClick={() => validation()} >Next</button>
                </div>
            </div>
            <div id='pdfViewer'/>
        </div>
   )

}

export default FileUploadsLTE 