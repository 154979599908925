import React from 'react';
import {HashLoader} from "react-spinners";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const LoadingBbBody = ({loading, border}) => {
    return (
        <div className={border ? 'loadingAnimation' : 'loadingAnimation borderedNo'}>
            <div>
                <HashLoader
                    css={override}
                    size={30}
                    color={"#0056A3"}
                    loading={loading}
                />
            </div>
      </div>

    );
}

export default LoadingBbBody;
