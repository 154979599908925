import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    ERROR_GET_ADDONS_LIST,
    GET_ADDONS_LIST,
    SUCCESS_GET_ADDONS_LIST,
    SUCCESS_ADD_ADDONS_DATA, CATCH_ERROR_MSG

} from "../../Types";
import {getAddonListDataUrl, getAddVASDataBundlePostPaidUrl, getAddVASDataBundlePostPaidV2Url} from "../../URL";


export  function getDataAddOnsList(subscriberID, packageName){
    let url = getAddonListDataUrl + 'subscriberID=' + subscriberID  + '&packageName=' + packageName
    return (dispatch) => {
        dispatch(loadingDispatch(GET_ADDONS_LIST))
        axiosInstance.get(url).then((response) => {
            if(response.status=== 200 && response.data.isSuccess){

                dispatch(dispatchSuccess(SUCCESS_GET_ADDONS_LIST, response.data.dataBundle))
            }else {
                dispatch(dispatchError(ERROR_GET_ADDONS_LIST, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_ADDONS_LIST, CATCH_ERROR_MSG))
        })
    }

}

export function addAddOnsData(subscriberID, packageId, commitUser, channel, success, isError, error){
    let url = getAddVASDataBundlePostPaidUrl + 'subscriberID=' + subscriberID + '&packageId=' + packageId  + '&commitUser=' + commitUser + '&channel=' + channel

    return (dispatch) => {

        dispatch(loadingDispatch(GET_ADDONS_LIST))
          axiosInstance.post(url).then((response) => {

              if (response.status === 200 && response.data.isSuccess) {
                  success(true)
                  dispatch(loadingDispatch(SUCCESS_ADD_ADDONS_DATA))

              }else {
                  error(response.data.errorShow)
                  isError(true)
                  dispatch(dispatchError(ERROR_GET_ADDONS_LIST, response.data.errorShow))
              }
          }).catch((error) => {
              error(CATCH_ERROR_MSG)
              isError(true)
              dispatch(dispatchError(ERROR_GET_ADDONS_LIST, CATCH_ERROR_MSG))
          })

    }


}

export function addAddOnsDataV2(subscriberID, packageId, commitUser, channel, success,setSuccessMsg, isError, error){
    let url = getAddVASDataBundlePostPaidV2Url + 'subscriberID=' + subscriberID + '&packageId=' + packageId  + '&commitUser=' + commitUser + '&channel=' + channel

    return (dispatch) => {

        dispatch(loadingDispatch(GET_ADDONS_LIST))
          axiosInstance.post(url).then((response) => {

              if (response.status === 200 && response.data.isSuccess) {
                  success(true)
                  setSuccessMsg("Successfully Redeem 20GB offer")
                  dispatch(loadingDispatch(SUCCESS_ADD_ADDONS_DATA))

              }else {
                  error(response.data.errorShow)
                  isError(true)
                  dispatch(dispatchError(ERROR_GET_ADDONS_LIST, response.data.errorShow))
              }
          }).catch((error) => {
              error(CATCH_ERROR_MSG)
              isError(true)
              dispatch(dispatchError(ERROR_GET_ADDONS_LIST, CATCH_ERROR_MSG))
          })

    }


}