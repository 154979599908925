import React from 'react';
import errorLog from "../../assests/images/error.png";

function ErrorAlert({error, setError, setIsError, onclick}) {
    return (
        <div className='alertView'>
            <div>
                <div className='title'>Error</div>
                <div className='mainText'>
                    <div className="img-container">
                    <img src={errorLog} alt='errorIcon'/></div>
                    <div className="message">{error}</div>
                </div>
                <div className='actionButton'>
                    {
                        setError ? <button className='blue p-1 pl-3 pr-3' onClick={() => {
                            setError('')
                            setIsError(false)
                            if ( onclick  !== undefined) {
                                onclick()
                            }
                        }}>Ok
                        </button> : null
                    }

                </div>
            </div>
        </div>
    );
}

export default ErrorAlert;