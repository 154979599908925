import React, {useState} from 'react';
import OtpInput from "react-otp-input";
import {AiFillCloseCircle} from "react-icons/ai";

function OTPValidationFTTH({otpValidation, otpType, email, mobile, setOtpState, sendOTPRequest, loading}) {
    const [otp, setOtp] = useState('');

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
        if (otpValue.length === 6) {
            otpValidation(otpValue);
        }
    };

    return (
        <div className='otpInput'>
            <div className='otpInputBody'>
                <div className='title'>
                    {/* <p className='d-inline-block mt-2 ml-2'>OTP sent to &nbsp; {otpType === 'EMAIL' ? email : mobile}</p> */}
                    <p className='d-inline-block mt-2 ml-2'>OTP sent to your mobile</p>
                    <span onClick={() => setOtpState(false)} ><AiFillCloseCircle color='red' size={22}/></span>
                </div>

                <div className='inputArea'>
                    <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle='otp'
                    />
                </div>

                <div className='d-flex justify-content-around w-50 ml-auto mr-auto mb-4 align-items-center  mb-3'>
                    <div className='resend' onClick={() => sendOTPRequest(otpType)}>Resend&nbsp; OTP &nbsp;</div>
                    <button className='medium blue' disabled={loading} onClick={() => otpValidation(otp)}>
                        {loading ? 'Verifying' : 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OTPValidationFTTH;
