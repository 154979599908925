import React from 'react';

function ConfirmAlert({setConfirmShow, onClick, msg}) {
    return (
        <div className='alertView'>
            <div>
                <div className='title'>Confirm</div>
                <div className='mainText'>
                    <div className="message">{msg}</div>
                </div>
                <div className='actionButton'>
                    <button className='blue p-1 pl-3 pr-3 mr-2' onClick={() => setConfirmShow(false)}>No</button>
                    <button className='blue p-1 pl-3 pr-3' onClick={() => {
                        onClick()
                        setConfirmShow(false)
                    }}>Yes
                    </button>
                </div>
            </div>

        </div>
    );
}

export default ConfirmAlert;