import {
    ERROR_PURCHASE_PAYMENT_HISTORY,
    LOADING_PURCHASE_PAYMENT_HISTORY,
    SUCCESS_PURCHASE_PAYMENT_HISTORY
} from "../../Types";

const initialState = {
    loading: false,
    dataBundle: [],
    error:''
}

export default function BroadBandPaymentReducer(state= initialState, action){
    switch (action.type){

        case LOADING_PURCHASE_PAYMENT_HISTORY : {
            return {
                ...state,
                loading: true,
                error:''
            }
        }

        case SUCCESS_PURCHASE_PAYMENT_HISTORY : {
            return {
                ...state,
                loading: false,
                dataBundle: action.payload
            }
        }

        case ERROR_PURCHASE_PAYMENT_HISTORY : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}
