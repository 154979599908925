import React, { useEffect, useState } from "react";
import Querystring from "querystring";
import { updateDraftDetails } from "../../../../redux/URL";
import { CATCH_ERROR_MSG } from "../../../../redux/Types";
import VisaIcon from "../../image/VisaIcon";
import ShowConditionFtthPayment from "./ShowConditionFtthPayment";
import axiosInstanceFtth from "../../../../redux/axiosInstanceFtth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Prompt } from "react-router-dom";

function FtthPayment({
  userInfo,
  orderRef,
  locationInfo,
  loading,
  setLoading,
  packageInfo,
  setState,
  chargeFTTH,
  setError,
  setIsError,
  error,
  isError,
  checkFesibilty,
}) {
  const homeLine = 390.0;

  const history = useHistory();

  const [agree3, setAgree3] = useState(false);
  const [isFTTHPaymentSelected, setIsFTTHPaymentSelected] = useState(false);
  const [isAcceptTermsConditions, setIsAcceptTermsConditions] = useState(false);
  const [showCondition, setShowCondition] = useState(false);
  const [broadbandMonthlyRental, SetBroadbandMonthlyRental] = useState(
    packageInfo.bbPackagesData ? packageInfo.bbPackagesData.MONTHLY_RENTAL : 0
  );
  const [peoTvMonthlyRental, SetPeoTvMonthlyRental] = useState(
    packageInfo.peoPackagesData ? packageInfo.peoPackagesData.MONTHLY_RENTAL : 0
  );
  const [voiceMonthlyRental, SetVoiceMonthlyRental] = useState(
    packageInfo.voicePackagesData
      ? packageInfo.voicePackagesData.MONTHLY_RENTAL
      : 0
  );

  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    if (checkFesibilty) {
      setIsFTTHPaymentSelected(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [showPrompt, setShowPrompt] = useState(true);

  // useEffect(() => {
  //   const beforeUnloadHandler = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "Do you want to leave this web page? Your existing data will be gone. Please click the bottom back button to return to the previous step.";
  //   };

  //   window.addEventListener("beforeunload", beforeUnloadHandler);

  //   return () => {
  //     window.removeEventListener("beforeunload", beforeUnloadHandler);
  //   };
  // }, []);

  const handleRadioChange = (value) => {
    setIsFTTHPaymentSelected(value);
    setIsAcceptTermsConditions(false);
    setShowPrompt(true);
    setDisplayText(
      <>
        <p>Our service is available in your location.</p>
        <p>
          If you <strong style={{ fontSize: "15px" }}>"PAY NOW"</strong>, we
          will reserve the service, please proceed with the payment by selecting{" "}
          <strong style={{ fontSize: "15px" }}>"PAY NOW"</strong>
          &nbsp;Or else you can{" "}
          <strong style={{ fontSize: "15px" }}>REGISTER & PAY LATER</strong>,
          our team will co-ordinate with you. Please note that we will only
          reserve the service once payment is made.
        </p>
        <p>
          If you are an existing Megaline customer, please select{" "}
          <strong style={{ fontSize: "15px" }}>REGISTER & PAY LATER</strong> as
          you are entitled for a special discount.
        </p>
      </>
    );
  };

  const [disableBeforeUnload, setDisableBeforeUnload] = useState(false);

  useEffect(() => {
    const beforeUnloadHandler = (event) => {
      if (!disableBeforeUnload) {
        event.preventDefault();
        event.returnValue =
          "Do you want to leave this web page? Your existing data will be gone. Please click the bottom back button to return to the previous step.";
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [disableBeforeUnload]);

  // Function to enable/disable beforeunload
  const toggleBeforeUnload = () => {
    setDisableBeforeUnload(!disableBeforeUnload);
  };

  useEffect(() => {
    if (checkFesibilty) {
      setDisplayText(
        <>
          <>
            <p>Our service is available in your location.</p>
            <p>
              If you <strong style={{ fontSize: "15px" }}>"PAY NOW"</strong>, we
              will reserve the service, please proceed with the payment by
              selecting <strong style={{ fontSize: "15px" }}>"PAY NOW"</strong>
              &nbsp;Or else you can{" "}
              <strong style={{ fontSize: "15px" }}>REGISTER & PAY LATER</strong>
              , our team will co-ordinate with you. Please note that we will
              only reserve the service once payment is made.
            </p>
            <p>
              If you are an existing Megaline customer, please select{" "}
              <strong style={{ fontSize: "15px" }}>REGISTER & PAY LATER</strong>{" "}
              as you are entitled for a special discount.
            </p>
          </>
        </>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (checkFesibilty && !isFTTHPaymentSelected){
  //     setDisplayText("Service is available in your location, and the initiation of a new order will be handled by the regional office")
  //   }
  // }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const updateDraft = () => {
    let body = Querystring["stringify"]({
      NIC: userInfo.nic,
      PP: userInfo.passport,
      TITLE: userInfo.title,
      FIRSTNAME: userInfo.fName,
      LASTNAME: userInfo.lName,
      BIRTH_DATE: userInfo.dateOfBirth,
      NATIONALITY: userInfo.nationality,
      SERVICE_ADD_NO: 60,
      SERVICE_ADD_STREET1: locationInfo.address1,
      SERVICE_ADD_STREET2: locationInfo.address2,
      SERVICE_ADD_CITY: locationInfo.city,
      SERVICE_ADD_DISTRICT: locationInfo.district,
      BILLING_ADD_NO: 60,
      BILLING_ADD_STREET1: packageInfo.address1,
      BILLING_ADD_STREET2: packageInfo.address2,
      BILLING_ADD_CITY: packageInfo.city,
      SERVICE_ADD_POSTAL_CODE: locationInfo.postalCode,
      RTOM: locationInfo.rTom,
      EQTYPE: "FDP",
      LONGITUDE: locationInfo.lng,
      LATITUDE: locationInfo.lat,
      SERVICE_TYPE: "FTTH",
      SERVICE_ADD_PROVINCE: "",
      BILLING_ADD_PROVINCE: "",
      VOICE_PACKAGE: packageInfo.voicePackages,
      BB_PACKAGE: packageInfo.bbPackages,
      PEO_PACKAGE: packageInfo.peoTvPackages,
      BILLING_ADD_POSTAL_CODE: packageInfo.postalCode,
      gender: userInfo.gender,
      iddstatus: packageInfo.iddActivate,
      billinG_MODE: packageInfo.billType,
      email: userInfo.email,
      mobileno: userInfo.mobile,
      LOOP_AVAILABLITY: checkFesibilty ? "Y" : "N",
      SLTNIC: userInfo.sltnic,
      REF_NO: orderRef,
      USER_COMMENT:
        checkFesibilty && isFTTHPaymentSelected ? "FULL" : "ADVANCED",
    });

    axiosInstanceFtth
      .post(updateDraftDetails, body)
      .then((response) => {
        if (response.data.isSuccess) {
          // setState(3)
        } else {
          let errors = {};
          errors["pack"] = response.data.errorShow;
          // setErrorList(errors)
        }
        setLoading(false);
      })
      .catch(() => {
        let errors = {};
        errors["pack"] = "Update draft failed";
        // setErrorList(errors)
      });
  };

  const getConnectionfees = (voice, broadband, peoTv) => {
    // Validate input values
    const isVoiceValid = !isNaN(parseFloat(voice));
    const isBroadbandValid = !isNaN(parseFloat(broadband));
    const isPeoTvValid = !isNaN(parseFloat(peoTv));

    // Check if at least one parameter is a valid number
    if (isVoiceValid || isBroadbandValid || isPeoTvValid) {
      // Add the valid values and return the sum
      let sum = 0;
      if (isVoiceValid) sum += parseFloat(voice);
      if (isBroadbandValid) sum += parseFloat(broadband);
      if (isPeoTvValid) sum += parseFloat(peoTv);

      return sum;
    } else {
      return 0;
    }
  };

  console.log("isFTTHPaymentSelected:", isFTTHPaymentSelected);
  console.log("checkFesibilty:", checkFesibilty);
  console.log("Message:", displayText);

  if (showCondition)
    return (
      <div>
        <ShowConditionFtthPayment
          setShowTerms={setShowCondition}
          setAgree={setIsAcceptTermsConditions}
          onClick={updateDraft}
          isFTTHPaymentSelected={isFTTHPaymentSelected}
        />
      </div>
    );
  return (
    <div className="paymentInformation">
      <Prompt
        when={showPrompt}
        message="Do you want to leave this web page? Your existing data will be gone. Please click the bottom back button to return to the previous step."
      />
      <div className="packages-container">
        <div className="package-container">
          <div className="sub-title">Customer Details</div>
          <div className="details-title">
            {userInfo.fName ? (
              <p>{userInfo.fName + " " + userInfo.lName}</p>
            ) : null}
            {/* {locationInfo.address1 ? (
              <p>
                {locationInfo.address1
                  ? locationInfo.address1 + ", " + locationInfo.address2
                  : locationInfo.address2}{" "}
              </p>
            ) : null} */}
            <p>
              Location :{" "}
              {/* {checkFesibilty ? " Service available" : " Service unavailable"}{" "} */}
              {locationInfo.address1 ? (
                <p>
                  {locationInfo.address1
                    ? locationInfo.address1 + ", " + locationInfo.address2
                    : locationInfo.address2}{" "}
                </p>
              ) : null}
            </p>
            {userInfo.email ? <p>{userInfo.email} </p> : null}
            {userInfo.mobile ? <p>{userInfo.mobile} </p> : null}
          </div>
        </div>

        <div className="package-container">
          <div className="sub-title">Package Details</div>
          <div className="details-title">
            {packageInfo.bbPackages ? (
              <p>
                {" "}
                Internet -{" "}
                {packageInfo.bbPackagesData
                  ? packageInfo.bbPackages + " - "
                  : "N/A "}
                {packageInfo.bbPackagesData.STANDARD_GB !== "0"
                  ? packageInfo.bbPackagesData.STANDARD_GB
                  : "Unlimited Data"}
                {packageInfo.bbPackagesData.FREE_GB !== "0"
                  ? " + " + packageInfo.bbPackagesData.FREE_GB
                  : null}
                {packageInfo.bbPackagesData.STANDARD_GB !== "0" ? " GB" : null}
                {""} | Rs. {parseFloat(broadbandMonthlyRental)}
              </p>
            ) : null}
            {packageInfo.peoTvPackages ? (
              <p>
                {" "}
                PEOTV &nbsp; -{" "}
                {packageInfo.peoTvPackages
                  ? packageInfo.peoTvPackages + " - "
                  : "N/A "}
                {packageInfo.peoPackagesData.NO_OF_CHANNELS}
                {""} Channels | Rs. {parseFloat(peoTvMonthlyRental)}
              </p>
            ) : null}

            {packageInfo.voicePackages ? (
              <p>
                {" "}
                Voice &nbsp; &nbsp; &nbsp;-{" "}
                {packageInfo.voicePackages
                  ? packageInfo.voicePackages + " - "
                  : "N/A "}
                {packageInfo.voicePackagesData.NO_OF_CHANNELS} | Rs.{" "}
                {parseFloat(voiceMonthlyRental)}
              </p>
            ) : null}
          </div>
        </div>

        <div className="package-container">
          <div className="sub-title">Your Connection Fee</div>

          {checkFesibilty ? (
            <div className="main-title" style={{ fontSize: "17px" }}>
              <span style={{ textDecoration: "line-through", color: "red" }}>
                Rs. {12500}
              </span>{" "}
              Rs. {chargeFTTH} + Tax <br />{" "}
              <span style={{ fontSize: "14px" }}>
                Inclusive of 20% discount from standard new connection fee only
                for online payments
              </span>
            </div>
          ) : (
            <div className="main-title" style={{ fontSize: "17px" }}>
              Rs. {chargeFTTH + 2500}
            </div>
          )}

          <div className="details-title">
            <p>Monthly Rental</p>
          </div>
          <div className="package-details">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="18"
                fill="none"
                viewBox="0 0 13 18"
              >
                <path
                  stroke="#717171"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.227 8.2h4.84m-4.84 3.2h4.84M4.227 5h4.84M1 1.8c0-.212.085-.416.236-.566A.81.81 0 011.806 1h9.681a.81.81 0 01.57.234c.152.15.237.354.237.566V17l-2.823-2-2.824 2-2.823-2L1 17V1.8z"
                ></path>
              </svg>
            </div>
            <div>
              Rs.
              {getConnectionfees(
                voiceMonthlyRental,
                broadbandMonthlyRental,
                peoTvMonthlyRental
              )}{" "}
              + Usage Charges + Taxes
            </div>
          </div>
        </div>
      </div>

      {/* <div className="payment-details-container">
        <div className="main-header">Review details and payment</div>

        <div className="button-container">
          <button 
           
            className={`${!isFTTHPaymentSelected ? "selected" : ""}`}
            onClick={
              () => {
                setIsFTTHPaymentSelected(false);
                setIsAcceptTermsConditions(false);
                setShowPrompt(true);
                setDisplayText(
                  <>
                    <p>Our service is available in your location.</p>
                    <p>
                      If you <strong style={{fontSize:'15px'}}>"PAY NOW"</strong>, we will reserve the service, please proceed with the payment by selecting <strong style={{fontSize:'15px'}}>"PAY NOW"</strong>
                      &nbsp;Or else you can <strong style={{fontSize:'15px'}}>REGISTER & PAY LATER</strong>, our team will co-ordinate with you. Please note that we will only reserve the service once payment is made.
                    </p>
                    <p>
                      If you are an existing Megaline customer, please select <strong style={{fontSize:'15px'}}>REGISTER & PAY LATER</strong> as you are entitled for a special discount.
                    </p>
                  </>
                );
                
              }
            }
          >
            Register Now & Pay Later
          </button>
          <button
            className={`${isFTTHPaymentSelected ? "selected" : ""} ${
              !checkFesibilty ? "disabled" : ""
            }`}
            disabled={!checkFesibilty}
            style={{ display: !checkFesibilty ?  'none' : 'block' }}
            onClick={
              () => {
                setIsFTTHPaymentSelected(true);
                setIsAcceptTermsConditions(false);
                setShowPrompt(true);
                setDisplayText(
                  <>
                    <p>Our service is available in your location.</p>
                    <p>
                      If you <strong style={{fontSize:'15px'}}>"PAY NOW"</strong>, we will reserve the service, please proceed with the payment by selecting <strong style={{fontSize:'15px'}}>"PAY NOW"</strong>
                      &nbsp;Or else you can <strong style={{fontSize:'15px'}}>REGISTER & PAY LATER</strong>, our team will co-ordinate with you. Please note that we will only reserve the service once payment is made.
                    </p>
                    <p>
                      If you are an existing Megaline customer, please select <strong style={{fontSize:'15px'}}>REGISTER & PAY LATER</strong> as you are entitled for a special discount.
                    </p>
                  </>
                );
                
              }
            }
          >
            Pay Now 
          </button>
          
        </div>

        { !isFTTHPaymentSelected ? (

          <div>
          <p className="main-headerNew">{!checkFesibilty ?  "Our service is currently under development in your area. Please REGISTER now. Our team will contact you once service is ready." : displayText}</p>
          <div className="payment-container">
            <div className="total">Pay after the service is verified by SLT - MOBITEL </div>
          </div>
          </div>

        ) : (

          <div>
          <p className="main-headerNew">{!checkFesibilty ?  "Our service is currently under development in your area. Please REGISTER now. Our team will contact you once service is ready." : displayText }</p>
          <div className="payment-container">
            <div className="total">Total Amount </div>
            <br/>
            <div className="payment">Rs.10,000.00</div>
          </div>
          </div>

        )}
      </div> */}

      {/* <div className="payment-details-container">
        <div className="main-header">Review details and payment</div>

        <div className="button-container">
          <label>
            <input
              type="radio"
              name="payment"
              value="register"
              checked={isFTTHPaymentSelected === false}
              onChange={() => handleRadioChange(false)}
            />
            Register Now & Pay Later
          </label>
          <label>
            <input
              type="radio"
              name="payment"
              value="paynow"
              checked={isFTTHPaymentSelected === true}
              disabled={!checkFesibilty}
              onChange={() => handleRadioChange(true)}
              style={{ display: !checkFesibilty ? "none" : "inline-block" }}
            />
            Pay Now
          </label>
        </div>

        {!isFTTHPaymentSelected ? (
          <div>
            <p className="main-headerNew">
              {!checkFesibilty
                ? "Our service is currently under development in your area. Please REGISTER now. Our team will contact you once service is ready."
                : displayText}
            </p>
            <div className="payment-container">
              <div className="total">
                Pay after the service is verified by SLT - MOBITEL
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="main-headerNew">
              {!checkFesibilty
                ? "Our service is currently under development in your area. Please REGISTER now. Our team will contact you once service is ready."
                : displayText}
            </p>
            <div className="payment-container">
              <div className="total">Total Amount</div>
              <br />
              <div className="payment">Rs.10,000.00</div>
            </div>
          </div>
        )}
      </div> */}

      <div className="payment-details-container">
        <div className="main-header">Review details and payment</div>

        <div className="button-container">
          <div
            className={`radio-option ${
              isFTTHPaymentSelected === false ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              name="payment"
              value="register"
              checked={isFTTHPaymentSelected === false}
              onChange={() => handleRadioChange(false)}
            />
            <label className="register-later">Register Now & Pay Later</label>
          </div>
          <div
            className={`radio-option ${
              isFTTHPaymentSelected === true ? "selected1" : ""
            }`}
          >
            <input
              type="radio"
              name="payment"
              value="paynow"
              checked={isFTTHPaymentSelected === true}
              disabled={!checkFesibilty}
              onChange={() => handleRadioChange(true)}
              style={{ display: !checkFesibilty ? "none" : "inline-block" }}
            />
            <label className="pay-now">Pay Now</label>
          </div>
        </div>

        {!isFTTHPaymentSelected ? (
          <div>
            <p className="main-headerNew">
              {!checkFesibilty
                ? "Our service is currently under development in your area. Please REGISTER now. Our team will contact you once service is ready."
                : displayText}
            </p>
            <div className="payment-container">
              <div className="total">
                Pay after the service is verified by SLT - MOBITEL
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="main-headerNew">
              {!checkFesibilty
                ? "Our service is currently under development in your area. Please REGISTER now. Our team will contact you once service is ready."
                : displayText}
            </p>
            <div className="payment-container">
              <div className="total">Total Amount</div>
              <br />
              <div className="payment">Rs.10,000.00</div>
            </div>
          </div>
        )}
      </div>

      {isFTTHPaymentSelected ? (
        <div className="payment-process-container">
          <div className="main-header" style={{ textAlign: "center" }}>
            Select Payment Method
          </div>
          <div className="payButton">
            <VisaIcon />
          </div>
          <div className="agreeCondition">
            <input
              type="checkbox"
              className="sign"
              checked={isAcceptTermsConditions}
              id="agree3"
              onChange={(e) => {
                setIsAcceptTermsConditions(!isAcceptTermsConditions);
                setShowPrompt(false);
                setAgree3(e.target.checked);
                updateDraft();
              }}
              onClick={toggleBeforeUnload}
            />
            <label onClick={() => setShowCondition(true)}>
              <span>I accept the </span>
              <span
                style={{
                  textDecoration: "underline",
                  color: "darkblue",
                  textDecorationStyle: "dotted",
                  cursor: "pointer",
                }}
              >
                Terms & Conditions
              </span>
            </label>
          </div>
        </div>
      ) : (
        <div className="payment-process-container">
          <div className="agreeCondition">
            <input
              type="checkbox"
              className="sign"
              checked={isAcceptTermsConditions}
              id="agree3"
              onChange={(e) => {
                setIsAcceptTermsConditions(!isAcceptTermsConditions);
                setShowPrompt(false);
                setAgree3(e.target.checked);
                // updateDraft();
              }}
              onClick={toggleBeforeUnload}
            />
            <label onClick={() => setShowCondition(true)}>
              <span>I accept the </span>
              <span
                style={{
                  textDecoration: "underline",
                  color: "darkblue",
                  textDecorationStyle: "dotted",
                  cursor: "pointer",
                }}
              >
                Terms & Conditions
              </span>
            </label>
          </div>
        </div>
      )}

      <div className="container-footer">
        <div className="action-container right">
          <button className="btn-back" onClick={() => setState(2)}>
            Back
          </button>
          {/* <button onClick={() => ()} disabled={!userInfo.isMobileVerify}>Next</button> */}
          {isFTTHPaymentSelected ? (
            <div className="actions-buttons-submit">
              <form
                action="https://billpay.slt.lk/intpay/confirm.php"
                method="post"
              >
                <input
                  hidden={true}
                  name="ContactNumber"
                  value={userInfo.mobile}
                />
                <input hidden={true} name="CustEmail" value={userInfo.email} />
                <input hidden={true} name="channel" value={"FTTX"} />
                <input
                  hidden={true}
                  name="AmountPay"
                  value={chargeFTTH} //isFTTHPaymentSelected ? chargeFTTH : 10
                />
                <input hidden={true} name="orderRefNo" value={orderRef} />
                <input hidden={true} name="NIC" value={userInfo.nic} />
                <input
                  hidden={true}
                  name="PassportNo"
                  value={userInfo.passport}
                />
                <button
                  type="submit"
                  disabled={!isAcceptTermsConditions}
                  className="btn-cart"
                >
                  Pay Now
                </button>
              </form>
            </div>
          ) : (
            <button
              className="btn-cart"
              disabled={!isAcceptTermsConditions}
              onClick={() => {
                updateDraft();
                history.push("/applyonline/fibre/statustracking/" + orderRef);
              }}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FtthPayment;
