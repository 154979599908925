import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getFreeData} from "../../redux/action/broadBand.action";
import ProgressBarView from "./ProgressBarView";
import LoadingGraphs from "./LoadingGraphs";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import {Carousel} from "react-responsive-carousel";
import NoDataToShow from "../common/NoDataToShow";
import nofreedata from '../../assests/images/nodatashow/free data.png'
import NoPreviledge from "../alerts/NoPreviledge";
import ErrorAlert from "../alerts/ErrorAlert";

function FreeData({getFreeData, serviceList, loading, freeData, profile, freeDataError}) {
    const [count, setCount] = useState(0);

    function increment() {
        let length = freeData.usageDetails.length
        if (length - 1 > count) {
            setCount(count + 1)
        }


    }

    function decrement() {
        if (count > 0) {
            setCount(count - 1)
        }

    }

    function splitWord(word) {
        let day = word.split('T')[0]
        let time = word.split('T')[1]

        return day + ' ' + time
    }

    useEffect(() => {
        if (profile.hasOwnProperty('priviledges') && profile.priviledges.free_data) {
            getFreeData(serviceList.listofBBService[0].serviceID)
        }

    }, [getFreeData, profile, serviceList]);

    if (profile.hasOwnProperty('priviledges') && !profile.priviledges.free_data) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><NoPreviledge msg={'Please change the SLT package for Free Data'}/>
        </div></div>
    } else if (loading) {
        return <LoadingGraphs loading={loading}/>
    } else if (freeDataError) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><ErrorAlert error={freeDataError} setIsError={null}
                                                                setError={null}/></div></div>
    } else if (freeData.hasOwnProperty('usageDetails')) {
        return (
            <div className='graphBody-wrapper'>
            <div className='graphBody'>
                <div>
                    <p className='graph-body-title'>Remaining Volume as of : {splitWord(freeData.reported_time)}</p>
                </div>

                {
                    freeData.usageDetails.length === 0 ? <NoDataToShow imgPath={nofreedata} msg='Wait for free data'/> :
                        <div className='d-flex justify-content-between align-items-center'>
                    <span style={{minWidth: '20px', cursor: 'pointer'}} className=''
                          onClick={() => decrement()}><BiChevronLeft
                        size={40} hidden={count === 0}/></span>

                            <div>
                                <Carousel showArrows={false} showStatus={false} showIndicators={false}
                                          selectedItem={count}
                                          width={500}>
                                    {
                                        freeData.usageDetails.map((val, key) => {
                                            return (
                                                <div key={key} className='h-auto m-auto' >
                                                    <div className='text-center'>
                                                        <p className='m-0'>{val.name}</p>
                                                        <ProgressBarView progress={val.percentage} word={val.remaining}
                                                                         unit={val.volume_unit}/>
                                                        <div className='used-of'>{val.used}{val.volume_unit} USED
                                                            OF {val.limit} {val.volume_unit}</div>
                                                        {
                                                            freeData.usageDetails.length > 0 ?
                                                                <p className='text-center blue'>(Valid Till
                                                                    : {freeData.usageDetails[0].expiry_date})</p> : null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>
                            </div>
                            <span style={{minWidth: '20px', cursor: 'pointer'}}
                                  onClick={() => increment()}><BiChevronRight size={40}
                                                                              hidden={count === freeData.usageDetails.length - 1 || freeData.usageDetails.length === 0}/></span>
                        </div>
                }


            </div>
            </div>
        );
    } else
        return null
}


const mapStateToProps = state => ({
    loading: state.broadBand.loadingFreeData,
    freeData: state.broadBand.freeData,
    freeDataError: state.broadBand.freeDataError,
    serviceList: state.accountDetails.service_list,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {getFreeData})(FreeData);