import React, {useEffect, useState} from 'react';
import axios from "axios";
import SingleVod from "./SingleVOD";
import LoadingBbBody from "../boardBand/LoadingBbBody";

function Vod(props) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [isError, setIssError] = useState(false);
    useEffect(() => {
        setLoading(true)
        let instance = axios.create();
        delete instance.defaults.headers.common['X-IBM-Client-Id'];
        instance.get('https://www.peotv.com/movies_api?api-key=71285B346E88BABEEE5167DB27B48').then(response => {
            if (response.status === 200) {
                setData(...[response.data])
                setLoading(false)
            }else
                setIssError(true)
        }).catch(() => {
            setLoading(false)
            setIssError(true)
        })
    }, []);

    if (loading) {
        return <div className='loading'> <LoadingBbBody loading={loading} border={true}/></div>
    } else if (selected) {
        return <SingleVod obj={selected} setSelected={setSelected} />
    }else if(isError) {
        return <div className='peoTVPackages'> Packages Loading Failed </div>
    } else {
        return (
            <div className='vod'>
                {
                    data.map((obj, index) => {
                        return obj.list.map((val, key) => {
                            return <div key={val.ID} onClick={()=>setSelected(val)}>
                                <img src={val.featured_poster} alt={'searchIcon'} className='imageView'/>
                                <div className='title'>{val.Movie_title} - {val.Year}</div>
                            </div>
                        })

                    })
                }


            </div>
        );
    }

}

export default Vod;
