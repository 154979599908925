import React, {useState} from 'react';
import {BsFillCaretRightFill} from "react-icons/bs";
import MyPackagePeoTv from "./MyPackagePeoTV";
import Vod from "./VOD";
import PeoTvPackages from "./PeoTvPackages";
import PeoTvGo from "./PeoTvGo";
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import {Redirect} from "react-router-dom";
import { isPrepaid } from '../../redux/action/accountDetails.action';

function PeoTvMain({loadingServices, account, loadingAccounts, loadingProfile, serviceList}) {

    const [state, setState] = useState(1);

    if (isPrepaid(serviceList && serviceList))
        return <Redirect to='/prepaid/boardBand'/>;

    if (loadingAccounts || loadingServices || loadingProfile) {
        return <div className='mainBody eBill'>
            <LoadingBbBody/>
        </div>
    } else if (!account || account.length === 0) {
        return <Redirect to='/noAccount'/>;
    }

    return (
        <div className='mainBody '>
            <div className='peoTV common-panel'>
                <div className='leftSide'>
                    <div className={state === 1 ? 'buttonTab blue' : 'buttonTab'} onClick={() => setState(1)}>
                        <div>
                            <p className='m-0'>My Package</p>
                        </div>
                        <span hidden={state !==1}>
                        <BsFillCaretRightFill size={20} color='#007ed6'/>
                    </span>
                    </div>


                    <div className={state === 2 ? 'buttonTab blue' : 'buttonTab'} onClick={() => setState(2)}>
                        <div>
                            <p className='m-0'>VOD</p>
                        </div>
                        <span hidden={state !==2}>
                        <BsFillCaretRightFill size={20} color='#007ed6'/>
                    </span>
                    </div>

                    <div className={state === 3 ? 'buttonTab blue' : 'buttonTab'} onClick={() => setState(3)}>
                        <div>
                            <p className='m-0'>PEOTVGO</p>
                        </div>
                        <span hidden={state !==3}>
                        <BsFillCaretRightFill size={20} color='#007ed6'/>
                    </span>
                    </div>

                    <div className={state === 4 ? 'buttonTab blue' : 'buttonTab'} onClick={() => setState(4)}>
                        <div>
                            <p className='m-0'>Packages</p>
                        </div>
                        <span hidden={state !==4}>
                        <BsFillCaretRightFill size={20} color='#007ed6'/>
                    </span>
                    </div>
                </div>

                <div className='rightSide'>
                    {state === 1 ? <MyPackagePeoTv/> : null}
                    {state === 2 ? <Vod/> : null}
                    {state === 3 ? <PeoTvGo/> : null}
                    {state === 4 ? <PeoTvPackages/> : null}

                </div>
            </div>
        </div>

    );
}

const mapStateToProps = state => ({
    loadingAccounts: state.accountDetails.loading,
    loadingServices: state.accountDetails.loadingServiceList,
    loadingProfile: state.broadBand.loadingProfile,
    serviceList: state.accountDetails.service_list,
    account: state.accountDetails.account_Details,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {})(PeoTvMain);

