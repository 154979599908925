import {
    ERROR_GET_DRAFT_DETAILS_LTE, ERROR_GET_PAYMENT_LOG_LTE, ERROR_GET_INVOICE_LTE,
    LOADING_GET_DRAFT_DETAILS_LTE, LOADING_GET_PAYMENT_LOG_LTE, LOADING_GET_INVOICE_LTE, 
    SUCCESS_GET_DRAFT_DETAILS_LTE, SUCCESS_GET_PAYMENT_LOG_LTE, SUCCESS_GET_INVOICE_LTE,

} from "../../Types";

const initialState = {
    loadingDraftLte: false,
    loadingPaymentLte: false,
    loadingInvoiceLte: false,
    draftDetailsLte: null,
    paymentsLogLte: null,
    invoiceLte: null,
    errorDraftLte: '',
    errorPaymentsLte: '',
    errorInvoiceLte: '',
}

export default function lteReducers(state = initialState, action) {
    switch (action.type) {

        case LOADING_GET_DRAFT_DETAILS_LTE : {
            return {
                ...state,
                loadingDraftLte: true,
            }
        }

        case SUCCESS_GET_DRAFT_DETAILS_LTE : {
            return {
                ...state,
                loadingDraftLte: false,
                loadingPaymentLte: true,
                draftDetailsLte: action.payload
            }
        }

        case ERROR_GET_DRAFT_DETAILS_LTE : {
            return {
                ...state,
                loadingDraftLte: false,
                loadingPaymentLte: false,
                errorDraftLte: action.payload,
            }
        }

        case LOADING_GET_PAYMENT_LOG_LTE : {
            return {
                ...state,
                loadingPaymentLte: true
            }
        }

        case SUCCESS_GET_PAYMENT_LOG_LTE : {
            return {
                ...state,
                paymentsLogLte: action.payload,
                loadingPaymentLte: false
            }
        }

        case ERROR_GET_PAYMENT_LOG_LTE : {
            return {
                ...state,
                errorPaymentsLte: action.payload,
                loadingPaymentLte: false,
            }
        }

        case LOADING_GET_INVOICE_LTE : {
            return {
                ...state,
                loadingInvoiceLte: true
            }
        }

        case SUCCESS_GET_INVOICE_LTE : {
            return {
                ...state,
                invoiceLte: action.payload,
                loadingInvoiceLte: false
            }
        }

        case ERROR_GET_INVOICE_LTE : {
            return {
                ...state,
                errorInvoiceLte: action.payload,
                loadingInvoiceLte: false,
            }
        }

        default:
            return state

    }
}
