import React, {useState} from 'react';
import ProgressBarView from "./ProgressBarView";
import {connect} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {BiChevronRight, BiChevronLeft} from "react-icons/bi";
import LoadingGraphs from "./LoadingGraphs";
import ErrorAlert from "../alerts/ErrorAlert";

function MyPackage({summary, loading, error}) {

    const [count, setCount] = useState(0);

    function increment() {
        let length = summary.my_package_info.usageDetails.length
        if (length - 1 > count) {
            setCount(count + 1)
        }


    }

    function decrement() {
        if (count > 0) {
            setCount(count - 1)
        }

    }


    if (loading)
        return <LoadingGraphs loading={loading}/>
    if (error)
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><ErrorAlert error={error} setIsError={null} setError={null}/></div></div>
    else if (summary.my_package_info.hasOwnProperty('usageDetails')) {
        return (
            <div className='graphBody-wrapper'>
            <div className='graphBody'>

                {
                    summary.my_package_info.usageDetails.length > 0 ?
                        <div>
                            <p className='graph-body-title'>Your speed is <span
                                className='speed'>{summary.status}</span> right
                                now
                            </p>
                        </div>
                        : null
                }

                <div className='d-flex justify-content-between align-items-center'>
                    <span style={{minWidth: '20px', cursor: 'pointer'}} onClick={() => decrement()}><BiChevronLeft
                        size={40} hidden={count === 0}/></span>

                    <div>
                        <Carousel showArrows={false} showStatus={false} showIndicators={false} selectedItem={count}
                                  width={500}>
                            {
                                summary.my_package_info.usageDetails.map((val, key) => {
                                    return <div key={key} className=' m-auto' style={{width: '100%'}}>
                                        <div className='text-center'>
                                            <div className="name">{val.name}</div>
                                            <div className="progress-bar-container">
                                                {
                                                    val.limit && val.remaining ?
                                                        <ProgressBarView progress={val.percentage} word={val.remaining}
                                                                         unit={val.volume_unit} /> :
                                                        <div className='unlimited'>
                                                            <div
                                                                className='unlimitedUsage'>{val.used}{val.volume_unit}</div>
                                                            <div className='used-of'>used</div>
                                                        </div>
                                                }

                                            </div>
                                            <div className='used-of'>{`${val.used} ${val.volume_unit} USED
                                                        OF ${val.limit ? val.limit : 'UNLIMITED'} ${val.limit ? val.volume_unit : ''}`}</div>
                                            {
                                                summary.my_package_info.usageDetails.length > 0 ?
                                                    <p className='text-center blue'>(Valid Till
                                                        : {summary.my_package_info.usageDetails[0].expiry_date})</p> : null
                                            }
                                        </div>
                                    </div>
                                })
                            }


                        </Carousel>

                    </div>

                    <span style={{minWidth: '20px', cursor: 'pointer'}}
                          onClick={() => increment()}><BiChevronRight size={40}
                                                                      hidden={count === summary.my_package_info.usageDetails.length - 1}/></span>
                </div>

            </div>
            </div>
        );
    } else
        return null

}

const mapStateToProps = state => ({
    summary: state.broadBand.summary,
    loading: state.broadBand.loading,
    error: state.broadBand.errorSummary
})

export default connect(mapStateToProps, {})(MyPackage);

