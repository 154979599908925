import { ProgressBar } from "react-bootstrap";
import backIcon from "../../../assests/images/loginUI/back.png";
import { Link } from "react-router-dom";

function DetailedReport({ dataObj, setDataObj }) {
  const selectColor = (sorter) => {
    if (sorter === 1) return "#00D300";
    else if (sorter === 2) return "#FFDD00";
    else if (sorter === 3) return "#ED4872";
    else return "#00FFFF";
  };

  return (
    <div className="bbSubBody">
      <div className="body-container">
        <div className="title d-flex">
          <div className="btn-back" onClick={() => setDataObj(null)}>
            <div className="back">
              <img src={backIcon} alt="backLogo" className="back" />
            </div>
          </div>
          <div>{`${dataObj.date}`}</div>
        </div>
        <div className="wrapper">
          <div className="colorRow">
            <div>
              {" "}
              <div className="bullet" style={{ background: selectColor(1) }} />
              <div>Base Bundle</div>
            </div>
            <div>
              {" "}
              <div className="bullet" style={{ background: selectColor(2) }} />
              <div>Loyalty</div>
            </div>
            <div>
              {" "}
              <div className="bullet" style={{ background: selectColor(3) }} />
              <div>Extra GB</div>
            </div>
            <div>
              {" "}
              <div className="bullet" style={{ background: selectColor(4) }} />
              <div>VAS bundles</div>
            </div>
          </div>

          <div className="mainBar mt-4">
            <div className="title text-center">{`Total Usage - ${dataObj.daily_total_usage}${dataObj.volumeunit}`}</div>
            <ProgressBar style={{ height: "18px" }}>
              {dataObj.hasOwnProperty("usages")
                ? dataObj.usages.map((obj, key) => {
                    return (
                      <ProgressBar
                        style={{ backgroundColor: selectColor(obj.sorter) }}
                        now={(dataObj.daily_percentage / 100) * obj.percentage}
                        key={key}
                      />
                    );
                  })
                : null}
            </ProgressBar>
          </div>

          <div className="rowCharts mt-5">
            {dataObj.hasOwnProperty("usages")
              ? dataObj.usages.map((obj, key) => {
                  return (
                    <div>
                      <div className="title">{`${obj.offer_name} - ${obj.volume}GB`}</div>
                      <div className="bar">
                        <ProgressBar style={{ height: "15px" }}>
                          <ProgressBar
                            style={{ backgroundColor: selectColor(obj.sorter) }}
                            now={obj.percentage}
                            key={key}
                          />
                        </ProgressBar>
                      </div>
                      {obj.volumes ? (
                        <div className="speed">
                          <div>
                            <div style={{color : '#17C84F'}}>&#8595;</div>
                            <div className="ml-1">PD</div>
                            <div className="ml-2">{`${obj.volumes.pdl}GB`}</div>
                          </div>
                          <div>
                            <div style={{color : '#17C84F'}}>&#8595;</div>
                            <div className="ml-1">OD</div>
                            <div className="ml-2">{`${obj.volumes.opdl}GB`}</div>
                          </div>
                          <div>
                            <div style={{color : '#FF6B00'}}>&#8593;</div>
                            <div className="ml-1">PU</div>
                            <div className="ml-2">{`${obj.volumes.pul}GB`}</div>
                          </div>
                          <div>
                            <div style={{color : '#FF6B00'}}>&#8593;</div>
                            <div className="ml-1">OU</div>
                            <div className="ml-2">{`${obj.volumes.opul}GB`}</div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
          <div className="colorRow mt-4 mb-2">
            <div>
              <div>PD - Peak Download</div>
            </div>
            <div>
              <div>PU - Peak Upload</div>
            </div>
            <div>
              <div>OD - Off Peak Download</div>
            </div>
            <div>
              <div>OU - Off Peak Upload</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
export default DetailedReport;
