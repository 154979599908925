import React from 'react';
import peoTvGo from '../../assests/images/peoTV/peoTVGO.png'
function PeoTvGo() {
    return (
        <div className='peoTvGo'>
            <div>
                <img src={peoTvGo} alt={'searchIcon'} />

                <div className='text-center mt-3'>
                    <button className='blue p-1 pl-3 pr-3' onClick={() => window.open('https://www.peotvgo.com/', "_blank")}>Explore More</button>
                </div>
            </div>
        </div>
    );
}

export default PeoTvGo;