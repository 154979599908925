import React, {useState} from "react";
import Modal from 'react-modal';
import AddTelephones from "./AddTelephones";
import AddBroadband from "./AddBroadband";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        width: '450px',
        height: "500px",
        outline: "none",
        borderRadius: '20px',

    },
};

function AddAccount(props) {

    const [isTelephoneView, setIsTelephoneView] = React.useState(false);

    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');


    return (
        <div>

            <Modal
                isOpen={props.show}
                onRequestClose={props.hide}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal"
            >
                <span className='float-right' onClick={() => props.hide()}
                      style={{width: '36px', height: '36px', fontSize: '1.5rem', outline: 'none', color: '#01539E'}}> <i
                    className='fa fa-times-circle'/></span>


                {success ? <SuccessAlert msg={'Account added successfully'} setSuccess={setSuccess} hide={props.hide}  /> : null}
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError} /> : null}

                <p className='medium font-weight-bolder mt-5 ml-3 text-white'>Add Account</p>

                <div className="switch-button m-auto">
                    <input className="switch-button-checkbox" type="checkbox" checked={isTelephoneView}
                           onChange={e => setIsTelephoneView(e.target.checked)}/>
                    <label className="switch-button-label white"><span
                        className="switch-button-label-span">Telephone</span></label>
                </div>

                {
                    isTelephoneView ?
                        <AddBroadband  success={success} setSuccess={setSuccess} isError={isError}
                                      setIsError={setIsError} error={error} setError={setError}/> :
                        <AddTelephones  success={success} setSuccess={setSuccess} isError={isError}
                                       setIsError={setIsError} error={error} setError={setError}/>
                }


            </Modal>
        </div>
    );
}

export default AddAccount
