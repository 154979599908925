import React from 'react';


function TermsConditons({setAgree, setShowTerms}) {

    return (
        <div className='termConditon'>
            <div className='headerTerm'> AGREEMENT FOR THE PROVISION OF TELECOMMUNICATION SERVICES
                BY SRI LANKA TELECOM PLC
            </div>

            <div className='bodyList'>
                <div className='discription'>
                    <div> Sri Lanka Telecom PLC (hereinafter referred to as "SLT") shall provide the Service and the
                        Customer shall obtain and continue to use the same subject to the Terms and Conditions stated
                        herein as amended from time to time and regulations made under relevant legislation and Sri
                        Lanka Telecom web site (www.slt.lk) (hereinafter referred to as "Website")
                    </div>
                    <div> The following terms and conditions form part and parcel of the Agreement between parties
                        termed under “Application for New Services” and the parties shall be bound by further or
                        additional terms which may be published in the SLT website. The Customer undertakes to abide
                        by such Terms and Conditions during the period of said Agreement.
                    </div>
                    <div>The Customer further acknowledges the Website may be modified from time to time without
                        notice to the Customer, but the Customer agrees to update him/her and be bound by the Terms
                        and Conditions published in the Website as long as the Customer continues to use the Service.
                        The Customer shall immediately notify, SLT to disconnect the Service in the event the Customer
                        is not agreeable to the Terms and Conditions so modified.
                    </div>
                    <div>This document is an electronic record in terms of Electronic Transactions Act. No 19 of 2006
                        and Evidence (Special Provisions) Act No.14 of 1995 and provisions there under as applicable
                        and the provisions pertaining to electronic records in various other statutes. This electronic
                        record is generated by a computer system and does not require any physical or digital
                        signatures.
                        Customer agree to carefully read and understand the Terms and Conditions set forth herein and
                        in the SLT website.
                    </div>

                    <div>By clicking the “I Accept” [* Pleases refer the Asterisk below] box below, Customer
                        acknowledges that the Customer is fully aware of the content of the Agreement and Customer
                        has read, understood and accept the Terms and Conditions of the Agreement;
                    </div>
                </div>

                <div className='title'>IT IS HEREBY AGREED BY AND BETWEEN THE CUSTOMER AND SLT AS
                    FOLLOWS:-
                </div>

                <div className='title'>1. Definitions
                </div>

                <div className='discription'>1.1 “Service” shall mean the provision of the service, which is selected by
                    the Customer in
                    the Application for New Services.
                </div>

                <div className='discription'> 1.2 “Site/Premises” shall mean the location where the Service is required
                    or affixed or
                    provided or is to be affixed or is to be provided, as the case may be.
                </div>

                <div className='discription'> 1.3 “Equipment” shall mean the equipment, machines and all other items
                    provided by SLT
                    to the Customer in related to Services including but not limited to telephone instrument,
                    router, Set Top Box and Network Terminating Unit (NTU) as required for the provision
                    of the Service.
                </div>

                <div className='discription'> 1.4 “Customer” means an individual over 18 years of age and whose details
                    are set out in
                    the Application for New Services.
                </div>

                <div className='discription'> 1.5 “Documents” shall mean the documents submitted by Customer to prove
                    the identity of
                    the Customer and Customer shall further submit additional Documents requested by SLT
                    if required at the Online Payment Process.
                </div>

                <div className='discription'> 1.6 “Service Enabling Fee” shall mean the New Connection Fee / One Time
                    Charge
                    /Service Initiation Charge paying at the Online Payment Process.
                </div>

                <div className='discription'>1.7 “Rupees” shall mean Sri Lankan Rupees.
                </div>

                <div className='discription'>1.8 All technical terms used in this Agreement shall have the meanings
                    given to such terms
                    in this Agreement or in the Sri Lanka Telecommunication Act No.25 of 1991 as amended
                    (hereinafter referred to as the “Act) provided the meanings contained in this Agreement
                    and the Act do not conflict. However in the event the meanings given to technical terms
                    in this Agreement and the Act are in conflict, the meanings given in the Act shall prevail.
                </div>

                <div className='discription'>1.9 Words imparting the singular shall include the plural and vice versa and
                    words imparting
                    one gender shall include the other gender.
                </div>


                <div className='title'>2. Provision of Service</div>

                <div className='discription'> 2.1 The Customer may obtain Services selected by the Customer in the Application for New
                    Services  under this Agreement.
                </div>

                <div className='discription'> 2.2 The Customer shall be committed to use the selected Option as stated under Clause 3.2.
                </div>

                <div className='discription'> 2.3 In the event of condition/capacity of the connection and/or the availability of technology
                    and the resources is identified to be inadequate to provide the selected Option/requested
                    package, during the Service provisioning process, SLT has the right to revoke any
                    Service(s) already provided to the Customer and/or to reject any request for new Service
                    provisioning by the Customer.
                </div>

                <div className='discription'> 2.4 In such event, the Customer`s only remedy and SLT`s entire liability would be to refund
                    any Service Enabling Fee already collected from the Customer, within One (01) month
                    from the Online Payment date, upon the return of any SLT Equipment already provided
                    to the Customer
                </div>

                <div className='discription'> 2.5      In the event if additional resources are required to provide the selected Option/requested
                    package, during the Service provisioning process, SLT shall impose Additional Fees from
                    the Customer. Customer shall make the payment of Additional Fees for the same within
                    Seven (07) days from the date the Additional Fees requested by SLT.
                </div>

                <div className='discription'> 2.6      In the event if Customer is not accepted the payment of Additional Fees, Customer shall
                    terminate the Agreement and request in writing to refund the Service Enabling Fee. SLT
                    shall refund the Service Enabling Fee within One (01) Month from the date SLT received
                    the written request of the Customer.
                </div>

                <div className='discription'> 2.7 IDD Facility: The Customer will be provided with the IDD facility as a value added
                    Service, usage charges of which shall be stated under the monthly invoice
                </div>

                <div className='discription'> 2.8In addition to the Services selected by the Customer in the Application for New Services
                    as stated under Clause 2.1, the Customer may obtain additional services (Additional
                    Services) such as on demand services, additional apparatus or extension lines upon
                    payment of applicable prevailing charges including but not limited to applicable service
                    enabling fee, usage fee, monthly rental, levies, taxes and any other fees and charges
                    which shall be levied by SLT in respect of such on demand services (Additional Fees).
                </div>

                <div className='title'> 3. Period
                </div>

                <div className='discription'> 3.1 This Agreement shall come into force on the date the Service Enabling Fee is paying via
                    online payment process and shall be valid until terminated either by SLT in accordance
                    with Clause No.9 herein or by the Customer as stated under Clause 7.1.
                </div>

                <div className='discription'> 3.2 Subject to Clause 2.1 above, the Customer shall not terminate this Agreement for a period
                    of One (01) year from the date of Service provisioning (hereinafter referred to as “the
                    Committed Period”) unless upon the payment of the early termination charges stated
                    under Clause 11.6.
                </div>

                <div className='discription'> 3.3 The Customer may request for the termination or a change of selected package only after
                    the lapse of the Committed Period stated in Clause 3.2.
                </div>



                <div className='title'> 4 Disclaimer and Exclusion of liability  </div>

                <div className='discription'>4.1       SLT will take reasonable endeavors in good faith to ensure that the Services are available
                    with minimal  service interruptions but does not guarantee that the Service will be
                    uninterrupted or error free.
                </div>

                <div className='discription'> 4.2      The Customer shall have no claim for loss or damages consequential or otherwise or any
                    other claim whatsoever against SLT on account of loss of revenue, business or any other
                    basis, either for the Customer  or for any third party, consequent to the suspension,
                    removal, disconnection, total or partial interruption of the Service or installation, natural
                    disaster or Force Majeure situations or improper use by the Customer or negligence of the
                    Customer or termination of the Service provided by SLT. Force Majeure includes acts
                    beyond the control of SLT, including without limitation, acts of God, acts or regulations
                    or any governmental or supra-national authority, war, terrorist activities or national
                    emergency, accident, riot, civil commotion, explosions, strikes, Lockouts, industrial
                    disputes (whether or not involving each party`s employees) or epidemics. However, force
                    majeure shall not include insufficiency of funds and in any event force majeure shall not
                    absolve the Customer from its obligations   under this Agreement including but not
                    limited to payment obligations.
                </div>

                <div className='discription'>4.3       SLT does not control the information or content passing through the SLT systems or
                    internet, and as such the use of any information passing through SLT system /  networks
                    is at the sole risk of the Customer. Further, the Customer shall be solely responsible for
                    all information retrieved, stored and transmitted through the Service by the Customer.
                </div>

                <div className='discription'>4.4     The Customer shall indemnify and keep SLT indemnified of any negligence, any willful
                    damage commission or omission, any unauthorized act, unlawful act on the part of the
                    Customer including but not limited to virus or spam downloads and uploads resulting
                    from Customer's actions and/or his/her terminal equipment which are beyond the control
                    of SLT.
                </div>

                <div className='discription'> 4.5       SLT shall not be liable to the Customer for any indirect, special, consequential or punitive
                    loss/damages/claims or for any loss of business, loss of revenue, loss of data or loss of
                    anticipated savings resulting from the use or inability to use the Service and
                    suspension/termination thereof.
                </div>

                <div className='discription'> 4.6      The Customer shall at all times indemnify SLT and keep SLT indemnified against any
                    claim by a third party over any claim for infringement of any intellectual property rights,
                    liability over the inaccuracy of data and data which are offensive on moral, religious,
                    racial or political grounds or of an abusive, indecent, obscene or menacing nature sent or
                    received by the Customer of SLT through the use of the Service or any slander and/or
                    defamatory content broadcast/transmitted through the Service.
                </div>

                <div className='discription'> 4.7       Neither SLT nor its directors, shareholders, its affiliates, subcontractors or their respective
                    employees shall under any circumstance whatsoever, be liable to the Customer for any
                    loss or damage sustained directly or indirectly by the Customer due to the reason of the
                    failure of the Customer to maintain its network in proper order, free from computer
                    viruses or harmful programmes being introduced or been let into/or transmitted either
                    through the use of an apparatus or otherwise into a telecommunication network while the
                    use of SLT service. SLT shall also not be liable for any loss or damage sustained by the
                    Customer due to reason of failure, breakdown or interruption of the Service whatsoever,
                    notwithstanding the cause of such failure, breakdown or interruption of the Service and
                    however long it shall last. Furthermore, no reduction in rates or outage credit shall be due
                    to the Customer in the event of such occurrences.
                </div>

                <div className='discription'> 4.8    SLT shall disclaims any representation made by any third party at any stage as to the    ​
                    ownership of the Service/Site/Premises.
                </div>

                
                <div className='title'>5 Indemnity</div>

                <div className='discription'>5.1 The Customer shall indemnify and hold harmless SLT against any claim, action, demand, expense, loss or other liabilities whatsoever which may arise as a result of inter alia the Customer`s (including the unauthorized personnel) negligence, fraud, misuse, and/or omission and/or failure to fulfill the Customer`s obligations under this Agreement, including but not limited to the infringement or alleged infringement by the Customer of any intellectual property rights arising out of the use of the Service, the use of the Service by the Customer for illegal or immoral purposes or for the transmission and/or introduction of harmful computer viruses or programmes into inter alia telecommunication networks, computer systems, computers and computer apparatus, any unauthorized use of the Service and the violation of any applicable laws and regulations by the Customer, Any such act that would tarnish the corporate image of SLT shall amount to a material breach of this Agreement, and entitle SLT to claim damages from the Customer. (Descriptive information vide website). Further the Customer shall indemnify SLT and hold SLT indemnified against any third party claim over the breach of this Clause.
                </div>

                <div className='discription'>5.2 The Customer shall indemnify, and hold SLT harmless in respect of any actions, claims, damages and/or losses arising as a result of suspension /disconnection of the Service and/or termination of this Agreement and/or any infringement of intellectual property rights, and/or any libel, slander or defamatory content, or any act, commissions or negligence of the Customer, his/her servants. 
                </div>

                <div className='discription'>5.3 SLT undertakes to take the best endeavors to provide the Services, however based on the availability of technology and the resources and/or condition/capacity of the connection and as such the Customer shall indemnify SLT of any delay failure or preferences in providing the Service.
                </div>

                <div className='title'>6 Customer's Responsibilities  </div>
                <div className='discription'>
                6.1.1   Customer shall provide true and accurate information including Customer`s personal information pertaining to the Service and packages given by SLT under this Agreement. 
                </div>
                <div className='discription'>
                6.1.2   Customer shall submit genuine Documents pertaining to the Service and packages given by SLT under this Agreement and Customer shall further submit additional Documents requested by SLT if required at the Online Payment Process.     
                </div>
                <div className='discription'>
                6.2     The Customer shall settle the Fees and Payments morefully stated under Clause 11 herein. 
                </div>
                <div className='discription'>
                6.3     The Customer shall be responsible for the Equipment at the Customer`s premises as morefully  stated under Clause 15 herein.   
                </div>
                <div className='discription'>
                6.4    The Customer shall not, without the prior written consent of SLT assign, let, sublet, resell or otherwise dispose of the Service herein. This Service is for, individual use only and may not be used for shared us for resale, commercial use etc.     
                </div>
                <div className='discription'>
                6.5    Customer shall not use the Service provided hereunder for any activity which violates a  Law/Regulation/Rule/Oder issued by the Government/Parliament or any  Regulatory Body/Legal Body or a Court of Law or for any illegal, immoral or improper purposes. If the Customer allows the Equipment to be put into such use, SLT shall withdraw the connection, disconnect the Service and may remove the Equipment provided to the Customer. The Customer shall have no claim for damages or any other claim in consequence of such a withdrawal or disconnection of the Service or any Equipment.     
                </div>
                <div className='discription'>
                6.6      The Customer shall answer the questions addressed by or on behalf of SLT to the Customer, with regard to the use of the Equipment supplied by SLT. The Customer shall have no claim against SLT whatsoever in consequence of the disconnection of the Equipment due to the refusal to answer such questions. Further, SLT shall not be liable to refund any Service enabling fee, monthly subscription, usage charges and/or any other charges.
                </div>                               
                <div className='discription'>
                6.7    The Customer shall not claim against SLT on account of any damage or injury caused by any high potential current (not emanating from SLT's premises) which may be conveyed to Customer's premises by the Equipment and shall indemnify SLT against any such claim, made by any third party.     
                </div>
                <div className='discription'>
                6.8 The Customer shall provide, at the Customer`s own expense, when so required by SLT, all facilities or resources, necessary for the proper provision of the Service, installation, testing, maintenance and operation of the Equipment, including without limitation power outlets, suitable supply of electricity, conduits, pipes, access, Ups, etc.    
                </div>
                <div className='discription'>
                6.9   The Customer shall grant SLT all facilities in his/her power, free of charge, and obtain necessary consent required for installation of Equipment and where necessary shall permit authorized employees of SLT at all reasonable times to have free access to all premises under the Customer's control to inspect and maintain the Service. If permission for access is required from any other person(s) the Customer shall obtain same at the request of SLT. SLT shall not be held responsible for any loss or damage caused to the Customer as a result of SLT`s failure to provide the Service due to the unavailability of free access.
                </div>
                <div className='discription'>
                6.10 In the event that the Service comprises of several components for which there are separate service agreements SLT may request that the Customer enter into such agreement/s in which event this agreement shall act as a supplementary to such Agreement/s. Default in one component facility shall deem to be default of the entire Service and in such an event this agreement and all services may be terminated by SLT without prejudice to any of its rights.     
                </div>
                <div className='discription'>
                 6.11	The Customer seize and possess of or otherwise become well and sufficiently entitled to the Site/Premises on which the respective Services are provided or in the event if the Customer is not seize and possess of or otherwise become well and sufficiently entitled to the Site/Premises on which the respective Services are provided, the Customer shall submit a Consent Letter obtained from the Owner of the Site/Premises in order to provide the service,  at the online payment process along with other requested Documents.   
                </div>



                <div className='title'>7. Request of disconnection by Customer  </div>
                <div className='discription'>
                7.1      Subsequent to the provision of the Service, Customer may request to disconnect the Service due to any technical defect and/or failure. SLT shall make its best efforts to rectify such technical defects and/or failures and if the same cannot be rectified as certified by SLT, Service shall be disconnected and/or this Agreement be terminated by SLT, however upon full payment of all Service enabling fee, monthly rental, usage charges and/or any other charges, taxes and levies due for usage and the return of the Equipment. In such an event, the Customer shall not be liable for the early termination fee stated under Clause 3.2
                </div>
                <div className='discription'>
                7.2     Under such circumstances, subject to Clause 2.4 and 11.5, SLT shall not be liable to pay any claims, charges, compensation, damages or refund any Fees and Payments made by the Customer including but not limited to the Service enabling fee, monthly subscription, usage charges, additional charges and/or any other charges, for any disconnection or reconnection effected hereunder.    
                </div>


               
                <div className='title'>8. Suspension or Disconnection of Service by SLT  </div>
                <div className='discription'>
                8.1      SLT reserves the right to suspend or disconnect the Service due to any reason attributable to any violation of terms and conditions by the Customer as stipulated herein or otherwise inter alia the violation of provisions published by SLT in its Website, and any violation of the general law. SLT may reconnect the Service upon payment of reconnection charges and settlement of all outstanding dues by the Customer. 
                </div>
                <div className='discription'>
                8.2 	SLT may, at any time disconnect the Service due to service reasons, as a result of which SLT  would not be in a position to provide the Service.    
                </div>
                <div className='discription'>
                8.3 	SLT may suspend or disconnect the Service due to any outstanding payment whether in full or part with regard to any of the Service provided by SLT. Accordingly, any balance pending due to a part payment of the monthly Subscription shall amount to an outstanding payment for the purpose of this section.    
                </div>
                <div className='discription'>
                8.4 	SLT shall suspend the Service and/or any other service provided by SLT in case of orders and/or instructions of any state and/or regulatory authority.    
                </div>

                <div className='title'>9. Termination  </div>
                <div className='discription'>
                9.1     SLT may terminate this Agreement and disconnect the Service due to following reasons. However such termination shall not prevent SLT from recovering all outstanding dues and Equipment from the Customer or his/her heirs, executors, administrator, successors or assigns.        
                </div>
                <div className='discription'>
                9.1.1  If any Service Enabling fee, Monthly subscription, Usage Charges or any additional fees, charges, taxes, levies, expenses, damages or any monies payable by the Customer is not settled for one month from the due date.   
                </div>
                <div className='discription'>
                9.1.2   The Customer fails to perform or commits a breach of the Customer`s obligations hereunder or is found to be in breach of the Customer`s warranties and/or representations hereunder.     
                </div>
                <div className='discription'>
                9.1.3   If the Customer shall be adjudicated as insolvent or make any composition or arrangement with the creditors to assign a benefit.     
                </div>
                <div className='discription'>
                9.1.4   In the event the license issued to SLT by the government Authority/Regulator is withdrawn or not renewed due to any reason. 
                </div>
                <div className='discription'>
                9.1.5 If SLT is unable at any time to obtain or maintain any licenses way leave permission or easement necessary for construction or maintenance of the telephone installation.     
                </div>
                <div className='discription'>
                9.1.6 If the Customer fails to give an undertaking forthwith, required by SLT to pay certified costs to prevent damages or injurious effects caused to the Service, due to electric lighting or power plant erected by or on behalf of the Customer or due to any other case.     
                </div>
                <div className='discription'>
                9.1.7 If the Customer is unable to obtain or if there is unreasonable delay in obtaining the necessary consent or permission required for the installation and use of the Equipment.     
                </div>
                <div className='discription'>
                9.1.8 If, in the opinion of SLT, any regulatory authority or governmental body, it is not in the public interest or in the interest of security to continue providing the Service to the Customer for any reason whatsoever. 
                </div>
                <div className='discription'>
                9.1.9 The Customer is, in the opinion of SLT, using the Service for illegal or immoral activities or criminal action has been taken or is in the process of being taken against the Customer for the use of any service which is linked or connected to the Service.    
                </div>
               
                <div className='discription'>
                9.2    SLT may terminate this Agreement at its sole discretion, without accruing any liability thereto or any obligation on the part of SLT for any compensation or refund ;
                </div>
                <div className='discription'>
                9.2.1 due to any technical constrains or operational issues or the technology being obsolete, causing difficulties in continuing with the Service hereunder, and if the same cannot be rectified, or      
                </div>
                <div className='discription'>
                9.2.2 due to any dispute in connection with provisioning of the Service including but not limited to disputes between the Customer and the land lords or occupants of the adjoining premises or lands. 
                </div>
                <div className='discription'>
                9.3     In the event SLT terminate the Service the Customer shall settle the dues stated under Clause 11.6 and return the Equipment as stated under Clause 15.5. The Customer shall not be entitled to any claim, reduction, refund, payment or compensation of whatsoever.    
                </div>


                <div className='title'>10.   Change of Location  </div>
                <div className='discription'>
                10.1 In the event the Customer makes a request to change the location of the existing Service installation, SLT shall make its best endeavors to provide all existing Services at the new location subject to technical feasibility, being provided with the necessary approvals and upon payment of the standard relocation charges by the Customer. In the event SLT is unable to provide the existing Service/package/features at the new location due to technical constrains or due to any other reason, the Customer hereby undertakes to absolve SLT of any liability of the same. SLT may enter into a fresh agreement for the provisioning of available Service, as appropriate. 
                </div>


                <div className='title'> 11.   Fees and Payments  </div>
                <div className='discription'>
                11.1 The Customer undertakes to settle all applicable charges including but not limited to the Service Enabling Fee, Usage Fee, Monthly Subscription, levies, taxes and such other charges detailed under this Agreement with regard to the Service on the due date as morefully stated under the SLT invoice.        
                </div>
                <div className='discription'>
                11.2 In the event of the Customer obtaining Additional Services, the Customer shall settle all such Additional Fees stated under Clause 2.8.     
                </div>
                <div className='discription'>
                11.3 In relation to any Service interruption, the Customer shall not be entitled to any service credits or refund whatsoever of the monthly Subscription  and/or Service enabling fee and/or any other payments made in order to obtain the Service.    
                </div>
                <div className='discription'>
                11.4 The Customer shall pay to SLT the certified costs morefully indicated in the invoice for repairing, renewing, or replacing in stock any parts of the Service at the Customer's premises including the Equipment which may be stolen, lost, damaged, misused or which may be destroyed by fire, lightning or other cause or which is used in any unauthorized manner further to the provisions under 15.3 and 15.4,. The Customer shall settle the same on the due date.    
                </div>
                <div className='discription'>
                11.5 In the event of condition/capacity of the connection and/or the availability of technology and the resources is identified to be inadequate to provide the selected Option/requested package, during the Service provisioning process, SLT shall then refund the service enabling fee to the Customer within One (01) month from the Online Payment date, without any interest thereto. In such an event this Agreement shall stand terminated.    
                </div>
                <div className='discription'>
                11.6 In the event of an early termination or a downgrade of the selected Option within the Committed Period stated under Clause 3.2, SLT shall charge an early termination fee calculated by SLT in accordance with the prevailing rates of SLT for such an early termination together with all outstanding dues. In the case of an upgrade of the selected Option, relevant service initiation charge for the upgrade of the selected Option  will apply. SLT may charge an administrative charge for such migration from existing Option to the new Option selected by the Customer. Any change from existing service to the Service hereunder shall be effected only upon settlement of the outstanding arrears under the old service and payment of new connection charges applicable for the new Service.     
                </div>
                <div className='discription'>
                11.7 With prior notice, SLT shall have the right to amalgamate, combine and consolidate amounts due from the Customer on account of other services provided by SLT to the Customer either subsisting presently or which may be provided in the future, and to claim a consolidated amount even if all such services are not governed by these Terms and Conditions.   
                </div>
                <div className='discription'>
                11.8 The Customer shall be liable for and shall pay monies due and payable in terms of this Agreement and set out in any of the Invoices, on the due dates notwithstanding that the Customer may dispute the same for any reason. If the Customer disputes the Customer’s liability to pay any such amount, the Customer shall, after making the payment, advise SLT in writing of the said dispute. Thereupon SLT shall examine such dispute. In the event that such a dispute is decided by SLT or another person duly appointed for that purpose, in the Customer’s favour, SLT shall refund to the Customer any excess amount paid by the Customer free of interest.    
                </div>


                <div className='title'>12.  Variations </div>
                <div className='discription'>
                12.1 The Service enabling fee and monthly subscription and any of the applicable payments in respect of the service shall be subject to tariff revisions and the terms and the conditions set forth herein may change from time to time.
                </div>
                <div className='discription'>
                12.2 The term and conditions set forth herein may change from time to time as a result of any changes made under Sri Lanka Telecommunication Act No.25 of 1991 or under any other Law or Regulations or Rules or any other policy decision made by SLT.      
                </div>



                <div className='title'>13.   Rights of SLT </div>     
                <div className='discription'>
                13.1 In case of the death of the Customer or vacation of premises by the Customer or in the event of a dispute, SLT reserve the right to disconnect the Service. In such an event SLT`s rights shall continue with regard to recovery of any arrears and/or Equipment. 
                </div>
                <div className='discription'>
                13.2 Customer agrees that SLT has the sole right to determine the customer premises Equipment repair, replacement and maintenance policy.   
                </div>
                <div className='discription'>
                13.3 Customer hereby permits SLT to remotely manage the customer premises Equipment owned either by SLT or by the Customer, by way of centralized remote provisioning of services, inventory management, group updates, monitoring, event triggering, support automation or using any other mechanism.     
                </div>
                <div className='discription'>
                13.4 SLT has the right to enter upon the Site/Premises with prior notice in order to examine the Service provided hereunder, any apparatus connected thereto and/or related details in connection to the installation and to take such action as may be needed to ensure compliance with the terms and conditions hereof and of any Statute, by the Customer.    
                </div>


                <div className='title'>14. Notices  </div>   
                <div className='discription'>
                 14.1 Notice shall be sent to the Customer by post to the address given in the Application for New Services . In case of a change of address the Customer undertakes to inform SLT one month prior to such change. SLT shall not be liable for any notice or invoice or other correspondence not being received by the Customer due to a change of address not being informed to SLT as provide herein. A notice shall deemed to be properly served/received, if personally delivered, upon delivery, if sent by registered post, after 3 working days of posting, if sent by facsimile, 12 hours after receipt of the confirmation slip.   
                </div>

                <div className='title'>15.  Equipment </div>   
                <div className='discription'>
                15.1 The Customers who wish to obtain Customer Premise Equipment (Equipment) from SLT, shall be provided with the Equipment required for the initial Service provisioning including but not limited to  telephone instrument, router,  Set Top Box & NTU, as required for the provision of Services. The Customer shall procure any additional equipment required for service enhancements and all such Customer owned equipment shall be subjected to SLT‟s rights under Clause 13.3.   
                </div>
                <div className='discription'>
                15.2 The Equipment provided/subsidized by SLT, shall be owned by and be the property of SLT and shall be used in accordance with any manuals and/or instructions provided by SLT. However SLT shall grant the Customer the use of such Equipment in provisioning the Service. SLT will maintain SLT Equipment in good order and working condition, fair wear and tear excepted, at its cost in accordance with its prevailing policies referred under Clause 13.2, however subject to Clauses 11.4 and 15.3 and 15.4 herein.    
                </div>
                <div className='discription'>
                15.3 SLT shall not be held responsible for any loss, negligence, willful damage, unauthorized act and/or failure to prevent any loss or damage on the part of the Customer in respect of the Service and/or Equipment which are beyond the control of SLT including but not limited to preventable loss or damage caused by fire or lightning and in the case of such damage being caused or loss of SLT owned Equipment, the Customer shall, pay damages and/or any other payment determined in respect of the loss or damage at the sole discretion of SLT.     
                </div>
                <div className='discription'>
                15.4 The Customer shall not damage, attach, alter, remove, sell or transfer any Equipment of SLT or place another apparatus with electrical connections therewith, or obliterate with any marks, words, numbers or permit any other person to carry out any of the above acts without the prior written approval of SLT. The Customer shall, make good the loss to SLT by payment of certified costs and damage caused to SLT.     
                </div>
                <div className='discription'>
                15.5 Forthwith the termination of Service, the Customer shall return the Equipment to SLT with all apparatus thereto in good condition, ordinary wear and tear excepted. In the event, the Customer fails to return the Equipment, SLT shall have the right to enter the premises of the Customer/installation Site/Premises with notice, to remove such Equipment and/or claim damages on demand.    
                </div>


                <div className='title'>16. Assignment  </div>   
                <div className='discription'>
                16.1 The Customer shall not assign, re-sell or trade, whole or part of the rights and/or obligations set forth under the terms and conditions herein to any third party without the prior written consent of SLT.
                </div>

                <div className='discription'>
                16.2 SLT may assign full or part of the rights and obligations set forth under the terms and conditions herein to any third party at its discretion. 
                    
                </div>

                <div className='title'>17.  Governing Law and Jurisdiction  </div>   
                <div className='discription'>
                17.1 This Agreement and the rights and liabilities of the Parties hereunder shall be governed by and interpreted pursuant to the laws of the Democratic Socialist Republic of Sri Lanka, and any disputes arising therefrom if not amicably settled within a period of 60 days shall be subject to the jurisdiction of Courts of Sri Lanka and the Parties agree to submit to the exclusive jurisdiction of the competent courts of Sri Lanka having jurisdiction over the place on which SLT’s Registered Office is situated.
                <br/> <br/>
                SLT is strictly adhere to the below mentioned Acts which are enforced by the Sri Lankan Government.
                <br/> <br/>

                <ul>
                    <li>Information and Communication Technology Act No.27 of 2003</li>
                    <li>Intellectual Property Act no. 36 of 2003</li>
                    <li>Electronic Transactions Act No. 19 of 2006</li>
                    <li>Computer Crimes Act No. 24 of 2007 </li>
                    <li>Payment And Settlement Systems Act, No. 28 of 2005</li>
                    <li>Evidence (Special Provisions) Act No.14 of 1995</li>
                </ul>                 
                          
                               

                </div>





                <div className='title'>18.  Severability </div>   
                <div className='discription'>
                    18.1 If any of the provisions of the terms and conditions hereto prove to be or become invalid, illegal or unenforceable under any applicable law, the legality and enforceability of the remaining provisions shall not be affected or impaired in any way and such invalid, or unenforceable provision shall be deemed deleted. 
                </div>

                <div className='title'>19.  Confidentiality  </div> 

                <div className='discription'>
                19.1 The Customer hereby undertakes to SLT that the Customer will keep in the strictest confidence, except where disclosure is required by law, any confidential or proprietary information or intellectual property of any nature belonging to SLT which may come into the Customer`s possession or to the Customer`s knowledge during the Customer`s association with SLT, except where the prior written consent of SLT is obtained.    
                </div>
                <div className='discription'>
                19.2 The Customer agrees that, if the Customer fails to observe the Customer`s obligations set forth in this Clause, SLT shall be immediately entitled to injunctive and other equitable relief ordering the Customer to specifically perform the Customer`s obligations under this Clause, and the Customer consents to the entry of such order and to such injunctive relief, and waives any rule or other requirement for the making of a bond as a condition for obtaining such relief. Such rights to specific performance and an injunction shall be cumulative and in addition to all other legal and equitable rights and remedies SLT may have.    
                </div>



                <div className='title'>20. Guidelines for Use</div>            
                <div className='discription'>
                The following terms and conditions serve as a guideline in addition to other polices such as Information Security Policy and Fair Usage Policy which are published in the aforesaid website    
                </div>
                <div className='discription'>
                <strong> A. Broadband Service</strong> (Further to clause No. 4.2 of the Application for New Services )     
                </div>
                <div className='discription'>
                * Unauthorized Access: Any attempt on the part of the Customer to access or modify unauthorized computer system, information or to interfere with normal system operations, which might result in causing a denial of Service to other users, whether successful or not, or any attempt on the part of the Customer to gain access to any account, not belonging to that Customer (spoofing), including but not limited to "hacking"- on SLT‟s Equipment or of any computer system or network accessed via SLT's communication service – will result in the immediate suspension of the Service. (Descriptive information vide Website).     
                </div>
                <div className='discription'>
                * Fair Usage (Applicable only for internet Unlimited Broadband Packages and Add-ons users): Customer acknowledges that SLT wishes to ensure the speed and the availability of SLT broadband service for Customers at all times as described in the Website. Thus the Customer with “Internet Unlimited” broadband package and Add-ons shall use the Service in a fair manner by the other SLT Customers.    
                </div>
                <div className='discription'>
                * Security: Customer shall not divulge his/her/its user name or password and shall not act in such a way that may reveal such information to any third party whom shall not be held responsible in the case of any breach of terms as set forth herein. The Customer is responsible for taking all reasonable steps necessary to secure his/her/its computer resources so that only authorized users can access such resources. Customer shall be solely responsible for any breach of this Agreement by such unauthorized third parties.     
                </div>
                <div className='discription'>
                *Value Added Services (VAS) : The Customer acknowledges the static IP address provided hereunder as a value addition to the Customers who select Web Pro/Any Storm and Packages upon a payment of rental, shall always remain the property of SLT. Upon the expiration or early termination of this Agreement such static IP address shall be used by SLT.    
                </div>
                <div className='discription'>
                * On line Conduct: Any action by a Customer which, in SLT's sole opinion, restricts, or inhibits other subscribers from using and enjoying the services offered by SLT is strictly prohibited. This includes inter alia the use of offensive language: committing or discussing with the intention to commit illegal activities, publication, transmission reception, data exchange, mail posting, news posting, news reading or any other form of transfer of data, material, information or software in violation of any law. Such violations are prohibited and shall be construed as a material breach of this Agreement. Therefore, the Customer shall not use the Broadband Service to send unsolicited bulk and/or commercial messages, otherwise known as "spamming", over the internet. The Customer specially agrees not to upload, post or reproduce, in any manner whatsoever, any materials protected under copyright without the permission of the copyright owner. In general, any act committed by the Customer in violation of intellectual property  rights of a third party shall construe a material breach and SLT shall forthwith receipt of such complain suspend/terminate the Service. (Descriptive information vide Website)     
                </div>

                <div className='discription'>
                <strong> B. Voice   IDD Facility</strong> : The customer who is provided with IDD facility may obtain the secret code and follow instructions given by SLT in connection with the use of the secret code depending on the availability of the secret code facility. If a secret code is not obtained an in the event the secret code has not been used properly Customer is solely responsible for all the calls originated through his / her / its number. SLT shall not be responsible for any loss or damage suffered by the Customer due to the failure on the part of the Customer to follow instructions given by SLT in this regard.    
                </div>
                <div className='discription'>
                <strong>C.PEO TV Service </strong> (Further to Clause No. 4.3 of the Application for New Services ) Content and Features of the Service 
    
                </div>
                <div className='discription'>
                a. SLT reserves the right to change the channels and/or content in any package offered including but not limited to any other features offered under the Service with or without notice to the Customer. SLT also reserves the right to enhance, add, downgrade and/or delete any features in the Service at its sole discretion. 
                </div>
                <div className='discription'>
                b. PEO TV service is provided to the Customer with the Parental Control feature which is a special feature in the Electronic Programme Guide (EPG). Accordingly, the Customer shall be fully responsible in operating the said special feature in respect of the content being broadcast. Parental Control shall mean the ability to control/hide or block channels according to your preference, restricting children from watching PEO TV channels not suitable for them.     
                </div>
                <div className='discription'>
                c. SLT reserves the right to charge for any extra Content ordered through the EPG.    
                </div>
                <div className='discription'>
                d. The Customer shall be committed for a minimum period of one (01) month for the channels selected on a-la-carte basis, for billing purposes.     
                </div>
                <div className='discription'>
                e. The content provided herein are protected under copyright laws and the Customer shall not cause to copy or duplicate or reproduce the content being broadcast through the Service and the Customer shall be held liable for any act of such unauthorized copying or duplication or re-broadcasting    
                </div>

                {/* <div className='discription'>
                    
                </div>
                <div className='discription'>
                    
                </div> */}
                <div className='d-flex justify-content-end mb-3 clearfix'>
                    <button className='medium noline blue mr-1' onClick={() => {
                        setShowTerms(false)
                        setAgree(true)
                    }}>Accept
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button className='medium noline blue' onClick={() => {
                        setShowTerms(false)
                        setAgree(false)
                    }}>Close
                    </button>


                </div>

            </div>

        </div>
    );


}

export default TermsConditons;
