import React from 'react'
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { MAP_KEY } from '../../../redux/MAPKEYS';

const SearchFeasibility = ({searchLatLon, setValueLocSearch, valueLocSearch}) => {
  return (
    <ReactGoogleAutocomplete className='search'

        apiKey={MAP_KEY}
        onPlaceSelected={(place) => {
            searchLatLon(place)
            setValueLocSearch(place.name)

        }}
        options={{
            types: ["geocode", "establishment"],
            componentRestrictions: {country: "lk"},
            fields: ["address_components", "geometry", "icon", "name"],
        }}
        onChange={(event => setValueLocSearch(event.target.value))}
        value={valueLocSearch}

    />
  )
}

export default SearchFeasibility