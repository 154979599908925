import {ERROR_LOGIN, LOG_OUT, REQUEST_LOGIN, REQUEST_SUCCESS, SETPUBLICTOINITIALSTATE, SUCCESS_LOGIN} from "../Types";

let token = localStorage.getItem('slt_accessToken')

const initialState = token ? { loggedIn: true, token , loading: false, error: '' } : {loading: false,loggedIn: false, error: ''};


export default function loginReducer(state = initialState,action){
    switch (action.type){

        case REQUEST_LOGIN: {
            return {
                loading: true
            }
        }

        case SUCCESS_LOGIN: {
            return {
                loading: false,
                loggedIn: true
            }
        }

        case REQUEST_SUCCESS : {
            return {
                ...state,
                loading: false
            }
        }

        case ERROR_LOGIN: {
            return {
                loading: false,
                error: action.payload
            }
        }

        case LOG_OUT: {
            return {
                loggedIn: false
            }
        }

        case SETPUBLICTOINITIALSTATE: {
            return {
                loading: false,
                error: ''
            }
        }

        default :
            return state
    }
}
